import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const HerosectionHeader = ({ folder1, folder2, name }) => {
  const backgroundImage = require('../../assets/images/bg/02.png');

  return (
    <>
     <section className="hero-banner position-relative custom-pt-0.5 custom-pb-0.5 bg-dark" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="container">
          <div className="row text-white text-center z-index-1">
            <div className="col">
              <h1 className="text-white">{name}</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HerosectionHeader;
