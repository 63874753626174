import React, { useState, FormEvent, useCallback, useEffect } from 'react';
import { Col, Nav, NavItem, NavLink, TabContent, TabPane, Row, FormGroup, Input } from 'reactstrap';
import PhoneMaskInput from '../../../../Componet/PhoneMaskInput';
import Modal from 'react-bootstrap/Modal';
import HerosectionHeader from '../../../../Componet/Herosection/HerosectionHeader'
import { useDropzone } from "react-dropzone";
import useAuth from '../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useSpinner } from '../../../../context/SpinnerContext';
import LocationAutocomplete from '../../../../Componet/Autocomplete/LocationAutocomplete';
import Options from '../../../../Componet/Option/Options';
import { States } from '../../../../store/Utility/Data';

const InitialData = {
  routingNumber: "",
  accountNumber: ""
}

const initialProfileData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: '',
  addressUnit: null,
  city: '',
  state: '',
  zipCode: '',
  experiencedBrandFirst: '',
  experiencedBrandSecond: '',
  experiencedBrandThird: '',
  serviceZipCode: '',
  serviceAddress: null,
  serviceLatitude: null,
  serviceLongitude: null
}

const Profile = () => {
  const [activeTab, setActiveTab] = useState('tab1-1');

  const [formData, setFormData] = useState(InitialData);
  const [profileData, setProfileData] = useState(initialProfileData);
  const [isEdit, setIsEdit] = useState((formData.routingNumber && formData.accountNumber) != '');
  const [isProfileEdit, setProfileEdit] = useState(false);
  const [isAddressEdit, setAddressEdit] = useState(false);
  const [isWorkPreferenceEdit, setWorkPreferenceEdit] = useState(false);
  const [isPayoutEdit, setPayoutEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState((formData.routingNumber && formData.accountNumber) != '');
  const [profilePhotoPreview, setProfilePhotoPreview] = useState();
  const [modal, showModal] = useState({ show: false, message: null });
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const { showLoader, hideLoader } = useSpinner();

  const [validationError, setValidationError] = useState({});


  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    const file = new FileReader;
    file.readAsDataURL(acceptedFiles[0]);
    file.onload = function () {
      setProfilePhotoPreview(file.result);
    }
  }, [])
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop, maxFiles: 1,
    accept: {
      'image/jpeg': [],
      'image/png': []
    }
  });

  const onPlaceChanged = (place) => {

    const addressComponents = place.address_components;


    var result = {
      city: profileData.city,
      state: profileData.state,
      zipCode: profileData.zipCode,
      address: profileData.address
    };

    const state = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
    if (state != null) {
      result.state = state.short_name;
    }
    const city = addressComponents.find(component => component.types.includes('locality'));
    if (city != null) {
      result.city = city.short_name;
    }
    const postalCode = addressComponents.find(component => component.types.includes('postal_code'));

    if (place.formatted_address) {
      result.address = place.formatted_address;
    }

    if (postalCode) {
      result.zipCode = postalCode.short_name;
    }

    if (place.geometry.location) {
      result.serviceLatitude = place.geometry.location.lat();
      result.serviceLongitude = place.geometry.location.lng();
    }

    if (Object.keys(result).length > 0) {
      updateProfileFields(result);
    }

  }

  const onServiceZipCodePlaceChanged = (place) => {
    if (!place) return;

    var result = {
      serviceAddress: profileData.serviceAddress,
      serviceLatitude: profileData.serviceLatitude,
      serviceLongitude: profileData.serviceLongitude,
      serviceZipCode: profileData.zipCodes
    };

    const addressComponents = place.address_components;

    if (place.formatted_address) {
      result.serviceAddress = place.formatted_address;
    }

    const postalCode = addressComponents.find(component => component.types.includes('postal_code'));

    if (place.geometry.location) {
      result.serviceLatitude = place.geometry.location.lat();
      result.serviceLongitude = place.geometry.location.lng();
    }

    if (postalCode) {
      result.serviceZipCode = postalCode.short_name
      updateProfileFields(result);
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }

  const handleEditClick = (event) => {
    setIsDisabled(false);
    setIsEdit(false);
  }

  const handlePasswordResetSubmit = (e: FormEvent) => {
    e.preventDefault();
    let errors = {};
    if (e.target.elements.currentPassword.value == '') errors.currentPassword = e.target.elements.currentPassword.attributes["data-error"].value;
    if (e.target.elements.newPassword.value == '') errors.newPassword = e.target.elements.newPassword.attributes["data-error"].value;
    if (e.target.elements.confirmNewPassword.value == '') errors.confirmNewPassword = e.target.elements.confirmNewPassword.attributes["data-error"].value;

    setValidationError(errors);
    if (Object.keys(errors).length > 0) return;
    alert("TODO Save")
  }

  function onSubmit(e) {
    e.preventDefault();
    setIsEdit(((formData.accountNumber && formData.routingNumber) != ''));
    setIsDisabled((formData.accountNumber && formData.routingNumber) != '');
  }
  const handleStateChange = (e) => {
    updateProfileFields({ state: e.target.value })
  }

  function updateProfileFields(fields) {
    setProfileData(prev => {
      return { ...prev, ...fields }
    })
  }
  const onProfileSubmit = async (e) => {
    e.preventDefault();
    showLoader();
    await axiosPrivate.post(`v1/inspector/update-profile`, { userId: auth?.user.id, phone: profileData.phone })
      .then(res => {
        if (res?.data.success) {
          showModal({ show: true, message: "Phone successfully updated." });
        }
        hideLoader();
      }).catch(error => {
        hideLoader();
        showModal({ show: true, message: "An error occured." });
      });
    setProfileEdit(false);
  }

  const onWorkPreferenceSubmit = async (e) => {
    e.preventDefault();
    showLoader();
    await axiosPrivate.post(`v1/inspector/update-workpreference`,
      {
        userId: auth?.user.id,
        experiencedBrandFirst: profileData.experiencedBrandFirst,
        experiencedBrandSecond: profileData.experiencedBrandSecond,
        experiencedBrandThird: profileData.experiencedBrandThird,
        serviceZipCode: profileData.serviceZipCode,
        serviceAddress: profileData.serviceAddress,
        serviceLatitude: profileData.serviceLatitude,
        serviceLongitude: profileData.serviceLongitude
      }
    )
      .then(res => {
        if (res?.data.success) {
          showModal({ show: true, message: "Work preferences successfully updated." });
        }
        hideLoader();
      }).catch(error => {
        hideLoader();
        showModal({ show: true, message: "An error occured." });
      });
    setWorkPreferenceEdit(false);
  }

  const onAddressSubmit = async (e) => {
    e.preventDefault();
    showLoader();
    await axiosPrivate.post(`v1/inspector/update-address`,
      {
        userId: auth?.user.id,
        address: profileData.address,
        city: profileData.city,
        state: profileData.state,
        zipCode: profileData.zipCode,
        addressUnit: profileData.addressUnit
      }).then(res => {
        if (res?.data.success) {
          showModal({ show: true, message: "Your address information has been successfully updated." });
        }
        hideLoader();
      }).catch(error => {
        hideLoader();
        showModal({ show: true, message: "An error occured." });
      });
    setAddressEdit(false);
  }

  const onSavePhoto = async (e) => {
    e.preventDefault();
    showLoader();
    const profileFormData = new FormData();
    profileFormData.append('ProfileImage', acceptedFiles[0]);
    profileFormData.append('id', auth?.user.id);

    const response = await axiosPrivate.post(`v1/user/update-photo`, profileFormData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
      hideLoader();
      showModal({ show: true, message: "Your profile picture has been successfully updated!" })
    }).catch(error => {
      hideLoader();
      showModal({ show: true, message: "An error occured while saving" })
    });
  }

  const onPayoutSubmit = async (e) => {
    e.preventDefault();
    showLoader();
    await axiosPrivate.post(`v1/inspector/update-payout`,
      {
        userId: auth?.user.id,
        accountNumber: profileData.accountNumber,
        routingNumber: profileData.routingNumber
      }
    )
      .then(res => {
        if (res?.data.success) {
          showModal({ show: true, message: "Your payout preferences successfully updated." });
        }
        hideLoader();
      }).catch(error => {
        hideLoader();
        showModal({ show: true, message: "An error occured." });
      });
    setPayoutEdit(false);
  }

  const onReferFriendSubmit = async (e) => {
    e.preventDefault();
  }

  const toggleTab = (tabId) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
    }
  };

  const handleClose = () => showModal({ show: false, message: null });

  const getInspectorProfileDetail = async () => {
    await axiosPrivate.get(`v1/inspector/profile/${auth?.user.id}`).then(res => {
      var response = res?.data.data;
      response.name = `${response.firstName} ${response.lastName}`;
      setProfileData(response);
      setFormData({ accountNumber: res?.data.data.accountNumber, routingNumber: res?.data.data.routingNumber });
    }).catch(error => {
      if (error.response.data.statusCode = 404) {
        showModal({ show: true, message: "No data found" })
      }
    });
  };

  useEffect(() => {
    getInspectorProfileDetail();
  }, []);


  return (
    <div>
      <HerosectionHeader folder1={"pages"} folder2={"Company"} name={"Profile"} />
      {<Modal show={modal.show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Result</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal.message}</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-dark" onClick={handleClose}>Close</button>
        </Modal.Footer>
      </Modal>}
      <section>
        <div className="container">
          {/* ... (rest of the code) ... */}
          <div className="col">
            <Row className="tab row">
              <Col lg="4">
                <Nav tabs className="nav nav-tabs bg-white shadow border-0 p-4">
                  <NavItem className="w-100">
                    <NavLink
                      className={`nav-item nav-link mb-4 mx-0 ${activeTab === 'tab1-1' ? 'active' : ''}`}
                      onClick={() => toggleTab('tab1-1')}
                    >
                      Profile
                    </NavLink>
                  </NavItem>
                  <NavItem className="w-100">
                    <NavLink
                      className={`nav-item nav-link mb-4 mx-0 ${activeTab === 'tab1-2' ? 'active' : ''}`}
                      onClick={() => toggleTab('tab1-2')}
                    >
                      Address
                    </NavLink>
                  </NavItem>
                  <NavItem className="w-100">
                    <NavLink
                      className={`nav-item nav-link mb-4 mx-0 ${activeTab === 'tab1-3' ? 'active' : ''}`}
                      onClick={() => toggleTab('tab1-3')}
                    >
                      Work Preference
                    </NavLink>
                  </NavItem>
                  <NavItem className="w-100">
                    <NavLink
                      className={`nav-item nav-link w-100 mb-4 mx-0 ${activeTab === 'tab1-4' ? 'active' : ''}`}
                      onClick={() => toggleTab('tab1-4')}
                    >
                      Password
                    </NavLink>
                  </NavItem>
                  <NavItem className="w-100">
                    <NavLink
                      className={`nav-item nav-link w-100 mb-4 mx-0 ${activeTab === 'tab1-5' ? 'active' : ''}`}
                      onClick={() => toggleTab('tab1-5')}
                    >
                      Payout
                    </NavLink>
                  </NavItem>
                  <NavItem className="w-100">
                    <NavLink
                      className={`nav-item nav-link w-100 mb-4 mx-0 ${activeTab === 'tab1-6' ? 'active' : ''}`}
                      onClick={() => toggleTab('tab1-6')}
                    >
                      Refer a Friend
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="8" mt="5 mt-lg-0">
                <TabContent activeTab={activeTab} id="nav-tabContent">
                  <TabPane tabId="tab1-1">

                    <div className="row justify-content-center text-center">
                      <div className="col-lg-10 col-md-12">
                        <div className="mb-5">
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-10 ms-auto me-auto">
                        <div className="register-form border p-4 rounded">
                          <form id="user-form">
                            <div className="messages"></div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">

                                  <div name="profilePhotoInput" className="dropzone" {...getRootProps()} style={{ height: "100px" }} >
                                    <input {...getInputProps()} />
                                    {
                                      acceptedFiles.length > 0 ? <p>{acceptedFiles.length} file selected</p> :
                                        isDragActive ?
                                          <p>Drop the files here ...</p> :
                                          <p>Drag 'n' drop your image here, or click to select files </p>
                                    }
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  {profilePhotoPreview ? <img src={profilePhotoPreview} style={{ borderRadius: "50%", height: "100px", width: "100px", border: "1px solid #f1f1f1" }} /> :
                                    <img src={auth.user.profile} style={{ borderRadius: "50%", height: "100px", width: "100px", border: "1px solid #f1f1f1" }} />
                                  }
                                </div>

                              </div>
                              {acceptedFiles.length > 0 && <div className="col-md-2 align-self-end">
                                <div className="form-group">
                                  <button className='btn btn-primary btn-sm' onClick={onSavePhoto}>Save Photo</button>
                                </div>
                              </div>}
                            </div>
                            <div className='my-2' style={{ borderTop: '1px solid #dee2e6' }}></div>
                            <div className="row form">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label for="name">Name </label>
                                  <input id="name"
                                    type="text"
                                    name="name"
                                    value={profileData.firstName}
                                    className="form-control"
                                    disabled
                                    placeholder="Name"
                                    required="required"
                                    data-error="Firstname is required." />
                                  <div className="help-block with-errors"></div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label for="surname">Last Name </label>
                                  <input id="surname" type="text" name="surname" value={profileData.lastName} className="form-control" disabled placeholder="Last name" required="required" data-error="Lastname is required." />
                                  <div className="help-block with-errors"></div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label for="email">Email </label>
                                  <input id="email" type="email" name="email" value={profileData.email} className="form-control" disabled placeholder="Email" required="required" data-error="Valid email is required." />
                                  <div className="help-block with-errors"></div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label for="phone">Phone </label>
                                  <PhoneMaskInput name="phone"
                                    value={profileData.phone}
                                    onChange={(e) => updateProfileFields({ phone: e.target.value })}
                                    disabled={!isProfileEdit}
                                    className="form-control" />
                                  <div className="help-block with-errors"></div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                              </div>
                              <div className="col-md-6">
                              </div>
                              <div className="col-md-2">
                                {!isProfileEdit && <button type='button' className='btn btn-primary' onClick={() => { setProfileEdit(true) }}>Edit</button>}
                                {isProfileEdit && <button type='button' className='btn btn-dark' onClick={onProfileSubmit}>Save</button>}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="tab1-2">

                    <div className="row justify-content-center text-center">
                      <div className="col-lg-10 col-md-12">
                        <div className="mb-5">
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-10 ms-auto me-auto">
                        <div className="register-form border p-4 rounded">
                          <form id="contact-form">
                            <div className="messages"></div>
                            <div className="row">
                              <div className="col-md-9">
                                <div className="form-group">
                                  <label for="address">Address </label>
                                  <LocationAutocomplete disabled={!isAddressEdit} onPlaceChanged={onPlaceChanged} defaultValue={profileData.address} />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label for="addressUnit">Unit/Apt # </label>
                                  <input id="addressUnit" type="addressUnit" disabled={!isAddressEdit} name="addressUnit"
                                    className="form-control" placeholder="Unit/Apt #"
                                    value={profileData.addressUnit}
                                    onChange={e => updateProfileFields({ addressUnit: e.target.value })} />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="city">City </label>
                                  <input id="city" type="text" name="city" className="form-control"
                                    value={profileData.city}
                                    onChange={e => updateProfileFields({ city: e.target.value })}
                                    disabled placeholder="City" required="required" data-error="Valid email is required." />
                                  <div className="help-block with-errors"></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="state">State </label>
                                  <Input
                                    type="select"
                                    id="requesterState"
                                    name="requesterState"
                                    value={profileData.state}
                                    className="form-control"
                                    onChange={handleStateChange}
                                    disabled
                                  >
                                    <Options data={States} />
                                  </Input>
                                  <div className="help-block with-errors"></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="zipCode">Zip Code </label>
                                  <input id="zipCode" type="text" name="zipCode"
                                    className="form-control"
                                    minLength={5}
                                    maxLength={5}
                                    value={profileData.zipCode}
                                    onChange={e => updateProfileFields({ zipCode: e.target.value })}
                                    disabled placeholder="Zip Code" required="required" data-error="Valid email is required." />
                                  <div className="help-block with-errors"></div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-4">
                              </div>
                              <div className="col-md-6">
                              </div>
                              <div className="col-md-2">
                                {!isAddressEdit && <button type='button' className='btn btn-primary' onClick={() => { setAddressEdit(true) }}>Edit</button>}
                                {isAddressEdit && <button type='button' className='btn btn-dark' onClick={onAddressSubmit}>Save</button>}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="tab1-3">
                    <div className="row justify-content-center text-center">
                      <div className="col-lg-10 col-md-12">
                        <div className="mb-5">
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-10 ms-auto me-auto">
                        <div className="register-form border p-4 rounded">
                          <form id="experience-form">
                            <div className="messages"></div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="experiencedBrandFirst">Experienced Brand 1 </label>
                                  <input id="experiencedBrandFirst" type="text" name="experiencedBrandFirst" className="form-control"
                                    value={profileData.experiencedBrandFirst}
                                    onChange={e => updateProfileFields({ experiencedBrandFirst: e.target.value })}
                                    disabled={!isWorkPreferenceEdit} placeholder="Experienced Brand 1" required="required" data-error="Valid email is required." />
                                  <div className="help-block with-errors"></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="experiencedBrandSecond">Experienced Brand 2 </label>
                                  <input id="experiencedBrandSecond" type="text" name="experiencedBrandSecond" className="form-control"
                                    value={profileData.experiencedBrandSecond}
                                    onChange={e => updateProfileFields({ experiencedBrandSecond: e.target.value })}
                                    disabled={!isWorkPreferenceEdit} placeholder="Experienced Brand 2" required="required" data-error="Phone is required" />
                                  <div className="help-block with-errors"></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="experiencedBrandThird">Experienced Brand 3 </label>
                                  <input id="experiencedBrandThird" type="text" name="experiencedBrandThird" className="form-control"
                                    value={profileData.experiencedBrandThird}
                                    onChange={e => updateProfileFields({ experiencedBrandThird: e.target.value })}
                                    disabled={!isWorkPreferenceEdit} placeholder="Experienced Brand 3" required="required" data-error="Valid email is required." />
                                  <div className="help-block with-errors"></div>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="form-group">
                                  <label for="serviceZipCode">Service Base Address </label>
                                  <LocationAutocomplete disabled={!isWorkPreferenceEdit} defaultValue={profileData.serviceAddress} onPlaceChanged={onServiceZipCodePlaceChanged} />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="serviceZipCode">Service Zip Code </label>
                                  <input id="serviceZipCode" type="text" name="serviceZipCode" className="form-control"
                                    value={profileData.serviceZipCode}
                                    onChange={e => updateProfileFields({ serviceZipCode: e.target.value })}
                                    disabled placeholder="Service Area (Zip Code)" required="required" />
                                  <div className="help-block with-errors"></div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-10">
                                <span>Update your service base address here for accurate distance measurements to inspection locations and to view available orders in your area.</span>
                              </div>
                              <div className="col-md-2">
                                {!isWorkPreferenceEdit && <button type='button' className='btn btn-primary' onClick={() => { setWorkPreferenceEdit(true) }}>Edit</button>}
                                {isWorkPreferenceEdit && <button type='button' className='btn btn-dark' onClick={onWorkPreferenceSubmit}>Save</button>}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="tab1-4">
                    <div className="row justify-content-center text-center">
                      <div className="col-lg-10 col-md-12">
                        <div className="mb-5">
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-10 ms-auto me-auto">
                        <div className="register-form border p-4 rounded">
                          <form onSubmit={handlePasswordResetSubmit}>
                            <div className="messages"></div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="currentPassword">Current Password</label>
                                  <input id="currentPassword" type="password" name="currentPassword" className="form-control" placeholder="Current Password" required data-error="Current Password is required." />
                                  <div className="help-block with-errors"></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="newPassword">New Password</label>
                                  <input id="newPassword" type="password" name="newPassword" className="form-control" placeholder="New Password" required data-error="Password is required." />
                                  <div className="help-block with-errors"></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="confirmNewPassword">Confirm New Password</label>
                                  <input id="confirmNewPassword" type="password" name="confirmNewPassword" className="form-control" placeholder="Confirm New Password" required data-error="Confirm Password is required." />
                                  <div className="help-block with-errors"></div>
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div style={{ color: "red", fontSize: "0.9rem" }}>
                                {Object.keys(validationError).length > 0 ? Object.values(validationError).map(item => <div className="row"><span>* {item}</span></div>) : null}
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-9'></div>
                              <div className="col-md-3">
                                <button type="submit" className="btn btn-dark">Reset Password</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="tab1-5">
                    <div className="row justify-content-center">
                      <div className="col-lg-10 col-md-12">
                        <div className="mb-5">
                        </div>
                      </div>
                    </div>
                    <Row >
                      <Col lg={12} md={12}>
                        <div className="checkout-form border p-4 rounded">
                          <h2 className="mb-4">Payout Account</h2>
                          <div className="col-lg-12 col-12">
                            <p className="lead mb-5">Please enter your banking details to ensure timely processing of your payments!</p>
                          </div>
                          <form onSubmit={onPayoutSubmit}>
                            <Row form>
                              <Col md={6}>
                                <FormGroup>
                                  <label for="routingNumber">Routing Number</label>
                                  <Input
                                    type="text"
                                    id="routingNumber"
                                    name="routingNumber"
                                    placeholder="Routing Number"
                                    value={profileData.routingNumber}
                                    disabled={!isPayoutEdit}
                                    onChange={e => updateProfileFields({ routingNumber: e.target.value })}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <label for="accountNumber">Account Number</label>
                                  <Input
                                    type="text"
                                    id="accountNumber"
                                    name="accountNumber"
                                    placeholder="Account Number"
                                    value={profileData.accountNumber}
                                    disabled={!isPayoutEdit}
                                    onChange={e => updateProfileFields({ accountNumber: e.target.value })}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={10}></Col>
                              <Col md={2}>
                                {!isPayoutEdit ? <button type="button" className="btn btn-primary" onClick={() => setPayoutEdit(true)}>Edit</button> : null}
                                {isPayoutEdit ? <button type="submit" className="btn btn-dark">Save</button> : null}
                              </Col>
                            </Row>

                          </form>

                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <div className="mb-5">
                        <p className="lead mb-0"><strong><b>Keep your account safe:</b></strong> <mark>inspectiver</mark> will never call to request a change to your payout account information</p>
                      </div>
                    </Row>
                  </TabPane>
                  <TabPane tabId="tab1-6">
                    <div className="row justify-content-center">
                      <div className="col-lg-10 col-md-12">
                        <div className="mb-5">
                        </div>
                      </div>
                    </div>
                    <Row >
                      <Col lg={12} md={12}>
                        <div className="checkout-form border p-4 rounded">
                          <h2 className="mb-4">Refer a Friend</h2>
                          <div className="col-lg-12 col-12">
                            <p className="lead mb-5">Refer an inspector and you’ll get $200 if they complete 10 inspections after signing up with your referral code.</p>
                          </div>
                          <form onSubmit={onReferFriendSubmit}>
                            <Row form className='row align-items-end'>
                              {/* <Col md={6}>
                              <div class="input-group ">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon1">Your Code</span>
                                </div>
                                <input type="text" style={{ border: '1px solid #ced4da', marginLeft: '-5px', borderTopRightRadius: '0.375rem', borderBottomRightRadius: '0.375rem', textAlign:'center' }} value={profileData.inspectorCode} aria-label="Username" aria-describedby="basic-addon1" />
                              </div>
                              </Col>
                              <Col md={2}>
                              <button className='btn btn-secondary btn-sm' >Copy Code</button>
                              </Col>
                              <Col md={2}>
                              <span>Copy Link</span>
                               </Col> */}





                              <Col md={4}>
                                <FormGroup>
                                  <label for="inspectorCode">Your Code</label>
                                  <Input
                                    type="text"
                                    id="inspectorCode"
                                    name="inspectorCode"
                                    placeholder="Routing Number"
                                    value={profileData.inspectorCode}
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={2}>
                                <FormGroup>
                                  <button className="btn btn-primary btn-sm" onClick={() => { navigator.clipboard.writeText(profileData.inspectorCode) }}>Copy Code</button>
                                </FormGroup>
                              </Col>
                              <Col md={2}>
                                <FormGroup>
                                  <button className="btn btn-primary btn-sm" onClick={() => { navigator.clipboard.writeText(`${window.location.origin}/inspector?code=${profileData.inspectorCode}`) }}>Copy Link</button>
                                </FormGroup>
                              </Col>
                            </Row>
                            {/* <Row>
                              <Col md={10}></Col>
                              <Col md={2}>
                               <button type="submit" className="btn btn-dark">Save</button>
                              </Col>
                            </Row> */}
                          </form>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </div>
        </div >
      </section >

    </div >
  )
}

export default Profile;