import React, { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import {
    Col,
    Row
} from "reactstrap";

import moment from "moment";
import { defaultIfNull } from "../../../store/Utility/Helper";
import HerosectionHeader from "../../../Componet/Herosection/HerosectionHeader";
import { QuotationStatusData } from "../../../store/Utility/Utility";
import { QuotationStatus, OrderStatus } from "../../../store/Utility/Status";
import EmptyDataResult from "../../../Componet/EmptyDataResult";
import VehicleTypeIcon from "../../../Componet/VehicleTypeIcon";
import SpinnerButton from "../../../Componet/Button/SpinnerButton";
import { VehicleTypes } from "../../../store/Utility/Status";

import Modal from "react-bootstrap/Modal";


const OrderList = () => {
    const [orders, setOrders] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(OrderStatus.AwaitingApproval);
    const [loading, setLoading] = useState(false);
    const [completeModal, setCompleteModal] = useState({ show: false, header: null, message: null, isError: null });
    const [completeModalValidation, setCompleteModalValidation] = useState({ show: false });
    const [validationError, setValidationError] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const orderNumber = useRef();
    const customerEmail = useRef();

    const fetchOrders = async () => {
        setLoading(true);
        var selectedItem = QuotationStatusData.find(t => t.id == selectedStatus);
        if (!selectedItem) return;
        await axiosPrivate.get(`v1/quotation/orders`,
            {
                params:
                {
                    status: selectedStatus,
                    customerEmail: customerEmail.current.value,
                    orderNumber: orderNumber.current.value,
                    orderBy: selectedItem.sorting
                },
            }).then(res => {
                setOrders(res.data.data);
                setLoading(false);
            });

    };

    const onSearchClick = (e) => {
        if (loading) return;
        fetchOrders();
    }


    const handleOptionChange = (e) => {
        setSelectedStatus(e.target.value);
    };


    const onCancelOrder = (id) => {
        //TODO
    }

    const handleInspectionReport = async (vehicleType, id) => {

        let path = '';
        if (vehicleType == VehicleTypes.Car) path = 'car';
        if (vehicleType == VehicleTypes.Motorcycle) path = 'motorcycle';
        if (vehicleType == VehicleTypes.Commercial) path = 'commercial';
        if (vehicleType == VehicleTypes.RV) path = 'rv';

        if (path != '') {
            navigate(`/orders/${path}/${id}`);
        }
    }

    const onCompleteOrder = async (id) => {
        setLoading(true);
        const response = await axiosPrivate.post(`v1/quotation/complete/${id}`).then(res => {
            setLoading(false);
            if (res.data.data) {
                setCompleteModal({ show: true, isResult: true, id: id, header: "Complete Order", message: "Order has been successfully completed" });
            } else {
                setCompleteModal({ show: true, isResult: true, id: id, header: "Error", message: "Order could not be completed!" })
            }
            // showModal({ show: true, message: "Report successfully approved", isError: false });
        }).catch(error => {
            setLoading(false);
            setCompleteModal({ show: true, id: id, isResult: true, header: "Error", message: "An unexpected error occured while saving!" })
            // showModal({ show: true, message: "An error occurred while approving report", isError: true });
        });

    }

    const handleCarFaxReport = (id) => {
        navigate(`/orders/carfax/${id}`);
    }

    const handleOfferedInspector = (id) => {
        navigate(`/orders/offered/${id}`);
    }

    const handleClose = () => setCompleteModal({ show: false, id: null, header: null, message: null, isSuccess: null });

    const handleCompleteModal = async (id) => {
        let selectedOrder = orders.find(q => q.id == id);
        if (selectedOrder != null) {
            let error = {};
            if (selectedOrder.isCarfaxReportRequested && !selectedOrder.isCarfaxUploaded) {
                error.carfaxUpload = "Upload the Carfax report";
            }
            if (selectedOrder.status != QuotationStatus.InspectionApproved) {
                error.isnpectionApproved = "Approve the report";
            }
            if (Object.keys(error).length != 0) {
                setValidationError(error);
                setCompleteModalValidation({ show: true });
                return 0;
            }
        } else { return; }

        setCompleteModal({ show: true, id: id, isResult: false, header: "Complete Order", message: "All necessary tasks have been completed. Are you sure you want to complete the order? This action cannot be undone" })
    }

    useEffect(() => {
        fetchOrders();
    }, [])

    return (<>
        <HerosectionHeader name={"Orders"} />
        {<Modal show={completeModalValidation.show} onHide={() => setCompleteModalValidation({ show: false })} centered>
            <Modal.Header closeButton>
                <Modal.Title>Incomplete Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>Please complete these tasks before proceeding.</Row>
                <Row>
                    <Col md={10}>
                        <div style={{ color: "red", fontSize: "0.9rem" }}>
                            {Object.keys(validationError).length > 0 ? Object.values(validationError).map(item => <div className="row"><span>* {item}</span></div>) : null}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-dark" onClick={() => setCompleteModalValidation({ show: false })}>Close</button>
            </Modal.Footer>
        </Modal>}
        {<Modal show={completeModal.show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{completeModal.header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{completeModal.message}</Modal.Body>
            <Modal.Footer>
                {!completeModal.isResult ? <Col>
                    <Row>
                        <Col md={6}></Col>
                        <Col md={2}><button className="btn btn-dark" onClick={handleClose}>No</button></Col>
                        <Col md={4}>
                            <SpinnerButton text="Yes" onClick={() => onCompleteOrder(completeModal.id)} className="btn btn-danger" loading={loading} />
                        </Col>
                    </Row>
                </Col> :
                    <button className="btn btn-dark" onClick={handleClose}>Close</button>
                }
            </Modal.Footer>
        </Modal>}
        <div class="container">
            <Row>
                <Col md={2}> <div className="mt-5 d-flex align-items-center"><input type="text" ref={orderNumber} className="form-control" name="orderNumber" placeholder="Order Number" /></div></Col>
                <Col md={2}><div className="mt-5 d-flex align-items-center"><input type="text" ref={customerEmail} className="form-control" name="customerEmail" placeholder="Customer Email" /></div></Col>
                <Col md={2}></Col>
                <Col md={2}></Col>
                <Col md={2}>
                    <div className="mt-5 d-flex align-items-center">
                        <select
                            className="form-select"
                            id="inputGroupSelect02"
                            onChange={handleOptionChange}
                            value={selectedStatus}
                        >
                            {QuotationStatusData && QuotationStatusData.length > 0 && QuotationStatusData.map((m) => (
                                <option value={m.id}>{m.displayName}</option>
                            ))}
                        </select>
                    </div>
                </Col>
                <Col md={2}>
                    <div className="mt-5 d-flex align-items-end">
                        {/* <button type="button" className="btn btn-primary btn-sm" onClick={onSearchClick} >Search</button> */}
                        <SpinnerButton type="button" className="btn btn-primary btn-sm" loading={loading} onClick={onSearchClick} text="Search" />
                    </div>
                </Col>
            </Row>
            <div class="row">
                <div class="col">
                    {(orders && orders.length > 0) ?
                        orders.map((item, index) => ((
                            <div class="card bg-transparent flex-md-row align-items-center p-md-0 3 mt-3 mb-3">
                                <div class="col-md-2">
                                    <div className="bg-primary p-2 d-inline-block rounded m-4">                                       
                                        <div className="f-icon-m text-white">
                                            <VehicleTypeIcon vehicleType={item.vehicleType} />
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body col-md-8">
                                    <div>
                                        <div class="d-inline-block bg-light text-center px-2 py-1 rounded me-2">
                                            {/* <span class="text-primary">{VehicleTypes.find(t => t.id == job.vehicleType).name}</span> */}
                                        </div>
                                        <div><span><strong>Order - Created At: </strong>{defaultIfNull(item.orderNumber)} - {moment(item.createdDate).format('MM.DD.YYYY HH:mm')}</span></div>
                                        <div><span><strong>{item.make} / {item.model} / {item.year}</strong></span></div>
                                        <div><span><strong>Plate: </strong>{defaultIfNull(item.licensePlateActual)}</span></div>
                                        <div><span><strong>VIN: </strong>{defaultIfNull(item.vinActual)}</span></div>
                                        <div><span><strong>CARFAX Requested: </strong>{item.isCarfaxReportRequested ? 'Yes' : 'No'}</span></div>
                                        <div><span><strong>Customer: </strong>{item.requesterFirstName} {item.requesterLastName}</span></div>
                                        <div><span><strong>Customer Address: </strong>{item.requesterAddress}</span></div>
                                        <div><span><strong>Customer  Phone: </strong>{item.requesterPhone}</span></div>
                                        <div><span><strong>Customer E-mail: </strong>{item.requesterEmail}</span></div>
                                        <div><span><strong>Seller: </strong>{item.sellerContactName}</span></div>
                                        <div><span><strong>Seller Phone: </strong>{item.sellerPhone}</span></div>
                                        <div><span><strong>Vehicle Address: </strong>{item.sellerAddress}</span></div>
                                        <div><span><strong>Notes from Customer: </strong>{item.requesterNote}</span></div>
                                        <div><span><strong>Assiggned Inspector: </strong>{item.assignedInspector}{item.inspectorAssignedDate != null ? `, assigned at ${moment(item.inspectorAssignedDate).format('MM.DD.YYYY HH:mm')}` : null}</span></div>
                                        <div><span><strong>Appointment Date: </strong>{item.appointmentDate != null ? moment(item.appointmentDate).format('MM.DD.YYYY HH:mm') : 'N/A'}</span></div>
                                        <div><span><strong>Inspection Started At: </strong>{item.inspectionStartDate != null ? moment(item.inspectionStartDate).format('MM.DD.YYYY HH:mm') : 'N/A'}</span></div>
                                        <div><span><strong>Inspection Ended At: </strong>{item.inspectionEndDate != null ? moment(item.inspectionEndDate).format('MM.DD.YYYY HH:mm') : 'N/A'}</span></div>
                                        <div><span><strong>Report Sent to Customer At: </strong>{item.completeOrderDate != null ? moment(item.completeOrderDate).format('MM.DD.YYYY HH:mm') : 'N/A'}</span></div>

                                    </div>
                                    {/* <h2 class="h5 my-3">
                                        <a class="link-title">{item.firstName} {item.lastName}</a>
                                    </h2>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item pe-3" key={index}>
                                            <a class="list-group-item-action">
                                                <i class="lar la-calendar-info ms-1 text-primary ic-1x"></i>
                                                {moment(item.createdDate).format('MM.DD.YYYY')}
                                            </a>
                                        </li>
                                    </ul> */}
                                </div>
                                <div class="col-md-2">
                                    <div class="d-flex flex-column gap-2 m-2">
                                        <button type="button" className={(item.status == QuotationStatus.InspectionApproved || item.status == QuotationStatus.Completed) ? "btn btn-primary" : item.status < QuotationStatus.Inspected ? "btn btn-dark" : "btn btn-warning"}
                                            disabled={item.status < QuotationStatus.Inspected} onClick={() => handleInspectionReport(item.vehicleType, item.id)} >{(item.status == QuotationStatus.InspectionApproved || item.status == QuotationStatus.Completed) ? "View Report" : "Inspection Report"}</button>
                                        {item.isCarfaxReportRequested && <button type="button" className={item.isCarfaxUploaded ? "btn btn-primary" : item.status < QuotationStatus.Inspected ? "btn btn-dark" : "btn btn-warning"}
                                            disabled={item.status < QuotationStatus.Inspected} onClick={() => handleCarFaxReport(item.id)} >CARFAX Report</button>}
                                        <button type="button" className="btn btn-primary" onClick={() => handleOfferedInspector(item.id)} >Inspectors Offered</button>
                                        <button type="button" className="btn btn-danger" onClick={() => onCancelOrder(item.id)}>Cancel Order</button>
                                        <button type="button" className="btn btn-dark" disabled={item.status == QuotationStatus.Completed} onClick={() => handleCompleteModal(item.id)} >Complete Order</button>
                                    </div>
                                </div>
                            </div>))) : <div> <EmptyDataResult message="There is no data to display" /></div>
                    }

                </div>
            </div>
        </div>
    </>);

}

export default OrderList;