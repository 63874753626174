import React from "react";
import HerosectionHeader from '../../../Componet/Herosection/HerosectionHeader'
import { Link } from 'react-router-dom';
import { Trainings } from "../../../store/Utility/Utility";

const Training = () => {
  return (
    <>
      <HerosectionHeader folder1={"pages"} folder2={"Company"} name={"Training"} />
      <section>
        <div class="container">
          <div class="row align-items-end justify-content-between">
            <div class="col-12 col-lg-5">
              <div>
                <h2 class="mb-0"><span class="font-w-4 d-block">Boost inspections with our essential training!</span></h2>
              </div>
            </div>
            <div class="col-12 col-lg-6 mt-3 my-lg-0">
              <p class="lead mb-0">Please review each subject here to gain insight into successfully completing any inspection.</p>
            </div>
          </div>
          {/* <!-- / .row --> */}
          <div class="row">
            <div class="col">
              {Trainings && Trainings.map((item) =>
                <div class="card bg-transparent flex-md-row align-items-center p-md-0 p-3 mt-4">
                  {/* <div class="col-md-4">
                    <img class="img-fluid" alt="Image1" />
                  </div> */}
                  <div class="card-body col-md-6">
                    <h2 class="h5 my-2">
                      <a class="link-title" href="blog-single.html">{item.description}</a>
                    </h2>
                  </div>
                  <div class="col-md-2">
                    <Link to={item.path} className="btn btn-primary">Read</Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Training;