import React from 'react'
import { useParams } from 'react-router-dom';
import HerosectionHeader from '../../../Componet/Herosection/HerosectionHeader';
import { TrainingList } from '../../../store/Utility/Utility';
import { Link } from 'react-router-dom';
import {
    Button,
    Col,
    Container,
    FormGroup,
    Input,
    Row
} from "reactstrap";

function TrainingDetail() {
    let { id } = useParams();
    const selectedTraining = TrainingList.find(item => item.id == id);
    return (
        <>
            <HerosectionHeader folder1={"pages"} folder2={"Blog"} name='Training' />

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {selectedTraining &&
                                <div className="card border-0 bg-transparent">
                                    {/* <img className="card-img-top shadow" src={require(`../../../assets/images/blog/${selectedTraining.image.split('/')[3]}`)} alt="Image1" /> */}
                                    <div className="card-body pt-5 px-0">
                                        <div>
                                            <div className="d-inline-block bg-light text-center px-2 py-1 rounded me-2">{selectedTraining.date}</div> <div className="d-inline-block btn-link">{selectedTraining.category}</div>
                                        </div>
                                        <h2 className="h5 my-4">
                                            <div className="link-title">{selectedTraining.title}</div>
                                        </h2>
                                        {selectedTraining.subHeader}

                                    </div>
                                    {selectedTraining.content.map((item) => <p>{item}</p>)}
                                    <div />
                                </div>}
                        </div>                        
                    </div>
                    <Row>
                        <Col lg={10}>

                        </Col>
                        <Col lg={2}><Link className='btn btn-primary' to="/training">Back</Link> <Link  className='btn btn-primary' to={`/training/${selectedTraining.nextId}`}>Next</Link></Col>
                    </Row>
                </div>
            </section>

        </>
    )
}

export default TrainingDetail;
