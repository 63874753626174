import React, { useState, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import ImageArea from "../../../Componet/Image/ImageArea";
import ImageSlider from "../../../Componet/Image/ImageSlider";

const InspectionImages = ({ uploadedFiles }) => {
    const [showSlider, setShowSlider] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const sliderArea = useRef(null);

    const handleSlide = (selectedIndex) => {
        setSelectedIndex(selectedIndex);
        setShowSlider(true);
    }

    const handleCloseSlide = (e) => {
        if (sliderArea.current && !sliderArea.current.contains(e.target)) {
            setShowSlider(false);
        }
    }

    const onClose = () => {
        setShowSlider(false);
    }


    return (
        <div>
            <div className="page-wrapper">
                <div className="page-content"></div>
                {showSlider &&
                    <div className="backdrop-image" onClick={handleCloseSlide}>
                        <ImageSlider images={uploadedFiles} currentIndex={selectedIndex} onCloseHandler={onClose} />
                    </div>
                }
                <Container>
                    <Row >
                        <Col lg={12} md={12}>
                            <div className="checkout-form">
                                <div className="col-lg-12 col-12">
                                    <p className="lead mb-3">Here are the photos from your inspection — click any image to view it in full screen, or download them all as a ZIP file.</p>
                                    <div class="accordion" id="accordion">
                                        <div class="accordion-item rounded mb-2">
                                            <div id="collapseVehicleInfo" class="accordion-collapse border-0 collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                                                <div class="accordion-body text-muted">
                                                    <Row form>
                                                        <Col md={12}>
                                                            <Row>
                                                                {uploadedFiles.map((t, index) =>
                                                                    <Col md={4} className="my-2">
                                                                        <ImageArea src={t.filePath} disableDelete={true} toggleSlider={() => handleSlide(index)} />
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default InspectionImages;