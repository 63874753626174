import { useState, useEffect, useRef } from "react";
import "./auto-complete-input.css";
import { Label, FormGroup } from "reactstrap";

const AutoCompleteInputFormGrouped = ({ id, options, placeholder, value, onSuggestionClick, handleChange, name, label }) => {
    const [showSuggestions, setShowSuggestions] = useState(false);

    const suggestions = options?.filter(option => option.name.toLowerCase().includes(value?.toLowerCase()))

    const autocompleteRef = useRef(id);

    useEffect(() => {
        const handleClick = (event) => {
            if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
                setShowSuggestions(false)
            }
        };
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick)
        }
    }, [])

    const handleSuggestionClick = (suggestion) => {
        onSuggestionClick(suggestion);
        setShowSuggestions(false);
    }

    return (
        <div className="autocomplete" ref={autocompleteRef}>
            <FormGroup floating>
                <input
                    type="text"
                    name={name}
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                    className="form-control"
                    onFocus={() => setShowSuggestions(true)}
                />
                <Label for={name}>{label}</Label>
                {showSuggestions && (
                    <div className="suggestions">
                        {suggestions && suggestions.map(suggestion => (
                            <div className="suggestion-item" onClick={() => handleSuggestionClick(suggestion)} key={suggestion.id}>
                                {suggestion.name}
                            </div>
                        ))}
                    </div>
                )}
            </FormGroup>
        </div>
    )
};

export default AutoCompleteInputFormGrouped;