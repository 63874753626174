export const InspectorStatus = {
    Created: 0,
    Applicant: 1,
    AwaitingContract: 2,
    AwaitingApprove: 3,
    Approved: 4,
    Warned: 5,
    Rejected: 6,
    Suspended: 7
}

export const QuotationStatus = {
    Created: 0,
    Updated: 1,
    Assigned: 2,
    Confirmed: 3,
    Offered: 4,
    Inspected: 5,
    InspectionApproved: 6,
    Completed: 7,
    Cancelled: 8,
    Closed: 9
}

export const OrderStatus = {
    All: 0,
    AwaitingInspector: 1,
    AwaitingInspection: 2,
    AwaitingApproval: 3,
    Completed: 4,
    Cancelled: 5
}



export const QuotationStep = {
    VehicleType: 1,
    VehicleInfo: 2,
    SellerInfo: 3,
    RequesterInfo: 4,
    Payment: 5,
    Review: 6
}

export const VehicleTypes = {
    Car: 1,
    Motorcycle: 2,
    Commercial: 3,
    RV: 4
}

export const TransactionTypes = {
    None: 0,
    Payment: 1,
    Balance: 2
}

export const PaymentTypes = {
    ACH: 1,
    Stripe: 2,
    Zelle: 3,
    Other: 4
}

export const BalanceTypes = {
    InspectionEarning: 1,
    ExtraPayment: 2,
    Other: 3
}