import React, { useEffect, useState } from "react";
import HerosectionHeader from '../../../../Componet/Herosection/HerosectionHeader'
import { Col, Container, FormGroup, Input, Row } from "reactstrap";
import useAuth from '../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { DashboardMessage, Roles } from "../../../../store/Utility/Utility";
import moment from "moment";

const InspectorReport = () => {
    return <>
        <HerosectionHeader folder1={"pages"} folder2={"Company"} name={"My Reports"} />
        <div className="page-wrapper">
            <div className="page-content">
                <section>
                    <Container>
                        <Row >
                            <div class="col-12 col-sm-6 col-xl-3">
                                <div class="card widget-card border-light shadow-sm">
                                    <div class="card-body p-4">
                                        <div class="row">
                                            <div class="col-8">
                                                <h5 class="card-title widget-card-title mb-3">Awaiting Inspection</h5>
                                                <h4 class="card-subtitle text-body-secondary m-0">N/A</h4>
                                            </div>
                                            <div class="col-4">
                                                <div class="d-flex justify-content-end">
                                                    <div class="lh-1 text-white bg-primary rounded-circle p-3 d-flex align-items-center justify-content-center">
                                                        <i class="las la-hourglass-half fs-4"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="row">
                                            <div class="col-12">
                                                <div class="d-flex align-items-center mt-3">
                                                    <span class="lh-1 me-3 bg-danger-subtle text-danger rounded-circle p-1 d-flex align-items-center justify-content-center">
                                                        <i class="bi bi-arrow-right-short bsb-rotate-45"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-3">
                                <div class="card widget-card border-light shadow-sm">
                                    <div class="card-body p-4">
                                        <div class="row">
                                            <div class="col-8">
                                                <h5 class="card-title widget-card-title mb-3">Completed Inspection</h5>
                                                <h4 class="card-subtitle text-body-secondary m-0">N/A</h4>
                                            </div>
                                            <div class="col-4">
                                                <div class="d-flex justify-content-end">
                                                    <div class="lh-1 text-white bg-primary rounded-circle p-3 d-flex align-items-center justify-content-center">
                                                        <i class="las la-check-circle fs-4"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="row">
                                            <div class="col-12">
                                                <div class="d-flex align-items-center mt-3">
                                                    <span class="lh-1 me-3 bg-danger-subtle text-danger rounded-circle p-1 d-flex align-items-center justify-content-center">
                                                        <i class="bi bi-arrow-right-short bsb-rotate-45"></i>
                                                    </span>

                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </section>
            </div>
        </div>
    </>
}

export default InspectorReport;