import React, { useState, useRef, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
    Col,
    Container,
    Input,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Table,
    FormGroup,
    Label
} from "reactstrap";
import moment from "moment";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { useSpinner } from "../../../../../context/SpinnerContext";
import SpinnerButton from "../../../../../Componet/Button/SpinnerButton";
import Modal from 'react-bootstrap/Modal';
import HerosectionHeader from "../../../../../Componet/Herosection/HerosectionHeader";
import Options from "../../../../../Componet/Option/Options";
import { PaymentType, BalanceType } from "../../../../../store/Utility/Data";
import { isNullOrEmpty } from "../../../../../store/Utility/Helper";
import { TransactionTypes } from "../../../../../store/Utility/Status";

const InspectorPayment = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { id } = useParams();
    const [modal, showModal] = useState({ show: false, message: null })
    const [formData, setFormData] = useState({});
    const [validationError, setValidationError] = useState({});
    const { showLoader, hideLoader } = useSpinner();
    const axiosPrivate = useAxiosPrivate();
    const [paymentAmount, setPaymentAmount] = useState();
    const [paymentData, setPaymentData] = useState({});
    const [balanceData, setBalanceData] = useState({});
    const [activeTab, setActiveTab] = useState("1");
    const [loading, setLoading] = useState(false);
    const [savePaymentModal, setSavePaymentModal] = useState({ show: false, type: null });

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleClose = () => showModal({ show: false, message: null });

    const getInspector = async () => {
        const response = await axiosPrivate.get(`v1/inspector/account/${id}`).then(res => {
            setFormData(res.data.data);
        });
    }

    useEffect(() => {
        getInspector();
    }, []);

    const validateValues = (inputValues) => {
        let errors = {};

        if (inputValues.email == '') {
            errors.email = "Email is required";
        }
        return errors;
    };


    const handleChange = (event) => {
        const { name, value } = event.target;
        setPaymentData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    const handleBalanceChange = (event) => {
        const { name, value } = event.target;
        setBalanceData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    const handlePaymentAmountChange = (e) => {
        const input = e.target.value;

        // Regex to allow numbers with up to 2 decimal places
        const regex = /^\d*\.?\d{0,2}$/;

        // If the input matches the regex, update the value
        if (regex.test(input)) {
            setPaymentData((prevFormData) => ({ ...prevFormData, amount: input }));
        }
    };

    const handleBalanceAmountChange = (e) => {
        const input = e.target.value;
        const regex = /^\d*\.?\d{0,2}$/;
        if (regex.test(input)) {
            setBalanceData((prevFormData) => ({ ...prevFormData, amount: input }));
        }
    };


    const handleOpenPaymentConfirmModal = (type) => {
        var model = {};
        if (type == "payment") {
            model = paymentData;
        } else {
            model = balanceData;
        }

        if (isNullOrEmpty(model.amount)) {
            return;
        }
        if (isNullOrEmpty(model.paymentType)) {
            return;
        }
        setSavePaymentModal({ show: true, type: type })
    }

    const onSubmitPayment = async () => {
        showLoader();
        const response = await axiosPrivate.post(`v1/inspector/account/payment`,
            { relationId: id, amount: paymentData.amount, paymentType: paymentData.paymentType, notes: paymentData.notes }).then(res => {
                if (res.data.data) {
                    setSavePaymentModal({ show: false, message: null, type: null })
                    showModal({ show: true, message: "Payment successfully completed." });
                    setPaymentData({ amount: '', paymentType: '', notes: '' });
                    hideLoader();
                    getInspector();
                }
            }).catch(error => {
                showModal({ show: true, message: "Sorry! An error occured" });
                hideLoader();
            });
    }

    const onSubmitBalance = async () => {
        showLoader();
        const response = await axiosPrivate.post(`v1/inspector/account/balance`,
            { relationId: id, amount: balanceData.amount, paymentType: balanceData.paymentType, notes: balanceData.notes }).then(res => {
                if (res.data.data) {
                    setSavePaymentModal({ show: false, message: null, type: null })
                    showModal({ show: true, message: "Balance successfully updated." });
                    setBalanceData({ amount: '', paymentType: '', notes: '' });
                    hideLoader();
                    getInspector();
                }
            }).catch(error => {
                showModal({ show: true, message: "Sorry! An error occured" });
                hideLoader();
            });
    }

    return (
        <div>
            <div className="page-wrapper">
                <HerosectionHeader name={"Inspector Account"} />
                <div className="page-content">
                    {<Modal show={modal.show} onHide={handleClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Result</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{modal.message}</Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-dark" onClick={handleClose}>Close</button>
                        </Modal.Footer>
                    </Modal>}

                    {<Modal show={savePaymentModal.show} onHide={() => setSavePaymentModal({ show: false, type: null })} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm {savePaymentModal.type == "payment" ? "Payment" : "Balance"}</Modal.Title>
                        </Modal.Header>
                        {savePaymentModal.type == "payment" ? (<Modal.Body>
                            <strong>${paymentData.amount}</strong>  will be deducted from <strong>{formData.inspector?.firstName} {formData.inspector?.lastName} </strong>'s balance
                            <br /> Are you sure to continue?
                        </Modal.Body>) :
                            (<Modal.Body>
                                <strong>${balanceData.amount}</strong>  will be added to <strong>{formData.inspector?.firstName} {formData.inspector?.lastName} </strong>'s balance
                                <br /> Are you sure to continue?
                            </Modal.Body>)
                        }
                        <Modal.Footer>
                            <Col>
                                <Row>
                                    <Col md={5}></Col>
                                    <Col md={3}><button className="btn btn-dark" onClick={() => setSavePaymentModal({ show: false, message: null })}>Cancel</button></Col>
                                    <Col md={4}>
                                        {/* <button type="submit" className="btn btn-warning">Confirm</button> */}
                                        <SpinnerButton onClick={() => savePaymentModal.type == "payment" ? onSubmitPayment() : onSubmitBalance()} text="Confirm" className="btn btn-danger" loading={loading} />
                                    </Col>
                                </Row>
                            </Col>
                        </Modal.Footer>
                    </Modal>}

                    <section>
                        <Container>
                            <div className="mt-6 shadow p-5">
                                <div className="row">
                                    <div className="mb-4 mb-md-0 col-md-auto">
                                        <img className="img-fluid rounded shadow" alt="image1" src="https://inspectiverstorage.blob.core.windows.net/web/profile/601313d4-dcbc-4f3a-9b61-6a4e1aeb6baa.png" />
                                    </div>
                                    <div className="col-md">
                                        <div className="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
                                            <h6 className="mb-0">{formData.inspector?.firstName} {formData.inspector?.lastName}</h6>  <small className="text-muted">Balance: <strong>${formData.inspector?.balance?.toFixed(2)}</strong></small>
                                        </div>
                                        <div>
                                            <ul className="list-unstyled mb-4">
                                                <li className="mb-2 text-black">
                                                    Code: <span className="text-muted">{formData.inspector?.inspectorCode}</span>
                                                </li>
                                                <li className="mb-2 text-black">
                                                    Account Number: <span className="text-muted">{formData.inspector?.accountNumber}</span>
                                                </li>
                                                <li className="text-black">
                                                    Routing Number: <span className="text-muted">{formData.inspector?.routingNumber}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </section>
                    <section className="bg-light">
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <Nav tabs className="nav nav-tabs" style={{ cursor: 'pointer', borderBottom: 'none' }}>
                                        <NavItem>
                                            <NavLink
                                                className={
                                                    activeTab === "1"
                                                        ? " active ms-0 nav-item nav-link"
                                                        : " ms-0 nav-item nav-link"
                                                }
                                                onClick={() => {
                                                    toggle("1");
                                                }}

                                            >
                                                Payment History
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={
                                                    activeTab === "2" ? "active nav-item nav-link" : "nav-item nav-link"
                                                }
                                                onClick={() => {
                                                    toggle("2");
                                                }}
                                            >
                                                Balance History
                                            </NavLink>
                                        </NavItem>
                                        {/* <NavItem>
                                            <NavLink
                                                className={
                                                    activeTab === "3" ? "active  nav-item nav-link disabled" : " nav-item nav-link disabled"
                                                }
                                                onClick={() => {
                                                    toggle("3");
                                                }}
                                            >
                                                Reviews
                                            </NavLink>
                                        </NavItem> */}
                                    </Nav>
                                    <TabContent activeTab={activeTab} className="pt-5">
                                        <TabPane tabId="1" className="tab-pane fade show">
                                            <Row>
                                                <Col md={4}>
                                                    <FormGroup floating>
                                                        <Input
                                                            type="select"
                                                            id="paymentType"
                                                            name="paymentType"
                                                            className="form-control custom-form-control"
                                                            value={paymentData.paymentType}
                                                            onChange={handleChange}
                                                            required
                                                        >
                                                            <Options data={PaymentType} />
                                                        </Input>
                                                        <Label for="paymentType">Payment Type</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup floating>
                                                        <input name="notes" type="text" className="form-control" value={paymentData.notes} onChange={handleChange} placeholder="Notes" />
                                                        <Label for="notes">Notes</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={2}>
                                                    <FormGroup floating>
                                                        <input name="amount" className="form-control" type="number" value={paymentData.amount} onChange={handlePaymentAmountChange} />
                                                        <Label for="amount">Amount</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={2}>

                                                    <SpinnerButton onClick={() => handleOpenPaymentConfirmModal("payment")} className="btn btn-primary" text="Save" loading={loading} /></Col>

                                            </Row>
                                            <Row>
                                                <h5 class="mt-2 mb-2">Payment History</h5>
                                                <Table bordered className="mb-0">
                                                    <thead>
                                                        <tr>
                                                            <td><strong>Payment Type</strong></td>
                                                            <td><strong>Note</strong></td>
                                                            <td><strong>Date</strong></td>
                                                            <td><strong>Amount</strong></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {formData.payments?.length > 0 ? formData.payments?.filter(t => (t.transactionType == TransactionTypes.Payment)).map(item =>
                                                            <tr>
                                                                <td>{PaymentType.find(t => t.value == item.paymentType)?.text}</td>
                                                                <td>{item.notes}</td>
                                                                <td>{moment(item.createdDate).format('MM.DD.YYYY HH:mm')}</td>
                                                                <td>${item.amount.toFixed(2)}</td>
                                                            </tr>

                                                        ) : <tr><td colSpan={4}>There is no data to display</td></tr>}

                                                    </tbody>
                                                </Table>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2" className="tab-pane fade show">

                                            <Row>
                                                <Col md={4}>
                                                    <FormGroup floating>
                                                        <Input
                                                            type="select"
                                                            id="paymentType"
                                                            name="paymentType"
                                                            className="form-control custom-form-control"
                                                            value={balanceData.paymentType}
                                                            onChange={handleBalanceChange}
                                                            required
                                                        >
                                                            <Options data={BalanceType} />
                                                        </Input>
                                                        <Label for="paymentType">Payment Reason</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup floating>
                                                        <input name="notes" type="text" className="form-control" value={balanceData.notes} onChange={handleBalanceChange} placeholder="Notes" />
                                                        <Label for="notes">Notes</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={2}>
                                                    <FormGroup floating>
                                                        <input name="amount" className="form-control" type="number" value={balanceData.amount} onChange={handleBalanceAmountChange} />
                                                        <Label for="amount">Amount</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={2}>

                                                    <SpinnerButton onClick={() => handleOpenPaymentConfirmModal("balance")} className="btn btn-primary" text="Add Extra Payment" loading={loading} /></Col>

                                            </Row>
                                            <Row>
                                                <h5 class="mt-2 mb-2">Balance History</h5>
                                                <Table bordered className="mb-0">
                                                    <thead>
                                                        <tr>
                                                            <td><strong>Transaction Type</strong></td>
                                                            <td><strong>Note</strong></td>
                                                            <td><strong>Date</strong></td>
                                                            <td><strong>Amount</strong></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {formData.payments?.length > 0 ? formData.payments?.filter(t => (t.transactionType == TransactionTypes.Balance)).map(item =>
                                                            <tr>
                                                                <td>{BalanceType.find(t => t.value == item.paymentType)?.text}</td>
                                                                <td>{item.notes}</td>
                                                                <td>{moment(item.createdDate).format('MM.DD.YYYY HH:mm')}</td>
                                                                <td>${item.amount.toFixed(2)}</td>
                                                            </tr>

                                                        ) : <tr><td colSpan={4}>There is no data to display</td></tr>}

                                                    </tbody>
                                                </Table>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="3" className="tab-pane fade show">
                                            <h5 className="mb-3">Reviews</h5>
                                            <Row>
                                                <Col md={7}>
                                                    {/* <RatingList />
                                                        <ReviewList /> */}
                                                </Col>
                                                <Col md={5}>
                                                    {/* <ReviewForm /> */}
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default InspectorPayment;