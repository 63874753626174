import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Collapse,
    Nav,
    Navbar,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle
} from 'reactstrap';
import useAuth from '../../hooks/useAuth';
import { Roles } from '../../store/Utility/Utility';

const BecomeInspectorHeader = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isCartview, setIsCartview] = useState(false);
    const wrapperRef = useRef(null);
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setIsCartview(false);
        }
    };
    const handleScroll = () => {
        var scrollTop =
            (document.documentElement && document.documentElement.scrollTop) ||
            document.body.scrollTop;
        if (scrollTop > 100) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    const handleLogout = () => {
        // logout();
        // AuthService.logout();
        setAuth({});
        // navigate('/');
        navigate('/', { replace: true });
    }

    return (
        <header id="site-header" className="header" style={{ backgroundColor: "white" }}>
            <div id="header-wrap" className={`${visible ? 'fixed-header ' : ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            {/* Navbar */}
                            <Navbar className="navbar navbar-expand-lg navbar-light">
                                <Link className="navbar-brand logo text-primary mb-0" to="/" style={{fontFamily:"Inter-SemiBold"}}>
                                    <span class="text-dark font-w-4">inspectiver</span>
                                </Link>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarNavDropdown"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={toggle}
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <Collapse
                                    isOpen={isOpen}
                                    className="collapse navbar-collapse"
                                    navbar
                                >
                                    {auth?.user ?
                                        (<Nav className="mx-auto" navbar>
                                            {auth?.user?.roles?.find(role => [Roles.Admin].includes(role)) && <>

                                                {/* <UncontrolledDropdown nav inNavbar>
                                                    <DropdownToggle nav caret>
                                                        Management
                                                    </DropdownToggle>
                                                    <DropdownMenu id={`submenu_management`} className='dropdown-menu'>
                                                        <ul className="list-unstyled" key="insp">
                                                            <li>
                                                                <DropdownItem
                                                                    tag={Link}
                                                                    to="/inspector-search"
                                                                >
                                                                    Inspectors
                                                                </DropdownItem>
                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown> */}
                                                <li className='nav-item'>
                                                    <li className='dropdown nav-item'>
                                                        <Link className='nav-link' to="/inspector-search">Payments</Link>
                                                    </li>
                                                </li>
                                                <li className='nav-item'>
                                                    <li className='dropdown nav-item'>
                                                        <Link className='nav-link' to="/orders">Orders</Link>
                                                    </li>
                                                </li>
                                                <li className='nav-item'>
                                                    <li className='dropdown nav-item'>
                                                        <Link className='nav-link' to="/inspector-list">Inspectors</Link>
                                                    </li>
                                                </li>
                                            </>}
                                            <li className='nav-item'>
                                                <li className='dropdown nav-item'>
                                                    <Link className='nav-link' to="/profile">Profile</Link>
                                                </li>
                                            </li>

                                            {auth?.user?.roles?.find(role => [Roles.VehicleInspector].includes(role)) && <><li className='nav-item'>
                                                <li className='dropdown nav-item'>
                                                    <Link className='nav-link' to="/workhub">Workhub</Link>
                                                </li>

                                            </li>
                                                <li className='nav-item'>
                                                    <li className='dropdown nav-item'>
                                                        <Link className='nav-link' to="/stats">My Stats</Link>
                                                    </li>

                                                </li>
                                                <li className='nav-item'>
                                                    <li className='dropdown nav-item'>
                                                        <Link className='nav-link' to="/training">Training</Link>
                                                    </li>
                                                </li>
                                                <li className='nav-item'>
                                                    <li className='dropdown nav-item'>
                                                        <Link className='nav-link' to="/support">Support</Link>
                                                    </li>
                                                </li>
                                                <li className='nav-item'>
                                                    <li className='dropdown nav-item'>
                                                        <Link className='nav-link' to="/faqs">FAQ</Link>
                                                    </li>
                                                </li>
                                            </>
                                            }
                                        </Nav>) : (<Nav className="mx-auto" navbar>

                                            <li className='nav-item'>
                                                <li className='dropdown nav-item'>
                                                    <Link className='nav-link' to="/about-us">About Us</Link>
                                                </li>
                                            </li>

                                            <li className='nav-item'>
                                                <li className='dropdown nav-item'>
                                                    <Link className='nav-link' to="/join-us" style={{ fontWeight: 800 }}>Why Join us</Link>
                                                </li>
                                            </li>

                                        </Nav>)}
                                </Collapse>

                                {!auth?.user && <div className="d-sm-flex align-items-center justify-content-end">
                                    <Link className="btn-link" to="login">Login</Link>
                                </div>}

                                {auth?.user && <div className="d-sm-flex align-items-center justify-content-end">

                                    <ul class="navbar-nav navbar-nav-icons flex-row">
                                        <li className="d-sm-flex nav-item  mb-0 font-w-7o">
                                            <a id="navbarDropdownUser" href="#!" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                                                <div className="avatar">
                                                    <img className="rounded-circle" style={{ width: "40px", height: "40px" }} src={auth?.user?.profile} alt="" />
                                                </div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border" aria-labelledby="navbarDropdownUser">
                                                <div class="card position-relative border-0">
                                                    <div className="card-body p-0">
                                                        <div className="text-center pt-4 pb-3">
                                                            <div className="avatar-l">
                                                                <img className="rounded-circle" style={{ width: "80px", height: "80px" }} src={auth?.user?.profile} alt="" />
                                                            </div>
                                                            <h6 className="mt-2 text-body-emphasis">{auth?.user.firstName} {auth?.user.lastName}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="overflow-auto scrollbar" style={{ height: "10rem" }}>
                                                        <ul className="nav d-flex flex-column mb-2 pb-1">
                                                            <li className="nav-item"><Link className="nav-link px-3" to="/profile">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user me-2 text-body"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg><span>Profile</span></Link></li>

                                                            <li className="nav-item"><Link className="nav-link px-3" to="/dashboard">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pie-chart me-2 text-body"><path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path></svg><span>Dashboard</span></Link></li>

                                                            <li className="nav-item"><Link className="nav-link px-3" to="/stats">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pie-chart me-2 text-body"><path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path></svg><span>My Stats</span></Link></li>

                                                            <li className="nav-item"><Link className="nav-link px-3" to="/my-report">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pie-chart me-2 text-body"><path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path></svg><span>Report</span></Link></li>

                                                            {/* <li className="nav-item"><a className="nav-link px-3" href="/profile"> <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user me-2 text-body"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg><span>Profile</span></a></li>
                                                            <li className="nav-item"><a className="nav-link px-3" href="#!"><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pie-chart me-2 text-body"><path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path></svg>Dashboard</a></li>
                                                            <li className="nav-item"><a className="nav-link px-3" href="#!"> <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock me-2 text-body"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>Posts &amp; Activity</a></li>
                                                            <li className="nav-item"><a className="nav-link px-3" href="#!"> <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings me-2 text-body"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>Settings &amp; Privacy </a></li>
                                                            <li className="nav-item"><a className="nav-link px-3" href="#!"> <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-help-circle me-2 text-body"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>Help Center</a></li>
                                                            <li className="nav-item"><a className="nav-link px-3" href="#!"> <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe me-2 text-body"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>Language</a></li> */}
                                                        </ul>
                                                    </div>
                                                    <div className="card-footer p-0 border-top border-translucent">
                                                        <div className="px-3 pt-3 pb-1">
                                                            <button className="btn btn-primary w-100" onClick={handleLogout}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out me-2"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                                                Sign out</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li></ul>
                                </div>}
                            </Navbar>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default BecomeInspectorHeader;
