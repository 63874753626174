import { useEffect, useState } from "react";
import "./image-slider.css";
import { InspectionSection } from "../../store/Utility/Utility";

const ImageSlider = ({ images, currentIndex, onCloseHandler }) => {
    const [imageIndex, setImageIndex] = useState(currentIndex);

    const showPreviousImage = () => {
        setImageIndex(index => {
            if (index === 0) return images.length - 1
            return index - 1
        })
    }

    const showNextImage = () => {
        setImageIndex(index => {
            if (index === images.length - 1) return 0
            return index + 1
        })
    }

    const setOnClick = () => {
        onCloseHandler();
    }

    let categoryName = images[imageIndex].subCategory === InspectionSection.VehicleInfo ? "Vehicle Info"
        : images[imageIndex].subCategory === InspectionSection.Summary ? "Extras" : images[imageIndex].subCategory;

    return (
        <div style={{ width: "100%" }}>
            <div style={{ width: "100%", maxWidth: "1200px", height: "800px", margin: "0 auto" }} >
                <div style={{ width: "100%", height: "100%" }}>
                    <div style={{ width: "100%", height: "100%", display: "flex", overflow: "hidden" }}>
                        {images.map(item => (
                            <img key={item.filePath} src={item.filePath} className="img-slider-img" style={{ translate: `${-100 * imageIndex}%` }} />
                        ))}
                    </div>
                    <span className="spn-title"><h5 style={{ color: "white", padding: "0.5rem" }}>{categoryName}</h5></span>
                    <button onClick={showPreviousImage} className="img-slider-btn" style={{ left: 0 }}><i className="las la-chevron-circle-left text-white la-3x"></i></button>
                    <button onClick={showNextImage} className="img-slider-btn" style={{ right: 0 }}><i class="las la-chevron-circle-right text-white la-3x"></i></button>
                    <button className="action-btn" onClick={setOnClick}><i className="las la-times text-white la-2x"></i></button>
                </div>
            </div>
            <div style={{ width: "100%", marginBottom: "0", display: "flex", overflowX: "scroll", bottom: 0, position:"absolute" }}>
                {images.map((t, index) =>
                    <div onClick={() => setImageIndex(index)} style={{ border: imageIndex == index ? "2px solid #F0F4C3" : null, margin: "2px", cursor: "pointer" }}>
                        <img src={t.filePath} style={{ width: "64px", height: "64px" }} />
                    </div>)}
            </div>
        </div>
    )
}

export default ImageSlider;