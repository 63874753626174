import React, { useState, useEffect, FormEvent } from "react";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import {
    Row,
    Col,
    Input
} from "reactstrap";

// import axios from "../../../../api/axios";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import useAuth from "../../../../hooks/useAuth";

import HerosectionHeader from "../../../../Componet/Herosection/HerosectionHeader";

import EmptyDataResult from "../../../../Componet/EmptyDataResult";
import VehicleTypeIcon from "../../../../Componet/VehicleTypeIcon";
import { VehicleTypes } from "../../../../store/Utility/Status";
const initialModal = {
    show: false,
    quotationId: null,
    appointmentDate: null
};

const initialCancelModal = {
    show: false,
    quotationId: null
}

const QueuedJobs = () => {
    const [jobs, setJobs] = useState();
    const [modal, setModal] = useState(initialModal);
    const [cancelModal, setCancelModal] = useState(initialCancelModal);
    const [showCancelReason, setShowCancelReason] = useState(false);
    const [cancelForm, setCancelFrom] = useState({ isValid: true, messages: [] });
    const [appointmentDate, setAppointmentDate] = useState();
    const navigate = useNavigate();
    const axios = useAxiosPrivate();
    const { auth } = useAuth();

    const SearchQueuedJob = async () => {
        await axios.get(`v1/quotation/queued`, {
            params: { userId: auth?.user.id }
        }).then(res => {
            setJobs(res.data.data);
        });
    }

    useEffect(() => {
        SearchQueuedJob();
    }, [])

    const handleClose = () => setModal({ show: false });

    const handleAppointment = async (quotationId) => {
        const response = await axios.post(`v1/quotation/appointment`, { quotationId: quotationId, userId: auth?.user.id, appointmentDate: appointmentDate }).then(res => {
            setModal(false);
            SearchQueuedJob();
        }).catch(error => {
            alert("Sorry an error occured");
        });

    }


    const onBeginInspection = async (id) => {
        var result = false;
        const response = await axios.post(`v1/inspection/begin`, { quotationId: id, userId: auth?.user.id }).then(res => {
            result = res.data.data;
        }).catch(error => {
            result = false;
        });
        return result;
    }

    const onNavigateHandler = async (vehicleType, id) => {
        var isInspectionBeginSet = await onBeginInspection(id);
        if (isInspectionBeginSet) {
            let path = '';
            if (vehicleType == VehicleTypes.Car) path = 'car';
            if (vehicleType == VehicleTypes.Motorcycle) path = 'motorcycle';
            if (vehicleType == VehicleTypes.Commercial) path = 'commercial';
            if (vehicleType == VehicleTypes.RV) path = 'rv';

            if (path != '') {
                navigate(`/workhub/inspection/${path}/${id}`);
            }
        } else {
            alert("Sorry an error occured to begin inspection!")
        }
    }

    const onCancelInspection = (id) => {
        setCancelModal({ show: true, quotationId: id });
        setShowCancelReason(false);
        setCancelFrom({ isValid: true, messages: [] });
    }

    const onCancelReasonChange = (e) => {
        if (e.target.value == "6") {
            setShowCancelReason(true);
        } else {
            setShowCancelReason(false);
        }
    }



    async function onCancelSubmitHandler(e: FormEvent) {
        e.preventDefault();
        let errors = [];
        let selectedItem = e.target.elements.cancelReasonSelection?.value;
        let cancelReason = e.target.elements.cancelReason?.value;
        if (selectedItem == '') {
            errors.push('Please select a cancel reason');
        }
        if (selectedItem == '6') {
            if (cancelReason == undefined || cancelReason == null || cancelReason == '') {
                errors.push('Please enter your specific reason');
            }
        }
        if (errors != null && errors.length > 0) {
            setCancelFrom({ isValid: false, messages: errors });
        } else {
            const response = await axios.post(`v1/quotation/cancel`,
                {
                    quotationId: cancelModal.quotationId,
                    userId: auth?.user.id,
                    cancelReasonId: selectedItem,
                    cancelReason: cancelReason

                }).then(res => {
                    setCancelModal(initialCancelModal);
                    setCancelFrom({ isValid: true, messages: [] });
                    SearchQueuedJob();
                }).catch(error => {
                    alert("Sorry an error occured");
                });
        }
    }


    return (
        <>
            {<Modal show={modal.show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Set Appointment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <label for="appointmentDate">Appointment Date</label>
                            <input type="datetime-local" name="appointmentDate" className="form-control" value={modal.appointmentDate} onChange={(e) => { setAppointmentDate(e.target.value); setModal({ ...modal, appointmentDate: e.target.value }) }} placeholder="Enter appointment date" />
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={handleClose}>Cancel</button>
                    <button className="btn btn-dark" onClick={() => handleAppointment(modal.quotationId)}>Save</button>
                </Modal.Footer>
            </Modal>}

            {<Modal show={cancelModal.show} onHide={() => setCancelModal(initialCancelModal)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Reason</Modal.Title>
                </Modal.Header>
                <form onSubmit={onCancelSubmitHandler}>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <label htmlFor="appointmentDate">Please select a reason</label>
                                <Input
                                    type="select"
                                    id="cancelReasonSelection"
                                    name="cancelReasonSelection"
                                    className="form-control"
                                    onChange={onCancelReasonChange}
                                >
                                    <option value="">Please select a reason</option>
                                    <option value="1">Accepted by mistake</option>
                                    <option value="2">Seller is not answering their phone</option>
                                    <option value="3"> I don't want to complete this inspection</option>
                                    <option value="4"> Personal emergencies</option>
                                    <option value="5"> Car issues (accident etc)</option>
                                    <option value="6"> Other (Please specify)</option>
                                </Input>
                            </Col>
                        </Row>
                        {
                            showCancelReason &&
                            <Row>
                                <Col md={12} className="mt-2">
                                    <label htmlFor="cancelReason">Please enter your reason</label>
                                    <Input
                                        type="text"
                                        id="cancelReason"
                                        name="cancelReason"
                                        className="form-control"
                                    />

                                </Col>
                            </Row>
                        }
                        {(cancelForm.messages && cancelForm.messages.length > 0) &&
                            <Row>
                                <Col md={12} className="mt-2" style={{ color: "red", fontSize: "0.9rem" }}>
                                    {cancelForm.messages.map(item => <div className="row"><span>* {item}</span></div>)}
                                </Col>
                            </Row>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Col>
                            <Row>
                                <Col md={4}></Col>
                                <Col md={3}><button className="btn btn-primary" onClick={() => setCancelModal(initialCancelModal)}>Close</button></Col>
                                <Col md={5}>
                                    <button type="submit" className="btn btn-danger">Cancel Inspection</button>
                                </Col>
                            </Row>
                        </Col>
                    </Modal.Footer>
                </form>
            </Modal>}

            <HerosectionHeader folder1={"pages"} folder2={"Company"} name={"Queued Jobs"} />
            <div class="container">
                {(jobs && jobs.length > 0) ?
                    jobs.map((job, index) => ((
                        <div className="row">
                            <div class="card bg-transparent p-md-0 p-3 mt-5">
                                <div class="row">
                                    <div className="col-lg-12 mb-2">
                                        <div className="bg-light text-center py-1 rounded-top" style={{ borderBottom: "1px solid #d2d2d2" }} >Please contact the seller immediately to confirm the inspection date and time. Once confirmed, kindly set the appointment time here right away to proceed with the inspection.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div className="col-md-1 align-self-center">
                                        <div className="mx-2 p-2 d-inline-block rounded" style={{ border: "1px solid #d2d2d2", backgroundColor: "#fcfbf7" }}>
                                            <div className="f-icon-m text-white">
                                                <VehicleTypeIcon vehicleType={job.vehicleType} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="card-body">
                                            <div>
                                                <Row>
                                                    <div><span class="text"><b>{job.make} / {job.model}  / {job.year} </b></span>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <div><span class="text"><b>Order Number:</b> {job.orderNumber}</span>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <div><span class="text"><b>Seller: </b>{job.sellerContactName}</span>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <div><span class="text"><b>Seller Phone: </b>{job.sellerPhone}</span>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <div><span class="text"><b>Vehicle Address: </b>{job.sellerAddress}, {job.sellerCity}, {job.sellerState}, {job.sellerZipCode}</span>
                                                    </div>
                                                </Row>
                                                {job.licensePlate &&
                                                    <Row>
                                                        <div><span class="text"><b>License Plate: </b>{job.licensePlate}</span>
                                                        </div>
                                                    </Row>
                                                }
                                                {job.vin &&
                                                    <Row>
                                                        <div><span class="text"><b>VIN: </b>{job.vin}</span>
                                                        </div>
                                                    </Row>
                                                }
                                                {job.requesterNote &&
                                                    <Row>
                                                        <div><span class="text"><b>Notes from customer: </b>{job.requesterNote}</span>
                                                        </div>
                                                    </Row>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div class="d-flex flex-column gap-2 m-2">
                                            <button type="button" className="btn btn-primary" onClick={() => setModal({ show: true, quotationId: job.id, appointmentDate: job.appointmentDate })}>{job.appointmentDate ? 'Edit Appointment' : 'Set Appointment'}</button>
                                            {(!job.isReportSubmitted && job.appointmentDate) && <button type="button" className="btn btn-dark" onClick={() => onNavigateHandler(job.vehicleType, job.id)}>{job.actualInsStartDate!=null && job.actualInsEndDate==null?'Continue Inspection':'Begin Inspection'}</button>}
                                            <button type="button" className="btn btn-danger" onClick={() => onCancelInspection(job.id)}>Cancel Inpsection</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div className="col-lg-12 mt-2">
                                        <div className="bg-light text-center py-1 rounded-bottom" style={{ borderTop: "1px solid #d2d2d2" }} >This inspection is ordered by <strong>{job.requesterFirstName} {job.requesterLastName.substring(0, 1)}.</strong> Please complete this order before <strong>{moment(job.estimatedEndDate).format('MM.DD.YYYY HH:mm')}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>))) :
                    <EmptyDataResult />}
            </div>
        </>);
}

export default QueuedJobs;