import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAuth from "../../../../../hooks/useAuth";
import { Roles, InspectionSection } from "../../../../../store/Utility/Utility";
import axios from "../../../../../api/axios";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import HerosectionHeader from "../../../../../Componet/Herosection/HerosectionHeader";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import useAutosave from "../../../../../hooks/useAtuosave";
import OtherInput from "../../../../../Componet/OtherInput";
import { isNullOrEmpty } from "../../../../../store/Utility/Helper";
import AutoCompleteInput from "../../../../../Componet/Autocomplete/AutoCompleteInput";
import SpinnerButton from '../../../../../Componet/Button/SpinnerButton';
import {
    InteriorMaterial, TransmissionType, EngineLayout, CylinderConfiguration, DriveType, FuelType, EFB, Dings, Dents,
    Rust, EFBNA, HornCondition, WorkingNA, CigaretteUse, Leaks, TireBrand, Warnings,
    TireTreadDepth, SpareTire, SpareTireSet, UnusualOdors, Scratches, RoadTestAvailabilityReasons
} from "../../../../../store/Utility/Data";
import Options from "../../../../../Componet/Option/Options";
import { useDropzone } from "react-dropzone";
import ImageArea from "../../../../../Componet/Image/ImageArea";


const CarInspection = () => {
    let { id } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const [data, setData] = useState({ isRoadTestAvailable: true });
    const [loading, setLoading] = useState(false);
    const [modal, showModal] = useState({ show: false, message: null, isError: null });
    const [makes, setMake] = useState();
    const [models, setModel] = useState();
    const [validationError, setValidationError] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadLoading, setUploadLoading] = useState(false);
    // const [isRoadTestAvailable, setIsRoadTestAvailable] = useState(true);
    const navigate = useNavigate();


    function updateFields(fields) {
        setData(prev => {
            return { ...prev, ...fields }
        })
    }


    const roadTestAvailableCheckHandler = (event) => {
        updateFields({ isRoadTestAvailable: !data.isRoadTestAvailable });
    }


    useEffect(() => {
        const getMakes = async () => {
            const response = await axios.get(`v1/make`).then(res => {
                setMake(res.data.data);
            });
        }
        getMakes();
    }, []);

    const getModelByMakeId = async (makeId) => {
        const response = await axios.get(`v1/model/${makeId}`).then(res => {
            setModel(res.data.data);
        });
    }

    const onMakeSelect = (clickedItem) => {
        updateFields({ makeId: clickedItem.id, makeInfo: clickedItem.name });
        updateFields({ modelInfo: "" });
        getModelByMakeId(clickedItem.id);
    }

    const onModelSelect = (clickedItem) => {
        updateFields({ modelId: clickedItem.id, modelInfo: clickedItem.name });
    }

    const getCarInspection = async () => {
        await axiosPrivate.get(`v1/inspection/car/${id}`).then(res => {
            setData(res.data.data);
            getInspectionFiles();
            if (res.data.data.makeId) {
                getModelByMakeId(res.data.data.makeId);
            }
        }).catch(error => {
        });
    }

    useEffect(() => {
        getCarInspection();
    }, [])


    function isOther(value) {
        if (value == undefined || value == null) return false;
        return value.toLowerCase() == "other";
    }

    function isFairOrBad(value) {
        return value == "fair" || value == "bad";
    }

    function UpdateWithSelection(e) {
        updateFields({ [e.target.name]: e.target.value });
    }

    const handleClose = () => showModal({ show: false, message: null, isSuccess: null });

    const handleRedirectDashboard = () => {
        showModal({ show: false, message: null, isSuccess: null });
        navigate('/');
    }

    const handleAutoSave = async () => {
        if (auth?.user?.roles.indexOf(Roles.VehicleInspector) == -1) return;
        const response = await axiosPrivate.post(`v1/inspection/car`, { ...data, quotationId: id }).then(res => {
        }).catch(error => {
        });
    }

    const validateFormItem = (inputValues) => {
        let errors = {};
        if (isNullOrEmpty(inputValues.makeId) || isNullOrEmpty(inputValues.makeInfo)) errors.makeId = "Select make";

        if (isNullOrEmpty(inputValues.modelId) || isNullOrEmpty(inputValues.modelInfo)) errors.modelId = "Select model";

        if (isNullOrEmpty(inputValues.year)) errors.year = "Enter year";

        if (isNullOrEmpty(inputValues.mileage)) errors.mileage = "Enter mileage";

        if (isNullOrEmpty(inputValues.licensePlate)) errors.licensePlate = "Enter License Plate";

        if (isNullOrEmpty(inputValues.vin)) errors.vin = "Enter VIN";

        if (isNullOrEmpty(inputValues.bodyColor)) errors.bodyColor = "Enter Body Color";

        if (isNullOrEmpty(inputValues.interiorColor)) errors.interiorColor = "Enter Interior Color";

        if (isNullOrEmpty(inputValues.interiorMaterial)) errors.interiorMaterial = "Select Interior Material";
        if (isOther(inputValues.interiorMaterial) && isNullOrEmpty(inputValues.interiorMaterialInfo)) errors.interiorMaterialInfo = "Input Interior Material";

        if (isNullOrEmpty(inputValues.transmissionType)) errors.transmissionType = "Select transmission type";
        if (isOther(inputValues.transmissionType) && isNullOrEmpty(inputValues.transmissionTypeInfo)) errors.transmissionTypeInfo = "Enter transmission type";

        if (isNullOrEmpty(inputValues.engineLayout)) errors.engineLayout = "Select engine layout";
        if (isOther(inputValues.engineLayout) && isNullOrEmpty(inputValues.engineLayoutInfo)) errors.engineLayoutInfo = "Enter engine layout";

        if (isNullOrEmpty(inputValues.cylinderConfiguration)) errors.cylinderConfiguration = "Select cylinder configuration";
        if (isOther(inputValues.cylinderConfiguration) && isNullOrEmpty(inputValues.cylinderConfigurationInfo)) errors.cylinderConfigurationInfo = "Enter cylinder configuration";

        if (isNullOrEmpty(inputValues.driveType)) errors.driveType = "Select drive type";
        if (isOther(inputValues.driveType) && isNullOrEmpty(inputValues.driveTypeInfo)) errors.driveTypeInfo = "Enter drive type";

        if (isNullOrEmpty(inputValues.fuelType)) errors.fuelType = "Select fuel type";
        if (isOther(inputValues.fuelType) && isNullOrEmpty(inputValues.fuelTypeInfo)) errors.fuelTypeInfo = "Enter fuel type";

        if (isNullOrEmpty(inputValues.vehicleInfoNote)) errors.vehicleInfoNote = "Comment about vehicle";

        if (isNullOrEmpty(inputValues.bodyAlignment)) errors.bodyAlignment = "Select body alignment";
        if (isFairOrBad(inputValues.bodyAlignment) && isNullOrEmpty(inputValues.bodyAlignmentIssues)) errors.bodyAlignmentIssues = "Enter body alignment issues";

        if (isNullOrEmpty(inputValues.paint)) errors.paint = "Select paint";
        if (isFairOrBad(inputValues.paint) && isNullOrEmpty(inputValues.paintIssues)) errors.paintIssues = "Enter paint issues";

        if (isNullOrEmpty(inputValues.frame)) errors.frame = "Select frame";
        if (isFairOrBad(inputValues.frame) && isNullOrEmpty(inputValues.frameIssues)) errors.frameIssues = "Enter frame issues";

        if (isNullOrEmpty(inputValues.glassAndWindow)) errors.glassAndWindow = "Select glass and window";
        if (isFairOrBad(inputValues.glassAndWindow) && isNullOrEmpty(inputValues.glassAndWindowIssues)) errors.glassAndWindowIssues = "Enter glass and window issues";

        if (isNullOrEmpty(inputValues.scratches)) errors.scratches = "Select scratches";
        if (isFairOrBad(inputValues.scratches) && isNullOrEmpty(inputValues.scratchesIssues)) errors.scratchesIssues = "Enter scratches issues";

        if (isNullOrEmpty(inputValues.dings)) errors.dings = "Select dings";
        if (isFairOrBad(inputValues.dings) && isNullOrEmpty(inputValues.dingsIssues)) errors.dingsIssues = "Enter dings issues";

        if (isNullOrEmpty(inputValues.dents)) errors.dents = "Select dents";
        if (isFairOrBad(inputValues.dents) && isNullOrEmpty(inputValues.dentsIssues)) errors.dentsIssues = "Enter dents issues";

        if (isNullOrEmpty(inputValues.rust)) errors.rust = "Select rust";
        if (isFairOrBad(inputValues.rust) && isNullOrEmpty(inputValues.rustIssues)) errors.rustIssues = "Enter rust issues";

        if (isNullOrEmpty(inputValues.bodySeals)) errors.bodySeals = "Select body seals";
        if (isFairOrBad(inputValues.bodySeals) && isNullOrEmpty(inputValues.bodySealsIssues)) errors.bodySealsIssues = "Enter body seals issues";

        if (isNullOrEmpty(inputValues.sideMoldings)) errors.sideMoldings = "Select side moldings";
        if (isFairOrBad(inputValues.sideMoldings) && isNullOrEmpty(inputValues.sideMoldingsIssues)) errors.sideMoldingsIssues = "Enter side moldings issues";

        if (isNullOrEmpty(inputValues.sideMirrors)) errors.sideMirrors = "Select side mirrors";
        if (isFairOrBad(inputValues.sideMirrors) && isNullOrEmpty(inputValues.sideMirrorsIssues)) errors.sideMirrorsIssues = "Enter side mirrors issues";

        if (isNullOrEmpty(inputValues.exteriorLights)) errors.exteriorLights = "Select exterior lights";
        if (isFairOrBad(inputValues.exteriorLights) && isNullOrEmpty(inputValues.exteriorLightsIssues)) errors.exteriorLightsIssues = "Enter exterior lights issues";

        if (isNullOrEmpty(inputValues.wiperBlades)) errors.wiperBlades = "Select wiper blades";
        if (isFairOrBad(inputValues.wiperBlades) && isNullOrEmpty(inputValues.wiperBladesIssues)) errors.wiperBladesIssues = "Enter wiper blades issues";

        if (isNullOrEmpty(inputValues.windshieldWasher)) errors.windshieldWasher = "Select windshield washer";
        if (isFairOrBad(inputValues.windshieldWasher) && isNullOrEmpty(inputValues.windshieldWasherIssues)) errors.windshieldWasherIssues = "Enter windshield washer issues";

        if (isNullOrEmpty(inputValues.leftFrontFender)) errors.leftFrontFender = "Select left front fender";
        if (isFairOrBad(inputValues.leftFrontFender) && isNullOrEmpty(inputValues.leftFrontFenderIssues)) errors.leftFrontFenderIssues = "Enter left front fender issues";

        if (isNullOrEmpty(inputValues.rightFrontFender)) errors.rightFrontFender = "Select right front fender";
        if (isFairOrBad(inputValues.rightFrontFender) && isNullOrEmpty(inputValues.rightFrontFenderIssues)) errors.rightFrontFenderIssues = "Enter right front fender issues";

        if (isNullOrEmpty(inputValues.grilleAndFrontBumper)) errors.grilleAndFrontBumper = "Select grille and front bumper";
        if (isFairOrBad(inputValues.grilleAndFrontBumper) && isNullOrEmpty(inputValues.grilleAndFrontBumperIssues)) errors.grilleAndFrontBumperIssues = "Enter grille and front bumper issues";

        if (isNullOrEmpty(inputValues.hood)) errors.hood = "Select hood";
        if (isFairOrBad(inputValues.hood) && isNullOrEmpty(inputValues.hoodIssues)) errors.hoodIssues = "Enter hood issues";

        if (isNullOrEmpty(inputValues.leftFrontDoor)) errors.leftFrontDoor = "Select left front door";
        if (isFairOrBad(inputValues.leftFrontDoor) && isNullOrEmpty(inputValues.leftFrontDoorIssues)) errors.leftFrontDoorIssues = "Enter left front door issues";

        if (isNullOrEmpty(inputValues.leftRearDoor)) errors.leftRearDoor = "Select left rear door";
        if (isFairOrBad(inputValues.leftRearDoor) && isNullOrEmpty(inputValues.leftRearDoorIssues)) errors.leftRearDoorIssues = "Enter left rear door issues";

        if (isNullOrEmpty(inputValues.leftRearFender)) errors.leftRearFender = "Select left rear fender";
        if (isFairOrBad(inputValues.leftRearFender) && isNullOrEmpty(inputValues.leftRearFenderIssues)) errors.leftRearFenderIssues = "Enter left rear fender issues";

        if (isNullOrEmpty(inputValues.rightRearFender)) errors.rightRearFender = "Select right rear fender";
        if (isFairOrBad(inputValues.rightRearFender) && isNullOrEmpty(inputValues.rightRearFenderIssues)) errors.rightRearFenderIssues = "Enter right rear fender issues";

        if (isNullOrEmpty(inputValues.rearBumper)) errors.rearBumper = "Select rear bumper";
        if (isFairOrBad(inputValues.rearBumper) && isNullOrEmpty(inputValues.rearBumperIssues)) errors.rearBumperIssues = "Enter rear bumper issues";

        if (isNullOrEmpty(inputValues.trunk)) errors.trunk = "Select trunk";
        if (isFairOrBad(inputValues.trunk) && isNullOrEmpty(inputValues.trunkIssues)) errors.trunkIssues = "Enter trunk issues";

        if (isNullOrEmpty(inputValues.rightFrontDoor)) errors.rightFrontDoor = "Select right front door";
        if (isFairOrBad(inputValues.rightFrontDoor) && isNullOrEmpty(inputValues.rightFrontDoorIssues)) errors.rightFrontDoorIssues = "Enter right front door issues";

        if (isNullOrEmpty(inputValues.rightRearDoor)) errors.rightRearDoor = "Select right rear door";
        if (isFairOrBad(inputValues.rightRearDoor) && isNullOrEmpty(inputValues.rightRearDoorIssues)) errors.rightRearDoorIssues = "Enter right rear door issues";

        if (isNullOrEmpty(inputValues.roof)) errors.roof = "Select roof";
        if (isFairOrBad(inputValues.roof) && isNullOrEmpty(inputValues.roofIssues)) errors.roofIssues = "Enter roof issues";

        if (isNullOrEmpty(inputValues.sunroof)) errors.sunroof = "Select sunroof";
        if (isFairOrBad(inputValues.sunroof) && isNullOrEmpty(inputValues.sunroofIssues)) errors.sunroofIssues = "Enter sunroof issues";

        if (isNullOrEmpty(inputValues.sunroofActuator)) errors.sunroofActuator = "Select sunroof actuator";
        if (isFairOrBad(inputValues.sunroofActuator) && isNullOrEmpty(inputValues.sunroofActuatorIssues)) errors.sunroofActuatorIssues = "Enter sunroof actuator issues";

        if (isNullOrEmpty(inputValues.convertibleActuator)) errors.convertibleActuator = "Select convertible actuator";
        if (isFairOrBad(inputValues.convertibleActuator) && isNullOrEmpty(inputValues.convertibleActuatorIssues)) errors.convertibleActuatorIssues = "Enter convertible actuator issues";

        if (isNullOrEmpty(inputValues.exteriorNote)) errors.exteriorNote = "Comment about exterior condition";

        if (isNullOrEmpty(inputValues.steeringWheel)) errors.steeringWheel = "Select steering wheel";
        if (isFairOrBad(inputValues.steeringWheel) && isNullOrEmpty(inputValues.steeringWheelIssues)) errors.steeringWheelIssues = "Enter steering wheel issues";

        if (isNullOrEmpty(inputValues.horn)) errors.horn = "Select horn";
        if (isFairOrBad(inputValues.horn) && isNullOrEmpty(inputValues.hornIssues)) errors.hornIssues = "Enter horn issues";

        if (isNullOrEmpty(inputValues.seats)) errors.seats = "Select seats";
        if (isFairOrBad(inputValues.seats) && isNullOrEmpty(inputValues.seatsIssues)) errors.seatsIssues = "Enter seats issues";

        if (isNullOrEmpty(inputValues.seatMechanisms)) errors.seatMechanisms = "Select seat mechanisms";
        if (isFairOrBad(inputValues.seatMechanisms) && isNullOrEmpty(inputValues.seatMechanismsIssues)) errors.seatMechanismsIssues = "Enter seat mechanisms issues";

        if (isNullOrEmpty(inputValues.sunVisors)) errors.sunVisors = "Select sun visors";
        if (isFairOrBad(inputValues.sunVisors) && isNullOrEmpty(inputValues.sunVisorsIssues)) errors.sunVisorsIssues = "Enter sun visors issues";

        if (isNullOrEmpty(inputValues.headliner)) errors.headliner = "Select headliner";
        if (isFairOrBad(inputValues.headliner) && isNullOrEmpty(inputValues.headlinerIssues)) errors.headlinerIssues = "Enter headliner issues";

        if (isNullOrEmpty(inputValues.innerDoorPanels)) errors.innerDoorPanels = "Select inner door panels";
        if (isFairOrBad(inputValues.innerDoorPanels) && isNullOrEmpty(inputValues.innerDoorPanelsIssues)) errors.innerDoorPanelsIssues = "Enter inner door panels issues";

        if (isNullOrEmpty(inputValues.interiorTrim)) errors.interiorTrim = "Select interior trim";
        if (isFairOrBad(inputValues.interiorTrim) && isNullOrEmpty(inputValues.interiorTrimIssues)) errors.interiorTrimIssues = "Enter interior trim issues";

        if (isNullOrEmpty(inputValues.interiorLights)) errors.interiorLights = "Select interior lights";
        if (isFairOrBad(inputValues.interiorLights) && isNullOrEmpty(inputValues.interiorLightsIssues)) errors.interiorLightsIssues = "Enter interior lights issues";

        if (isNullOrEmpty(inputValues.powerLocks)) errors.powerLocks = "Select power locks";
        if (isFairOrBad(inputValues.powerLocks) && isNullOrEmpty(inputValues.powerLocksIssues)) errors.powerLocksIssues = "Enter power locks issues";

        if (isNullOrEmpty(inputValues.windowSwitches)) errors.windowSwitches = "Select window switches";
        if (isFairOrBad(inputValues.windowSwitches) && isNullOrEmpty(inputValues.windowSwitchesIssues)) errors.windowSwitchesIssues = "Enter window switches issues";

        if (isNullOrEmpty(inputValues.windowFunctioning)) errors.windowFunctioning = "Select window functioning";
        if (isFairOrBad(inputValues.windowFunctioning) && isNullOrEmpty(inputValues.windowFunctioningIssues)) errors.windowFunctioningIssues = "Enter window functioning issues";

        if (isNullOrEmpty(inputValues.trunkActuator)) errors.trunkActuator = "Select trunk actuator";
        if (isFairOrBad(inputValues.trunkActuator) && isNullOrEmpty(inputValues.trunkActuatorIssues)) errors.trunkActuatorIssues = "Enter trunk actuator issues";

        if (isNullOrEmpty(inputValues.hoodActuator)) errors.hoodActuator = "Select hood actuator";
        if (isFairOrBad(inputValues.hoodActuator) && isNullOrEmpty(inputValues.hoodActuatorIssues)) errors.hoodActuatorIssues = "Enter hood actuator issues";

        if (isNullOrEmpty(inputValues.fuelDoorActuator)) errors.fuelDoorActuator = "Select fuel door actuator";
        if (isFairOrBad(inputValues.fuelDoorActuator) && isNullOrEmpty(inputValues.fuelDoorActuatorIssues)) errors.fuelDoorActuatorIssues = "Enter fuel door actuator issues";

        if (isNullOrEmpty(inputValues.dash)) errors.dash = "Select dash";
        if (isFairOrBad(inputValues.dash) && isNullOrEmpty(inputValues.dashIssues)) errors.dashIssues = "Enter dash issues";

        if (isNullOrEmpty(inputValues.dashLights)) errors.dashLights = "Select dash lights";
        if (isFairOrBad(inputValues.dashLights) && isNullOrEmpty(inputValues.dashLightsIssues)) errors.dashLightsIssues = "Enter dash lights issues";

        if (isNullOrEmpty(inputValues.dashWarnings)) errors.dashWarnings = "Select dash warnings";
        if (isFairOrBad(inputValues.dashWarnings) && isNullOrEmpty(inputValues.dashWarningsIssues)) errors.dashWarningsIssues = "Enter dash warnings issues";

        if (isNullOrEmpty(inputValues.dashGauges)) errors.dashGauges = "Select dash gauges";
        if (isFairOrBad(inputValues.dashGauges) && isNullOrEmpty(inputValues.dashGaugesIssues)) errors.dashGaugesIssues = "Enter dash gauges issues";

        if (isNullOrEmpty(inputValues.centerConsole)) errors.centerConsole = "Select center console";
        if (isFairOrBad(inputValues.centerConsole) && isNullOrEmpty(inputValues.centerConsoleIssues)) errors.centerConsoleIssues = "Enter center console issues";

        if (isNullOrEmpty(inputValues.cupholder)) errors.cupholder = "Select cupholder";
        if (isFairOrBad(inputValues.cupholder) && isNullOrEmpty(inputValues.cupholderIssues)) errors.cupholderIssues = "Enter cupholder issues";

        if (isNullOrEmpty(inputValues.radio)) errors.radio = "Select radio";
        if (isFairOrBad(inputValues.radio) && isNullOrEmpty(inputValues.radioIssues)) errors.radioIssues = "Enter radio issues";

        if (isNullOrEmpty(inputValues.navigation)) errors.navigation = "Select navigation";
        if (isFairOrBad(inputValues.navigation) && isNullOrEmpty(inputValues.navigationIssues)) errors.navigationIssues = "Enter navigation issues";

        if (isNullOrEmpty(inputValues.airbags)) errors.airbags = "Select airbags";
        if (isFairOrBad(inputValues.airbags) && isNullOrEmpty(inputValues.airbagsIssues)) errors.airbagsIssues = "Enter airbags issues";

        if (isNullOrEmpty(inputValues.seatBelts)) errors.seatBelts = "Select seat belts";
        if (isFairOrBad(inputValues.seatBelts) && isNullOrEmpty(inputValues.seatBeltsIssues)) errors.seatBeltsIssues = "Enter seat belts issues";

        if (isNullOrEmpty(inputValues.ac)) errors.ac = "Select AC";
        if (isFairOrBad(inputValues.ac) && isNullOrEmpty(inputValues.acIssues)) errors.acIssues = "Enter AC issues";

        if (isNullOrEmpty(inputValues.heater)) errors.heater = "Select heater";
        if (isFairOrBad(inputValues.heater) && isNullOrEmpty(inputValues.heaterIssues)) errors.heaterIssues = "Enter heater issues";

        if (isNullOrEmpty(inputValues.rearDefroster)) errors.rearDefroster = "Select rear defroster";
        if (isFairOrBad(inputValues.rearDefroster) && isNullOrEmpty(inputValues.rearDefrosterIssues)) errors.rearDefrosterIssues = "Enter rear defroster issues";

        if (isNullOrEmpty(inputValues.blowerMotor)) errors.blowerMotor = "Select blower motor";
        if (isFairOrBad(inputValues.blowerMotor) && isNullOrEmpty(inputValues.blowerMotorIssues)) errors.blowerMotorIssues = "Enter blower motor issues";

        if (isNullOrEmpty(inputValues.seatHeater)) errors.seatHeater = "Select seat heater";
        if (isFairOrBad(inputValues.seatHeater) && isNullOrEmpty(inputValues.seatHeaterIssues)) errors.seatHeaterIssues = "Enter seat heater issues";

        if (isNullOrEmpty(inputValues.carpet)) errors.carpet = "Select carpet";
        if (isFairOrBad(inputValues.carpet) && isNullOrEmpty(inputValues.carpetIssues)) errors.carpetIssues = "Enter carpet issues";

        if (isNullOrEmpty(inputValues.floorMat)) errors.floorMat = "Select floor mat";
        if (isFairOrBad(inputValues.floorMat) && isNullOrEmpty(inputValues.floorMatIssues)) errors.floorMatIssues = "Enter floor mat issues";

        if (isNullOrEmpty(inputValues.cigaretteUse)) errors.cigaretteUse = "Select cigarette use";
        if (isFairOrBad(inputValues.cigaretteUse) && isNullOrEmpty(inputValues.cigaretteUseIssues)) errors.cigaretteUseIssues = "Enter cigarette use issues";

        if (isNullOrEmpty(inputValues.unusualOdors)) errors.unusualOdors = "Select unusual odors";
        if (isFairOrBad(inputValues.unusualOdors) && isNullOrEmpty(inputValues.unusualOdorsIssues)) errors.unusualOdorsIssues = "Enter unusual odors issues";

        if (isNullOrEmpty(inputValues.interiorNote)) errors.interiorNote = "Comment about interior condition";

        if (isNullOrEmpty(inputValues.keyFOB)) errors.keyFOB = "Select key FOB";
        if (isFairOrBad(inputValues.keyFOB) && isNullOrEmpty(inputValues.keyFOBIssues)) errors.keyFOBIssues = "Enter key FOB issues";

        if (isNullOrEmpty(inputValues.computerDiagnosisAndOBD2)) errors.computerDiagnosisAndOBD2 = "Select computer diagnosis and OBD2";
        if (isFairOrBad(inputValues.computerDiagnosisAndOBD2) && isNullOrEmpty(inputValues.computerDiagnosisAndOBD2Issues)) errors.computerDiagnosisAndOBD2Issues = "Enter computer diagnosis and OBD2 issues";

        if (isNullOrEmpty(inputValues.battery)) errors.battery = "Select battery";
        if (isFairOrBad(inputValues.battery) && isNullOrEmpty(inputValues.batteryIssues)) errors.batteryIssues = "Enter battery issues";

        if (isNullOrEmpty(inputValues.chargingSystem)) errors.chargingSystem = "Select charging system";
        if (isFairOrBad(inputValues.chargingSystem) && isNullOrEmpty(inputValues.chargingSystemIssues)) errors.chargingSystemIssues = "Enter charging system issues";

        if (isNullOrEmpty(inputValues.alternator)) errors.alternator = "Select alternator";
        if (isFairOrBad(inputValues.alternator) && isNullOrEmpty(inputValues.alternatorIssues)) errors.alternatorIssues = "Enter alternator issues";

        if (isNullOrEmpty(inputValues.starter)) errors.starter = "Select starter";
        if (isFairOrBad(inputValues.starter) && isNullOrEmpty(inputValues.starterIssues)) errors.starterIssues = "Enter starter issues";

        if (isNullOrEmpty(inputValues.belts)) errors.belts = "Select belts";
        if (isFairOrBad(inputValues.belts) && isNullOrEmpty(inputValues.beltsIssues)) errors.beltsIssues = "Enter belts issues";

        if (isNullOrEmpty(inputValues.hoses)) errors.hoses = "Select hoses";
        if (isFairOrBad(inputValues.hoses) && isNullOrEmpty(inputValues.hosesIssues)) errors.hosesIssues = "Enter hoses issues";

        if (isNullOrEmpty(inputValues.airFilter)) errors.airFilter = "Select air filter";
        if (isFairOrBad(inputValues.airFilter) && isNullOrEmpty(inputValues.airFilterIssues)) errors.airFilterIssues = "Enter air filter issues";

        if (isNullOrEmpty(inputValues.coolingFan)) errors.coolingFan = "Select cooling fan";
        if (isFairOrBad(inputValues.coolingFan) && isNullOrEmpty(inputValues.coolingFanIssues)) errors.coolingFanIssues = "Enter cooling fan issues";

        if (isNullOrEmpty(inputValues.radiatorAndLeaks)) errors.radiatorAndLeaks = "Select radiator and leaks";
        if (isFairOrBad(inputValues.radiatorAndLeaks) && isNullOrEmpty(inputValues.radiatorAndLeaksIssues)) errors.radiatorAndLeaksIssues = "Enter radiator and leaks issues";

        if (isNullOrEmpty(inputValues.coolantSystemAndLeaks)) errors.coolantSystemAndLeaks = "Select coolant system and leaks";
        if (isFairOrBad(inputValues.coolantSystemAndLeaks) && isNullOrEmpty(inputValues.coolantSystemAndLeaksIssues)) errors.coolantSystemAndLeaksIssues = "Enter coolant system and leaks issues";

        if (isNullOrEmpty(inputValues.powerSteeringSystemAndFluid)) errors.powerSteeringSystemAndFluid = "Select power steering system and fluid";
        if (isFairOrBad(inputValues.powerSteeringSystemAndFluid) && isNullOrEmpty(inputValues.powerSteeringSystemAndFluidIssues)) errors.powerSteeringSystemAndFluidIssues = "Enter power steering system and fluid issues";

        if (isNullOrEmpty(inputValues.powerSteeringFluidLeaks)) errors.powerSteeringFluidLeaks = "Select power steering fluid leaks";
        if (isFairOrBad(inputValues.powerSteeringFluidLeaks) && isNullOrEmpty(inputValues.powerSteeringFluidLeaksIssues)) errors.powerSteeringFluidLeaksIssues = "Enter power steering fluid leaks issues";

        if (isNullOrEmpty(inputValues.engineBlockAndOil)) errors.engineBlockAndOil = "Select engine block and oil";
        if (isFairOrBad(inputValues.engineBlockAndOil) && isNullOrEmpty(inputValues.engineBlockAndOilIssues)) errors.engineBlockAndOilIssues = "Enter engine block and oil issues";

        if (isNullOrEmpty(inputValues.engineFluidAndOilLeaks)) errors.engineFluidAndOilLeaks = "Select engine fluid and oil leaks";
        if (isFairOrBad(inputValues.engineFluidAndOilLeaks) && isNullOrEmpty(inputValues.engineFluidAndOilLeaksIssues)) errors.engineFluidAndOilLeaksIssues = "Enter engine fluid and oil leaks issues";

        if (isNullOrEmpty(inputValues.transmissionSystemAndFluid)) errors.transmissionSystemAndFluid = "Select transmission system and fluid";
        if (isFairOrBad(inputValues.transmissionSystemAndFluid) && isNullOrEmpty(inputValues.transmissionSystemAndFluidIssues)) errors.transmissionSystemAndFluidIssues = "Enter transmission system and fluid issues";

        if (isNullOrEmpty(inputValues.transmissionFluidLeaks)) errors.transmissionFluidLeaks = "Select transmission fluid leaks";
        if (isFairOrBad(inputValues.transmissionFluidLeaks) && isNullOrEmpty(inputValues.transmissionFluidLeaksIssues)) errors.transmissionFluidLeaksIssues = "Enter transmission fluid leaks issues";

        if (isNullOrEmpty(inputValues.brakeSystemAndPads)) errors.brakeSystemAndPads = "Select brake system and pads";
        if (isFairOrBad(inputValues.brakeSystemAndPads) && isNullOrEmpty(inputValues.brakeSystemAndPadsIssues)) errors.brakeSystemAndPadsIssues = "Enter brake system and pads issues";

        if (isNullOrEmpty(inputValues.brakeFluidLeaks)) errors.brakeFluidLeaks = "Select brake fluid leaks";
        if (isFairOrBad(inputValues.brakeFluidLeaks) && isNullOrEmpty(inputValues.brakeFluidLeaksIssues)) errors.brakeFluidLeaksIssues = "Enter brake fluid leaks issues";

        if (isNullOrEmpty(inputValues.differentialSystem)) errors.differentialSystem = "Select differential system";
        if (isFairOrBad(inputValues.differentialSystem) && isNullOrEmpty(inputValues.differentialSystemIssues)) errors.differentialSystemIssues = "Enter differential system issues";

        if (isNullOrEmpty(inputValues.differentialFluidLeaks)) errors.differentialFluidLeaks = "Select differential fluid leaks";
        if (isFairOrBad(inputValues.differentialFluidLeaks) && isNullOrEmpty(inputValues.differentialFluidLeaksIssues)) errors.differentialFluidLeaksIssues = "Enter differential fluid leaks issues";

        if (isNullOrEmpty(inputValues.catalyticConverter)) errors.catalyticConverter = "Select catalytic converter";
        if (isFairOrBad(inputValues.catalyticConverter) && isNullOrEmpty(inputValues.catalyticConverterIssues)) errors.catalyticConverterIssues = "Enter catalytic converter issues";

        if (isNullOrEmpty(inputValues.mufflerSystem)) errors.mufflerSystem = "Select muffler system";
        if (isFairOrBad(inputValues.mufflerSystem) && isNullOrEmpty(inputValues.mufflerSystemIssues)) errors.mufflerSystemIssues = "Enter muffler system issues";

        if (isNullOrEmpty(inputValues.cvJointsAndAxles)) errors.cvJointsAndAxles = "Select CV joints and axles";
        if (isFairOrBad(inputValues.cvJointsAndAxles) && isNullOrEmpty(inputValues.cvJointsAndAxlesIssues)) errors.cvJointsAndAxlesIssues = "Enter CV joints and axles issues";

        if (isNullOrEmpty(inputValues.shocksAndStruts)) errors.shocksAndStruts = "Select shocks and struts";
        if (isFairOrBad(inputValues.shocksAndStruts) && isNullOrEmpty(inputValues.shocksAndStrutsIssues)) errors.shocksAndStrutsIssues = "Enter shocks and struts issues";

        if (isNullOrEmpty(inputValues.bushingsBallJointsRodsAndArms)) errors.bushingsBallJointsRodsAndArms = "Select bushings, ball joints, rods, and arms";
        if (isFairOrBad(inputValues.bushingsBallJointsRodsAndArms) && isNullOrEmpty(inputValues.bushingsBallJointsRodsAndArmsIssues)) errors.bushingsBallJointsRodsAndArmsIssues = "Enter bushings, ball joints, rods, and arms issues";

        if (isNullOrEmpty(inputValues.undercarriage)) errors.undercarriage = "Select undercarriage";
        if (isFairOrBad(inputValues.undercarriage) && isNullOrEmpty(inputValues.undercarriageIssues)) errors.undercarriageIssues = "Enter undercarriage issues";

        if (isNullOrEmpty(inputValues.mechanicalNote)) errors.mechanicalNote = "Comment about mechanical condition";

        if (isNullOrEmpty(inputValues.driverFrontBrand)) errors.driverFrontBrand = "Select driver front brand";

        if (isNullOrEmpty(inputValues.driverFrontSize)) errors.driverFrontSize = "Select driver front size";

        if (isNullOrEmpty(inputValues.driverFrontTreadDepth)) errors.driverFrontTreadDepth = "Select driver front tread depth";

        if (isNullOrEmpty(inputValues.driverFrontTireAndWheel)) errors.driverFrontTireAndWheel = "Select driver front tire and wheel";

        if (isNullOrEmpty(inputValues.passengerFrontBrand)) errors.passengerFrontBrand = "Select passenger front brand";

        if (isNullOrEmpty(inputValues.passengerFrontSize)) errors.passengerFrontSize = "Select passenger front size";

        if (isNullOrEmpty(inputValues.passengerFrontTreadDepth)) errors.passengerFrontTreadDepth = "Select passenger front tread depth";

        if (isNullOrEmpty(inputValues.passengerFrontTireAndWheel)) errors.passengerFrontTireAndWheel = "Select passenger front tire and wheel";

        if (isNullOrEmpty(inputValues.spareTire)) errors.spareTire = "Select spare tire";

        if (isNullOrEmpty(inputValues.spareTireSet)) errors.spareTireSet = "Select spare tire set";

        if (isNullOrEmpty(inputValues.tiresNote)) errors.tiresNote = "Comment about tires note";

        if (isNullOrEmpty(inputValues.driverRearBrand)) errors.driverRearBrand = "Select driver rear brand";

        if (isNullOrEmpty(inputValues.driverRearSize)) errors.driverRearSize = "Select driver rear size";

        if (isNullOrEmpty(inputValues.driverRearTreadDepth)) errors.driverRearTreadDepth = "Select driver rear tread depth";

        if (isNullOrEmpty(inputValues.driverRearTireAndWheel)) errors.driverRearTireAndWheel = "Select driver rear tire and wheel";

        if (isNullOrEmpty(inputValues.passengerRearBrand)) errors.passengerRearBrand = "Select passenger rear brand";

        if (isNullOrEmpty(inputValues.passengerRearSize)) errors.passengerRearSize = "Select passenger rear size";

        if (isNullOrEmpty(inputValues.passengerRearTreadDepth)) errors.passengerRearTreadDepth = "Select passenger rear tread depth";

        if (isNullOrEmpty(inputValues.passengerRearTireAndWheel)) errors.passengerRearTireAndWheel = "Select passenger rear tire and wheel";


        if (inputValues.isRoadTestAvailable) {
            if (isNullOrEmpty(inputValues.engineStartingPerformance)) errors.engineStartingPerformance = "Select engine starting performance";
            if (isFairOrBad(inputValues.engineStartingPerformance) && isNullOrEmpty(inputValues.engineStartingPerformanceIssues)) errors.engineStartingPerformanceIssues = "Enter engine starting performance issues";

            if (isNullOrEmpty(inputValues.wheelBearingPerformance)) errors.wheelBearingPerformance = "Select wheel bearing performance";
            if (isFairOrBad(inputValues.wheelBearingPerformance) && isNullOrEmpty(inputValues.wheelBearingPerformanceIssues)) errors.wheelBearingPerformanceIssues = "Enter wheel bearing performance issues";

            if (isNullOrEmpty(inputValues.steeringPerformance)) errors.steeringPerformance = "Select steering performance";
            if (isFairOrBad(inputValues.steeringPerformance) && isNullOrEmpty(inputValues.steeringPerformanceIssues)) errors.steeringPerformanceIssues = "Enter steering performance issues";

            if (isNullOrEmpty(inputValues.suspensionPerformance)) errors.suspensionPerformance = "Select suspension performance";
            if (isFairOrBad(inputValues.suspensionPerformance) && isNullOrEmpty(inputValues.suspensionPerformanceIssues)) errors.suspensionPerformanceIssues = "Enter suspension performance issues";

            if (isNullOrEmpty(inputValues.transmissionPerformance)) errors.transmissionPerformance = "Select transmission performance";
            if (isFairOrBad(inputValues.transmissionPerformance) && isNullOrEmpty(inputValues.transmissionPerformanceIssues)) errors.transmissionPerformanceIssues = "Enter transmission performance issues";

            if (isNullOrEmpty(inputValues.differentialPerformance)) errors.differentialPerformance = "Select differential performance";
            if (isFairOrBad(inputValues.differentialPerformance) && isNullOrEmpty(inputValues.differentialPerformanceIssues)) errors.differentialPerformanceIssues = "Enter differential performance issues";

            if (isNullOrEmpty(inputValues.transferCasePerformance)) errors.transferCasePerformance = "Select transfer case performance";
            if (isFairOrBad(inputValues.transferCasePerformance) && isNullOrEmpty(inputValues.transferCasePerformanceIssues)) errors.transferCasePerformanceIssues = "Enter transfer case performance issues";

            if (isNullOrEmpty(inputValues.brakingPerformance)) errors.brakingPerformance = "Select braking performance";
            if (isFairOrBad(inputValues.brakingPerformance) && isNullOrEmpty(inputValues.brakingPerformanceIssues)) errors.brakingPerformanceIssues = "Enter braking performance issues";

            if (isNullOrEmpty(inputValues.interiorNoisePerformance)) errors.interiorNoisePerformance = "Select interior noise performance";
            if (isFairOrBad(inputValues.interiorNoisePerformance) && isNullOrEmpty(inputValues.interiorNoisePerformanceIssues)) errors.interiorNoisePerformanceIssues = "Enter interior noise performance issues";

            if (isNullOrEmpty(inputValues.overallEnginePerformance)) errors.overallEnginePerformance = "Select overall engine performance";
            if (isFairOrBad(inputValues.overallEnginePerformance) && isNullOrEmpty(inputValues.overallEnginePerformanceIssues)) errors.overallEnginePerformanceIssues = "Enter overall engine performance issues";

            if (isNullOrEmpty(inputValues.automaticStartStop)) errors.automaticStartStop = "Select automatic start/stop";

            if (isFairOrBad(inputValues.automaticStartStop) && isNullOrEmpty(inputValues.automaticStartStopIssues)) errors.automaticStartStopIssues = "Enter automatic start/stop issues";

            if (isNullOrEmpty(inputValues.cruiseControl)) errors.cruiseControl = "Select cruise control";
            if (isFairOrBad(inputValues.cruiseControl) && isNullOrEmpty(inputValues.cruiseControlIssues)) errors.cruiseControlIssues = "Enter cruise control issues";

            if (isNullOrEmpty(inputValues.blindSpotDetection)) errors.blindSpotDetection = "Select blind spot detection";
            if (isFairOrBad(inputValues.blindSpotDetection) && isNullOrEmpty(inputValues.blindSpotDetectionIssues)) errors.blindSpotDetectionIssues = "Enter blind spot detection issues";

            if (isNullOrEmpty(inputValues.laneAssist)) errors.laneAssist = "Select lane assist";
            if (isFairOrBad(inputValues.laneAssist) && isNullOrEmpty(inputValues.laneAssistIssues)) errors.laneAssistIssues = "Enter lane assist issues";

            if (isNullOrEmpty(inputValues.hillStartAssist)) errors.hillStartAssist = "Select hill start assist";
            if (isFairOrBad(inputValues.hillStartAssist) && isNullOrEmpty(inputValues.hillStartAssistIssues)) errors.hillStartAssistIssues = "Enter hill start assist issues";

            if (isNullOrEmpty(inputValues.parkingAssist)) errors.parkingAssist = "Select parking assist";
            if (isFairOrBad(inputValues.parkingAssist) && isNullOrEmpty(inputValues.parkingAssistIssues)) errors.parkingAssistIssues = "Enter parking assist issues";

            if (isNullOrEmpty(inputValues.backUpCamera)) errors.backUpCamera = "Select back-up camera";
            if (isFairOrBad(inputValues.backUpCamera) && isNullOrEmpty(inputValues.backUpCameraIssues)) errors.backUpCameraIssues = "Enter back-up camera issues";

            if (isNullOrEmpty(inputValues.autoPilotAssist)) errors.autoPilotAssist = "Select auto pilot assist";
            if (isFairOrBad(inputValues.autoPilotAssist) && isNullOrEmpty(inputValues.autoPilotAssistIssues)) errors.autoPilotAssistIssues = "Enter auto pilot assist issues";

            if (isNullOrEmpty(inputValues.roadTestNote)) errors.roadTestNote = "Comment about road test";

        } else {
            if (isNullOrEmpty(inputValues.roadTestAvailabilityReason)) errors.roadTestAvailabilityReason = "Select road test availability reason";
        }


        if (isNullOrEmpty(inputValues.summary)) errors.summary = "Complete a summary of inspection";

        const groupedCounts = uploadedFiles.reduce((accumulator, currentValue) => {
            // Extract the key for grouping
            const key = currentValue.subCategory;

            // Initialize the key in the accumulator if it doesn't exist
            if (!accumulator[key]) {
                accumulator[key] = 0;
            }

            // Increment the count for the key
            accumulator[key] += 1;

            return accumulator;
        }, {});


        if (Object.keys(groupedCounts).length == 0) {
            errors.files = "Please upload Files";
        }
        if (groupedCounts.VehicleInfo == undefined || groupedCounts.VehicleInfo < 5) {
            errors.VehicleInfo = "At least 5 photo should be uploaded for Vehicle Info";
        }
        if (groupedCounts.Exterior == undefined || groupedCounts.Exterior < 10) {
            errors.Exterior = "At least 10 photo should be uploaded for Exterior";
        }
        if (groupedCounts.Interior == undefined || groupedCounts.Interior < 10) {
            errors.Interior = "At least 10 photo should be uploaded for Interior";
        }
        if (groupedCounts.Mechanical == undefined || groupedCounts.Mechanical < 7) {
            errors.Mechanical = "At least 7 photo should be uploaded for Mechanical";
        }
        if (groupedCounts.Tires == undefined || groupedCounts.Tires < 4) {
            errors.Tires = "At least 4 photo should be uploaded for Tires";
        }

        return errors;
    };


    const getInspectionFiles = useCallback(async () => {
        axiosPrivate.get(`v1/file/${id}`).then(res => {
            setUploadedFiles(res.data.data);
        }).catch(error => {
            console.error('Error:', error);
        });;
    }, [uploadedFiles])

    const onUploadFile = async (section) => {
        const formData = new FormData();


        if (section == InspectionSection.VehicleInfo) {
            if (vehicleInfoAcceptedFiles.length == 0) return;
            vehicleInfoAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.Exterior) {
            if (exteriorAcceptedFiles.length == 0) return;
            exteriorAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.Interior) {
            if (interiorAcceptedFiles.length == 0) return;
            interiorAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.Mechanical) {
            if (mechanicalAcceptedFiles.length == 0) return;
            mechanicalAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.Tires) {
            if (tiresAcceptedFiles.length == 0) return;
            tiresAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.RoadTest) {
            if (roadTestAcceptedFiles.length == 0) return;
            roadTestAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.Summary) {
            if (summaryAcceptedFiles.length == 0) return;
            summaryAcceptedFiles.map(file => formData.append('files', file));
        } else {
            return;
        }

        formData.append('relationId', id);
        formData.append('subCategory', section);
        setUploadLoading(true);
        const response = await axiosPrivate.post(`v1/file/upload`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            setUploadLoading(false);
            getInspectionFiles();
            removeFiles(section);
            // setVehicleInfoFile(res.data.data);
        }).catch(error => {
            setUploadLoading(false);
        });
    }

    const handleRemoveImage = async (id) => {
        var deleteItem = uploadedFiles.find(t => t.id == id);
        if (!deleteItem) return;
        const response = await axiosPrivate.post(`v1/file/delete`, { id: id, uniqueName: deleteItem.uniqueName, extension: deleteItem.extension }).then(res => {
            getInspectionFiles();
        }).catch(error => {

        });
    }

    const onSubmitReport = async () => {

        setLoading(true);
        var error = validateFormItem(data);
        if (Object.keys(error).length > 0) {
            setLoading(false);
            setValidationError(error);
            return;
        }

        const response = await axiosPrivate.post(`v1/inspection/car`, { ...data, quotationId: id, isSubmitReport: true }).then(res => {
            setLoading(false);
            showModal({ show: true, message: "Your application successfully submitted!", isError: false });
        }).catch(error => {
            setLoading(false);
            showModal({ show: true, message: "An error occurred while submitting your report. Please try again. If the problem persists, contact support.", isError: true });
        });
    }

    useAutosave(() => { handleAutoSave(); }, 20 * 1000);

    const removeFiles = (section) => {
        if (section == InspectionSection.VehicleInfo) {
            vehicleInfoAcceptedFiles.length = 0;
            vehicleInfoAcceptedFiles.splice(0, vehicleInfoAcceptedFiles.length);
        }
        if (section == InspectionSection.Exterior) {
            exteriorAcceptedFiles.length = 0;
            exteriorAcceptedFiles.splice(0, exteriorAcceptedFiles.length);
        }
        if (section == InspectionSection.Interior) {
            interiorAcceptedFiles.length = 0;
            interiorAcceptedFiles.splice(0, interiorAcceptedFiles.length);
        }
        if (section == InspectionSection.Mechanical) {
            mechanicalAcceptedFiles.length = 0;
            mechanicalAcceptedFiles.splice(0, mechanicalAcceptedFiles.length);
        }
        if (section == InspectionSection.Tires) {
            tiresAcceptedFiles.length = 0;
            tiresAcceptedFiles.splice(0, tiresAcceptedFiles.length);
        }
        if (section == InspectionSection.RoadTest) {
            roadTestAcceptedFiles.length = 0;
            roadTestAcceptedFiles.splice(0, roadTestAcceptedFiles.length);
        }
        if (section == InspectionSection.Summary) {
            summaryAcceptedFiles.length = 0;
            summaryAcceptedFiles.splice(0, summaryAcceptedFiles.length);
        }
    }




    const { acceptedFiles: vehicleInfoAcceptedFiles, getRootProps: getVehicleInfoRootProps, getInputProps: getVehicleInfoInputProps, isDragActive: isVehicleDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (vehicleInfoAcceptedFiles) => {
        },
    });

    const { acceptedFiles: exteriorAcceptedFiles, getRootProps: getExteriorRootProps, getInputProps: getExteriorInputProps, isDragActive: isExteriorDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (exteriorAcceptedFiles) => {
        },
    });

    const { acceptedFiles: interiorAcceptedFiles, getRootProps: getInteriorRootProps, getInputProps: getInteriorInputProps, isDragActive: isInteriorDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (interiorAcceptedFiles) => {
        },
    });

    const { acceptedFiles: mechanicalAcceptedFiles, getRootProps: getMechanicalRootProps, getInputProps: getMechanicalInputProps, isDragActive: isMechanicalDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (mechanicalAcceptedFiles) => {
        },
    });

    const { acceptedFiles: tiresAcceptedFiles, getRootProps: getTiresRootProps, getInputProps: getTiresInputProps, isDragActive: isTiresDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (tiresAcceptedFiles) => {
        },
    });

    const { acceptedFiles: roadTestAcceptedFiles, getRootProps: getRoadTestRootProps, getInputProps: getRoadTestInputProps, isDragActive: isRoadTestDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (roadTestAcceptedFiles) => {
        },
    });

    const { acceptedFiles: summaryAcceptedFiles, getRootProps: getSummaryRootProps, getInputProps: getSummaryInputProps, isDragActive: isSummaryDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (summaryAcceptedFiles) => {
        },
    });

    return (
        <div>
            <div className="page-wrapper">
                <HerosectionHeader name={"Car Inspection"} />
                <div className="page-content"></div>
                {<Modal show={modal.show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{!modal.isError ? 'Report Submitted' : 'Error'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modal.message}</Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-dark" onClick={!modal.isError ? handleRedirectDashboard : handleClose}>Close</button>
                    </Modal.Footer>
                </Modal>}
                <section>
                    <Container>
                        <Row >
                            <Col lg={12} md={12}>
                                <div className="checkout-form border p-4 rounded">
                                    <h2 className="mb-4">Begin Inspection: {data.orderNumber}</h2>
                                    <div className="col-lg-12 col-12">
                                        <p className="lead mb-5">Carefully follow the process and fill out form</p>
                                        <div class="accordion" id="accordion">
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVehicleInfo" aria-expanded="true" aria-controls="collapseOne">
                                                        Vehicle Info
                                                    </button>
                                                </h2>
                                                <div id="collapseVehicleInfo" class="accordion-collapse border-0 collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="Make">Make</Label>
                                                                            <AutoCompleteInput
                                                                                name="makeId"
                                                                                label="Make"
                                                                                placeholder="Make"
                                                                                value={data.makeInfo}
                                                                                options={makes}
                                                                                onSuggestionClick={onMakeSelect}
                                                                                handleChange={(event) => updateFields({ makeInfo: event.target.value })}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="modelId">Model</Label>
                                                                            <AutoCompleteInput placeholder="Model" value={data.modelInfo} options={models} onSuggestionClick={onModelSelect} handleChange={(event) => updateFields({ modelInfo: event.target.value })} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="Year">Year</Label>
                                                                            <Input
                                                                                type="text"
                                                                                id="Year"
                                                                                name="Year"
                                                                                value={data.year}
                                                                                onChange={e => updateFields({ year: e.target.value })}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="mileage" size="xs">Mileage</Label>
                                                                            <Input
                                                                                type="text"
                                                                                id="mileage"
                                                                                name="mileage"
                                                                                value={data.mileage}
                                                                                onChange={e => updateFields({ mileage: e.target.value })}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="licensePlate">License Plate</Label>
                                                                            <Input
                                                                                type="text"
                                                                                id="licensePlate"
                                                                                name="License Plate"
                                                                                value={data.licensePlate}
                                                                                onChange={e => updateFields({ licensePlate: e.target.value })}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="vin">VIN</Label>
                                                                            <Input
                                                                                type="text"
                                                                                id="vin"
                                                                                name="vin"
                                                                                value={data.vin}
                                                                                onChange={e => updateFields({ vin: e.target.value })}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="bodyColor">Body Color</Label>
                                                                            <Input
                                                                                type="text"
                                                                                id="bodyColor"
                                                                                name="bodyColor"
                                                                                value={data.bodyColor}
                                                                                onChange={e => updateFields({ bodyColor: e.target.value })}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="interiorColor">Interior Color</Label>
                                                                            <Input
                                                                                type="text"
                                                                                id="interiorColor"
                                                                                name="interiorColor"
                                                                                value={data.interiorColor}
                                                                                onChange={e => updateFields({ interiorColor: e.target.value })}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="interiorMaterial">Interior Material</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="interiorMaterial"
                                                                                name="interiorMaterial"
                                                                                value={data.interiorMaterial}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={InteriorMaterial} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isOther(data.interiorMaterial) && <OtherInput text="Input Material" name="interiorMaterialInfo" value={data.interiorMaterialInfo} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="transmissionType">Transmission Type</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="transmissionType"
                                                                                name="transmissionType"
                                                                                value={data.transmissionType}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={TransmissionType} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isOther(data.transmissionType) && <OtherInput text="Input Transmission Type" name="transmissionTypeInfo" value={data.transmissionTypeInfo} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="engineLayout">Engine Layout</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="engineLayout"
                                                                                name="engineLayout"
                                                                                className="form-control"
                                                                                value={data.engineLayout}
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EngineLayout} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isOther(data.engineLayout) && <OtherInput text="Input Engine Layout" name="engineLayoutInfo" value={data.engineLayoutInfo} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="cylinderConfiguration">Cylinder Configuration</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="cylinderConfiguration"
                                                                                name="cylinderConfiguration"
                                                                                value={data.cylinderConfiguration}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={CylinderConfiguration} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isOther(data.cylinderConfiguration) && <OtherInput text="Input Cylinder Configuration" name="cylinderConfigurationInfo" value={data.cylinderConfigurationInfo} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="driveType">Drive Type</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="driveType"
                                                                                name="driveType"
                                                                                value={data.driveType}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={DriveType} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isOther(data.driveType) && <OtherInput text="Input Drive Type" name="driveTypeInfo" value={data.driveTypeInfo} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="fuelType">Fuel Type</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="fuelType"
                                                                                name="fuelType"
                                                                                value={data.fuelType}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={FuelType} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isOther(data.fuelType) && <OtherInput text="Input Fuel Type" name="fuelTypeInfo" value={data.fuelTypeInfo} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="vehicleInfoNote">Comments about vehicle info</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="vehicleInfoNote"
                                                                        name="vehicleInfoNote"
                                                                        value={data.vehicleInfoNote}
                                                                        onChange={e => updateFields({ vehicleInfoNote: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="vehicleInfoFileInput">Please upload at least 5 well-taken and explanative pictures in the Vehicle Info section. This should include clear images of the VIN, odometer reading, license plate, and any manufacturer labels or stickers that provide important vehicle information. Select your pictures by clicking the box first, then click the <strong>'Upload Vehicle Info Pictures'</strong> button on the right to submit them.</Label></Col>
                                                            <Col md={10}>
                                                                <FormGroup>
                                                                    <div name="vehicleInfoFileInput" className="dropzone" {...getVehicleInfoRootProps()}>
                                                                        <input type="file" {...getVehicleInfoInputProps()} />
                                                                        {
                                                                            vehicleInfoAcceptedFiles.length > 0 ? <p>{vehicleInfoAcceptedFiles.length} file selected</p> :
                                                                                isVehicleDragActive ?
                                                                                    <p>Drop the files here ...</p> :
                                                                                    <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                        }
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={2}>
                                                                <SpinnerButton onClick={() => onUploadFile(InspectionSection.VehicleInfo)} disabled={vehicleInfoAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Vehicle Info Pictures" loading={uploadLoading} />
                                                            </Col>
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.VehicleInfo).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingTwo">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Exterior
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="bodyAlignment" size="xs">Body Alignment Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="bodyAlignment"
                                                                                name="bodyAlignment"
                                                                                className="form-control"
                                                                                value={data.bodyAlignment}
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.bodyAlignment) && <OtherInput text="Body Allignment Issues" name="bodyAlignmentIssues" value={data.bodyAlignmentIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="paint">Paint Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="paint"
                                                                                name="paint"
                                                                                className="form-control"
                                                                                value={data.paint}
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>{isFairOrBad(data.paint) && <OtherInput text="Paint Issues" name="paintIssues" value={data.paintIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="frame" size="xs">Frame Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="frame"
                                                                                name="frame"
                                                                                className="form-control"
                                                                                value={data.frame}
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.frame) && <OtherInput text="Frame Issues" name="frameIssues" value={data.frameIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="glassAndWindow">Glass & Window Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="glassAndWindow"
                                                                                name="glassAndWindow"
                                                                                value={data.glassAndWindow}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.glassAndWindow) && <OtherInput text="Glass & Window Issues" name="glassAndWindowIssues" value={data.glassAndWindowIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="scratches" size="xs">Scratches</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="scratches"
                                                                                name="scratches"
                                                                                value={data.scratches}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Scratches} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.scratches) && <OtherInput text="Scratches Issues" name="scratchesIssues" value={data.scratchesIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="dings">Dings</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="dings"
                                                                                name="dings"
                                                                                value={data.dings}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Dings} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.dings) && <OtherInput text="Dings Issues" name="dingsIssues" value={data.dingsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="dents" size="xs">Dents</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="dents"
                                                                                name="dents"
                                                                                value={data.dents}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Dents} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.dents) && <OtherInput text="Dents Issues" name="dentsIssues" value={data.dentsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="rust">Rust</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="rust"
                                                                                name="rust"
                                                                                value={data.rust}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Rust} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.rust) && <OtherInput text="Rust Issues" name="rustIssues" value={data.rustIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="bodySeals" size="xs">Body Seals Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="bodySeals"
                                                                                name="bodySeals"
                                                                                value={data.bodySeals}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.bodySeals) && <OtherInput text="Body Seals Issues" name="bodySealsIssues" value={data.bodySealsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="sideMoldings">Side Moldings Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="sideMoldings"
                                                                                name="sideMoldings"
                                                                                value={data.sideMoldings}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.sideMoldings) && <OtherInput text="Side Moldings Issues" name="sideMoldingsIssues" value={data.sideMoldingsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="sideMirrors" size="xs">Side Mirrors Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="sideMirrors"
                                                                                name="sideMirrors"
                                                                                value={data.sideMirrors}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.sideMirrors) && <OtherInput text="Side Mirrors Issues" name="sideMirrorsIssues" value={data.sideMirrorsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="exteriorLights">Exterior Lights Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="exteriorLights"
                                                                                name="exteriorLights"
                                                                                value={data.exteriorLights}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.exteriorLights) && <OtherInput text="Exterior Lights Issues" name="exteriorLightsIssues" value={data.exteriorLightsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="leftFrontFender" size="xs">Left Front Fender Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="leftFrontFender"
                                                                                name="leftFrontFender"
                                                                                value={data.leftFrontFender}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.leftFrontFender) && <OtherInput text="Left Front Fender Issues" name="leftFrontFenderIssues" value={data.leftFrontFenderIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="rightFrontFender">Right Front Fender Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="rightFrontFender"
                                                                                name="rightFrontFender"
                                                                                value={data.rightFrontFender}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.rightFrontFender) && <OtherInput text="Right Front Fender Issues" name="rightFrontFenderIssues" value={data.rightFrontFenderIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="grilleAndFrontBumper" size="xs">Grille & Front Bumper Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="grilleAndFrontBumper"
                                                                                name="grilleAndFrontBumper"
                                                                                value={data.grilleAndFrontBumper}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.grilleAndFrontBumper) && <OtherInput text="Grille & Front Bumper Issues" name="grilleAndFrontBumperIssues" value={data.grilleAndFrontBumperIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="hood">Hood Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="hood"
                                                                                name="hood"
                                                                                value={data.hood}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.hood) && <OtherInput text="Hood Issues" name="hoodIssues" value={data.hoodIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="leftFrontDoor" size="xs">Left Front Door Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="leftFrontDoor"
                                                                                name="leftFrontDoor"
                                                                                value={data.leftFrontDoor}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.leftFrontDoor) && <OtherInput text="Left Front Door Issues" name="leftFrontDoorIssues" value={data.leftFrontDoorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="leftRearDoor">Left Rear Door Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="leftRearDoor"
                                                                                name="leftRearDoor"
                                                                                value={data.leftRearDoor}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.leftRearDoor) && <OtherInput text="Left Rear Door Issues" name="leftRearDoorIssues" value={data.leftRearDoorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="leftRearFender" size="xs">Left Rear Fender Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="leftRearFender"
                                                                                name="leftRearFender"
                                                                                value={data.leftRearFender}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.leftRearFender) && <OtherInput text="Left Rear Fender Issues" name="leftRearFenderIssues" value={data.leftRearFenderIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="rightRearFender">Right Rear Fender Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="rightRearFender"
                                                                                name="rightRearFender"
                                                                                value={data.rightRearFender}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.rightRearFender) && <OtherInput text="Right Rear Fender Issues" name="rightRearFenderIssues" value={data.rightRearFenderIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="rearBumper" size="xs">Rear Bumper Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="rearBumper"
                                                                                name="rearBumper"
                                                                                value={data.rearBumper}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.rearBumper) && <OtherInput text="Rear Bumper Issues" name="rearBumperIssues" value={data.rearBumperIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="trunk">Trunk Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="trunk"
                                                                                name="trunk"
                                                                                value={data.trunk}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.trunk) && <OtherInput text="Trunk Issues" name="trunkIssues" value={data.trunkIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="rightFrontDoor" size="xs">Right Front Door Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="rightFrontDoor"
                                                                                name="rightFrontDoor"
                                                                                value={data.rightFrontDoor}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.rightFrontDoor) && <OtherInput text="Right Front Door Issues" name="rightFrontDoorIssues" value={data.rightFrontDoorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="rightRearDoor">Right Rear Door Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="rightRearDoor"
                                                                                name="rightRearDoor"
                                                                                value={data.rightRearDoor}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.rightRearDoor) && <OtherInput text="Right Rear Door Issues" name="rightRearDoorIssues" value={data.rightRearDoorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="roof" size="xs">Roof Panel Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="roof"
                                                                                name="roof"
                                                                                value={data.roof}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.roof) && <OtherInput text="Roof Issues" name="roofIssues" value={data.roofIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="sunroof">Sunroof Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="sunroof"
                                                                                name="sunroof"
                                                                                value={data.sunroof}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.sunroof) && <OtherInput text="Sunroof Issues" name="sunroofIssues" value={data.sunroofIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="wiperBlades">Wiper Blades Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="wiperBlades"
                                                                                name="wiperBlades"
                                                                                value={data.wiperBlades}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.wiperBlades) && <OtherInput text="Wiper Blades Issues" name="wiperBladesIssues" value={data.wiperBladesIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="windshieldWasher">Windshield Washer Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="windshieldWasher"
                                                                                name="windshieldWasher"
                                                                                value={data.windshieldWasher}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.windshieldWasher) && <OtherInput text="Windshield Washer Issues" name="windshieldWasherIssues" value={data.windshieldWasherIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="exteriorNote">Comments about exterior condition of vehicle</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="exteriorNote"
                                                                        name="exteriorNote"
                                                                        value={data.exteriorNote}
                                                                        onChange={e => updateFields({ exteriorNote: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="exteriorFileInput">Please provide a minimum of 10 well-taken and explanative pictures of the vehicle's exterior. Ensure to capture all sides (front, rear, left, and right), as well as close-ups of any noticeable dents, scratches, rust spots, or other imperfections. Include images of the headlights, taillights, mirrors, and windows. Select your pictures by clicking the box first, then click the <strong>'Upload Exterior Pictures'</strong> button on the right to submit them.</Label></Col>
                                                            <Col md={10}>
                                                                <FormGroup>
                                                                    <div name="exteriorFileInput" className="dropzone" {...getExteriorRootProps()}>
                                                                        <input type="file" {...getExteriorInputProps()} />
                                                                        {
                                                                            exteriorAcceptedFiles.length > 0 ? <p>{exteriorAcceptedFiles.length} file selected</p> :
                                                                                isExteriorDragActive ?
                                                                                    <p>Drop the files here ...</p> :
                                                                                    <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                        }
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={2}>
                                                                <SpinnerButton onClick={() => onUploadFile(InspectionSection.Exterior)} disabled={exteriorAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Exterior Pictures" loading={uploadLoading} />
                                                            </Col>
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.Exterior).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Interior
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse border-0 collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="steeringWheel">Steering Wheel Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="steeringWheel"
                                                                                name="steeringWheel"
                                                                                value={data.steeringWheel}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.steeringWheel) && <OtherInput text="Steering Wheel Issues" name="steeringWheelIssues" value={data.steeringWheelIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="horn">Horn Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="horn"
                                                                                name="horn"
                                                                                value={data.horn}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={HornCondition} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.horn) && <OtherInput text="Horn Issues" name="hornIssues" value={data.hornIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="seats">Seats Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="seats"
                                                                                name="seats"
                                                                                value={data.seats}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.seats) && <OtherInput text="Seats Issues" name="seatsIssues" value={data.seatsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="seatMechanisms">Seat Mechanisms Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="seatMechanisms"
                                                                                name="seatMechanisms"
                                                                                value={data.seatMechanisms}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.seatMechanisms) && <OtherInput text="Seat Mechanisms Issues" name="seatMechanismsIssues" value={data.seatMechanismsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="sunVisors">Sun Visors Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="sunVisors"
                                                                                name="sunVisors"
                                                                                value={data.sunVisors}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.sunVisors) && <OtherInput text="Sun Visors Issues" name="sunVisorsIssues" value={data.sunVisorsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="headliner" size="xs">Headliner Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="headliner"
                                                                                name="headliner"
                                                                                value={data.headliner}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.headliner) && <OtherInput text="Headliner Issues" name="headlinerIssues" value={data.headlinerIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="innerDoorPanels">Inner Door Panels Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="innerDoorPanels"
                                                                                name="innerDoorPanels"
                                                                                value={data.innerDoorPanels}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.innerDoorPanels) && <OtherInput text="Inner Door Panels Issues" name="innerDoorPanelsIssues" value={data.innerDoorPanelsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="interiorTrim" size="xs">Interior Trim Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="interiorTrim"
                                                                                name="interiorTrim"
                                                                                value={data.interiorTrim}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.interiorTrim) && <OtherInput text="Interior Trim Issues" name="interiorTrimIssues" value={data.interiorTrimIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="interiorLights">Interior Lights Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="interiorLights"
                                                                                name="interiorLights"
                                                                                value={data.interiorLights}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.interiorLights) && <OtherInput text="Interior Lights Issues" name="interiorLightsIssues" value={data.interiorLightsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="powerLocks" size="xs">Power Locks Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="powerLocks"
                                                                                name="powerLocks"
                                                                                value={data.powerLocks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.powerLocks) && <OtherInput text="Power Locks Issues" name="powerLocksIssues" value={data.powerLocksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="windowSwitches">Window Switches Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="windowSwitches"
                                                                                name="windowSwitches"
                                                                                value={data.windowSwitches}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.windowSwitches) && <OtherInput text="Window Switches Issues" name="windowSwitchesIssues" value={data.windowSwitchesIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="windowFunctioning" size="xs">Window Functioning Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="windowFunctioning"
                                                                                name="windowFunctioning"
                                                                                value={data.windowFunctioning}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.windowFunctioning) && <OtherInput text="Window Functioning Issues" name="windowFunctioningIssues" value={data.windowFunctioningIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="trunkActuator" size="xs">Trunk Actuator</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="trunkActuator"
                                                                                name="trunkActuator"
                                                                                value={data.trunkActuator}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.trunkActuator) && <OtherInput text="Trunk Actuator Issues" name="trunkActuatorIssues" value={data.trunkActuatorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="hoodActuator" size="xs">Hood Actuator</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="hoodActuator"
                                                                                name="hoodActuator"
                                                                                value={data.hoodActuator}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.hoodActuator) && <OtherInput text="Hood Actuator Issues" name="hoodActuatorIssues" value={data.hoodActuatorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="fuelDoorActuator" size="xs">Fuel Door Actuator</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="fuelDoorActuator"
                                                                                name="fuelDoorActuator"
                                                                                value={data.fuelDoorActuator}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.fuelDoorActuator) && <OtherInput text="Fuel Door Actuator Issues" name="fuelDoorActuatorIssues" value={data.fuelDoorActuatorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="sunroofActuator" size="xs">Sunroof Actuator</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="sunroofActuator"
                                                                                name="sunroofActuator"
                                                                                value={data.sunroofActuator}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.sunroofActuator) && <OtherInput text="Sunroof Actuator Issues" name="sunroofActuatorIssues" value={data.sunroofActuatorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="convertibleActuator" size="xs">Convertible Actuator</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="convertibleActuator"
                                                                                name="convertibleActuator"
                                                                                value={data.convertibleActuator}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.convertibleActuator) && <OtherInput text="Convertible Actuator Issues" name="convertibleActuatorIssues" value={data.convertibleActuatorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="dash">Dash Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="dash"
                                                                                name="dash"
                                                                                value={data.dash}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.dash) && <OtherInput text="Dash Issues" name="dashIssues" value={data.dashIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="dashLights" size="xs">Dash Lights</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="dashLights"
                                                                                name="dashLights"
                                                                                value={data.dashLights}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.dashLights) && <OtherInput text="Dash Lights Issues" name="dashLightsIssues" value={data.dashLightsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="dashWarnings">Dash Warnings</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="dashWarnings"
                                                                                name="dashWarnings"
                                                                                value={data.dashWarnings}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Warnings} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.dashWarnings) && <OtherInput text="Dash Warnings Issues" name="dashWarningsIssues" value={data.dashWarningsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="dashGauges">Dash Gauges</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="dashGauges"
                                                                                name="dashGauges"
                                                                                value={data.dashGauges}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.dashGauges) && <OtherInput text="Dash Gauges Issues" name="dashGaugesIssues" value={data.dashGaugesIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="centerConsole" size="xs">Center Console Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="centerConsole"
                                                                                name="centerConsole"
                                                                                value={data.centerConsole}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.centerConsole) && <OtherInput text="Center Console Issues" name="centerConsoleIssues" value={data.centerConsoleIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="cupholder">Cupholder Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="cupholder"
                                                                                name="cupholder"
                                                                                value={data.cupholder}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.cupholder) && <OtherInput text="Cupholder Issues" name="cupholderIssues" value={data.cupholderIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="radio">Radio Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="radio"
                                                                                name="radio"
                                                                                value={data.radio}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.radio) && <OtherInput text="Radio Issues" name="radioIssues" value={data.radioIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="navigation" size="xs">Navigation Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="navigation"
                                                                                name="navigation"
                                                                                value={data.navigation}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.navigation) && <OtherInput text="Navigation Issues" name="navigationIssues" value={data.navigationIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="airbags">Airbags Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="airbags"
                                                                                name="airbags"
                                                                                value={data.airbags}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.airbags) && <OtherInput text="Airbags Issues" name="airbagsIssues" value={data.airbagsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="seatBelts" size="xs">Seat Belts Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="seatBelts"
                                                                                name="seatBelts"
                                                                                value={data.seatBelts}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.seatBelts) && <OtherInput text="Seat Belts Issues" name="seatBeltsIssues" value={data.seatBeltsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="AC">AC Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="ac"
                                                                                name="ac"
                                                                                value={data.ac}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.ac) && <OtherInput text="AC Issues" name="acIssues" value={data.acIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="heater" size="xs">Heater Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="heater"
                                                                                name="heater"
                                                                                value={data.heater}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.heater) && <OtherInput text="Heater Issues" name="heaterIssues" value={data.heaterIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="rearDefroster">Rear Defroster Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="rearDefroster"
                                                                                name="rearDefroster"
                                                                                value={data.rearDefroster}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.rearDefroster) && <OtherInput text="Rear Defroster Issues" name="rearDefrosterIssues" value={data.rearDefrosterIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="blowerMotor" size="xs">Blower Motor Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="blowerMotor"
                                                                                name="blowerMotor"
                                                                                value={data.blowerMotor}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.blowerMotor) && <OtherInput text="Blower Motor Issues" name="blowerMotorIssues" value={data.blowerMotorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="seatHeater">Seat Heater Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="seatHeater"
                                                                                name="seatHeater"
                                                                                value={data.seatHeater}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.seatHeater) && <OtherInput text="Seat Heater Issues" name="seatHeaterIssues" value={data.seatHeaterIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="carpet" size="xs">Carpet Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="carpet"
                                                                                name="carpet"
                                                                                value={data.carpet}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.carpet) && <OtherInput text="Carpet Issues" name="carpetIssues" value={data.carpetIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="floorMat" size="xs">Floor Mat Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="floorMat"
                                                                                name="floorMat"
                                                                                value={data.floorMat}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.floorMat) && <OtherInput text="Floor Mat Issues" name="floorMatIssues" value={data.floorMatIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="cigaretteUse">Cigarette Use</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="cigaretteUse"
                                                                                name="cigaretteUse"
                                                                                value={data.cigaretteUse}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={CigaretteUse} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.cigaretteUse) && <OtherInput text="Cigarette Use Issues" name="cigaretteUseIssues" value={data.cigaretteUseIssues} updateFields={updateFields} />}
                                                                    <Col md={6}>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="unusualOdors" size="xs">Unusual Odors</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="unusualOdors"
                                                                                name="unusualOdors"
                                                                                value={data.unusualOdors}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={UnusualOdors} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.unusualOdors) && <OtherInput text="Unusual Odors Issues" name="unusualOdorsIssues" value={data.unusualOdorsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="interiorNote">Comments about interior condition of vehicle</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="interiorNote"
                                                                        name="interiorNote"
                                                                        value={data.interiorNote}
                                                                        onChange={e => updateFields({ interiorNote: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="interiorFileInput">Please include at least 10 well-taken and explanative pictures of the vehicle's interior. Focus on the condition of the seats (front and rear), steering wheel, dashboard, center console, and door panels. Additionally, capture the condition of the floor mats, headliner, and any interior features such as the infotainment system, climate controls, and instrument cluster. Select your pictures by clicking the box first, then click the <strong>'Upload Interior Pictures'</strong> button on the right to submit them.</Label></Col>
                                                            <Col md={10}>
                                                                <FormGroup>
                                                                    <div name="interiorFileInput" className="dropzone" {...getInteriorRootProps()}>
                                                                        <input type="file" {...getInteriorInputProps()} />
                                                                        {
                                                                            interiorAcceptedFiles.length > 0 ? <p>{interiorAcceptedFiles.length} file selected</p> :
                                                                                isInteriorDragActive ?
                                                                                    <p>Drop the files here ...</p> :
                                                                                    <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                        }
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={2}>
                                                                <SpinnerButton onClick={() => onUploadFile(InspectionSection.Interior)} disabled={interiorAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Interior Pictures" loading={uploadLoading} />
                                                            </Col>
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.Interior).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingFour">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        Mechanical
                                                    </button>
                                                </h2>
                                                <div id="collapseFour" class="accordion-collapse border-0 collapse" aria-labelledby="headingFour" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="keyFOB">Key FOB</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="keyFOB"
                                                                                name="keyFOB"
                                                                                value={data.keyFOB}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.keyFOB) && <OtherInput text="Key FOB Issues" name="keyFOBIssues" value={data.keyFOBIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="computerDiagnosisAndOBD2">Computer Diagnosis / OBD2</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="computerDiagnosisAndOBD2"
                                                                                name="computerDiagnosisAndOBD2"
                                                                                value={data.computerDiagnosisAndOBD2}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.computerDiagnosisAndOBD2) && <OtherInput text="Computer Diagnosis/OBD2 Issues" name="computerDiagnosisAndOBD2Issues" value={data.computerDiagnosisAndOBD2Issues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="battery">Battery Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="battery"
                                                                                name="battery"
                                                                                value={data.battery}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.battery) && <OtherInput text="Battery Issues" name="batteryIssues" value={data.batteryIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="chargingSystem">Charging System</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="chargingSystem"
                                                                                name="chargingSystem"
                                                                                value={data.chargingSystem}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.chargingSystem) && <OtherInput text="Charging System Issues" name="chargingSystemIssues" value={data.chargingSystemIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="alternator">Alternator Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="alternator"
                                                                                name="alternator"
                                                                                value={data.alternator}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.alternator) && <OtherInput text="Alternator Issues" name="alternatorIssues" value={data.alternatorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="starter" size="xs">Starter Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="starter"
                                                                                name="starter"
                                                                                value={data.starter}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.starter) && <OtherInput text="Starter Issues" name="starterIssues" value={data.starterIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="belts" size="xs">Belts Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="belts"
                                                                                name="belts"
                                                                                value={data.belts}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.belts) && <OtherInput text="Belts Issues" name="beltsIssues" value={data.beltsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="hoses">Hoses Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="hoses"
                                                                                name="hoses"
                                                                                value={data.hoses}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.hoses) && <OtherInput text="Hoses Issues" name="hosesIssues" value={data.hosesIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="airFilter" size="xs">Air Filter Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="airFilter"
                                                                                name="airFilter"
                                                                                value={data.airFilter}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.airFilter) && <OtherInput text="Air Filter Issues" name="airFilterIssues" value={data.airFilterIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="coolingFan">Cooling Fan Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="coolingFan"
                                                                                name="coolingFan"
                                                                                value={data.coolingFan}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.coolingFan) && <OtherInput text="Cooling Fan Issues" name="coolingFanIssues" value={data.coolingFanIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="radiatorAndLeaks" size="xs">Radiator Condition and Leaks</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="radiatorAndLeaks"
                                                                                name="radiatorAndLeaks"
                                                                                value={data.radiatorAndLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.radiatorAndLeaks) && <OtherInput text="Radiator Condition and Leaks Issues" name="radiatorAndLeaksIssues" value={data.radiatorAndLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="coolantSystemAndLeaks">Coolant System And Leaks</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="coolantSystemAndLeaks"
                                                                                name="coolantSystemAndLeaks"
                                                                                value={data.coolantSystemAndLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.coolantSystemAndLeaks) && <OtherInput text="Coolant System and Leaks Issues" name="coolantSystemAndLeaksIssues" value={data.coolantSystemAndLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="powerSteeringSystemAndFluid" size="xs">Power Steering System and Fluid</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="powerSteeringSystemAndFluid"
                                                                                name="powerSteeringSystemAndFluid"
                                                                                value={data.powerSteeringSystemAndFluid}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.powerSteeringSystemAndFluid) && <OtherInput text="Power Steering System and Fluid Issues" name="powerSteeringSystemAndFluidIssues" value={data.powerSteeringSystemAndFluidIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="powerSteeringFluidLeaks" size="xs">Power Steering Fluid Leaks</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="powerSteeringFluidLeaks"
                                                                                name="powerSteeringFluidLeaks"
                                                                                value={data.powerSteeringFluidLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Leaks} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.powerSteeringFluidLeaks) && <OtherInput text="Power Steering Fluid Leaks Issues" name="powerSteeringFluidLeaksIssues" value={data.powerSteeringFluidLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="engineBlockAndOil">Engine Block and Oil Level</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="engineBlockAndOil"
                                                                                name="engineBlockAndOil"
                                                                                value={data.engineBlockAndOil}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.engineBlockAndOil) && <OtherInput text="Engine Block and Oil Level Issues" name="engineBlockAndOilIssues" value={data.engineBlockAndOilIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="engineFluidAndOilLeaks" size="xs">Engine Fluid and Oil Leaks</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="engineFluidAndOilLeaks"
                                                                                name="engineFluidAndOilLeaks"
                                                                                value={data.engineFluidAndOilLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Leaks} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.engineFluidAndOilLeaks) && <OtherInput text="Engine Fluid And Oil Leaks Issues" name="engineFluidAndOilLeaksIssues" value={data.engineFluidAndOilLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="transmissionSystemAndFluid">Transmission System And Fluid</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="transmissionSystemAndFluid"
                                                                                name="transmissionSystemAndFluid"
                                                                                value={data.transmissionSystemAndFluid}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.transmissionSystemAndFluid) && <OtherInput text="Transmission System And Fluid Issues" name="transmissionSystemAndFluidIssues" value={data.transmissionSystemAndFluidIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="transmissionFluidLeaks" size="xs">Transmission Fluid Leaks</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="transmissionFluidLeaks"
                                                                                name="transmissionFluidLeaks"
                                                                                value={data.transmissionFluidLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.transmissionFluidLeaks) && <OtherInput text="Transmission Fluid Leaks Issues" name="transmissionFluidLeaksIssues" value={data.transmissionFluidLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="brakeSystemAndPads">Brake System and Pads</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="brakeSystemAndPads"
                                                                                name="brakeSystemAndPads"
                                                                                value={data.brakeSystemAndPads}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.brakeSystemAndPads) && <OtherInput text="Brake System and Pads Issues" name="brakeSystemAndPadsIssues" value={data.brakeSystemAndPadsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="brakeFluidLeaks" size="xs">Brake Fluid Leaks</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="brakeFluidLeaks"
                                                                                name="brakeFluidLeaks"
                                                                                value={data.brakeFluidLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Leaks} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.brakeFluidLeaks) && <OtherInput text="Brake Fluid Leaks Issues" name="brakeFluidLeaksIssues" value={data.brakeFluidLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="differentialSystem">Differential System</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="differentialSystem"
                                                                                name="differentialSystem"
                                                                                value={data.differentialSystem}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.differentialSystem) && <OtherInput text="Differential System Issues" name="differentialSystemIssues" value={data.differentialSystemIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="differentialFluidLeaks" size="xs">Differential Fluid Leaks</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="differentialFluidLeaks"
                                                                                name="differentialFluidLeaks"
                                                                                value={data.differentialFluidLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Leaks} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.differentialFluidLeaks) && <OtherInput text="Differential Fluid Leaks Issues" name="differentialFluidLeaksIssues" value={data.differentialFluidLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="catalyticConverter" size="xs">Catalytic Converter</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="catalyticConverter"
                                                                                name="catalyticConverter"
                                                                                value={data.catalyticConverter}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.catalyticConverter) && <OtherInput text="Catalytic Converter Issues" name="catalyticConverterIssues" value={data.catalyticConverterIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="mufflerSystem" size="xs">Muffler System</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="mufflerSystem"
                                                                                name="mufflerSystem"
                                                                                value={data.mufflerSystem}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.mufflerSystem) && <OtherInput text="Muffler System Issues" name="mufflerSystemIssues" value={data.mufflerSystemIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="cvJointsAndAxles">CV Joints and Axles</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="cvJointsAndAxles"
                                                                                name="cvJointsAndAxles"
                                                                                value={data.cvJointsAndAxles}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.cvJointsAndAxles) && <OtherInput text="CV Joints and Axles Issues" name="cvJointsAndAxlesIssues" value={data.cvJointsAndAxlesIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="shocksAndStruts" size="xs">Shocks and Struts</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="shocksAndStruts"
                                                                                name="shocksAndStruts"
                                                                                value={data.shocksAndStruts}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.shocksAndStruts) && <OtherInput text="Shocks and Struts Issues" name="shocksAndStrutsIssues" value={data.shocksAndStrutsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="bushingsBallJointsRodsAndArms">Bushings, Ball Joints, Rods and Arms</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="bushingsBallJointsRodsAndArms"
                                                                                name="bushingsBallJointsRodsAndArms"
                                                                                value={data.bushingsBallJointsRodsAndArms}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.bushingsBallJointsRodsAndArms) && <OtherInput text="Bushings, Ball Joints, Rods, and Arms Issues" name="bushingsBallJointsRodsAndArmsIssues" value={data.bushingsBallJointsRodsAndArmsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="undercarriage">Undercarriage Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="undercarriage"
                                                                                name="undercarriage"
                                                                                value={data.undercarriage}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.undercarriage) && <OtherInput text="Undercarriage Issues" name="undercarriageIssues" value={data.undercarriageIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="mechanicalNote">Comments about mechanical condition of vehicle</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="mechanicalNote"
                                                                        name="mechanicalNote"
                                                                        value={data.mechanicalNote}
                                                                        onChange={e => updateFields({ mechanicalNote: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="mechanicalFileInput">Please include at least 7 well-taken and explanative pictures of the vehicle's mechanical components. Focus on the engine bay, including the engine itself, belts, hoses, and fluid levels. Capture images of the undercarriage, highlighting any leaks, rust, or damage. Additionally, include pictures of the brakes, suspension components, and any other relevant mechanical parts. Select your pictures by clicking the box first, then click the <strong>'Upload Mechanical Pictures'</strong> button on the right to submit them.</Label></Col>
                                                            <Col md={10}>
                                                                <FormGroup>
                                                                    <div name="mechanicalFileInput" className="dropzone" {...getMechanicalRootProps()}>
                                                                        <input type="file" {...getMechanicalInputProps()} />
                                                                        {
                                                                            mechanicalAcceptedFiles.length > 0 ? <p>{mechanicalAcceptedFiles.length} file selected</p> :
                                                                                isMechanicalDragActive ?
                                                                                    <p>Drop the files here ...</p> :
                                                                                    <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                        }
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={2}>
                                                                <SpinnerButton onClick={() => onUploadFile(InspectionSection.Mechanical)} disabled={mechanicalAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Mechanical Pictures" loading={uploadLoading} />
                                                            </Col>
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.Mechanical).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingFive">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                        Tires
                                                    </button>
                                                </h2>
                                                <div id="collapseFive" class="accordion-collapse border-0 collapse" aria-labelledby="headingFive" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="driverFrontBrand">Driver Front Tire Brand</Label>
                                                                    <Input
                                                                        type="select"
                                                                        id="driverFrontBrand"
                                                                        name="driverFrontBrand"
                                                                        className="form-control"
                                                                        value={data.driverFrontBrand}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={TireBrand} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="driverFrontSize">Driver Front Tire Size</Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="driverFrontSize"
                                                                        name="driverFrontSize"
                                                                        value={data.driverFrontSize}
                                                                        onChange={e => updateFields({ driverFrontSize: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="driverFrontTreadDepth">Driver Front Tire Tread Depth</Label>
                                                                    <Input
                                                                        type="select"
                                                                        id="driverFrontTreadDepth"
                                                                        name="driverFrontTreadDepth"
                                                                        className="form-control"
                                                                        value={data.driverFrontTreadDepth}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={TireTreadDepth} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="driverFrontTireAndWheel">Driver Front Tire and Wheel Condition</Label>
                                                                    <Input
                                                                        type="select"
                                                                        id="driverFrontTireAndWheel"
                                                                        name="driverFrontTireAndWheel"
                                                                        className="form-control"
                                                                        value={data.driverFrontTireAndWheel}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={EFBNA} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="driverRearBrand">Driver Rear Tire Brand</Label>
                                                                    <Input
                                                                        type="select"
                                                                        id="driverRearBrand"
                                                                        name="driverRearBrand"
                                                                        className="form-control"
                                                                        value={data.driverRearBrand}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={TireBrand} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="driverRearSize">Driver Rear Tire Size</Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="driverRearSize"
                                                                        name="driverRearSize"
                                                                        value={data.driverRearSize}
                                                                        onChange={e => updateFields({ driverRearSize: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="driverRearTreadDepth">Driver Rear Tire Tread Depth</Label>
                                                                    <Input
                                                                        type="select"
                                                                        id="driverRearTreadDepth"
                                                                        name="driverRearTreadDepth"
                                                                        className="form-control"
                                                                        value={data.driverRearTreadDepth}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={TireTreadDepth} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="driverRearTireAndWheel">Driver Rear Tire and Wheel Condition</Label>
                                                                    <Input
                                                                        type="select"
                                                                        id="driverRearTireAndWheel"
                                                                        name="driverRearTireAndWheel"
                                                                        className="form-control"
                                                                        value={data.driverRearTireAndWheel}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={EFBNA} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="passengerFrontBrand">Passenger Front Tire Brand</Label>
                                                                    <Input
                                                                        type="select"
                                                                        id="passengerFrontBrand"
                                                                        name="passengerFrontBrand"
                                                                        className="form-control"
                                                                        value={data.passengerFrontBrand}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={TireBrand} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="passengerFrontSize">Passenger Front Tire Size</Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="passengerFrontSize"
                                                                        name="passengerFrontSize"
                                                                        value={data.passengerFrontSize}
                                                                        onChange={e => updateFields({ passengerFrontSize: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="passengerFrontTreadDepth">Passenger Front Tire Tread Depth</Label>
                                                                    <Input
                                                                        type="select"
                                                                        id="passengerFrontTreadDepth"
                                                                        name="passengerFrontTreadDepth"
                                                                        className="form-control"
                                                                        value={data.passengerFrontTreadDepth}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={TireTreadDepth} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="passengerFrontTireAndWheel">Passenger Front Tire and Wheel Condition</Label>
                                                                    <Input
                                                                        type="select"
                                                                        id="passengerFrontTireAndWheel"
                                                                        name="passengerFrontTireAndWheel"
                                                                        className="form-control"
                                                                        value={data.passengerFrontTireAndWheel}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={EFBNA} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="passengerRearBrand">Passenger Rear Tire Brand</Label>
                                                                    <Input
                                                                        type="select"
                                                                        id="passengerRearBrand"
                                                                        name="passengerRearBrand"
                                                                        className="form-control"
                                                                        value={data.passengerRearBrand}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={TireBrand} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="passengerRearSize">Passenger Rear Tire Size</Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="passengerRearSize"
                                                                        name="passengerRearSize"
                                                                        value={data.passengerRearSize}
                                                                        onChange={e => updateFields({ passengerRearSize: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="passengerRearTreadDepth">Passenger Rear Tire Tread Depth</Label>
                                                                    <Input
                                                                        type="select"
                                                                        id="passengerRearTreadDepth"
                                                                        name="passengerRearTreadDepth"
                                                                        className="form-control"
                                                                        value={data.passengerRearTreadDepth}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={TireTreadDepth} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="passengerRearTireAndWheel">Passenger Rear Tire and Wheel Condition</Label>
                                                                    <Input
                                                                        type="select"
                                                                        id="passengerRearTireAndWheel"
                                                                        name="passengerRearTireAndWheel"
                                                                        className="form-control"
                                                                        value={data.passengerRearTireAndWheel}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={EFBNA} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="spareTire">Spare Tire</Label>
                                                                    <Input
                                                                        type="select"
                                                                        id="spareTire"
                                                                        name="spareTire"
                                                                        className="form-control"
                                                                        value={data.spareTire}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={SpareTire} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="spareTireSet">Spare Tire Set</Label>
                                                                    <Input
                                                                        type="select"
                                                                        id="spareTireSet"
                                                                        name="spareTireSet"
                                                                        className="form-control"
                                                                        value={data.spareTireSet}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={SpareTireSet} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="tiresNote">Comments about tires and wheels condition of vehicle</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="tiresNote"
                                                                        name="tiresNote"
                                                                        value={data.tiresNote}
                                                                        onChange={e => updateFields({ tiresNote: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="tiresFileInput">Please provide a minimum of 4 well-taken and explanative pictures of the tires. Capture close-up images of the tread depth for each tire, as well as any signs of uneven wear or damage. Include an image of the tire brand and model information, and a shot showing the overall condition of each tire. Select your pictures by clicking the box first, then click the <strong>'Upload Tires Pictures'</strong> button on the right to submit them.</Label></Col>
                                                            <Col md={10}>
                                                                <FormGroup>
                                                                    <div name="tiresFileInput" className="dropzone" {...getTiresRootProps()}>
                                                                        <input type="file" {...getTiresInputProps()} />
                                                                        {
                                                                            tiresAcceptedFiles.length > 0 ? <p>{tiresAcceptedFiles.length} file selected</p> :
                                                                                isTiresDragActive ?
                                                                                    <p>Drop the files here ...</p> :
                                                                                    <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                        }
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={2}>
                                                                <SpinnerButton onClick={() => onUploadFile(InspectionSection.Tires)} disabled={tiresAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Tires Pictures" loading={uploadLoading} />
                                                            </Col>
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.Tires).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingSix">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                        Road Test
                                                    </button>
                                                </h2>
                                                <div id="collapseSix" class="accordion-collapse border-0 collapse" aria-labelledby="headingSix" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="form-check-input"><strong>Is road test available for this vehicle?</strong> <br />  If yes, please conduct the road test and provide detailed feedback by following the instructions. If no, please select a reason from the options provided</Label>
                                                                    <div class="form-check form-switch">
                                                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={data.isRoadTestAvailable} onChange={roadTestAvailableCheckHandler} />
                                                                        <label class="form-check-label" for="flexSwitchCheckDefault">{data.isRoadTestAvailable ? 'Yes' : 'No'}</label>
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                            {!data.isRoadTestAvailable && <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="odometerEndReading">Reason</Label>
                                                                    <Input
                                                                        type="select"
                                                                        id="roadTestAvailabilityReason"
                                                                        name="roadTestAvailabilityReason"
                                                                        value={data.roadTestAvailabilityReason}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={RoadTestAvailabilityReasons} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            }
                                                        </Row>
                                                        {data.isRoadTestAvailable && <>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="odometerStartReading">Road Test Start Odometer Reading</Label>
                                                                        <Input
                                                                            type="text"
                                                                            id="odometerStartReading"
                                                                            name="odometerStartReading"
                                                                            value={data.odometerStartReading}
                                                                            onChange={e => updateFields({ odometerStartReading: e.target.value })}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="odometerEndReading">Road Test End Odometer Reading</Label>
                                                                        <Input
                                                                            type="text"
                                                                            id="odometerEndReading"
                                                                            name="odometerEndReading"
                                                                            value={data.odometerEndReading}
                                                                            onChange={e => updateFields({ odometerEndReading: e.target.value })}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="engineStartingPerformance">Engine Starting Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="engineStartingPerformance"
                                                                                    name="engineStartingPerformance"
                                                                                    value={data.engineStartingPerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.engineStartingPerformance) && <OtherInput text="Engine Starting Performance Issues" name="engineStartingPerformanceIssues" value={data.engineStartingPerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="wheelBearingPerformance">Wheel Bearing Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="wheelBearingPerformance"
                                                                                    name="wheelBearingPerformance"
                                                                                    value={data.wheelBearingPerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.wheelBearingPerformance) && <OtherInput text="Wheel Bearing Performance Issues" name="wheelBearingPerformanceIssues" value={data.wheelBearingPerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="steeringPerformance">Steering Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="steeringPerformance"
                                                                                    name="steeringPerformance"
                                                                                    value={data.steeringPerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.steeringPerformance) && <OtherInput text="Steering Performance Issues" name="steeringPerformanceIssues" value={data.steeringPerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="suspensionPerformance">Suspension Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="suspensionPerformance"
                                                                                    name="suspensionPerformance"
                                                                                    value={data.suspensionPerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.suspensionPerformance) && <OtherInput text="Suspension Performance Issues" name="suspensionPerformanceIssues" value={data.suspensionPerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="transmissionPerformance">Transmission Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="transmissionPerformance"
                                                                                    name="transmissionPerformance"
                                                                                    value={data.transmissionPerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.transmissionPerformance) && <OtherInput text="Transmission Performance Issues" name="transmissionPerformanceIssues" value={data.transmissionPerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="differentialPerformance">Differential Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="differentialPerformance"
                                                                                    name="differentialPerformance"
                                                                                    value={data.differentialPerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.differentialPerformance) && <OtherInput text="Differential Performance Issues" name="differentialPerformanceIssues" value={data.differentialPerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="transferCasePerformance">Transfer Case Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="transferCasePerformance"
                                                                                    name="transferCasePerformance"
                                                                                    value={data.transferCasePerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.transferCasePerformance) && <OtherInput text="Transfer Case Performance Issues" name="transferCasePerformanceIssues" value={data.transferCasePerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="brakingPerformance">Braking Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="brakingPerformance"
                                                                                    name="brakingPerformance"
                                                                                    value={data.brakingPerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.brakingPerformance) && <OtherInput text="Braking Performance Issues" name="brakingPerformanceIssues" value={data.brakingPerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="interiorNoisePerformance">Interior Noise and Isolation Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="interiorNoisePerformance"
                                                                                    name="interiorNoisePerformance"
                                                                                    value={data.interiorNoisePerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.interiorNoisePerformance) && <OtherInput text="Interior Noise Performance Issues" name="interiorNoisePerformanceIssues" value={data.interiorNoisePerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="overallEnginePerformance">Overall Engine Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="overallEnginePerformance"
                                                                                    name="overallEnginePerformance"
                                                                                    value={data.overallEnginePerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.overallEnginePerformance) && <OtherInput text="Overall Engine Performance Issues" name="overallEnginePerformanceIssues" value={data.overallEnginePerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="automaticStartStop">Automatic Start Stop</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="automaticStartStop"
                                                                                    name="automaticStartStop"
                                                                                    value={data.automaticStartStop}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.automaticStartStop) && <OtherInput text="Automatic Start-Stop Issues" name="automaticStartStopIssues" value={data.automaticStartStopIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="cruiseControl">Cruise Control</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="cruiseControl"
                                                                                    name="cruiseControl"
                                                                                    value={data.cruiseControl}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={WorkingNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.cruiseControl) && <OtherInput text="Cruise Control Issues" name="cruiseControlIssues" value={data.cruiseControlIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="blindSpotDetection">Blind Spot Detection</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="blindSpotDetection"
                                                                                    name="blindSpotDetection"
                                                                                    value={data.blindSpotDetection}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={WorkingNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.blindSpotDetection) && <OtherInput text="Blind Spot Detection Issues" name="blindSpotDetectionIssues" value={data.blindSpotDetectionIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="laneAssist">Lane Assist</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="laneAssist"
                                                                                    name="laneAssist"
                                                                                    value={data.laneAssist}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={WorkingNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.laneAssist) && <OtherInput text="Lane Assist Issues" name="laneAssistIssues" value={data.laneAssistIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="hillStartAssist">Hill Start Assist</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="hillStartAssist"
                                                                                    name="hillStartAssist"
                                                                                    value={data.hillStartAssist}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={WorkingNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.hillStartAssist) && <OtherInput text="Hill Start Assist Issues" name="hillStartAssistIssues" value={data.hillStartAssistIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="parkingAssist">Parking Assist</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="parkingAssist"
                                                                                    name="parkingAssist"
                                                                                    value={data.parkingAssist}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={WorkingNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.parkingAssist) && <OtherInput text="Parking Assist Issues" name="parkingAssistIssues" value={data.parkingAssistIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="backUpCamera">Back-Up Camera Condition</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="backUpCamera"
                                                                                    name="backUpCamera"
                                                                                    value={data.backUpCamera}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={WorkingNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.backUpCamera) && <OtherInput text="Back-Up Camera Issues" name="backUpCameraIssues" value={data.backUpCameraIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="autoPilotAssist">Auto Pilot Assist</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    id="autoPilotAssist"
                                                                                    name="autoPilotAssist"
                                                                                    value={data.autoPilotAssist}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={WorkingNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.autoPilotAssist) && <OtherInput text="AutoPilot Assist Issues" name="autoPilotAssistIssues" value={data.autoPilotAssistIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={12}>
                                                                    <FormGroup>
                                                                        <Label for="roadTestNote">Comments about road test</Label>
                                                                        <Input
                                                                            type="textarea"
                                                                            id="roadTestNote"
                                                                            name="roadTestNote"
                                                                            value={data.roadTestNote}
                                                                            onChange={e => updateFields({ roadTestNote: e.target.value })}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={12}><Label for="roadTestFileInput">If a road test is conducted, please upload well-taken and explanative pictures during the test. These should include images of the vehicle's dashboard showing the instrument panel while the vehicle is in motion, any warning lights, and an overall view of the driving experience. If possible, include photos that highlight the vehicle's handling and performance. Select your pictures by clicking the box first, then click the <strong>'Upload Road Test Pictures'</strong> button on the right to submit them.</Label></Col>
                                                                <Col md={10}>
                                                                    <FormGroup>
                                                                        <div name="roadTestFileInput" className="dropzone" {...getRoadTestRootProps()}>
                                                                            <input type="file" {...getRoadTestInputProps()} />
                                                                            {
                                                                                roadTestAcceptedFiles.length > 0 ? <p>{roadTestAcceptedFiles.length} file selected</p> :
                                                                                    isRoadTestDragActive ?
                                                                                        <p>Drop the files here ...</p> :
                                                                                        <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                            }
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={2}>
                                                                    <SpinnerButton onClick={() => onUploadFile(InspectionSection.RoadTest)} disabled={roadTestAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Road Test Pictures" loading={uploadLoading} />
                                                                </Col>
                                                                <Col md={12}>
                                                                    <Row>
                                                                        {uploadedFiles.filter(t => t.subCategory == InspectionSection.RoadTest).map(t =>
                                                                            <Col md={2} className="my-2">
                                                                                <ImageArea src={t.filePath} handleClick={() => handleRemoveImage(t.id)} />
                                                                            </Col>
                                                                        )}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingSeven">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                        Summary
                                                    </button>
                                                </h2>
                                                <div id="collapseSeven" class="accordion-collapse border-0 collapse" aria-labelledby="headingSeven" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="summary">Please provide a summary of inspection with few paragraphs.</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="summary"
                                                                        name="summary"
                                                                        value={data.summary}
                                                                        onChange={e => updateFields({ summary: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="summaryFileInput">Please upload additional well-taken and explanative pictures in the Summary section if there are specific areas that need further clarification or emphasis. This can include any unique features of the vehicle, additional damage not covered in other sections, or areas where further inspection is recommended. Select your pictures by clicking the box first, then click the <strong>'Upload Additional Pictures'</strong> button on the right to submit them.</Label></Col>
                                                            <Col md={10}>
                                                                <FormGroup>
                                                                    <div name="summaryFileInput" className="dropzone" {...getSummaryRootProps()}>
                                                                        <input type="file" {...getSummaryInputProps()} />
                                                                        {
                                                                            summaryAcceptedFiles.length > 0 ? <p>{summaryAcceptedFiles.length} file selected</p> :
                                                                                isSummaryDragActive ?
                                                                                    <p>Drop the files here ...</p> :
                                                                                    <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                        }
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={2}>
                                                                <SpinnerButton onClick={() => onUploadFile(InspectionSection.Summary)} disabled={summaryAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Additional Pictures" loading={uploadLoading} />
                                                            </Col>
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.Summary).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col md={9}>
                                                <div style={{ color: "red", fontSize: "0.9rem" }}>
                                                    {Object.keys(validationError).length > 0 ? Object.values(validationError).map(item => <div className="row"><span>* {item}</span></div>) : null}
                                                </div>
                                            </Col>
                                            {auth?.user?.roles.indexOf(Roles.VehicleInspector) != -1 &&
                                                <Col md={3}>
                                                    <Row>
                                                        <Col md={3}> </Col>
                                                        <Col md={9}>
                                                            {/* <button type="submit" onClick={onSubmitReport} className="btn btn-dark my-5">Submit Report</button> */}
                                                            <SpinnerButton onClick={onSubmitReport} className="btn btn-dark my-5" text="Submit Report" loading={loading} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </section>
            </div>
        </div>

    )
}

export default CarInspection;