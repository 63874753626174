import React, { useState, useEffect } from "react";
import HerosectionHeader from "../../../../Componet/Herosection/HerosectionHeader";
import { VehicleTypes } from "../../../../store/Utility/Utility";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import useAuth from "../../../../hooks/useAuth";
import VehicleTypeIcon from "../../../../Componet/VehicleTypeIcon";

const CompletedJobs = () => {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const [jobs, setJobs] = useState(null);

    const SearchInspectedJob = async () => {
        await axiosPrivate.get(`v1/quotation/inspected`, {
            params: { userId: auth?.user.id }
        }).then(res => {
            setJobs(res.data.data);
        });
    }

    useEffect(() => {
        SearchInspectedJob();
    }, [])

    return (<>
        <HerosectionHeader folder1={"pages"} folder2={"Company"} name={"Completed"} />
        <div class="container">
            <div class="row">
                <div class="col">
                    {(jobs && jobs.length>0) ?
                        jobs.map((job, index) => ((
                            <div class="card bg-transparent flex-md-row align-items-center p-md-0 p-3 mt-5">
                                <div class="col-md-2">
                                    <div className="bg-primary p-2 d-inline-block rounded m-4">
                                        <div className="f-icon-m text-white">
                                        <VehicleTypeIcon vehicleType={job.vehicleType} />
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body col-md-6">
                                    <div>
                                        <div class="d-inline-block bg-light text-center rounded me-2">
                                            <span class="text-primary">{VehicleTypes.find(t => t.id == job.vehicleType).name}</span>
                                        </div>
                                        <span>{job.make} / {job.model} / {job.year}</span>

                                    </div>
                                    <h2 class="h5 my-3">
                                        <a class="link-title">You have successfully completed this order. Many thanks!</a>
                                    </h2>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item pe-3"> <a class="list-group-item-action">
                                            <i class="las la-money-bill ms-1 text-primary ic-1x">
                                            </i> You earned ${job.vehicleType == 1 ? 70 :
                                                (
                                                    (job.vehicleType == 2 ? 70 :
                                                        (job.vehicleType == 3 ? 80 : 100)))} from this inspection</a>
                                        </li>
                                    </ul>
                                </div>

                            </div>))) :
                        <div>There is no jobs available</div>}

                </div>
            </div>
        </div>
    </>);
}

export default CompletedJobs;