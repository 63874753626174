export const EFB = [
    { value: "excellent", text: "Excellent - No Issues" },
    { value: "fair", text: "Fair - Minor issues" },
    { value: "bad", text: "Bad - Significant Issues" }
];

export const EFBNA = [
    { value: "excellent", text: "Excellent - No Issues" },
    { value: "fair", text: "Fair - Minor issues" },
    { value: "bad", text: "Bad - Significant Issues" },
    { value: "notAvailable", text: "Not Available" }
];

export const InteriorMaterial = [
    { value: 'Fabric', text: 'Fabric' },
    { value: 'Leather', text: 'Leather' },
    { value: 'FabricAndLeather', text: 'Fabric & Leather' },
    { value: 'Other', text: 'Other' }
];

export const TransmissionType = [
    { value: "automatic", text: "Automatic Transmission" },
    { value: "manual", text: "Manual Transmission" },
    { value: "cvt", text: "Continuously Variable Transmission (CVT)" },
    { value: "dct", text: "Dual-Clutch Transmission (DCT)" },
    { value: "amt", text: "Automated Manual Transmission (AMT)" },
    { value: "smt", text: "Sequential Manual Transmission (SMT)" },
    { value: "semiauto", text: "Semi-Automatic Transmission" },
    { value: "ivt", text: "Infinitely Variable Transmission (IVT)" },
    { value: "hybrid", text: "Hybrid Transmission" },
    { value: "pihybrid", text: "Plug-In Hybrid Transmission" },
    { value: "electric", text: "Electric Vehicle Transmission (EV)" },
    { value: "other", text: "Other" }
];

export const MotorcycleTransmissionType = [
    { value: "automatic", text: "Automatic Transmission" },
    { value: "manual", text: "Manual Transmission" },
    { value: "semiauto", text: "Semi-Automatic Transmission" },
    { value: "cvt", text: "Continuously Variable Transmission (CVT)" },
    { value: "dct", text: "Dual-Clutch Transmission (DCT)" },
    { value: "electric", text: "Electric Vehicle Transmission (EV)" },
    { value: "other", text: "Other" }
];

export const EngineLayout = [
    { value: "inline", text: "Inline or Straight (I)" },
    { value: "vshaped", text: "V-Shaped (V)" },
    { value: "electric", text: "Electric" },
    { value: "hybrid", text: "Hybrid" },
    { value: "wshaped", text: "W-Shaped (W)" },
    { value: "flat", text: "Flat (Boxer)" },
    { value: "rotary", text: "Rotary" },
    { value: "hengine", text: "H Engine" },
    { value: "xengine", text: "X Engine" },
    { value: "uengine", text: "U Engine" },
    { value: "radial", text: "Radial Engine" },
    { value: "other", text: "Other" }
];

export const MotorcycleEngineType = [
    { value: "inline", text: "Inline" },
    { value: "vtwin", text: "V-Twin" },
    { value: "flat", text: "Flat (Boxer)" },
    { value: "singlecylinder", text: "Single-Cylinder" },
    { value: "paralleltwin", text: "Parallel-Twin" },
    { value: "electric", text: "Electric" },
    { value: "triple", text: "Triple" },
    { value: "fourcylinder", text: "Four-Cylinder" },
    { value: "vfour", text: "V-Four" },
    { value: "rotaryengine", text: "Rotary Engine" },
    { value: "wankelengine", text: "Wankel Engine" },
    { value: "opposedtwin", text: "Opposed-Twin (180-Degree Crank)" },
    { value: "squarefour", text: "Square-Four" },
    { value: "boxertwin", text: "Boxer-Twin" },
    { value: "Other", text: "Other" }
];

export const MotorcycleEngineSize = [
    { value: "electric", text: "Electric" },
    { value: "l50cc", text: "Less than 50cc" },
    { value: "50-125cc", text: "50cc - 125cc" },
    { value: "126-250cc", text: "126cc - 250cc" },
    { value: "251-500cc", text: "251cc - 500cc" },
    { value: "501-750cc", text: "501cc - 750cc" },
    { value: "751-1000cc", text: "751cc - 1000cc" },
    { value: "1001-1500cc", text: "1001cc - 1500cc" },
    { value: "1501-2000cc", text: "1501cc - 2000cc" },
    { value: "m2000cc", text: "More than 2000cc" },
    { value: "other", text: "Other" }
];


export const CylinderConfiguration = [
    { value: "inline2", text: "Inline-2" },
    { value: "inline3", text: "Inline-3" },
    { value: "inline4", text: "Inline-4" },
    { value: "inline5", text: "Inline-5" },
    { value: "inline6", text: "Inline-6" },
    { value: "v6", text: "V6" },
    { value: "v8", text: "V8" },
    { value: "v10", text: "V10" },
    { value: "v12", text: "V12" },
    { value: "flat4", text: "Flat-4 (Boxer-4)" },
    { value: "flat6", text: "Flat-6 (Boxer-6)" },
    { value: "w12", text: "W12" },
    { value: "w16", text: "W16" },
    { value: "rotary", text: "Rotary" },
    { value: "h4", text: "H4" },
    { value: "h6", text: "H6" },
    { value: "radial", text: "Radial" },
    { value: "electric", text: "Electric" },
    { value: "hybrid", text: "Hybrid" },
    { value: "other", text: "Other" }
];

export const DriveType = [
    { value: "front", text: "Front-Wheel Drive (FWD)" },
    { value: "rear", text: "Rear-Wheel Drive (RWD)" },
    { value: "allwheel", text: "All-Wheel Drive (AWD)" },
    { value: "fourwheel", text: "Four-Wheel Drive (4WD)" },
    { value: "parttime4wd", text: "Part-Time Four-Wheel Drive" },
    { value: "fulltime4wd", text: "Full-Time Four-Wheel Drive" },
    { value: "other", text: "Other" }
];

export const FuelType = [
    { value: "gasoline", text: "Gasoline" },
    { value: "diesel", text: "Diesel" },
    { value: "electric", text: "Electric" },
    { value: "hybrid", text: "Hybrid (Gasoline/Electric)" },
    { value: "hydrogen", text: "Hydrogen Fuel Cell" },
    { value: "ethanol", text: "Ethanol (E85)" },
    { value: "propane", text: "Propane" },
    { value: "biodiesel", text: "Biodiesel" },
    { value: "cng", text: "Compressed Natural Gas (CNG)" },
    { value: "lpg", text: "Liquefied Petroleum Gas (LPG)" },
    { value: "other", text: "Other" }
];

export const MotorcycleFuelType = [
    { value: "gasoline", text: "Gasoline" },
    { value: "diesel", text: "Diesel" },
    { value: "electric", text: "Electric" },
    { value: "hybrid", text: "Hybrid" },
    { value: "other", text: "Other" }
];


export const Dings = [
    { value: "excellent", text: "No Dings" },
    { value: "fair", text: "Minor Dings" },
    { value: "bad", text: "Significant Dings" }
];


export const Dents = [
    { value: "excellent", text: "No Dents" },
    { value: "fair", text: "Minor Dents" },
    { value: "bad", text: "Significant Dents" }
];

export const Rust = [
    { value: "excellent", text: "No Rust" },
    { value: "fair", text: "Minor Rust" },
    { value: "bad", text: "Significant Rust" }
];


export const HornCondition = [
    { value: "excellent", text: "Working" },
    { value: "fair", text: "Working With Some Issues" },
    { value: "bad", text: "Not Working" },
    { value: "notAvailable", text: "Not Available" }
];



export const WorkingNA = [
    { value: "excellent", text: "Working" },
    { value: "fair", text: "Working With Some Issues" },
    { value: "bad", text: "Not Working" },
    { value: "notAvailable", text: "Not Available" }
];

export const HasIssueNA = [
    { value: "excellent", text: "Available" },
    { value: "bad", text: "Has Issue" },
    { value: "notAvailable", text: "Not Available" }
]


export const CigaretteUse = [
    { value: "excellent", text: "Non-Smoked Car" },
    { value: "fair", text: "Light Smoking Presence" },
    { value: "bad", text: "Severe Smoking Presence" }
];

export const UnusualOdors = [
    { value: "excellent", text: "Odor-Free" },
    { value: "fair", text: "Moderate Odor" },
    { value: "bad", text: "Strong Odor" }
];

export const Leaks = [
    { value: "excellent", text: "No Leaks" },
    { value: "fair", text: "Minor leaks" },
    { value: "bad", text: "Significant Leaks" },
    { value: "notAvailable", text: "Not Available" }
];

export const TireBrand = [
    { value: "Arroyo", text: "Arroyo" },
    { value: "Avon", text: "Avon" },
    { value: "BFGoodrich", text: "BFGoodrich" },
    { value: "BKT", text: "BKT" },
    { value: "Blackhawk", text: "Blackhawk" },
    { value: "Bridgestone", text: "Bridgestone" },
    { value: "Carlisle", text: "Carlisle" },
    { value: "Continental", text: "Continental" },
    { value: "Cooper", text: "Cooper" },
    { value: "Crossmax", text: "Crossmax" },
    { value: "Crosswind", text: "Crosswind" },
    { value: "Dextero", text: "Dextero" },
    { value: "Douglas", text: "Douglas" },
    { value: "Falken", text: "Falken" },
    { value: "Firestone", text: "Firestone" },
    { value: "Fullway", text: "Fullway" },
    { value: "General", text: "General" },
    { value: "Goodyear", text: "Goodyear" },
    { value: "GT Radial", text: "GT Radial" },
    { value: "Hankook", text: "Hankook" },
    { value: "Hercules", text: "Hercules" },
    { value: "Kumho", text: "Kumho" },
    { value: "Lexani", text: "Lexani" },
    { value: "Maxxis", text: "Maxxis" },
    { value: "Michelin", text: "Michelin" },
    { value: "Mickey Thompson", text: "Mickey Thompson" },
    { value: "Nankang", text: "Nankang" },
    { value: "Nexen", text: "Nexen" },
    { value: "Nitto", text: "Nitto" },
    { value: "Pirelli", text: "Pirelli" },
    { value: "Riken", text: "Riken" },
    { value: "Roadstone", text: "Roadstone" },
    { value: "Sailun", text: "Sailun" },
    { value: "Starfire", text: "Starfire" },
    { value: "Summit", text: "Summit" },
    { value: "TBC Brands", text: "TBC Brands" },
    { value: "Thunderer", text: "Thunderer" },
    { value: "Toyo", text: "Toyo" },
    { value: "Uniroyal", text: "Uniroyal" },
    { value: "Vredestein", text: "Vredestein" },
    { value: "Waterfall", text: "Waterfall" },
    { value: "Yokohama", text: "Yokohama" },
    { value: "Zeetex", text: "Zeetex" },
    { value: "Other", text: "Other" }
]

export const MotorcycleTireBrand = [
    { value: "Anlas", text: "Anlas" },
    { value: "Avon", text: "Avon" },
    { value: "BKT", text: "BKT" },
    { value: "Bridgestone", text: "Bridgestone" },
    { value: "Continental", text: "Continental" },
    { value: "CST", text: "CST" },
    { value: "Dunlop", text: "Dunlop" },
    { value: "Duro", text: "Duro" },
    { value: "GoldenTyre", text: "GoldenTyre" },
    { value: "Gripster", text: "Gripster" },
    { value: "Hankook", text: "Hankook" },
    { value: "Heidenau", text: "Heidenau" },
    { value: "IRCTires", text: "IRC Tires" },
    { value: "Kenda", text: "Kenda" },
    { value: "Kings Tire", text: "Kings Tire" },
    { value: "Maxxis", text: "Maxxis" },
    { value: "Mefo", text: "Mefo" },
    { value: "Metzeler", text: "Metzeler" },
    { value: "Michelin", text: "Michelin" },
    { value: "Mitas", text: "Mitas" },
    { value: "Nankang", text: "Nankang" },
    { value: "Nitto", text: "Nitto" },
    { value: "Pirelli", text: "Pirelli" },
    { value: "Sava", text: "Sava" },
    { value: "Sedona", text: "Sedona" },
    { value: "Shinko", text: "Shinko" },
    { value: "VeeRubber", text: "Vee Rubber" },
    { value: "Vredestein", text: "Vredestein" },
    { value: "Yokohama", text: "Yokohama" },
    { value: "Zeetex", text: "Zeetex" },
    { value: "Other", text: "Other" }
]

export const TireTreadDepth = [
    { value: 'M10/32', text: 'More than 10/32" (Special Tire)' },
    { value: '10/32', text: '10/32" (Brand New)' },
    { value: '9/32', text: '9/32"' },
    { value: '8/32', text: '8/32"' },
    { value: '7/32', text: '7/32"' },
    { value: '6/32', text: '6/32"' },
    { value: '5/32', text: '5/32"' },
    { value: '4/32', text: '4/32"' },
    { value: '3/32', text: '3/32"' },
    { value: '2/32', text: '2/32" (Minimum Legal Depth)' },
    { value: 'L2/32', text: 'Less than 2/32" (Bald Tire)' }
];

export const MotorcycleTireTreadDepth = [
    { value: 'M9/32', text: 'More than 8/32" (Special Tire)' },
    { value: '8/32', text: '8/32"' },
    { value: '7/32', text: '7/32"' },
    { value: '6/32', text: '6/32"' },
    { value: '5/32', text: '5/32"' },
    { value: '4/32', text: '4/32"' },
    { value: '3/32', text: '3/32"' },
    { value: '2/32', text: '2/32" (Minimum Legal Depth)' },
    { value: 'L1/32', text: 'Less than 2/32" (Bald Tire)' },
];

export const CommercialTireTreadDepth = [
    { value: 'M20/32', text: 'More than 20/32' },
    { value: '20/32', text: '20/32' },
    { value: '18/32', text: '18/32' },
    { value: '16/32', text: '16/32' },
    { value: '14/32', text: '14/32' },
    { value: '12/32', text: '12/32' },
    { value: '10/32', text: '10/32' },
    { value: '8/32', text: '8/32' },
    { value: '6/32', text: '6/32' },
    { value: '4/32', text: '4/32' },
    { value: 'L4/32', text: 'Less than 4/32" (Worn-Out)' }
];

export const SpareTire = [
    { value: "excellent", text: "Available" },
    { value: "notAvailable", text: "Not Available" }
];

export const SpareTireSet = [
    { value: "excellent", text: "Full Set Available" },
    { value: "fair", text: "Set Partly Available" },
    { value: "notAvailable", text: "Set Not Available" }
];

export const Scratches = [
    { value: "excellent", text: "No Scratches" },
    { value: "fair", text: "Minor Scratches" },
    { value: "bad", text: "Significant Scratches" }
];

export const Warnings = [
    { value: 'excellent', text: 'No Warnings' },
    { value: 'bad', text: 'Has Warnings' }
];


export const RoadTestAvailabilityReasons = [
    { value: "1", text: "Vehicle Does Not Have Keys", description: "The vehicle keys are not available, making it impossible to start and drive the vehicle" },
    { value: "2", text: "Vehicle is Not Operational", description: "The vehicle is not in a drivable condition due to mechanical issues or damage" },
    { value: "3", text: "Legal or Safety Concerns", description: "There are legal restrictions or safety concerns that prevent the vehicle from being driven, such as expired registration, insurance issues, or severe weather conditions" },
    { value: "4", text: "Fuel Levels", description: "The vehicle does not have enough fuel to conduct a road test" },
    { value: "5", text: "Tire Condition", description: "The tires are not in safe condition for a road test" },
    { value: "6", text: "Owner's Request", description: "The vehicle owner has specifically requested that a road test not be conducted" },
    { value: "7", text: "Mechanical Risk", description: "The inspector detects potential mechanical issues that could pose a risk if the vehicle is driven" },
    { value: "8", text: "Inspection Location", description: "The inspection is being conducted at a location where it is not feasible to perform a road test, such as a small or restricted area" },
    { value: "9", text: "Traffic or Road Conditions", description: "Unfavorable traffic or road conditions make it unsafe or impractical to perform a road test" }

]


export const States = [
    { value: "AL", text: "Alabama" },
    { value: "AK", text: "Alaska" },
    { value: "AZ", text: "Arizona" },
    { value: "AR", text: "Arkansas" },
    { value: "CA", text: "California" },
    { value: "CO", text: "Colorado" },
    { value: "CT", text: "Connecticut" },
    { value: "DE", text: "Delaware" },
    { value: "DC", text: "District Of Columbia" },
    { value: "FL", text: "Florida" },
    { value: "GA", text: "Georgia" },
    { value: "HI", text: "Hawaii" },
    { value: "ID", text: "Idaho" },
    { value: "IL", text: "Illinois" },
    { value: "IN", text: "Indiana" },
    { value: "IA", text: "Iowa" },
    { value: "KS", text: "Kansas" },
    { value: "KY", text: "Kentucky" },
    { value: "LA", text: "Louisiana" },
    { value: "ME", text: "Maine" },
    { value: "MD", text: "Maryland" },
    { value: "MA", text: "Massachusetts" },
    { value: "MI", text: "Michigan" },
    { value: "MN", text: "Minnesota" },
    { value: "MS", text: "Mississippi" },
    { value: "MO", text: "Missouri" },
    { value: "MT", text: "Montana" },
    { value: "NE", text: "Nebraska" },
    { value: "NV", text: "Nevada" },
    { value: "NH", text: "New Hampshire" },
    { value: "NJ", text: "New Jersey" },
    { value: "NM", text: "New Mexico" },
    { value: "NY", text: "New York" },
    { value: "NC", text: "North Carolina" },
    { value: "ND", text: "North Dakota" },
    { value: "OH", text: "Ohio" },
    { value: "OK", text: "Oklahoma" },
    { value: "OR", text: "Oregon" },
    { value: "PA", text: "Pennsylvania" },
    { value: "RI", text: "Rhode Island" },
    { value: "SC", text: "South Carolina" },
    { value: "SD", text: "South Dakota" },
    { value: "TN", text: "Tennessee" },
    { value: "TX", text: "Texas" },
    { value: "UT", text: "Utah" },
    { value: "VT", text: "Vermont" },
    { value: "VA", text: "Virginia" },
    { value: "WA", text: "Washington" },
    { value: "WV", text: "West Virginia" },
    { value: "WI", text: "Wisconsin" },
    { value: "WY", text: "Wyoming" }
];

export const PaymentType = [
    { value: '1', text: 'ACH' },
    { value: '2', text: 'Stripe' },
    { value: '3', text: 'Zelle' },
    { value: '4', text: 'Other' }
];

export const BalanceType = [
    { value: '1', text: 'Inspection Earning' },
    { value: '2', text: 'Extra Payment' },
    { value: '3', text: 'Other' }
];

const currentYear = new Date().getFullYear();
export const YearsArray = Array.from({ length: currentYear - 1920 + 1 }, (v, i) => currentYear - i);