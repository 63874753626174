import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

import moment from "moment";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { useSpinner } from "../../../../../context/SpinnerContext";
import SpinnerButton from "../../../../../Componet/Button/SpinnerButton";

import HerosectionHeader from "../../../../../Componet/Herosection/HerosectionHeader";
import { InspectorStatus } from '../../../../../store/Utility/Status';
import { InspectorStatusData } from "../../../../../store/Utility/Utility";
import EmptyDataResult from "../../../../../Componet/EmptyDataResult";


const InspectorSearch = () => {
    const [inspectors, setInspectors] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(InspectorStatus.Applicant);
    const [loading, setLoading] = useState(false);
    const inspectorCode = useRef();
    const inspectorEmail = useRef();
    const [onlyWithBalance, setOnlyWithBalance]=useState(true);
    const axiosPrivate = useAxiosPrivate();
    const { showLoader, hideLoader } = useSpinner();

    const fetchInspectors = async (e) => {
        setLoading(true);
        await axiosPrivate.get(`v1/inspector/search`,
            {
                params: {
                    status: (selectedStatus != undefined || selectedStatus != null) ? selectedStatus : InspectorStatus.AwaitingApproval,
                    inspectorCode: inspectorCode.current.value, email: inspectorEmail.current.value,
                    haveBalance:onlyWithBalance
                }
            }).then(res => {
                setLoading(false);
                setInspectors(res?.data?.data);
            });
    };


    const handleOptionChange = (e) => {
        setSelectedStatus(e.target.value);
    };

    return (<>
        <HerosectionHeader name={"Inspector Search"} />
        <div class="container">
            <Row>
                <Col md={2}> <div className="mt-5 d-flex align-items-center"><input type="text" ref={inspectorCode} className="form-control" name="inspectorCode" placeholder="Inspector Code" /></div></Col>
                <Col md={2}><div className="mt-5 d-flex align-items-center"><input type="text" ref={inspectorEmail} className="form-control" name="inspectorEmail" placeholder="Inspector Email" /></div></Col>
                <Col md={2}>
                    <div className="form-check mt-6 d-flex align-items-center">
                        <input type="checkbox" className="form-check-input" id="onlyWithBalance" value={onlyWithBalance} checked={onlyWithBalance} onChange={()=>setOnlyWithBalance(!onlyWithBalance)} />
                        <label className="form-check-label px-2" for="onlyWithBalance" >Only with Balance</label>
                    </div>
                </Col>
                <Col md={2}></Col>
                <Col md={2}></Col>

                <Col md={2}>
                    <div className="mt-5 d-flex">
                        <SpinnerButton type="button" className="btn btn-primary btn-sm" loading={loading} onClick={fetchInspectors} text="Search" />
                    </div>
                </Col>
            </Row>
            <div class="row">
                <div class="col">
                    {(inspectors && inspectors.length > 0) ?
                        inspectors.map((item, index) => ((
                            <div class="card bg-transparent flex-md-row align-items-center p-md-0 3 mt-3 mb-3">
                                <div class="col-md-2">
                                    <div className="bg-primary p-2 d-inline-block rounded m-4">
                                        <div className="f-icon-m text-white">
                                            <i className="la la-user"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body col-md-6">
                                    <div><span><strong>{item.inspectorCode} </strong></span></div>
                                    <div>
                                        <h2 class="h5 my-2">
                                            <a class="link-title">{item.firstName} {item.lastName}</a>
                                        </h2>
                                    </div>
                                    <div><span>{item.email} </span></div>
                                    <div><span><strong>Member From: </strong>{moment(item.createdDate).format('MM.DD.YYYY')} </span></div>
                                    <div><span><strong>Status: </strong>{InspectorStatusData && InspectorStatusData.find((t) => t.id == item.status)?.displayName} </span></div>
                                    <div><span><strong>Balance: </strong>${item.balance.toFixed(2)} </span></div>
                                </div>
                                <div class="col-md-2">
                                    <Link className='btn btn-primary' to={`/inspector/payment/${item.id}`}> Detail</Link>
                                </div>
                            </div>))) : <div> <EmptyDataResult message="There is no data to display" /></div>
                    }

                </div>
            </div>
        </div>
    </>);

}

export default InspectorSearch;