import React from "react";
import {
    Col,
    FormGroup,
    Input,
    Label
} from "reactstrap";

const OtherInput = ({ name, disabled, value, updateFields, text, colmd = 6 }) => {
    return (
        <Col md={colmd}>
            <FormGroup>
                <Label for={name}>{text}</Label>
                <Input
                    type="text"
                    id={name}
                    name={name}
                    value={value}
                    onChange={e => updateFields({ [name]: e.target.value })}
                    disabled={disabled}
                />
            </FormGroup>
        </Col>
    )
}

export default OtherInput;