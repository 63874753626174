import React, { useState } from "react";
import {
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import HerosectionHeader from '../../../Componet/Herosection/HerosectionHeader'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { isValidEmail, isValidZipCode, isValidPhoneNumber } from "../../../store/Utility/Helper";
import { useSpinner } from "../../../context/SpinnerContext";
import Modal from 'react-bootstrap/Modal';

const InitialData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "",
    message: ""
}
const Support = () => {
    const [formData, setFormData] = useState(InitialData);
    const axiosPrivate = useAxiosPrivate();
    const [validationError, setValidationError] = useState({});
    const [modal, showModal] = useState({ show: false, message: null })
    const { showLoader, hideLoader } = useSpinner();

    const handleClose = () => showModal({ show: false, message: null });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    const validateInputs = (inputValues) => {
        let errors = {};

        if (inputValues.firstName == '') { errors.firstName = "First Name is required"; }
        if (inputValues.lastName == '') { errors.lastName = "First Name is required"; }
        if (inputValues.email == '') {
            errors.email = "Email is required";
        } else {
            if (!isValidEmail(inputValues.email)) errors.email = "Please enter valid email";
        }


        if (inputValues.phone != '' && !isValidPhoneNumber(inputValues.phone)) errors.phone = "Please enter valid phone number";
        if (inputValues.subject == '') { errors.subject = "Subject is required"; }
        if (inputValues.message == '') { errors.message = "Message is required"; }


        return errors;
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        var error = validateInputs(formData);
        setValidationError(error);
        if (Object.keys(error).length != 0) return;
        else {
            showLoader();
            const response = await axiosPrivate.post(`v1/contact`, formData).then(res => {
                hideLoader();
                showModal({ show: true, message: "Your application successfully submitted!" })
                setFormData(InitialData);
            }).catch(error => {
                hideLoader();
                showModal({ show: true, message: error.response?.data?.Error })
            });
        }
    }

    return (
        <>
            <HerosectionHeader folder1={"pages"} folder2={"Company"} name={"Support"} />
            <div class="page-content">
                {<Modal show={modal.show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Result</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modal.message}</Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-dark" onClick={handleClose}>Close</button>
                    </Modal.Footer>
                </Modal>}
                <section >
                    <div className='container'>
                        <div className='row'>
                            <div className="col-12 col-lg-7">
                                <div>
                                    <div>
                                        <h2><span class="font-w-4 d-block">You can reach us by completing the form below or simply calling!</span></h2>
                                    </div>
                                    <form id="contact" >
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup floating>
                                                    <Input
                                                        id="firstName"
                                                        type="text"
                                                        name="firstName"
                                                        className="form-control"
                                                        value={formData.firstName}
                                                        placeholder="First Name"
                                                        onChange={handleChange}></Input>
                                                    <Label for="firstName">First Name</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup floating>
                                                    <Input
                                                        id="lastName"
                                                        type="text"
                                                        name="lastName"
                                                        className="form-control"
                                                        value={formData.lastName}
                                                        placeholder="Last Name"
                                                        onChange={handleChange}></Input>
                                                    <Label for="lastName">Last Name</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup floating>
                                                    <Input
                                                        id="email"
                                                        type="email"
                                                        name="email"
                                                        className="form-control"
                                                        value={formData.email}
                                                        placeholder="Email"
                                                        onChange={handleChange}></Input>
                                                    <Label for="email">Email</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup floating>
                                                    <Input
                                                        id="phone"
                                                        type="tel"
                                                        name="phone"
                                                        className="form-control"
                                                        value={formData.phone}
                                                        placeholder="Phone"
                                                        onChange={handleChange}></Input>
                                                    <Label for="phone">Phone</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup floating>
                                                    <Input
                                                        id="subject"
                                                        type="text"
                                                        name="subject"
                                                        className="form-control"
                                                        value={formData.subject}
                                                        placeholder="Subject"
                                                        onChange={handleChange}></Input>
                                                    <Label for="subject">Subject</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={12}>
                                                <FormGroup floating>
                                                    <Input
                                                        id="message"
                                                        type="textarea"
                                                        name="message"
                                                        rows="4"
                                                        className="form-control h-auto"
                                                        value={formData.message}
                                                        placeholder="Message"
                                                        onChange={handleChange}></Input>
                                                    <Label for="message">Message</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={9}>
                                                <div style={{ color: "red", fontSize: "0.9rem" }}>
                                                    {Object.keys(validationError).length > 0 ? Object.values(validationError).map(item => <div className="row"><span>* {item}</span></div>) : null}
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <button className="btn btn-dark" onClick={onSubmit}>Send Messages</button>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 ms-auto mt-5 mt-lg-0">
                                <div class="d-flex align-items-center bg-white p-3 shadow-sm rounded  mb-3">
                                    <div class="me-3">
                                        <div class="f-icon-s p-3 rounded" style={{ backgroundColor: "#d0faec" }}> <i class="flaticon-mail"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <h5 class="mb-1">Email Us:</h5>
                                        <a class="btn-link" href="mailto:support@inspectiver.com"> support@inspectiver.com</a>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center bg-white p-3 shadow-sm rounded">
                                    <div class="me-3">
                                        <div class="f-icon-s p-3 rounded" style={{ backgroundColor: "#d0faec" }}> <i class="flaticon-telephone"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <h5 class="mb-1">Call Us:</h5>
                                        <a class="btn-link">-</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Support;