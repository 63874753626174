
import React from "react";
import {
    Col,
    Row
} from "reactstrap";

const EmptyDataResult = (props) => {
    let emptyMessage = "There is no data to display";
    return <>
        <Row>
            <Col md={12}>
                <div className="checkout-form border p-4 mt-3 mb-3 rounded">
                    {props.message ? props.message : emptyMessage}
                </div>
            </Col>
        </Row>
    </>
}

export default EmptyDataResult;