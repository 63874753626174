import React, { useState } from "react";
import {
    Col,
    Container,
    FormGroup,
    Input,
    Row
} from "reactstrap";
import HerosectionHeader from '../../../Componet/Herosection/HerosectionHeader'

const InitialData = {
    routingNumber: "",
    accountNumber: ""
}

const Payout = () => {
    const [formData, setFormData] = useState(InitialData);
    const [isEdit, setIsEdit] = useState((formData.routingNumber && formData.accountNumber)!='');
    const [isDisabled, setIsDisabled] = useState((formData.routingNumber && formData.accountNumber)!='');


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    const handleEditClick = (event) => {
        setIsDisabled(false);
        setIsEdit(false);
    }

    function onSubmit(e) {
        e.preventDefault();
        setIsEdit(((formData.accountNumber && formData.routingNumber) != ''));
        setIsDisabled((formData.accountNumber && formData.routingNumber)!='');
    }
    return (
        <div>
            <div className="page-wrapper">
                <HerosectionHeader name={"Payout"} />
                <div className="page-content">
                    <section>
                        <Container>
                            <Row >
                                <Col lg={12} md={12}>
                                    <div className="checkout-form border p-4 rounded">
                                        <h2 className="mb-4">Payout Account</h2>
                                        <div className="col-lg-12 col-12">
                                            <p className="lead mb-5">Please enter your banking details to ensure timely processing of your payments!</p>
                                        </div>
                                        <form onSubmit={onSubmit}>
                                            <Row form>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Input
                                                            type="text"
                                                            id="routingNumber"
                                                            name="routingNumber"
                                                            placeholder="Routing Number"
                                                            value={formData.routingNumber}
                                                            disabled={isDisabled}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Input
                                                            type="text"
                                                            id="accountNumber"
                                                            name="accountNumber"
                                                            placeholder="Account Number"
                                                            value={formData.accountNumber}
                                                            disabled={isDisabled}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={11}></Col>
                                                <Col md={1}>
                                                    {isEdit ? <button type="button" className="btn btn-dark" onClick={handleEditClick}>Edit</button> : null}
                                                    {!isEdit ? <button type="submit" className="btn btn-dark">Save</button> : null}
                                                </Col>
                                            </Row>
                                        </form>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </div>
            <div className="row justify-content-center text-center">
          <div className="col-lg-8">
            <div className="mb-5">
              <p className="lead mb-0"><strong><b>Keep your account safe:</b></strong> <mark>inspectiver</mark> will never call to request a change to your payout account information</p>
            </div>
          </div>
        </div>
        </div>
        
    )
}

export default Payout;