import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { Roles, InspectionSection } from "../../../../store/Utility/Utility";
import axios from "../../../../api/axios";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import HerosectionHeader from "../../../../Componet/Herosection/HerosectionHeader";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import OtherInput from "../../../../Componet/OtherInput";
import { isNullOrEmpty } from "../../../../store/Utility/Helper";
import AutoCompleteInput from "../../../../Componet/Autocomplete/AutoCompleteInput";
import SpinnerButton from "../../../../Componet/Button/SpinnerButton";
import {
    InteriorMaterial, TransmissionType, EngineLayout, CylinderConfiguration, DriveType, FuelType, EFB, Dings, Dents,
    Rust, EFBNA, HornCondition, WorkingNA, CigaretteUse, Leaks, TireBrand,
    SpareTire, SpareTireSet, UnusualOdors, Scratches, RoadTestAvailabilityReasons,
    CommercialTireTreadDepth, HasIssueNA, Warnings
} from "../../../../store/Utility/Data";
import Options from "../../../../Componet/Option/Options";
import { useDropzone } from "react-dropzone";
import ImageArea from "../../../../Componet/Image/ImageArea";
import { QuotationStatus } from "../../../../store/Utility/Status";


const RVOrder = () => {
    let { id } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const [data, setData] = useState({});
    const [originalData, setOriginalData] = useState({});
    const [loading, setLoading] = useState(false);
    const [modal, showModal] = useState({ show: false, message: null, isError: null });
    const [approvalModal, setApprovalModal] = useState({ show: false, message: null, isError: null });
    const [saveModal, setSaveModal] = useState({ show: false });
    const [cancelModal, setCancelModal] = useState({ show: false });
    const [makes, setMake] = useState();
    const [models, setModel] = useState();
    const [validationError, setValidationError] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [disableUploadItem, setDisableUploadItem] = useState(true);
    const navigate = useNavigate();

    function updateFields(fields) {
        setData(prev => {
            return { ...prev, ...fields }
        })
    }

    const roadTestAvailableCheckHandler = (event) => {
        updateFields({ isRoadTestAvailable: !data.isRoadTestAvailable });
    }

    useEffect(() => {
        const getMakes = async () => {
            const response = await axios.get(`v1/make`).then(res => {
                setMake(res.data.data);
            });
        }
        getMakes();
    }, []);

    const getModelByMakeId = async (makeId) => {
        const response = await axios.get(`v1/model/${makeId}`).then(res => {
            setModel(res.data.data);
        });
    }

    const onMakeSelect = (clickedItem) => {
        updateFields({ makeId: clickedItem.id, makeInfo: clickedItem.name });
        updateFields({ modelInfo: "" });
        getModelByMakeId(clickedItem.id);
    }

    const onModelSelect = (clickedItem) => {
        updateFields({ modelId: clickedItem.id, modelInfo: clickedItem.name });
    }

    const getRVInspection = async () => {
        await axiosPrivate.get(`v1/inspection/rv/${id}`).then(res => {
            setData(res.data.data);
            getInspectionFiles();
            if (res.data.data.makeId) {
                getModelByMakeId(res.data.data.makeId);
            }
            let statusData = res.data.data.status;
            setDisableUploadItem((statusData == QuotationStatus.InspectionApproved
                || statusData == QuotationStatus.Completed
                || statusData == QuotationStatus.Closed
                || statusData == QuotationStatus.Cancelled))
            setOriginalData(res.data.data);
        }).catch(error => {

        });
    }

    useEffect(() => {
        getRVInspection();
    }, [])


    function isOther(value) {
        if (value == undefined || value == null) return false;
        return value.toLowerCase() == "other";
    }

    function isFairOrBad(value) {
        return value == "fair" || value == "bad";
    }

    const handleClose = () => showModal({ show: false, message: null, isSuccess: null });

    function UpdateWithSelection(e) {
        updateFields({ [e.target.name]: e.target.value });
    }

    const validateFormItem = (inputValues) => {
        let errors = {};
        if (isNullOrEmpty(inputValues.makeId)) errors.makeId = "Select make";

        if (isNullOrEmpty(inputValues.modelId)) errors.modelId = "Select model";

        if (isNullOrEmpty(inputValues.year)) errors.year = "Select year";

        if (isNullOrEmpty(inputValues.mileage)) errors.mileage = "Enter mileage";

        if (isNullOrEmpty(inputValues.licensePlate)) errors.licensePlate = "Enter License Plate";

        if (isNullOrEmpty(inputValues.vin)) errors.vin = "Enter VIN";

        if (isNullOrEmpty(inputValues.bodyColor)) errors.bodyColor = "Enter Body Color";

        if (isNullOrEmpty(inputValues.interiorColor)) errors.interiorColor = "Enter Interior Color";

        if (isNullOrEmpty(inputValues.interiorMaterial)) errors.interiorMaterial = "Select Interior Material";
        if (isOther(inputValues.interiorMaterial) && isNullOrEmpty(inputValues.interiorMaterialInfo)) errors.interiorMaterialInfo = "Input Interior Material";

        if (isNullOrEmpty(inputValues.transmissionType)) errors.transmissionType = "Select transmission type";
        if (isOther(inputValues.transmissionType) && isNullOrEmpty(inputValues.transmissionTypeInfo)) errors.transmissionTypeInfo = "Enter transmission type";

        if (isNullOrEmpty(inputValues.engineLayout)) errors.engineLayout = "Select engine layout";
        if (isOther(inputValues.engineLayout) && isNullOrEmpty(inputValues.engineLayoutInfo)) errors.engineLayoutInfo = "Enter engine layout";

        if (isNullOrEmpty(inputValues.cylinderConfiguration)) errors.cylinderConfiguration = "Select cylinder configuration";
        if (isOther(inputValues.cylinderConfiguration) && isNullOrEmpty(inputValues.cylinderConfigurationInfo)) errors.cylinderConfigurationInfo = "Enter cylinder configuration";

        if (isNullOrEmpty(inputValues.driveType)) errors.driveType = "Select drive type";
        if (isOther(inputValues.driveType) && isNullOrEmpty(inputValues.driveTypeInfo)) errors.driveTypeInfo = "Enter drive type";

        if (isNullOrEmpty(inputValues.fuelType)) errors.fuelType = "Select fuel type";
        if (isOther(inputValues.fuelType) && isNullOrEmpty(inputValues.fuelTypeInfo)) errors.fuelTypeInfo = "Enter fuel type";

        if (isNullOrEmpty(inputValues.vehicleInfoNote)) errors.vehicleInfoNote = "Comment about vehicle";

        if (isNullOrEmpty(inputValues.bodyAlignment)) errors.bodyAlignment = "Select body alignment";
        if (isFairOrBad(inputValues.bodyAlignment) && isNullOrEmpty(inputValues.bodyAlignmentIssues)) errors.bodyAlignmentIssues = "Enter body alignment issues";

        if (isNullOrEmpty(inputValues.paint)) errors.paint = "Select paint";
        if (isFairOrBad(inputValues.paint) && isNullOrEmpty(inputValues.paintIssues)) errors.paintIssues = "Enter paint issues";

        if (isNullOrEmpty(inputValues.frame)) errors.frame = "Select frame";
        if (isFairOrBad(inputValues.frame) && isNullOrEmpty(inputValues.frameIssues)) errors.frameIssues = "Enter frame issues";

        if (isNullOrEmpty(inputValues.glassAndWindow)) errors.glassAndWindow = "Select glass and window";
        if (isFairOrBad(inputValues.glassAndWindow) && isNullOrEmpty(inputValues.glassAndWindowIssues)) errors.glassAndWindowIssues = "Enter glass and window issues";

        if (isNullOrEmpty(inputValues.scratches)) errors.scratches = "Select scratches";
        if (isFairOrBad(inputValues.scratches) && isNullOrEmpty(inputValues.scratchesIssues)) errors.scratchesIssues = "Enter scratches issues";

        if (isNullOrEmpty(inputValues.dings)) errors.dings = "Select dings";
        if (isFairOrBad(inputValues.dings) && isNullOrEmpty(inputValues.dingsIssues)) errors.dingsIssues = "Enter dings issues";

        if (isNullOrEmpty(inputValues.dents)) errors.dents = "Select dents";
        if (isFairOrBad(inputValues.dents) && isNullOrEmpty(inputValues.dentsIssues)) errors.dentsIssues = "Enter dents issues";

        if (isNullOrEmpty(inputValues.rust)) errors.rust = "Select rust";
        if (isFairOrBad(inputValues.rust) && isNullOrEmpty(inputValues.rustIssues)) errors.rustIssues = "Enter rust issues";

        if (isNullOrEmpty(inputValues.bodySeals)) errors.bodySeals = "Select body seals";
        if (isFairOrBad(inputValues.bodySeals) && isNullOrEmpty(inputValues.bodySealsIssues)) errors.bodySealsIssues = "Enter body seals issues";

        if (isNullOrEmpty(inputValues.sideMoldings)) errors.sideMoldings = "Select side moldings";
        if (isFairOrBad(inputValues.sideMoldings) && isNullOrEmpty(inputValues.sideMoldingsIssues)) errors.sideMoldingsIssues = "Enter side moldings issues";

        if (isNullOrEmpty(inputValues.sideMirrors)) errors.sideMirrors = "Select side mirrors";
        if (isFairOrBad(inputValues.sideMirrors) && isNullOrEmpty(inputValues.sideMirrorsIssues)) errors.sideMirrorsIssues = "Enter side mirrors issues";

        if (isNullOrEmpty(inputValues.exteriorLights)) errors.exteriorLights = "Select exterior lights";
        if (isFairOrBad(inputValues.exteriorLights) && isNullOrEmpty(inputValues.exteriorLightsIssues)) errors.exteriorLightsIssues = "Enter exterior lights issues";

        if (isNullOrEmpty(inputValues.wiperBlades)) errors.wiperBlades = "Select wiper blades";
        if (isFairOrBad(inputValues.wiperBlades) && isNullOrEmpty(inputValues.wiperBladesIssues)) errors.wiperBladesIssues = "Enter wiper blades issues";

        if (isNullOrEmpty(inputValues.windshieldWasher)) errors.windshieldWasher = "Select windshield washer";
        if (isFairOrBad(inputValues.windshieldWasher) && isNullOrEmpty(inputValues.windshieldWasherIssues)) errors.windshieldWasherIssues = "Enter windshield washer issues";

        if (isNullOrEmpty(inputValues.exteriorNote)) errors.exteriorNote = "Comment about exterior condition";

        if (isNullOrEmpty(inputValues.steeringWheel)) errors.steeringWheel = "Select steering wheel";
        if (isFairOrBad(inputValues.steeringWheel) && isNullOrEmpty(inputValues.steeringWheelIssues)) errors.steeringWheelIssues = "Enter steering wheel issues";

        if (isNullOrEmpty(inputValues.horn)) errors.horn = "Select horn";
        if (isFairOrBad(inputValues.horn) && isNullOrEmpty(inputValues.hornIssues)) errors.hornIssues = "Enter horn issues";

        if (isNullOrEmpty(inputValues.seats)) errors.seats = "Select seats";
        if (isFairOrBad(inputValues.seats) && isNullOrEmpty(inputValues.seatsIssues)) errors.seatsIssues = "Enter seats issues";

        if (isNullOrEmpty(inputValues.seatMechanisms)) errors.seatMechanisms = "Select seat mechanisms";
        if (isFairOrBad(inputValues.seatMechanisms) && isNullOrEmpty(inputValues.seatMechanismsIssues)) errors.seatMechanismsIssues = "Enter seat mechanisms issues";

        if (isNullOrEmpty(inputValues.sunVisors)) errors.sunVisors = "Select sun visors";
        if (isFairOrBad(inputValues.sunVisors) && isNullOrEmpty(inputValues.sunVisorsIssues)) errors.sunVisorsIssues = "Enter sun visors issues";

        if (isNullOrEmpty(inputValues.headliner)) errors.headliner = "Select headliner";
        if (isFairOrBad(inputValues.headliner) && isNullOrEmpty(inputValues.headlinerIssues)) errors.headlinerIssues = "Enter headliner issues";

        if (isNullOrEmpty(inputValues.innerDoorPanels)) errors.innerDoorPanels = "Select inner door panels";
        if (isFairOrBad(inputValues.innerDoorPanels) && isNullOrEmpty(inputValues.innerDoorPanelsIssues)) errors.innerDoorPanelsIssues = "Enter inner door panels issues";

        if (isNullOrEmpty(inputValues.interiorTrim)) errors.interiorTrim = "Select interior trim";
        if (isFairOrBad(inputValues.interiorTrim) && isNullOrEmpty(inputValues.interiorTrimIssues)) errors.interiorTrimIssues = "Enter interior trim issues";

        if (isNullOrEmpty(inputValues.interiorLights)) errors.interiorLights = "Select interior lights";
        if (isFairOrBad(inputValues.interiorLights) && isNullOrEmpty(inputValues.interiorLightsIssues)) errors.interiorLightsIssues = "Enter interior lights issues";

        if (isNullOrEmpty(inputValues.powerLocks)) errors.powerLocks = "Select power locks";
        if (isFairOrBad(inputValues.powerLocks) && isNullOrEmpty(inputValues.powerLocksIssues)) errors.powerLocksIssues = "Enter power locks issues";

        if (isNullOrEmpty(inputValues.windowSwitches)) errors.windowSwitches = "Select window switches";
        if (isFairOrBad(inputValues.windowSwitches) && isNullOrEmpty(inputValues.windowSwitchesIssues)) errors.windowSwitchesIssues = "Enter window switches issues";

        if (isNullOrEmpty(inputValues.windowFunctioning)) errors.windowFunctioning = "Select window functioning";
        if (isFairOrBad(inputValues.windowFunctioning) && isNullOrEmpty(inputValues.windowFunctioningIssues)) errors.windowFunctioningIssues = "Enter window functioning issues";

        if (isNullOrEmpty(inputValues.trunkActuator)) errors.trunkActuator = "Select trunk actuator";
        if (isFairOrBad(inputValues.trunkActuator) && isNullOrEmpty(inputValues.trunkActuatorIssues)) errors.trunkActuatorIssues = "Enter trunk actuator issues";

        if (isNullOrEmpty(inputValues.hoodActuator)) errors.hoodActuator = "Select hood actuator";
        if (isFairOrBad(inputValues.hoodActuator) && isNullOrEmpty(inputValues.hoodActuatorIssues)) errors.hoodActuatorIssues = "Enter hood actuator issues";

        if (isNullOrEmpty(inputValues.fuelDoorActuator)) errors.fuelDoorActuator = "Select fuel door actuator";
        if (isFairOrBad(inputValues.fuelDoorActuator) && isNullOrEmpty(inputValues.fuelDoorActuatorIssues)) errors.fuelDoorActuatorIssues = "Enter fuel door actuator issues";

        if (isNullOrEmpty(inputValues.dash)) errors.dash = "Select dash";
        if (isFairOrBad(inputValues.dash) && isNullOrEmpty(inputValues.dashIssues)) errors.dashIssues = "Enter dash issues";

        if (isNullOrEmpty(inputValues.dashLights)) errors.dashLights = "Select dash lights";
        if (isFairOrBad(inputValues.dashLights) && isNullOrEmpty(inputValues.dashLightsIssues)) errors.dashLightsIssues = "Enter dash lights issues";

        if (isNullOrEmpty(inputValues.dashWarnings)) errors.dashWarnings = "Select dash warnings";
        if (isFairOrBad(inputValues.dashWarnings) && isNullOrEmpty(inputValues.dashWarningsIssues)) errors.dashWarningsIssues = "Enter dash warnings issues";

        if (isNullOrEmpty(inputValues.dashGauges)) errors.dashGauges = "Select dash gauges";
        if (isFairOrBad(inputValues.dashGauges) && isNullOrEmpty(inputValues.dashGaugesIssues)) errors.dashGaugesIssues = "Enter dash gauges issues";

        if (isNullOrEmpty(inputValues.centerConsole)) errors.centerConsole = "Select center console";
        if (isFairOrBad(inputValues.centerConsole) && isNullOrEmpty(inputValues.centerConsoleIssues)) errors.centerConsoleIssues = "Enter center console issues";

        if (isNullOrEmpty(inputValues.cupholder)) errors.cupholder = "Select cupholder";
        if (isFairOrBad(inputValues.cupholder) && isNullOrEmpty(inputValues.cupholderIssues)) errors.cupholderIssues = "Enter cupholder issues";

        if (isNullOrEmpty(inputValues.radio)) errors.radio = "Select radio";
        if (isFairOrBad(inputValues.radio) && isNullOrEmpty(inputValues.radioIssues)) errors.radioIssues = "Enter radio issues";

        if (isNullOrEmpty(inputValues.navigation)) errors.navigation = "Select navigation";
        if (isFairOrBad(inputValues.navigation) && isNullOrEmpty(inputValues.navigationIssues)) errors.navigationIssues = "Enter navigation issues";

        if (isNullOrEmpty(inputValues.airbags)) errors.airbags = "Select airbags";
        if (isFairOrBad(inputValues.airbags) && isNullOrEmpty(inputValues.airbagsIssues)) errors.airbagsIssues = "Enter airbags issues";

        if (isNullOrEmpty(inputValues.seatBelts)) errors.seatBelts = "Select seat belts";
        if (isFairOrBad(inputValues.seatBelts) && isNullOrEmpty(inputValues.seatBeltsIssues)) errors.seatBeltsIssues = "Enter seat belts issues";

        if (isNullOrEmpty(inputValues.ac)) errors.ac = "Select AC";
        if (isFairOrBad(inputValues.ac) && isNullOrEmpty(inputValues.acIssues)) errors.acIssues = "Enter AC issues";

        if (isNullOrEmpty(inputValues.heater)) errors.heater = "Select heater";
        if (isFairOrBad(inputValues.heater) && isNullOrEmpty(inputValues.heaterIssues)) errors.heaterIssues = "Enter heater issues";

        if (isNullOrEmpty(inputValues.rearDefroster)) errors.rearDefroster = "Select rear defroster";
        if (isFairOrBad(inputValues.rearDefroster) && isNullOrEmpty(inputValues.rearDefrosterIssues)) errors.rearDefrosterIssues = "Enter rear defroster issues";

        if (isNullOrEmpty(inputValues.blowerMotor)) errors.blowerMotor = "Select blower motor";
        if (isFairOrBad(inputValues.blowerMotor) && isNullOrEmpty(inputValues.blowerMotorIssues)) errors.blowerMotorIssues = "Enter blower motor issues";

        if (isNullOrEmpty(inputValues.seatHeater)) errors.seatHeater = "Select seat heater";
        if (isFairOrBad(inputValues.seatHeater) && isNullOrEmpty(inputValues.seatHeaterIssues)) errors.seatHeaterIssues = "Enter seat heater issues";

        if (isNullOrEmpty(inputValues.carpet)) errors.carpet = "Select carpet";
        if (isFairOrBad(inputValues.carpet) && isNullOrEmpty(inputValues.carpetIssues)) errors.carpetIssues = "Enter carpet issues";

        if (isNullOrEmpty(inputValues.floorMat)) errors.floorMat = "Select floor mat";
        if (isFairOrBad(inputValues.floorMat) && isNullOrEmpty(inputValues.floorMatIssues)) errors.floorMatIssues = "Enter floor mat issues";

        if (isNullOrEmpty(inputValues.cigaretteUse)) errors.cigaretteUse = "Select cigarette use";
        if (isFairOrBad(inputValues.cigaretteUse) && isNullOrEmpty(inputValues.cigaretteUseIssues)) errors.cigaretteUseIssues = "Enter cigarette use issues";

        if (isNullOrEmpty(inputValues.unusualOdors)) errors.unusualOdors = "Select unusual odors";
        if (isFairOrBad(inputValues.unusualOdors) && isNullOrEmpty(inputValues.unusualOdorsIssues)) errors.unusualOdorsIssues = "Enter unusual odors issues";

        if (isNullOrEmpty(inputValues.interiorNote)) errors.interiorNote = "Comment about interior condition";

        if (isNullOrEmpty(inputValues.keyFOB)) errors.keyFOB = "Select key FOB";
        if (isFairOrBad(inputValues.keyFOB) && isNullOrEmpty(inputValues.keyFOBIssues)) errors.keyFOBIssues = "Enter key FOB issues";

        if (isNullOrEmpty(inputValues.computerDiagnosisAndOBD2)) errors.computerDiagnosisAndOBD2 = "Select computer diagnosis and OBD2";
        if (isFairOrBad(inputValues.computerDiagnosisAndOBD2) && isNullOrEmpty(inputValues.computerDiagnosisAndOBD2Issues)) errors.computerDiagnosisAndOBD2Issues = "Enter computer diagnosis and OBD2 issues";

        if (isNullOrEmpty(inputValues.battery)) errors.battery = "Select battery";
        if (isFairOrBad(inputValues.battery) && isNullOrEmpty(inputValues.batteryIssues)) errors.batteryIssues = "Enter battery issues";

        if (isNullOrEmpty(inputValues.chargingSystem)) errors.chargingSystem = "Select charging system";
        if (isFairOrBad(inputValues.chargingSystem) && isNullOrEmpty(inputValues.chargingSystemIssues)) errors.chargingSystemIssues = "Enter charging system issues";

        if (isNullOrEmpty(inputValues.alternator)) errors.alternator = "Select alternator";
        if (isFairOrBad(inputValues.alternator) && isNullOrEmpty(inputValues.alternatorIssues)) errors.alternatorIssues = "Enter alternator issues";

        if (isNullOrEmpty(inputValues.starter)) errors.starter = "Select starter";
        if (isFairOrBad(inputValues.starter) && isNullOrEmpty(inputValues.starterIssues)) errors.starterIssues = "Enter starter issues";

        if (isNullOrEmpty(inputValues.belts)) errors.belts = "Select belts";
        if (isFairOrBad(inputValues.belts) && isNullOrEmpty(inputValues.beltsIssues)) errors.beltsIssues = "Enter belts issues";

        if (isNullOrEmpty(inputValues.hoses)) errors.hoses = "Select hoses";
        if (isFairOrBad(inputValues.hoses) && isNullOrEmpty(inputValues.hosesIssues)) errors.hosesIssues = "Enter hoses issues";

        if (isNullOrEmpty(inputValues.airFilter)) errors.airFilter = "Select air filter";
        if (isFairOrBad(inputValues.airFilter) && isNullOrEmpty(inputValues.airFilterIssues)) errors.airFilterIssues = "Enter air filter issues";

        if (isNullOrEmpty(inputValues.coolingFan)) errors.coolingFan = "Select cooling fan";
        if (isFairOrBad(inputValues.coolingFan) && isNullOrEmpty(inputValues.coolingFanIssues)) errors.coolingFanIssues = "Enter cooling fan issues";

        if (isNullOrEmpty(inputValues.radiatorAndLeaks)) errors.radiatorAndLeaks = "Select radiator and leaks";
        if (isFairOrBad(inputValues.radiatorAndLeaks) && isNullOrEmpty(inputValues.radiatorAndLeaksIssues)) errors.radiatorAndLeaksIssues = "Enter radiator and leaks issues";

        if (isNullOrEmpty(inputValues.coolantSystemAndLeaks)) errors.coolantSystemAndLeaks = "Select coolant system and leaks";
        if (isFairOrBad(inputValues.coolantSystemAndLeaks) && isNullOrEmpty(inputValues.coolantSystemAndLeaksIssues)) errors.coolantSystemAndLeaksIssues = "Enter coolant system and leaks issues";

        if (isNullOrEmpty(inputValues.powerSteeringSystemAndFluid)) errors.powerSteeringSystemAndFluid = "Select power steering system and fluid";
        if (isFairOrBad(inputValues.powerSteeringSystemAndFluid) && isNullOrEmpty(inputValues.powerSteeringSystemAndFluidIssues)) errors.powerSteeringSystemAndFluidIssues = "Enter power steering system and fluid issues";

        if (isNullOrEmpty(inputValues.powerSteeringFluidLeaks)) errors.powerSteeringFluidLeaks = "Select power steering fluid leaks";
        if (isFairOrBad(inputValues.powerSteeringFluidLeaks) && isNullOrEmpty(inputValues.powerSteeringFluidLeaksIssues)) errors.powerSteeringFluidLeaksIssues = "Enter power steering fluid leaks issues";

        if (isNullOrEmpty(inputValues.engineBlockAndOil)) errors.engineBlockAndOil = "Select engine block and oil";
        if (isFairOrBad(inputValues.engineBlockAndOil) && isNullOrEmpty(inputValues.engineBlockAndOilIssues)) errors.engineBlockAndOilIssues = "Enter engine block and oil issues";

        if (isNullOrEmpty(inputValues.engineFluidAndOilLeaks)) errors.engineFluidAndOilLeaks = "Select engine fluid and oil leaks";
        if (isFairOrBad(inputValues.engineFluidAndOilLeaks) && isNullOrEmpty(inputValues.engineFluidAndOilLeaksIssues)) errors.engineFluidAndOilLeaksIssues = "Enter engine fluid and oil leaks issues";

        if (isNullOrEmpty(inputValues.transmissionSystemAndFluid)) errors.transmissionSystemAndFluid = "Select transmission system and fluid";
        if (isFairOrBad(inputValues.transmissionSystemAndFluid) && isNullOrEmpty(inputValues.transmissionSystemAndFluidIssues)) errors.transmissionSystemAndFluidIssues = "Enter transmission system and fluid issues";

        if (isNullOrEmpty(inputValues.transmissionFluidLeaks)) errors.transmissionFluidLeaks = "Select transmission fluid leaks";
        if (isFairOrBad(inputValues.transmissionFluidLeaks) && isNullOrEmpty(inputValues.transmissionFluidLeaksIssues)) errors.transmissionFluidLeaksIssues = "Enter transmission fluid leaks issues";

        if (isNullOrEmpty(inputValues.brakeSystemAndPads)) errors.brakeSystemAndPads = "Select brake system and pads";
        if (isFairOrBad(inputValues.brakeSystemAndPads) && isNullOrEmpty(inputValues.brakeSystemAndPadsIssues)) errors.brakeSystemAndPadsIssues = "Enter brake system and pads issues";

        if (isNullOrEmpty(inputValues.brakeFluidLeaks)) errors.brakeFluidLeaks = "Select brake fluid leaks";
        if (isFairOrBad(inputValues.brakeFluidLeaks) && isNullOrEmpty(inputValues.brakeFluidLeaksIssues)) errors.brakeFluidLeaksIssues = "Enter brake fluid leaks issues";

        if (isNullOrEmpty(inputValues.differentialSystem)) errors.differentialSystem = "Select differential system";
        if (isFairOrBad(inputValues.differentialSystem) && isNullOrEmpty(inputValues.differentialSystemIssues)) errors.differentialSystemIssues = "Enter differential system issues";

        if (isNullOrEmpty(inputValues.differentialFluidLeaks)) errors.differentialFluidLeaks = "Select differential fluid leaks";
        if (isFairOrBad(inputValues.differentialFluidLeaks) && isNullOrEmpty(inputValues.differentialFluidLeaksIssues)) errors.differentialFluidLeaksIssues = "Enter differential fluid leaks issues";

        if (isNullOrEmpty(inputValues.catalyticConverter)) errors.catalyticConverter = "Select catalytic converter";
        if (isFairOrBad(inputValues.catalyticConverter) && isNullOrEmpty(inputValues.catalyticConverterIssues)) errors.catalyticConverterIssues = "Enter catalytic converter issues";

        if (isNullOrEmpty(inputValues.mufflerSystem)) errors.mufflerSystem = "Select muffler system";
        if (isFairOrBad(inputValues.mufflerSystem) && isNullOrEmpty(inputValues.mufflerSystemIssues)) errors.mufflerSystemIssues = "Enter muffler system issues";

        if (isNullOrEmpty(inputValues.cvJointsAndAxles)) errors.cvJointsAndAxles = "Select CV joints and axles";
        if (isFairOrBad(inputValues.cvJointsAndAxles) && isNullOrEmpty(inputValues.cvJointsAndAxlesIssues)) errors.cvJointsAndAxlesIssues = "Enter CV joints and axles issues";

        if (isNullOrEmpty(inputValues.shocksAndStruts)) errors.shocksAndStruts = "Select shocks and struts";
        if (isFairOrBad(inputValues.shocksAndStruts) && isNullOrEmpty(inputValues.shocksAndStrutsIssues)) errors.shocksAndStrutsIssues = "Enter shocks and struts issues";

        if (isNullOrEmpty(inputValues.bushingsBallJointsRodsAndArms)) errors.bushingsBallJointsRodsAndArms = "Select bushings, ball joints, rods, and arms";
        if (isFairOrBad(inputValues.bushingsBallJointsRodsAndArms) && isNullOrEmpty(inputValues.bushingsBallJointsRodsAndArmsIssues)) errors.bushingsBallJointsRodsAndArmsIssues = "Enter bushings, ball joints, rods, and arms issues";

        if (isNullOrEmpty(inputValues.undercarriage)) errors.undercarriage = "Select undercarriage";
        if (isFairOrBad(inputValues.undercarriage) && isNullOrEmpty(inputValues.undercarriageIssues)) errors.undercarriageIssues = "Enter undercarriage issues";

        if (isNullOrEmpty(inputValues.hydraulics)) errors.hydraulics = "Select hydraulics";
        if (isFairOrBad(inputValues.hydraulics) && isNullOrEmpty(inputValues.hydraulicsIssues)) errors.hydraulicsIssues = "Enter hydraulics issues";

        if (isNullOrEmpty(inputValues.extrasAndUpgrades)) errors.extrasAndUpgrades = "Enter if there is extras and upgrades";

        if (isNullOrEmpty(inputValues.mechanicalNote)) errors.mechanicalNote = "Comment about mechanical condition";

        //Tire

        if (isNullOrEmpty(inputValues.driverFrontBrand)) errors.driverFrontBrand = "Select driver front brand";

        if (isNullOrEmpty(inputValues.driverFrontSize)) errors.driverFrontSize = "Select driver front size";

        if (isNullOrEmpty(inputValues.driverFrontTreadDepth)) errors.driverFrontTreadDepth = "Select driver front tread depth";

        if (isNullOrEmpty(inputValues.driverFrontTireAndWheel)) errors.driverFrontTireAndWheel = "Select driver front tire and wheel";

        if (isNullOrEmpty(inputValues.driverFirstAxleInnerBrand)) errors.driverFirstAxleInnerBrand = "Select driver first axle inner brand";

        if (isNullOrEmpty(inputValues.driverFirstAxleInnerSize)) errors.driverFirstAxleInnerSize = "Select driver first axle inner size";

        if (isNullOrEmpty(inputValues.driverFirstAxleInnerTreadDepth)) errors.driverFirstAxleInnerTreadDepth = "Select driver first axle inner tread depth";

        if (isNullOrEmpty(inputValues.driverFirstAxleInnerTireAndWheel)) errors.driverFirstAxleInnerTireAndWheel = "Select driver first axle inner tire and wheel";

        if (isNullOrEmpty(inputValues.driverFirstAxleOuterBrand)) errors.driverFirstAxleOuterBrand = "Select driver first axle outer brand";

        if (isNullOrEmpty(inputValues.driverFirstAxleOuterSize)) errors.driverFirstAxleOuterSize = "Select driver first axle outer size";

        if (isNullOrEmpty(inputValues.driverFirstAxleOuterTreadDepth)) errors.driverFirstAxleOuterTreadDepth = "Select driver first axle outer tread depth";

        if (isNullOrEmpty(inputValues.driverFirstAxleOuterTireAndWheel)) errors.driverFirstAxleOuterTireAndWheel = "Select driver first axle outer tire and wheel";

        if (isNullOrEmpty(inputValues.passengerFrontBrand)) errors.passengerFrontBrand = "Select passenger front brand";

        if (isNullOrEmpty(inputValues.passengerFrontSize)) errors.passengerFrontSize = "Select passenger front size";

        if (isNullOrEmpty(inputValues.passengerFrontTreadDepth)) errors.passengerFrontTreadDepth = "Select passenger front tread depth";

        if (isNullOrEmpty(inputValues.passengerFrontTireAndWheel)) errors.passengerFrontTireAndWheel = "Select passenger front tire and wheel";

        if (isNullOrEmpty(inputValues.passengerFirstAxleInnerBrand)) errors.passengerFirstAxleInnerBrand = "Select passenger first axle inner brand";

        if (isNullOrEmpty(inputValues.passengerFirstAxleInnerSize)) errors.passengerFirstAxleInnerSize = "Select passenger first axle inner size";

        if (isNullOrEmpty(inputValues.passengerFirstAxleInnerTreadDepth)) errors.passengerFirstAxleInnerTreadDepth = "Select passenger first axle inner tread depth";

        if (isNullOrEmpty(inputValues.passengerFirstAxleInnerTireAndWheel)) errors.passengerFirstAxleInnerTireAndWheel = "Select passenger first axle inner tire and wheel";

        if (isNullOrEmpty(inputValues.passengerFirstAxleOuterBrand)) errors.passengerFirstAxleOuterBrand = "Select passenger first axle outer brand";

        if (isNullOrEmpty(inputValues.passengerFirstAxleOuterSize)) errors.passengerFirstAxleOuterSize = "Select passenger first axle outer size";

        if (isNullOrEmpty(inputValues.passengerFirstAxleOuterTreadDepth)) errors.passengerFirstAxleOuterTreadDepth = "Select passenger first axle outer tread depth";

        if (isNullOrEmpty(inputValues.passengerFirstAxleOuterTireAndWheel)) errors.passengerFirstAxleOuterTireAndWheel = "Select passenger first axle outer tire and wheel";

        if (isNullOrEmpty(inputValues.spareTire)) errors.spareTire = "Select spare tire";

        if (isNullOrEmpty(inputValues.spareTireSet)) errors.spareTireSet = "Select spare tire set";

        if (isNullOrEmpty(inputValues.tiresNote)) errors.tiresNote = "Select tires note";

        if (inputValues.isRoadTestAvailable) {
            if (isNullOrEmpty(inputValues.engineStartingPerformance)) errors.engineStartingPerformance = "Select engine starting performance";
            if (isFairOrBad(inputValues.engineStartingPerformance) && isNullOrEmpty(inputValues.engineStartingPerformanceIssues)) errors.engineStartingPerformanceIssues = "Enter engine starting performance issues";

            if (isNullOrEmpty(inputValues.wheelBearingPerformance)) errors.wheelBearingPerformance = "Select wheel bearing performance";
            if (isFairOrBad(inputValues.wheelBearingPerformance) && isNullOrEmpty(inputValues.wheelBearingPerformanceIssues)) errors.wheelBearingPerformanceIssues = "Enter wheel bearing performance issues";

            if (isNullOrEmpty(inputValues.steeringPerformance)) errors.steeringPerformance = "Select steering performance";
            if (isFairOrBad(inputValues.steeringPerformance) && isNullOrEmpty(inputValues.steeringPerformanceIssues)) errors.steeringPerformanceIssues = "Enter steering performance issues";

            if (isNullOrEmpty(inputValues.suspensionPerformance)) errors.suspensionPerformance = "Select suspension performance";
            if (isFairOrBad(inputValues.suspensionPerformance) && isNullOrEmpty(inputValues.suspensionPerformanceIssues)) errors.suspensionPerformanceIssues = "Enter suspension performance issues";

            if (isNullOrEmpty(inputValues.transmissionPerformance)) errors.transmissionPerformance = "Select transmission performance";
            if (isFairOrBad(inputValues.transmissionPerformance) && isNullOrEmpty(inputValues.transmissionPerformanceIssues)) errors.transmissionPerformanceIssues = "Enter transmission performance issues";

            if (isNullOrEmpty(inputValues.differentialPerformance)) errors.differentialPerformance = "Select differential performance";
            if (isFairOrBad(inputValues.differentialPerformance) && isNullOrEmpty(inputValues.differentialPerformanceIssues)) errors.differentialPerformanceIssues = "Enter differential performance issues";

            if (isNullOrEmpty(inputValues.transferCasePerformance)) errors.transferCasePerformance = "Select transfer case performance";
            if (isFairOrBad(inputValues.transferCasePerformance) && isNullOrEmpty(inputValues.transferCasePerformanceIssues)) errors.transferCasePerformanceIssues = "Enter transfer case performance issues";

            if (isNullOrEmpty(inputValues.brakingPerformance)) errors.brakingPerformance = "Select braking performance";
            if (isFairOrBad(inputValues.brakingPerformance) && isNullOrEmpty(inputValues.brakingPerformanceIssues)) errors.brakingPerformanceIssues = "Enter braking performance issues";

            if (isNullOrEmpty(inputValues.interiorNoisePerformance)) errors.interiorNoisePerformance = "Select interior noise performance";
            if (isFairOrBad(inputValues.interiorNoisePerformance) && isNullOrEmpty(inputValues.interiorNoisePerformanceIssues)) errors.interiorNoisePerformanceIssues = "Enter interior noise performance issues";

            if (isNullOrEmpty(inputValues.overallEnginePerformance)) errors.overallEnginePerformance = "Select overall engine performance";
            if (isFairOrBad(inputValues.overallEnginePerformance) && isNullOrEmpty(inputValues.overallEnginePerformanceIssues)) errors.overallEnginePerformanceIssues = "Enter overall engine performance issues";

            if (isNullOrEmpty(inputValues.automaticStartStop)) errors.automaticStartStop = "Select automatic start/stop";
            if (isFairOrBad(inputValues.automaticStartStop) && isNullOrEmpty(inputValues.automaticStartStopIssues)) errors.automaticStartStopIssues = "Enter automatic start/stop issues";

            if (isNullOrEmpty(inputValues.cruiseControl)) errors.cruiseControl = "Select cruise control";
            if (isFairOrBad(inputValues.cruiseControl) && isNullOrEmpty(inputValues.cruiseControlIssues)) errors.cruiseControlIssues = "Enter cruise control issues";

            if (isNullOrEmpty(inputValues.blindSpotDetection)) errors.blindSpotDetection = "Select blind spot detection";
            if (isFairOrBad(inputValues.blindSpotDetection) && isNullOrEmpty(inputValues.blindSpotDetectionIssues)) errors.blindSpotDetectionIssues = "Enter blind spot detection issues";

            if (isNullOrEmpty(inputValues.laneAssist)) errors.laneAssist = "Select lane assist";
            if (isFairOrBad(inputValues.laneAssist) && isNullOrEmpty(inputValues.laneAssistIssues)) errors.laneAssistIssues = "Enter lane assist issues";

            if (isNullOrEmpty(inputValues.hillStartAssist)) errors.hillStartAssist = "Select hill start assist";
            if (isFairOrBad(inputValues.hillStartAssist) && isNullOrEmpty(inputValues.hillStartAssistIssues)) errors.hillStartAssistIssues = "Enter hill start assist issues";

            if (isNullOrEmpty(inputValues.parkingAssist)) errors.parkingAssist = "Select parking assist";
            if (isFairOrBad(inputValues.parkingAssist) && isNullOrEmpty(inputValues.parkingAssistIssues)) errors.parkingAssistIssues = "Enter parking assist issues";

            if (isNullOrEmpty(inputValues.backUpCamera)) errors.backUpCamera = "Select back-up camera";
            if (isFairOrBad(inputValues.backUpCamera) && isNullOrEmpty(inputValues.backUpCameraIssues)) errors.backUpCameraIssues = "Enter back-up camera issues";

            if (isNullOrEmpty(inputValues.autoPilotAssist)) errors.autoPilotAssist = "Select auto pilot assist";
            if (isFairOrBad(inputValues.autoPilotAssist) && isNullOrEmpty(inputValues.autoPilotAssistIssues)) errors.autoPilotAssistIssues = "Enter auto pilot assist issues";

            if (isNullOrEmpty(inputValues.roadTestNote)) errors.roadTestNote = "Comment about road test";
        } else {
            if (isNullOrEmpty(inputValues.roadTestAvailabilityReason)) errors.roadTestAvailabilityReason = "Select road test availability reason";
        }

        if (isNullOrEmpty(inputValues.refrigerator)) errors.refrigerator = "Select refrigerator";
        if (isFairOrBad(inputValues.refrigerator) && isNullOrEmpty(inputValues.refrigeratorIssues)) errors.refrigeratorIssues = "Enter refrigerator issues";

        if (isNullOrEmpty(inputValues.stove)) errors.stove = "Select stove";
        if (isFairOrBad(inputValues.stove) && isNullOrEmpty(inputValues.stoveIssues)) errors.stoveIssues = "Enter stove issues";

        if (isNullOrEmpty(inputValues.microwave)) errors.microwave = "Select microwave";
        if (isFairOrBad(inputValues.microwave) && isNullOrEmpty(inputValues.microwaveIssues)) errors.microwaveIssues = "Enter microwave issues";

        if (isNullOrEmpty(inputValues.acSystem)) errors.acSystem = "Select AC system";
        if (isFairOrBad(inputValues.acSystem) && isNullOrEmpty(inputValues.acSystemIssues)) errors.acSystemIssues = "Enter AC system issues";

        if (isNullOrEmpty(inputValues.grayWaterTank)) errors.grayWaterTank = "Select gray water tank";
        if (isFairOrBad(inputValues.grayWaterTank) && isNullOrEmpty(inputValues.grayWaterTankIssues)) errors.grayWaterTankIssues = "Enter gray water tank issues";

        if (isNullOrEmpty(inputValues.blackWaterTank)) errors.blackWaterTank = "Select black water tank";
        if (isFairOrBad(inputValues.blackWaterTank) && isNullOrEmpty(inputValues.blackWaterTankIssues)) errors.blackWaterTankIssues = "Enter black water tank issues";

        if (isNullOrEmpty(inputValues.solarPanels)) errors.solarPanels = "Select solar panels";
        if (isFairOrBad(inputValues.solarPanels) && isNullOrEmpty(inputValues.solarPanelsIssues)) errors.solarPanelsIssues = "Enter solar panels issues";

        if (isNullOrEmpty(inputValues.waterHeater)) errors.waterHeater = "Select water heater";
        if (isFairOrBad(inputValues.waterHeater) && isNullOrEmpty(inputValues.waterHeaterIssues)) errors.waterHeaterIssues = "Enter water heater issues";

        if (isNullOrEmpty(inputValues.kitchen)) errors.kitchen = "Select kitchen";
        if (isFairOrBad(inputValues.kitchen) && isNullOrEmpty(inputValues.kitchenIssues)) errors.kitchenIssues = "Enter kitchen issues";

        if (isNullOrEmpty(inputValues.bathrooms)) errors.bathrooms = "Select bathrooms";
        if (isFairOrBad(inputValues.bathrooms) && isNullOrEmpty(inputValues.bathroomsIssues)) errors.bathroomsIssues = "Enter bathrooms issues";

        if (isNullOrEmpty(inputValues.bedrooms)) errors.bedrooms = "Select bedrooms";
        if (isFairOrBad(inputValues.bedrooms) && isNullOrEmpty(inputValues.bedroomsIssues)) errors.bedroomsIssues = "Enter bedrooms issues";

        if (isNullOrEmpty(inputValues.livingRoom)) errors.livingRoom = "Select living room";
        if (isFairOrBad(inputValues.livingRoom) && isNullOrEmpty(inputValues.livingRoomIssues)) errors.livingRoomIssues = "Enter living room issues";

        if (isNullOrEmpty(inputValues.lighting)) errors.lighting = "Select lighting";
        if (isFairOrBad(inputValues.lighting) && isNullOrEmpty(inputValues.lightingIssues)) errors.lightingIssues = "Enter lighting issues";

        if (isNullOrEmpty(inputValues.generator)) errors.generator = "Select generator";
        if (isFairOrBad(inputValues.generator) && isNullOrEmpty(inputValues.generatorIssues)) errors.generatorIssues = "Enter generator issues";

        if (isNullOrEmpty(inputValues.slideOuts)) errors.slideOuts = "Select slide outs";
        if (isFairOrBad(inputValues.slideOuts) && isNullOrEmpty(inputValues.slideOutsIssues)) errors.slideOutsIssues = "Enter slide outs issues";

        if (isNullOrEmpty(inputValues.awning)) errors.awning = "Select awning";
        if (isFairOrBad(inputValues.awning) && isNullOrEmpty(inputValues.awningIssues)) errors.awningIssues = "Enter awning issues";

        if (isNullOrEmpty(inputValues.securitySystems)) errors.securitySystems = "Select security systems";
        if (isFairOrBad(inputValues.securitySystems) && isNullOrEmpty(inputValues.securitySystemsIssues)) errors.securitySystemsIssues = "Enter security systems issues";


        if (isNullOrEmpty(inputValues.summary)) errors.summary = "Complete a summary of inspection";

        const groupedCounts = uploadedFiles.reduce((accumulator, currentValue) => {
            // Extract the key for grouping
            const key = currentValue.subCategory;

            // Initialize the key in the accumulator if it doesn't exist
            if (!accumulator[key]) {
                accumulator[key] = 0;
            }

            // Increment the count for the key
            accumulator[key] += 1;

            return accumulator;
        }, {});

        if (Object.keys(groupedCounts).length == 0) {
            errors.files = "Please upload Files";
        }
        // if (groupedCounts.VehicleInfo == undefined || groupedCounts.VehicleInfo < 5) {
        //     errors.VehicleInfo = "At least 5 photo should be uploaded for Vehicle Info";
        // }
        // if (groupedCounts.Exterior == undefined || groupedCounts.Exterior < 10) {
        //     errors.Exterior = "At least 10 photo should be uploaded for Exterior";
        // }
        // if (groupedCounts.Interior == undefined || groupedCounts.Interior < 10) {
        //     errors.Interior = "At least 10 photo should be uploaded for Interior";
        // }
        // if (groupedCounts.Mechanical == undefined || groupedCounts.Mechanical < 7) {
        //     errors.Mechanical = "At least 7 photo should be uploaded for Mechanical";
        // }
        // if (groupedCounts.Tires == undefined || groupedCounts.Tires < 4) {
        //     errors.Tires = "At least 4 photo should be uploaded for Tires";
        // }

        return errors;
    };

    const getInspectionFiles = useCallback(async () => {
        axiosPrivate.get(`v1/file/${id}`).then(res => {
            setUploadedFiles(res.data.data);
        }).catch(error => {
            console.error('Error:', error);
        });;
    }, [uploadedFiles])

    const onUploadFile = async (section) => {
        const formData = new FormData();


        if (section == InspectionSection.VehicleInfo) {
            if (vehicleInfoAcceptedFiles.length == 0) return;
            vehicleInfoAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.Exterior) {
            if (exteriorAcceptedFiles.length == 0) return;
            exteriorAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.Interior) {
            if (interiorAcceptedFiles.length == 0) return;
            interiorAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.Mechanical) {
            if (mechanicalAcceptedFiles.length == 0) return;
            mechanicalAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.Tires) {
            if (tiresAcceptedFiles.length == 0) return;
            tiresAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.RoadTest) {
            if (roadTestAcceptedFiles.length == 0) return;
            roadTestAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.RVExtra) {
            if (rvExtraAcceptedFiles.length == 0) return;
            rvExtraAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.Summary) {
            if (summaryAcceptedFiles.length == 0) return;
            summaryAcceptedFiles.map(file => formData.append('files', file));
        } else {
            return;
        }

        formData.append('relationId', id);
        formData.append('subCategory', section);
        setUploadLoading(true);
        const response = await axiosPrivate.post(`v1/file/upload`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            setUploadLoading(false);
            getInspectionFiles();
            removeFiles(section);
            // setVehicleInfoFile(res.data.data);
        }).catch(error => {
            setUploadLoading(false);
        });
    }

    const handleRemoveImage = async (id) => {
        var deleteItem = uploadedFiles.find(t => t.id == id);
        if (!deleteItem) return;
        const response = await axiosPrivate.post(`v1/file/delete`, { id: id, uniqueName: deleteItem.uniqueName, extension: deleteItem.extension }).then(res => {
            getInspectionFiles();
        }).catch(error => {

        });
    }

    const handleApproveReport = async () => {
        setLoading(true);
        const response = await axiosPrivate.post(`v1/quotation/approve/${id}`).then(res => {
            setLoading(false);
            showModal({ show: true, header: 'Approve Result', message: "Report successfully approved", isError: false });
        }).catch(error => {
            setLoading(false);
            showModal({ show: true, message: "An error occurred while approving report", isError: true });
        });

    }

    const handleCancelConfirm = async (e) => {
        setData(originalData);
        setIsEdit(false);
        setCancelModal({ show: false, message: null });

    }

    const handleRedirectToOrders = () => {
        showModal({ show: false, message: null, isSuccess: null });
        navigate('/orders');
    }

    const onSubmitReport = async () => {

        setLoading(true);
        var error = validateFormItem(data);
        if (Object.keys(error).length > 0) {
            setLoading(false);
            setValidationError(error);
            return;
        }

        const response = await axiosPrivate.post(`v1/inspection/rv`, { ...data, quotationId: id, isSubmitReport: true }).then(res => {
            setLoading(false);
            showModal({ show: true, message: "Your application successfully submitted!", isError: false });
        }).catch(error => {
            setLoading(false);
            showModal({ show: true, message: "An error occurred while submitting your report. Please try again. If the problem persists, contact support.", isError: true });
        });
    }

    const removeFiles = (section) => {
        if (section == InspectionSection.VehicleInfo) {
            vehicleInfoAcceptedFiles.length = 0;
            vehicleInfoAcceptedFiles.splice(0, vehicleInfoAcceptedFiles.length);
        }
        if (section == InspectionSection.Exterior) {
            exteriorAcceptedFiles.length = 0;
            exteriorAcceptedFiles.splice(0, exteriorAcceptedFiles.length);
        }
        if (section == InspectionSection.Interior) {
            interiorAcceptedFiles.length = 0;
            interiorAcceptedFiles.splice(0, interiorAcceptedFiles.length);
        }
        if (section == InspectionSection.Mechanical) {
            mechanicalAcceptedFiles.length = 0;
            mechanicalAcceptedFiles.splice(0, mechanicalAcceptedFiles.length);
        }
        if (section == InspectionSection.Tires) {
            tiresAcceptedFiles.length = 0;
            tiresAcceptedFiles.splice(0, tiresAcceptedFiles.length);
        }
        if (section == InspectionSection.RoadTest) {
            roadTestAcceptedFiles.length = 0;
            roadTestAcceptedFiles.splice(0, roadTestAcceptedFiles.length);
        }
        if (section == InspectionSection.RVExtra) {
            rvExtraAcceptedFiles.length = 0;
            rvExtraAcceptedFiles.splice(0, rvExtraAcceptedFiles.length);
        }
        if (section == InspectionSection.Summary) {
            summaryAcceptedFiles.length = 0;
            summaryAcceptedFiles.splice(0, summaryAcceptedFiles.length);
        }

    }




    const { acceptedFiles: vehicleInfoAcceptedFiles, getRootProps: getVehicleInfoRootProps, getInputProps: getVehicleInfoInputProps, isDragActive: isVehicleDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (vehicleInfoAcceptedFiles) => {
        },
    });

    const { acceptedFiles: exteriorAcceptedFiles, getRootProps: getExteriorRootProps, getInputProps: getExteriorInputProps, isDragActive: isExteriorDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (exteriorAcceptedFiles) => {
        },
    });

    const { acceptedFiles: interiorAcceptedFiles, getRootProps: getInteriorRootProps, getInputProps: getInteriorInputProps, isDragActive: isInteriorDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (interiorAcceptedFiles) => {
        },
    });

    const { acceptedFiles: mechanicalAcceptedFiles, getRootProps: getMechanicalRootProps, getInputProps: getMechanicalInputProps, isDragActive: isMechanicalDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (mechanicalAcceptedFiles) => {
        },
    });

    const { acceptedFiles: tiresAcceptedFiles, getRootProps: getTiresRootProps, getInputProps: getTiresInputProps, isDragActive: isTiresDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (tiresAcceptedFiles) => {
        },
    });

    const { acceptedFiles: roadTestAcceptedFiles, getRootProps: getRoadTestRootProps, getInputProps: getRoadTestInputProps, isDragActive: isRoadTestDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (roadTestAcceptedFiles) => {
        },
    });

    const { acceptedFiles: rvExtraAcceptedFiles, getRootProps: getRVExtraRootProps, getInputProps: getRVExtraInputProps, isDragActive: isRVExtraDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (rvExtraAcceptedFiles) => {
        },
    });

    const { acceptedFiles: summaryAcceptedFiles, getRootProps: getSummaryRootProps, getInputProps: getSummaryInputProps, isDragActive: isSummaryDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (summaryAcceptedFiles) => {
        },
    });

    return (
        <div>
            <div className="page-wrapper">
                <HerosectionHeader name={"Review Inspection"} />
                <div className="page-content"></div>
                {<Modal show={modal.show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{modal.header ? modal.header : !modal.isError ? 'Report Modified' : 'Error'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modal.message}</Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-dark" onClick={!modal.isError ? handleRedirectToOrders : handleClose}>Close</button>
                    </Modal.Footer>
                </Modal>}

                {<Modal show={cancelModal.show} onHide={() => setCancelModal({ show: false, message: null })} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Dismiss Changes</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>Are you sure you want to cancel? Any unsaved changes will be lost.</Modal.Body>
                    <Modal.Footer>
                        <Col>
                            <Row>
                                <Col md={6}></Col>
                                <Col md={3}><button className="btn btn-dark" onClick={() => setCancelModal({ show: false, message: null })}>No</button></Col>
                                <Col md={3}>
                                    {/* <button type="submit" className="btn btn-warning">Confirm</button> */}
                                    <SpinnerButton text="Yes" onClick={handleCancelConfirm} className="btn btn-danger" loading={loading} />
                                </Col>
                            </Row>
                        </Col>
                    </Modal.Footer>

                </Modal>}
                {<Modal show={saveModal.show} onHide={() => setSaveModal({ show: false, message: null })} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Save Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you want to save the changes to this report? Ensure all information is correct before saving.</Modal.Body>
                    <Modal.Footer>
                        <Col>
                            <Row>
                                <Col md={5}></Col>
                                <Col md={3}><button className="btn btn-dark" onClick={() => setSaveModal({ show: false, message: null })}>Cancel</button></Col>
                                <Col md={4}>
                                    {/* <button type="submit" className="btn btn-warning">Confirm</button> */}
                                    <SpinnerButton onClick={onSubmitReport} text="Save" className="btn btn-danger" loading={loading} />
                                </Col>
                            </Row>
                        </Col>
                    </Modal.Footer>
                </Modal>}

                {<Modal show={approvalModal.show} onHide={() => setApprovalModal({ show: false, message: null })} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Approve Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{approvalModal.message}</Modal.Body>
                    <Modal.Footer>
                        <Col>
                            <Row>
                                <Col md={5}></Col>
                                <Col md={3}><button className="btn btn-dark" onClick={() => setApprovalModal({ show: false, message: null })}>Cancel</button></Col>
                                <Col md={4}>
                                    {/* <button type="submit" className="btn btn-warning">Confirm</button> */}
                                    <SpinnerButton onClick={handleApproveReport} text="Approve" className="btn btn-danger" loading={loading} />
                                </Col>
                            </Row>
                        </Col>
                    </Modal.Footer>
                </Modal>}
                <section>
                    <Container>
                        <Row >
                            <Col lg={12} md={12}>
                                <div className="checkout-form border p-4 rounded">
                                    <h2 className="mb-4">Review - {data.orderNumber ? data.orderNumber : "N/A"}</h2>
                                    <div className="col-lg-12 col-12">
                                        <p className="lead mb-5">Please carefully review the report before approving it, ensuring all information is correct and free of spelling or logical errors. Double-check the VIN and license plate against the pictures provided before ordering the CARFAX report, if ordered</p>
                                        <div class="accordion" id="accordion">
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVehicleInfo" aria-expanded="true" aria-controls="collapseOne">
                                                        Vehicle Info
                                                    </button>
                                                </h2>
                                                <div id="collapseVehicleInfo" class="accordion-collapse border-0 collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="Make">Make</Label>
                                                                            <AutoCompleteInput
                                                                                name="makeId"
                                                                                label="Make"
                                                                                placeholder="Make"
                                                                                value={data.makeInfo}
                                                                                options={makes}
                                                                                onSuggestionClick={onMakeSelect}
                                                                                handleChange={(event) => updateFields({ makeInfo: event.target.value })}
                                                                                disabled={!isEdit}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="modelId">Model</Label>
                                                                            <AutoCompleteInput placeholder="Model" disabled={!isEdit} value={data.modelInfo} options={models} onSuggestionClick={onModelSelect} handleChange={(event) => updateFields({ modelInfo: event.target.value })} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="Year">Year</Label>
                                                                            <Input
                                                                                type="text"
                                                                                id="Year"
                                                                                name="Year"
                                                                                value={data.year}
                                                                                onChange={e => updateFields({ year: e.target.value })}
                                                                                disabled={!isEdit}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="mileage" size="xs">Mileage</Label>
                                                                            <Input
                                                                                type="text"
                                                                                id="mileage"
                                                                                name="mileage"
                                                                                value={data.mileage}
                                                                                onChange={e => updateFields({ mileage: e.target.value })}
                                                                                disabled={!isEdit}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="licensePlate">License Plate</Label>
                                                                            <Input
                                                                                type="text"
                                                                                id="licensePlate"
                                                                                name="License Plate"
                                                                                value={data.licensePlate}
                                                                                onChange={e => updateFields({ licensePlate: e.target.value })}
                                                                                disabled={!isEdit}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="vin">VIN</Label>
                                                                            <Input
                                                                                type="text"
                                                                                id="vin"
                                                                                name="vin"
                                                                                value={data.vin}
                                                                                onChange={e => updateFields({ vin: e.target.value })}
                                                                                disabled={!isEdit}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="bodyColor">Body Color</Label>
                                                                            <Input
                                                                                type="text"
                                                                                id="bodyColor"
                                                                                name="bodyColor"
                                                                                value={data.bodyColor}
                                                                                onChange={e => updateFields({ bodyColor: e.target.value })}
                                                                                disabled={!isEdit}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="interiorColor">Interior Color</Label>
                                                                            <Input
                                                                                type="text"
                                                                                id="interiorColor"
                                                                                name="interiorColor"
                                                                                value={data.interiorColor}
                                                                                onChange={e => updateFields({ interiorColor: e.target.value })}
                                                                                disabled={!isEdit}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="interiorMaterial">Interior Material</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="interiorMaterial"
                                                                                name="interiorMaterial"
                                                                                value={data.interiorMaterial}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={InteriorMaterial} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isOther(data.interiorMaterial) && <OtherInput disabled={!isEdit} text="Input Material" name="interiorMaterialInfo" value={data.interiorMaterialInfo} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="transmissionType">Transmission Type</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="transmissionType"
                                                                                name="transmissionType"
                                                                                value={data.transmissionType}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={TransmissionType} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isOther(data.transmissionType) && <OtherInput disabled={!isEdit} text="Input Transmission Type" name="transmissionTypeInfo" value={data.transmissionTypeInfo} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="engineLayout">Engine Layout</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="engineLayout"
                                                                                name="engineLayout"
                                                                                className="form-control"
                                                                                value={data.engineLayout}
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EngineLayout} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isOther(data.engineLayout) && <OtherInput disabled={!isEdit} text="Input Engine Layout" name="engineLayoutInfo" value={data.engineLayoutInfo} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="cylinderConfiguration">Cylinder Configuration</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="cylinderConfiguration"
                                                                                name="cylinderConfiguration"
                                                                                value={data.cylinderConfiguration}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={CylinderConfiguration} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isOther(data.cylinderConfiguration) && <OtherInput disabled={!isEdit} text="Input Cylinder Configuration" name="cylinderConfigurationInfo" value={data.cylinderConfigurationInfo} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="driveType">Drive Type</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="driveType"
                                                                                name="driveType"
                                                                                value={data.driveType}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={DriveType} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isOther(data.driveType) && <OtherInput disabled={!isEdit} text="Input Drive Type" name="driveTypeInfo" value={data.driveTypeInfo} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="fuelType">Fuel Type</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="fuelType"
                                                                                name="fuelType"
                                                                                value={data.fuelType}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={FuelType} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isOther(data.fuelType) && <OtherInput disabled={!isEdit} text="Input Fuel Type" name="fuelTypeInfo" value={data.fuelTypeInfo} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="vehicleInfoNote">Comments about vehicle info</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="vehicleInfoNote"
                                                                        name="vehicleInfoNote"
                                                                        value={data.vehicleInfoNote}
                                                                        onChange={e => updateFields({ vehicleInfoNote: e.target.value })}
                                                                        disabled={!isEdit}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="vehicleInfoFileInput">Prior to approval, please review all uploaded images to ensure they meet our quality standards, including clarity, detail, and relevance to the inspection report.</Label></Col>
                                                            {(!disableUploadItem && isEdit) &&
                                                                <>
                                                                    <Col md={10}>
                                                                        <FormGroup>
                                                                            <div name="vehicleInfoFileInput" className="dropzone" {...getVehicleInfoRootProps()}>
                                                                                <input type="file" {...getVehicleInfoInputProps()} />
                                                                                {
                                                                                    vehicleInfoAcceptedFiles.length > 0 ? <p>{vehicleInfoAcceptedFiles.length} file selected</p> :
                                                                                        isVehicleDragActive ?
                                                                                            <p>Drop the files here ...</p> :
                                                                                            <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                                }
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <SpinnerButton onClick={() => onUploadFile(InspectionSection.VehicleInfo)} disabled={vehicleInfoAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Vehicle Info Pictures" loading={uploadLoading} />
                                                                    </Col>
                                                                </>
                                                            }
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.VehicleInfo).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} disableDelete={!isEdit ? true : disableUploadItem} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingTwo">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Exterior
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="bodyAlignment" size="xs">Body Alignment Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="bodyAlignment"
                                                                                name="bodyAlignment"
                                                                                className="form-control"
                                                                                value={data.bodyAlignment}
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.bodyAlignment) && <OtherInput disabled={!isEdit} text="Body Allignment Issues" name="bodyAlignmentIssues" value={data.bodyAlignmentIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="paint">Paint Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="paint"
                                                                                name="paint"
                                                                                className="form-control"
                                                                                value={data.paint}
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>{isFairOrBad(data.paint) && <OtherInput disabled={!isEdit} text="Paint Issues" name="paintIssues" value={data.paintIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="frame" size="xs">Frame Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="frame"
                                                                                name="frame"
                                                                                className="form-control"
                                                                                value={data.frame}
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.frame) && <OtherInput disabled={!isEdit} text="Frame Issues" name="frameIssues" value={data.frameIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="glassAndWindow">Glass & Window Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="glassAndWindow"
                                                                                name="glassAndWindow"
                                                                                value={data.glassAndWindow}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.glassAndWindow) && <OtherInput disabled={!isEdit} text="Glass & Window Issues" name="glassAndWindowIssues" value={data.glassAndWindowIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="scratches" size="xs">Scratches</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="scratches"
                                                                                name="scratches"
                                                                                value={data.scratches}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Scratches} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.scratches) && <OtherInput disabled={!isEdit} text="Scratches Issues" name="scratchesIssues" value={data.scratchesIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="dings">Dings</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="dings"
                                                                                name="dings"
                                                                                value={data.dings}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Dings} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.dings) && <OtherInput disabled={!isEdit} text="Dings Issues" name="dingsIssues" value={data.dingsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="dents" size="xs">Dents</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="dents"
                                                                                name="dents"
                                                                                value={data.dents}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Dents} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.dents) && <OtherInput disabled={!isEdit} text="Dents Issues" name="dentsIssues" value={data.dentsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="rust">Rust</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="rust"
                                                                                name="rust"
                                                                                value={data.rust}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Rust} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.rust) && <OtherInput disabled={!isEdit} text="Rust Issues" name="rustIssues" value={data.rustIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="bodySeals" size="xs">Body Seals Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="bodySeals"
                                                                                name="bodySeals"
                                                                                value={data.bodySeals}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.bodySeals) && <OtherInput disabled={!isEdit} text="Body Seals Issues" name="bodySealsIssues" value={data.bodySealsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="sideMoldings">Side Moldings Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="sideMoldings"
                                                                                name="sideMoldings"
                                                                                value={data.sideMoldings}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.sideMoldings) && <OtherInput disabled={!isEdit} text="Side Moldings Issues" name="sideMoldingsIssues" value={data.sideMoldingsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="sideMirrors" size="xs">Side Mirrors Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="sideMirrors"
                                                                                name="sideMirrors"
                                                                                value={data.sideMirrors}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.sideMirrors) && <OtherInput disabled={!isEdit} text="Side Mirrors Issues" name="sideMirrorsIssues" value={data.sideMirrorsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="exteriorLights">Exterior Lights Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="exteriorLights"
                                                                                name="exteriorLights"
                                                                                value={data.exteriorLights}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.exteriorLights) && <OtherInput disabled={!isEdit} text="Exterior Lights Issues" name="exteriorLightsIssues" value={data.exteriorLightsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="wiperBlades">Wiper Blades Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="wiperBlades"
                                                                                name="wiperBlades"
                                                                                value={data.wiperBlades}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.wiperBlades) && <OtherInput disabled={!isEdit} text="Wiper Blades Issues" name="wiperBladesIssues" value={data.wiperBladesIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="windshieldWasher">Windshield Washer Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="windshieldWasher"
                                                                                name="windshieldWasher"
                                                                                value={data.windshieldWasher}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.windshieldWasher) && <OtherInput disabled={!isEdit} text="Windshield Washer Issues" name="windshieldWasherIssues" value={data.windshieldWasherIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="exteriorNote">Comments about exterior condition of vehicle</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="exteriorNote"
                                                                        name="exteriorNote"
                                                                        value={data.exteriorNote}
                                                                        onChange={e => updateFields({ exteriorNote: e.target.value })}
                                                                        disabled={!isEdit}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="exteriorFileInput">Prior to approval, please review all uploaded images to ensure they meet our quality standards, including clarity, detail, and relevance to the inspection report.</Label></Col>
                                                            {(!disableUploadItem && isEdit) &&
                                                                <>
                                                                    <Col md={10}>
                                                                        <FormGroup>
                                                                            <div name="exteriorFileInput" className="dropzone" {...getExteriorRootProps()}>
                                                                                <input type="file" {...getExteriorInputProps()} />
                                                                                {
                                                                                    exteriorAcceptedFiles.length > 0 ? <p>{exteriorAcceptedFiles.length} file selected</p> :
                                                                                        isExteriorDragActive ?
                                                                                            <p>Drop the files here ...</p> :
                                                                                            <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                                }
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <SpinnerButton onClick={() => onUploadFile(InspectionSection.Exterior)} disabled={exteriorAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Exterior Pictures" loading={uploadLoading} />
                                                                    </Col>
                                                                </>}
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.Exterior).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} disableDelete={!isEdit ? true : disableUploadItem} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Interior
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse border-0 collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="steeringWheel">Steering Wheel Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="steeringWheel"
                                                                                name="steeringWheel"
                                                                                value={data.steeringWheel}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.steeringWheel) && <OtherInput disabled={!isEdit} text="Steering Wheel Issues" name="steeringWheelIssues" value={data.steeringWheelIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="horn">Horn Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="horn"
                                                                                name="horn"
                                                                                value={data.horn}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={HornCondition} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.horn) && <OtherInput disabled={!isEdit} text="Horn Issues" name="hornIssues" value={data.hornIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="seats">Seats Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="seats"
                                                                                name="seats"
                                                                                value={data.seats}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.seats) && <OtherInput disabled={!isEdit} text="Seats Issues" name="seatsIssues" value={data.seatsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="seatMechanisms">Seat Mechanisms Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="seatMechanisms"
                                                                                name="seatMechanisms"
                                                                                value={data.seatMechanisms}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.seatMechanisms) && <OtherInput disabled={!isEdit} text="Seat Mechanisms Issues" name="seatMechanismsIssues" value={data.seatMechanismsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="sunVisors">Sun Visors Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="sunVisors"
                                                                                name="sunVisors"
                                                                                value={data.sunVisors}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.sunVisors) && <OtherInput disabled={!isEdit} text="Sun Visors Issues" name="sunVisorsIssues" value={data.sunVisorsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="headliner" size="xs">Headliner Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="headliner"
                                                                                name="headliner"
                                                                                value={data.headliner}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.headliner) && <OtherInput disabled={!isEdit} text="Headliner Issues" name="headlinerIssues" value={data.headlinerIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="innerDoorPanels">Inner Door Panels Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="innerDoorPanels"
                                                                                name="innerDoorPanels"
                                                                                value={data.innerDoorPanels}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.innerDoorPanels) && <OtherInput disabled={!isEdit} text="Inner Door Panels Issues" name="innerDoorPanelsIssues" value={data.innerDoorPanelsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="interiorTrim" size="xs">Interior Trim Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="interiorTrim"
                                                                                name="interiorTrim"
                                                                                value={data.interiorTrim}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.interiorTrim) && <OtherInput disabled={!isEdit} text="Interior Trim Issues" name="interiorTrimIssues" value={data.interiorTrimIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="interiorLights">Interior Lights Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="interiorLights"
                                                                                name="interiorLights"
                                                                                value={data.interiorLights}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.interiorLights) && <OtherInput disabled={!isEdit} text="Interior Lights Issues" name="interiorLightsIssues" value={data.interiorLightsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="powerLocks" size="xs">Power Locks Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="powerLocks"
                                                                                name="powerLocks"
                                                                                value={data.powerLocks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.powerLocks) && <OtherInput disabled={!isEdit} text="Power Locks Issues" name="powerLocksIssues" value={data.powerLocksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="windowSwitches">Window Switches Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="windowSwitches"
                                                                                name="windowSwitches"
                                                                                value={data.windowSwitches}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.windowSwitches) && <OtherInput disabled={!isEdit} text="Window Switches Issues" name="windowSwitchesIssues" value={data.windowSwitchesIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="windowFunctioning" size="xs">Window Functioning Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="windowFunctioning"
                                                                                name="windowFunctioning"
                                                                                value={data.windowFunctioning}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.windowFunctioning) && <OtherInput disabled={!isEdit} text="Window Functioning Issues" name="windowFunctioningIssues" value={data.windowFunctioningIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="trunkActuator" size="xs">Trunk Actuator</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="trunkActuator"
                                                                                name="trunkActuator"
                                                                                value={data.trunkActuator}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.trunkActuator) && <OtherInput disabled={!isEdit} text="Trunk Actuator Issues" name="trunkActuatorIssues" value={data.trunkActuatorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="hoodActuator" size="xs">Hood Actuator</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="hoodActuator"
                                                                                name="hoodActuator"
                                                                                value={data.hoodActuator}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.hoodActuator) && <OtherInput disabled={!isEdit} text="Hood Actuator Issues" name="hoodActuatorIssues" value={data.hoodActuatorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="fuelDoorActuator" size="xs">Fuel Door Actuator</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="fuelDoorActuator"
                                                                                name="fuelDoorActuator"
                                                                                value={data.fuelDoorActuator}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.fuelDoorActuator) && <OtherInput disabled={!isEdit} text="Fuel Door Actuator Issues" name="fuelDoorActuatorIssues" value={data.fuelDoorActuatorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="dash">Dash Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="dash"
                                                                                name="dash"
                                                                                value={data.dash}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.dash) && <OtherInput disabled={!isEdit} text="Dash Issues" name="dashIssues" value={data.dashIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="dashLights" size="xs">Dash Lights</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="dashLights"
                                                                                name="dashLights"
                                                                                value={data.dashLights}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.dashLights) && <OtherInput disabled={!isEdit} text="Dash Lights Issues" name="dashLightsIssues" value={data.dashLightsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="dashWarnings">Dash Warnings</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="dashWarnings"
                                                                                name="dashWarnings"
                                                                                value={data.dashWarnings}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Warnings} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.dashWarnings) && <OtherInput disabled={!isEdit} text="Dash Warnings Issues" name="dashWarningsIssues" value={data.dashWarningsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="dashGauges">Dash Gauges</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="dashGauges"
                                                                                name="dashGauges"
                                                                                value={data.dashGauges}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.dashGauges) && <OtherInput disabled={!isEdit} text="Dash Gauges Issues" name="dashGaugesIssues" value={data.dashGaugesIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="centerConsole" size="xs">Center Console Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="centerConsole"
                                                                                name="centerConsole"
                                                                                value={data.centerConsole}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.centerConsole) && <OtherInput disabled={!isEdit} text="Center Console Issues" name="centerConsoleIssues" value={data.centerConsoleIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="cupholder">Cupholder Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="cupholder"
                                                                                name="cupholder"
                                                                                value={data.cupholder}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.cupholder) && <OtherInput disabled={!isEdit} text="Cupholder Issues" name="cupholderIssues" value={data.cupholderIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="radio">Radio Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="radio"
                                                                                name="radio"
                                                                                value={data.radio}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.radio) && <OtherInput disabled={!isEdit} text="Radio Issues" name="radioIssues" value={data.radioIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="navigation" size="xs">Navigation Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="navigation"
                                                                                name="navigation"
                                                                                value={data.navigation}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.navigation) && <OtherInput disabled={!isEdit} text="Navigation Issues" name="navigationIssues" value={data.navigationIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="airbags">Airbags Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="airbags"
                                                                                name="airbags"
                                                                                value={data.airbags}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.airbags) && <OtherInput disabled={!isEdit} text="Airbags Issues" name="airbagsIssues" value={data.airbagsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="seatBelts" size="xs">Seat Belts Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="seatBelts"
                                                                                name="seatBelts"
                                                                                value={data.seatBelts}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.seatBelts) && <OtherInput disabled={!isEdit} text="Seat Belts Issues" name="seatBeltsIssues" value={data.seatBeltsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="AC">AC Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="ac"
                                                                                name="ac"
                                                                                value={data.ac}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.ac) && <OtherInput disabled={!isEdit} text="AC Issues" name="acIssues" value={data.acIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="heater" size="xs">Heater Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="heater"
                                                                                name="heater"
                                                                                value={data.heater}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.heater) && <OtherInput disabled={!isEdit} text="Heater Issues" name="heaterIssues" value={data.heaterIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="rearDefroster">Rear Defroster Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="rearDefroster"
                                                                                name="rearDefroster"
                                                                                value={data.rearDefroster}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.rearDefroster) && <OtherInput disabled={!isEdit} text="Rear Defroster Issues" name="rearDefrosterIssues" value={data.rearDefrosterIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="blowerMotor" size="xs">Blower Motor Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="blowerMotor"
                                                                                name="blowerMotor"
                                                                                value={data.blowerMotor}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.blowerMotor) && <OtherInput disabled={!isEdit} text="Blower Motor Issues" name="blowerMotorIssues" value={data.blowerMotorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="seatHeater">Seat Heater Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="seatHeater"
                                                                                name="seatHeater"
                                                                                value={data.seatHeater}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.seatHeater) && <OtherInput disabled={!isEdit} text="Seat Heater Issues" name="seatHeaterIssues" value={data.seatHeaterIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="carpet" size="xs">Carpet Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="carpet"
                                                                                name="carpet"
                                                                                value={data.carpet}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.carpet) && <OtherInput disabled={!isEdit} text="Carpet Issues" name="carpetIssues" value={data.carpetIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="floorMat" size="xs">Floor Mat Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="floorMat"
                                                                                name="floorMat"
                                                                                value={data.floorMat}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.floorMat) && <OtherInput disabled={!isEdit} text="Floor Mat Issues" name="floorMatIssues" value={data.floorMatIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="cigaretteUse">Cigarette Use</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="cigaretteUse"
                                                                                name="cigaretteUse"
                                                                                value={data.cigaretteUse}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={CigaretteUse} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.cigaretteUse) && <OtherInput disabled={!isEdit} text="Cigarette Use Issues" name="cigaretteUseIssues" value={data.cigaretteUseIssues} updateFields={updateFields} />}
                                                                    <Col md={6}>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="unusualOdors" size="xs">Unusual Odors</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="unusualOdors"
                                                                                name="unusualOdors"
                                                                                value={data.unusualOdors}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={UnusualOdors} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.unusualOdors) && <OtherInput disabled={!isEdit} text="Unusual Odors Issues" name="unusualOdorsIssues" value={data.unusualOdorsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="interiorNote">Comments about interior condition of vehicle</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="interiorNote"
                                                                        name="interiorNote"
                                                                        value={data.interiorNote}
                                                                        onChange={e => updateFields({ interiorNote: e.target.value })}
                                                                        disabled={!isEdit}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="interiorFileInput">Prior to approval, please review all uploaded images to ensure they meet our quality standards, including clarity, detail, and relevance to the inspection report.</Label></Col>
                                                            {(!disableUploadItem && isEdit) &&
                                                                <>
                                                                    <Col md={10}>
                                                                        <FormGroup>
                                                                            <div name="interiorFileInput" className="dropzone" {...getInteriorRootProps()}>
                                                                                <input type="file" {...getInteriorInputProps()} />
                                                                                {
                                                                                    interiorAcceptedFiles.length > 0 ? <p>{interiorAcceptedFiles.length} file selected</p> :
                                                                                        isInteriorDragActive ?
                                                                                            <p>Drop the files here ...</p> :
                                                                                            <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                                }
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <SpinnerButton onClick={() => onUploadFile(InspectionSection.Interior)} disabled={interiorAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Interior Pictures" loading={uploadLoading} />
                                                                    </Col>
                                                                </>}
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.Interior).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} disableDelete={!isEdit ? true : disableUploadItem} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingFour">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        Mechanical
                                                    </button>
                                                </h2>
                                                <div id="collapseFour" class="accordion-collapse border-0 collapse" aria-labelledby="headingFour" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="keyFOB">Key FOB</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="keyFOB"
                                                                                name="keyFOB"
                                                                                value={data.keyFOB}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.keyFOB) && <OtherInput disabled={!isEdit} text="Key FOB Issues" name="keyFOBIssues" value={data.keyFOBIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="computerDiagnosisAndOBD2">Computer Diagnosis / OBD2</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="computerDiagnosisAndOBD2"
                                                                                name="computerDiagnosisAndOBD2"
                                                                                value={data.computerDiagnosisAndOBD2}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.computerDiagnosisAndOBD2) && <OtherInput disabled={!isEdit} text="Computer Diagnosis/OBD2 Issues" name="computerDiagnosisAndOBD2Issues" value={data.computerDiagnosisAndOBD2Issues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="battery">Battery Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="battery"
                                                                                name="battery"
                                                                                value={data.battery}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.battery) && <OtherInput disabled={!isEdit} text="Battery Issues" name="batteryIssues" value={data.batteryIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="chargingSystem">Charging System</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="chargingSystem"
                                                                                name="chargingSystem"
                                                                                value={data.chargingSystem}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.chargingSystem) && <OtherInput disabled={!isEdit} text="Charging System Issues" name="chargingSystemIssues" value={data.chargingSystemIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="alternator">Alternator Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="alternator"
                                                                                name="alternator"
                                                                                value={data.alternator}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.alternator) && <OtherInput disabled={!isEdit} text="Alternator Issues" name="alternatorIssues" value={data.alternatorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="starter" size="xs">Starter Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="starter"
                                                                                name="starter"
                                                                                value={data.starter}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.starter) && <OtherInput disabled={!isEdit} text="Starter Issues" name="starterIssues" value={data.starterIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="belts" size="xs">Belts Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="belts"
                                                                                name="belts"
                                                                                value={data.belts}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.belts) && <OtherInput disabled={!isEdit} text="Belts Issues" name="beltsIssues" value={data.beltsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="hoses">Hoses Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="hoses"
                                                                                name="hoses"
                                                                                value={data.hoses}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.hoses) && <OtherInput disabled={!isEdit} text="Hoses Issues" name="hosesIssues" value={data.hosesIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="airFilter" size="xs">Air Filter Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="airFilter"
                                                                                name="airFilter"
                                                                                value={data.airFilter}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.airFilter) && <OtherInput disabled={!isEdit} text="Air Filter Issues" name="airFilterIssues" value={data.airFilterIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="coolingFan">Cooling Fan Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="coolingFan"
                                                                                name="coolingFan"
                                                                                value={data.coolingFan}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.coolingFan) && <OtherInput disabled={!isEdit} text="Cooling Fan Issues" name="coolingFanIssues" value={data.coolingFanIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="radiatorAndLeaks" size="xs">Radiator Condition and Leaks</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="radiatorAndLeaks"
                                                                                name="radiatorAndLeaks"
                                                                                value={data.radiatorAndLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.radiatorAndLeaks) && <OtherInput disabled={!isEdit} text="Radiator Condition and Leaks Issues" name="radiatorAndLeaksIssues" value={data.radiatorAndLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="coolantSystemAndLeaks">Coolant System And Leaks</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="coolantSystemAndLeaks"
                                                                                name="coolantSystemAndLeaks"
                                                                                value={data.coolantSystemAndLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.coolantSystemAndLeaks) && <OtherInput disabled={!isEdit} text="Coolant System and Leaks Issues" name="coolantSystemAndLeaksIssues" value={data.coolantSystemAndLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="powerSteeringSystemAndFluid" size="xs">Power Steering System and Fluid</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="powerSteeringSystemAndFluid"
                                                                                name="powerSteeringSystemAndFluid"
                                                                                value={data.powerSteeringSystemAndFluid}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.powerSteeringSystemAndFluid) && <OtherInput disabled={!isEdit} text="Power Steering System and Fluid Issues" name="powerSteeringSystemAndFluidIssues" value={data.powerSteeringSystemAndFluidIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="powerSteeringFluidLeaks" size="xs">Power Steering Fluid Leaks</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="powerSteeringFluidLeaks"
                                                                                name="powerSteeringFluidLeaks"
                                                                                value={data.powerSteeringFluidLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Leaks} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.powerSteeringFluidLeaks) && <OtherInput disabled={!isEdit} text="Power Steering Fluid Leaks Issues" name="powerSteeringFluidLeaksIssues" value={data.powerSteeringFluidLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="engineBlockAndOil">Engine Block and Oil Level</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="engineBlockAndOil"
                                                                                name="engineBlockAndOil"
                                                                                value={data.engineBlockAndOil}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.engineBlockAndOil) && <OtherInput disabled={!isEdit} text="Engine Block and Oil Level Issues" name="engineBlockAndOilIssues" value={data.engineBlockAndOilIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="engineFluidAndOilLeaks" size="xs">Engine Fluid and Oil Leaks</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="engineFluidAndOilLeaks"
                                                                                name="engineFluidAndOilLeaks"
                                                                                value={data.engineFluidAndOilLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Leaks} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.engineFluidAndOilLeaks) && <OtherInput disabled={!isEdit} text="Engine Fluid And Oil Leaks Issues" name="engineFluidAndOilLeaksIssues" value={data.engineFluidAndOilLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="transmissionSystemAndFluid">Transmission System And Fluid</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="transmissionSystemAndFluid"
                                                                                name="transmissionSystemAndFluid"
                                                                                value={data.transmissionSystemAndFluid}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.transmissionSystemAndFluid) && <OtherInput disabled={!isEdit} text="Transmission System And Fluid Issues" name="transmissionSystemAndFluidIssues" value={data.transmissionSystemAndFluidIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="transmissionFluidLeaks" size="xs">Transmission Fluid Leaks</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="transmissionFluidLeaks"
                                                                                name="transmissionFluidLeaks"
                                                                                value={data.transmissionFluidLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.transmissionFluidLeaks) && <OtherInput disabled={!isEdit} text="Transmission Fluid Leaks Issues" name="transmissionFluidLeaksIssues" value={data.transmissionFluidLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="brakeSystemAndPads">Brake System and Pads</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="brakeSystemAndPads"
                                                                                name="brakeSystemAndPads"
                                                                                value={data.brakeSystemAndPads}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.brakeSystemAndPads) && <OtherInput disabled={!isEdit} text="Brake System and Pads Issues" name="brakeSystemAndPadsIssues" value={data.brakeSystemAndPadsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="brakeFluidLeaks" size="xs">Brake Fluid Leaks</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="brakeFluidLeaks"
                                                                                name="brakeFluidLeaks"
                                                                                value={data.brakeFluidLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Leaks} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.brakeFluidLeaks) && <OtherInput disabled={!isEdit} text="Brake Fluid Leaks Issues" name="brakeFluidLeaksIssues" value={data.brakeFluidLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="differentialSystem">Differential System</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="differentialSystem"
                                                                                name="differentialSystem"
                                                                                value={data.differentialSystem}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.differentialSystem) && <OtherInput disabled={!isEdit} text="Differential System Issues" name="differentialSystemIssues" value={data.differentialSystemIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="differentialFluidLeaks" size="xs">Differential Fluid Leaks</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="differentialFluidLeaks"
                                                                                name="differentialFluidLeaks"
                                                                                value={data.differentialFluidLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Leaks} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.differentialFluidLeaks) && <OtherInput disabled={!isEdit} text="Differential Fluid Leaks Issues" name="differentialFluidLeaksIssues" value={data.differentialFluidLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="catalyticConverter" size="xs">Catalytic Converter</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="catalyticConverter"
                                                                                name="catalyticConverter"
                                                                                value={data.catalyticConverter}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.catalyticConverter) && <OtherInput disabled={!isEdit} text="Catalytic Converter Issues" name="catalyticConverterIssues" value={data.catalyticConverterIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="mufflerSystem" size="xs">Muffler System</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="mufflerSystem"
                                                                                name="mufflerSystem"
                                                                                value={data.mufflerSystem}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.mufflerSystem) && <OtherInput disabled={!isEdit} text="Muffler System Issues" name="mufflerSystemIssues" value={data.mufflerSystemIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="cvJointsAndAxles">CV Joints and Axles</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="cvJointsAndAxles"
                                                                                name="cvJointsAndAxles"
                                                                                value={data.cvJointsAndAxles}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.cvJointsAndAxles) && <OtherInput disabled={!isEdit} text="CV Joints and Axles Issues" name="cvJointsAndAxlesIssues" value={data.cvJointsAndAxlesIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="shocksAndStruts" size="xs">Shocks and Struts</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="shocksAndStruts"
                                                                                name="shocksAndStruts"
                                                                                value={data.shocksAndStruts}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.shocksAndStruts) && <OtherInput disabled={!isEdit} text="Shocks and Struts Issues" name="shocksAndStrutsIssues" value={data.shocksAndStrutsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="bushingsBallJointsRodsAndArms">Bushings, Ball Joints, Rods and Arms</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="bushingsBallJointsRodsAndArms"
                                                                                name="bushingsBallJointsRodsAndArms"
                                                                                value={data.bushingsBallJointsRodsAndArms}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.bushingsBallJointsRodsAndArms) && <OtherInput disabled={!isEdit} text="Bushings, Ball Joints, Rods, and Arms Issues" name="bushingsBallJointsRodsAndArmsIssues" value={data.bushingsBallJointsRodsAndArmsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="undercarriage">Undercarriage Condition</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="undercarriage"
                                                                                name="undercarriage"
                                                                                value={data.undercarriage}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.undercarriage) && <OtherInput disabled={!isEdit} text="Undercarriage Issues" name="undercarriageIssues" value={data.undercarriageIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="hydraulics">Hydraulics</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="hydraulics"
                                                                                name="hydraulics"
                                                                                value={data.hydraulics}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.hydraulics) && <OtherInput disabled={!isEdit} text="Hydraulics Issues" name="hydraulicsIssues" value={data.hydraulicsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="extrasAndUpgrades">Extras and Upgrades</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="extrasAndUpgrades"
                                                                        name="extrasAndUpgrades"
                                                                        value={data.extrasAndUpgrades}
                                                                        placeholder="NA"
                                                                        onChange={e => updateFields({ extrasAndUpgrades: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="mechanicalNote">Comments about mechanical condition of vehicle</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="mechanicalNote"
                                                                        name="mechanicalNote"
                                                                        value={data.mechanicalNote}
                                                                        onChange={e => updateFields({ mechanicalNote: e.target.value })}
                                                                        disabled={!isEdit}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="mechanicalFileInput">Prior to approval, please review all uploaded images to ensure they meet our quality standards, including clarity, detail, and relevance to the inspection report.</Label></Col>
                                                            {(!disableUploadItem && isEdit) &&
                                                                <>
                                                                    <Col md={10}>
                                                                        <FormGroup>
                                                                            <div name="mechanicalFileInput" className="dropzone" {...getMechanicalRootProps()}>
                                                                                <input type="file" {...getMechanicalInputProps()} />
                                                                                {
                                                                                    mechanicalAcceptedFiles.length > 0 ? <p>{mechanicalAcceptedFiles.length} file selected</p> :
                                                                                        isMechanicalDragActive ?
                                                                                            <p>Drop the files here ...</p> :
                                                                                            <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                                }
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <SpinnerButton onClick={() => onUploadFile(InspectionSection.Mechanical)} disabled={mechanicalAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Mechanical Pictures" loading={uploadLoading} />
                                                                    </Col>
                                                                </>}
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.Mechanical).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} disableDelete={!isEdit ? true : disableUploadItem} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingFive">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                        Tires
                                                    </button>
                                                </h2>
                                                <div id="collapseFive" class="accordion-collapse border-0 collapse" aria-labelledby="headingFive" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Label for="driverFrontBrand">Driver Front Tire Brand</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="driverFrontBrand"
                                                                        name="driverFrontBrand"
                                                                        className="form-control"
                                                                        value={data.driverFrontBrand}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={TireBrand} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Label for="driverFrontSize">Driver Front Tire Size</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        id="driverFrontSize"
                                                                        name="driverFrontSize"
                                                                        value={data.driverFrontSize}
                                                                        onChange={e => updateFields({ driverFrontSize: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Label for="driverFrontTreadDepth">Driver Front Tire Tread Depth</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="driverFrontTreadDepth"
                                                                        name="driverFrontTreadDepth"
                                                                        className="form-control"
                                                                        value={data.driverFrontTreadDepth}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={CommercialTireTreadDepth} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Label for="driverFrontTireAndWheel">Driver Front Tire and Wheel Condition</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="driverFrontTireAndWheel"
                                                                        name="driverFrontTireAndWheel"
                                                                        className="form-control"
                                                                        value={data.driverFrontTireAndWheel}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={EFBNA} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Label for="driverFirstAxleInnerBrand">Driver First Axle Inner Tire Brand</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="driverFirstAxleInnerBrand"
                                                                        name="driverFirstAxleInnerBrand"
                                                                        className="form-control"
                                                                        value={data.driverFirstAxleInnerBrand}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={TireBrand} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Label for="driverFirstAxleInnerSize">Driver First Axle Inner Tire Size</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        id="driverFirstAxleInnerSize"
                                                                        name="driverFirstAxleInnerSize"
                                                                        value={data.driverFirstAxleInnerSize}
                                                                        onChange={e => updateFields({ driverFirstAxleInnerSize: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Label for="driverFirstAxleInnerTreadDepth">Driver First Axle Inner Tire Tread Depth</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="driverFirstAxleInnerTreadDepth"
                                                                        name="driverFirstAxleInnerTreadDepth"
                                                                        className="form-control"
                                                                        value={data.driverFirstAxleInnerTreadDepth}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={CommercialTireTreadDepth} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Label for="driverFirstAxleInnerTireAndWheel">Driver First Axle Inner Tire and Wheel Condition</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="driverFirstAxleInnerTireAndWheel"
                                                                        name="driverFirstAxleInnerTireAndWheel"
                                                                        className="form-control"
                                                                        value={data.driverFirstAxleInnerTireAndWheel}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={EFBNA} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Label for="driverFirstAxleOuterBrand">Driver First Axle Outer Tire Brand</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="driverFirstAxleOuterBrand"
                                                                        name="driverFirstAxleOuterBrand"
                                                                        className="form-control"
                                                                        value={data.driverFirstAxleOuterBrand}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={TireBrand} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Label for="driverFirstAxleOuterSize">Driver First Axle Outer Tire Size</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        id="driverFirstAxleOuterSize"
                                                                        name="driverFirstAxleOuterSize"
                                                                        value={data.driverFirstAxleOuterSize}
                                                                        onChange={e => updateFields({ driverFirstAxleOuterSize: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Label for="driverFirstAxleOuterTreadDepth">Driver First Axle Outer Tire Tread Depth</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="driverFirstAxleOuterTreadDepth"
                                                                        name="driverFirstAxleOuterTreadDepth"
                                                                        className="form-control"
                                                                        value={data.driverFirstAxleOuterTreadDepth}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={CommercialTireTreadDepth} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Label for="driverFirstAxleOuterTireAndWheel">Driver First Axle Outer Tire and Wheel Condition</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="driverFirstAxleOuterTireAndWheel"
                                                                        name="driverFirstAxleOuterTireAndWheel"
                                                                        className="form-control"
                                                                        value={data.driverFirstAxleOuterTireAndWheel}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={EFBNA} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Label for="passengerFrontBrand">Passenger Front Tire Brand</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="passengerFrontBrand"
                                                                        name="passengerFrontBrand"
                                                                        className="form-control"
                                                                        value={data.passengerFrontBrand}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={TireBrand} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Label for="passengerFrontSize">Passenger Front Tire Size</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        id="passengerFrontSize"
                                                                        name="passengerFrontSize"
                                                                        value={data.passengerFrontSize}
                                                                        onChange={e => updateFields({ passengerFrontSize: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Label for="passengerFrontTreadDepth">Passenger Front Tire Tread Depth</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="passengerFrontTreadDepth"
                                                                        name="passengerFrontTreadDepth"
                                                                        className="form-control"
                                                                        value={data.passengerFrontTreadDepth}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={CommercialTireTreadDepth} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Label for="passengerFrontTireAndWheel">Passenger Front Tire and Wheel Condition</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="passengerFrontTireAndWheel"
                                                                        name="passengerFrontTireAndWheel"
                                                                        className="form-control"
                                                                        value={data.passengerFrontTireAndWheel}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={EFBNA} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Label for="passengerFirstAxleInnerBrand">Passenger First Axle Inner Tire Brand</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="passengerFirstAxleInnerBrand"
                                                                        name="passengerFirstAxleInnerBrand"
                                                                        className="form-control"
                                                                        value={data.passengerFirstAxleInnerBrand}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={TireBrand} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Label for="passengerFirstAxleInnerSize">Passenger First Axle Inner Tire Size</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        id="passengerFirstAxleInnerSize"
                                                                        name="passengerFirstAxleInnerSize"
                                                                        value={data.passengerFirstAxleInnerSize}
                                                                        onChange={e => updateFields({ passengerFirstAxleInnerSize: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Label for="passengerFirstAxleInnerTreadDepth">Passenger First Axle Inner Tire Tread Depth</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="passengerFirstAxleInnerTreadDepth"
                                                                        name="passengerFirstAxleInnerTreadDepth"
                                                                        className="form-control"
                                                                        value={data.passengerFirstAxleInnerTreadDepth}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={CommercialTireTreadDepth} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Label for="passengerFirstAxleInnerTireAndWheel">Passenger First Axle Inner Tire and Wheel Condition</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="passengerFirstAxleInnerTireAndWheel"
                                                                        name="passengerFirstAxleInnerTireAndWheel"
                                                                        className="form-control"
                                                                        value={data.passengerFirstAxleInnerTireAndWheel}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={EFBNA} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Label for="passengerFirstAxleOuterBrand">Passenger First Axle Outer Tire Brand</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="passengerFirstAxleOuterBrand"
                                                                        name="passengerFirstAxleOuterBrand"
                                                                        className="form-control"
                                                                        value={data.passengerFirstAxleOuterBrand}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={TireBrand} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Label for="passengerFirstAxleOuterSize">Passenger First Axle Outer Tire Size</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        id="passengerFirstAxleOuterSize"
                                                                        name="passengerFirstAxleOuterSize"
                                                                        value={data.passengerFirstAxleOuterSize}
                                                                        onChange={e => updateFields({ passengerFirstAxleOuterSize: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Label for="passengerFirstAxleOuterTreadDepth">Passenger First Axle Outer Tire Tread Depth</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="passengerFirstAxleOuterTreadDepth"
                                                                        name="passengerFirstAxleOuterTreadDepth"
                                                                        className="form-control"
                                                                        value={data.passengerFirstAxleOuterTreadDepth}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={CommercialTireTreadDepth} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Label for="passengerFirstAxleOuterTireAndWheel">Passenger First Axle Outer Tire and Wheel Condition</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="passengerFirstAxleOuterTireAndWheel"
                                                                        name="passengerFirstAxleOuterTireAndWheel"
                                                                        className="form-control"
                                                                        value={data.passengerFirstAxleOuterTireAndWheel}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={EFBNA} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Label for="spareTire">Spare Tire</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="spareTire"
                                                                        name="spareTire"
                                                                        className="form-control"
                                                                        value={data.spareTire}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={SpareTire} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Label for="spareTireSet">Spare Tire Set</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="spareTireSet"
                                                                        name="spareTireSet"
                                                                        className="form-control"
                                                                        value={data.spareTireSet}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={SpareTireSet} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="tiresNote">Comments about tires and wheels condition of vehicle</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="tiresNote"
                                                                        name="tiresNote"
                                                                        value={data.tiresNote}
                                                                        onChange={e => updateFields({ tiresNote: e.target.value })}
                                                                        disabled={!isEdit}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="tiresFileInput">Prior to approval, please review all uploaded images to ensure they meet our quality standards, including clarity, detail, and relevance to the inspection report.</Label></Col>
                                                            {(!disableUploadItem && isEdit) &&
                                                                <>
                                                                    <Col md={10}>
                                                                        <FormGroup>
                                                                            <div name="tiresFileInput" className="dropzone" {...getTiresRootProps()}>
                                                                                <input type="file" {...getTiresInputProps()} />
                                                                                {
                                                                                    tiresAcceptedFiles.length > 0 ? <p>{tiresAcceptedFiles.length} file selected</p> :
                                                                                        isTiresDragActive ?
                                                                                            <p>Drop the files here ...</p> :
                                                                                            <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                                }
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <SpinnerButton onClick={() => onUploadFile(InspectionSection.Tires)} disabled={tiresAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Tires Pictures" loading={uploadLoading} />
                                                                    </Col>
                                                                </>}
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.Tires).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} disableDelete={!isEdit ? true : disableUploadItem} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingSix">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                        Road Test
                                                    </button>
                                                </h2>
                                                <div id="collapseSix" class="accordion-collapse border-0 collapse" aria-labelledby="headingSix" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="form-check-input"><strong>Is road test available for this vehicle?</strong> <br />  If yes, please conduct the road test and provide detailed feedback by following the instructions. If no, please select a reason from the options provided</Label>
                                                                    <div class="form-check form-switch">
                                                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={data.isRoadTestAvailable} onChange={roadTestAvailableCheckHandler} />
                                                                        <label class="form-check-label" for="flexSwitchCheckDefault">{data.isRoadTestAvailable ? 'Yes' : 'No'}</label>
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                            {!data.isRoadTestAvailable && <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="odometerEndReading">Reason</Label>
                                                                    <Input
                                                                        type="select"
                                                                        disabled={true}
                                                                        id="roadTestAvailabilityReason"
                                                                        name="roadTestAvailabilityReason"
                                                                        value={data.roadTestAvailabilityReason}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={RoadTestAvailabilityReasons} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            }
                                                        </Row>
                                                        {data.isRoadTestAvailable && <>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="odometerStartReading">Road Test Start Odometer Reading</Label>
                                                                        <Input
                                                                            type="text"
                                                                            id="odometerStartReading"
                                                                            name="odometerStartReading"
                                                                            value={data.odometerStartReading}
                                                                            onChange={e => updateFields({ odometerStartReading: e.target.value })}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="odometerEndeReading">Road Test End Odometer Reading</Label>
                                                                        <Input
                                                                            type="text"
                                                                            id="odometerEndReading"
                                                                            name="odometerEndReading"
                                                                            value={data.odometerEndReading}
                                                                            onChange={e => updateFields({ odometerEndReading: e.target.value })}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="engineStartingPerformance">Engine Starting Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="engineStartingPerformance"
                                                                                    name="engineStartingPerformance"
                                                                                    value={data.engineStartingPerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.engineStartingPerformance) && <OtherInput disabled={!isEdit} text="Engine Starting Performance Issues" name="engineStartingPerformanceIssues" value={data.engineStartingPerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="wheelBearingPerformance">Wheel Bearing Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="wheelBearingPerformance"
                                                                                    name="wheelBearingPerformance"
                                                                                    value={data.wheelBearingPerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.wheelBearingPerformance) && <OtherInput disabled={!isEdit} text="Wheel Bearing Performance Issues" name="wheelBearingPerformanceIssues" value={data.wheelBearingPerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="steeringPerformance">Steering Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="steeringPerformance"
                                                                                    name="steeringPerformance"
                                                                                    value={data.steeringPerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.steeringPerformance) && <OtherInput disabled={!isEdit} text="Steering Performance Issues" name="steeringPerformanceIssues" value={data.steeringPerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="suspensionPerformance">Suspension Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="suspensionPerformance"
                                                                                    name="suspensionPerformance"
                                                                                    value={data.suspensionPerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.suspensionPerformance) && <OtherInput disabled={!isEdit} text="Suspension Performance Issues" name="suspensionPerformanceIssues" value={data.suspensionPerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="transmissionPerformance">Transmission Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="transmissionPerformance"
                                                                                    name="transmissionPerformance"
                                                                                    value={data.transmissionPerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.transmissionPerformance) && <OtherInput disabled={!isEdit} text="Transmission Performance Issues" name="transmissionPerformanceIssues" value={data.transmissionPerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="differentialPerformance">Differential Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="differentialPerformance"
                                                                                    name="differentialPerformance"
                                                                                    value={data.differentialPerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.differentialPerformance) && <OtherInput disabled={!isEdit} text="Differential Performance Issues" name="differentialPerformanceIssues" value={data.differentialPerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="transferCasePerformance">Transfer Case Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="transferCasePerformance"
                                                                                    name="transferCasePerformance"
                                                                                    value={data.transferCasePerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.transferCasePerformance) && <OtherInput disabled={!isEdit} text="Transfer Case Performance Issues" name="transferCasePerformanceIssues" value={data.transferCasePerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="brakingPerformance">Braking Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="brakingPerformance"
                                                                                    name="brakingPerformance"
                                                                                    value={data.brakingPerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.brakingPerformance) && <OtherInput disabled={!isEdit} text="Braking Performance Issues" name="brakingPerformanceIssues" value={data.brakingPerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="interiorNoisePerformance">Interior Noise and Isolation Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="interiorNoisePerformance"
                                                                                    name="interiorNoisePerformance"
                                                                                    value={data.interiorNoisePerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.interiorNoisePerformance) && <OtherInput disabled={!isEdit} text="Interior Noise Performance Issues" name="interiorNoisePerformanceIssues" value={data.interiorNoisePerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="overallEnginePerformance">Overall Engine Performance</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="overallEnginePerformance"
                                                                                    name="overallEnginePerformance"
                                                                                    value={data.overallEnginePerformance}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.overallEnginePerformance) && <OtherInput disabled={!isEdit} text="Overall Engine Performance Issues" name="overallEnginePerformanceIssues" value={data.overallEnginePerformanceIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="automaticStartStop">Automatic Start Stop</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="automaticStartStop"
                                                                                    name="automaticStartStop"
                                                                                    value={data.automaticStartStop}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={EFBNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.automaticStartStop) && <OtherInput disabled={!isEdit} text="Automatic Start-Stop Issues" name="automaticStartStopIssues" value={data.automaticStartStopIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="cruiseControl">Cruise Control</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="cruiseControl"
                                                                                    name="cruiseControl"
                                                                                    value={data.cruiseControl}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={WorkingNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.cruiseControl) && <OtherInput disabled={!isEdit} text="Cruise Control Issues" name="cruiseControlIssues" value={data.cruiseControlIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="blindSpotDetection">Blind Spot Detection</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="blindSpotDetection"
                                                                                    name="blindSpotDetection"
                                                                                    value={data.blindSpotDetection}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={WorkingNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.blindSpotDetection) && <OtherInput disabled={!isEdit} text="Blind Spot Detection Issues" name="blindSpotDetectionIssues" value={data.blindSpotDetectionIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="laneAssist">Lane Assist</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="laneAssist"
                                                                                    name="laneAssist"
                                                                                    value={data.laneAssist}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={WorkingNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.laneAssist) && <OtherInput disabled={!isEdit} text="Lane Assist Issues" name="laneAssistIssues" value={data.laneAssistIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="hillStartAssist">Hill Start Assist</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="hillStartAssist"
                                                                                    name="hillStartAssist"
                                                                                    value={data.hillStartAssist}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={WorkingNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.hillStartAssist) && <OtherInput disabled={!isEdit} text="Hill Start Assist Issues" name="hillStartAssistIssues" value={data.hillStartAssistIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="parkingAssist">Parking Assist</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="parkingAssist"
                                                                                    name="parkingAssist"
                                                                                    value={data.parkingAssist}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={WorkingNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.parkingAssist) && <OtherInput disabled={!isEdit} text="Parking Assist Issues" name="parkingAssistIssues" value={data.parkingAssistIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="backUpCamera">Back-Up Camera Condition</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="backUpCamera"
                                                                                    name="backUpCamera"
                                                                                    value={data.backUpCamera}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={WorkingNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.backUpCamera) && <OtherInput disabled={!isEdit} text="Back-Up Camera Issues" name="backUpCameraIssues" value={data.backUpCameraIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row form>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Label for="autoPilotAssist">Auto Pilot Assist</Label>
                                                                                <Input
                                                                                    type="select"
                                                                                    disabled={true}
                                                                                    id="autoPilotAssist"
                                                                                    name="autoPilotAssist"
                                                                                    value={data.autoPilotAssist}
                                                                                    className="form-control"
                                                                                    onChange={UpdateWithSelection}
                                                                                >
                                                                                    <Options data={WorkingNA} />
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {isFairOrBad(data.autoPilotAssist) && <OtherInput disabled={!isEdit} text="AutoPilot Assist Issues" name="autoPilotAssistIssues" value={data.autoPilotAssistIssues} updateFields={updateFields} />}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={12}>
                                                                    <FormGroup>
                                                                        <Label for="roadTestNote">Comments about road test</Label>
                                                                        <Input
                                                                            type="textarea"
                                                                            id="roadTestNote"
                                                                            name="roadTestNote"
                                                                            value={data.roadTestNote}
                                                                            onChange={e => updateFields({ roadTestNote: e.target.value })}
                                                                            disabled={!isEdit}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col md={12}><Label for="roadTestFileInput">Prior to approval, please review all uploaded images to ensure they meet our quality standards, including clarity, detail, and relevance to the inspection report.</Label></Col>
                                                                {(!disableUploadItem && isEdit) &&
                                                                    <>
                                                                        <Col md={10}>
                                                                            <FormGroup>
                                                                                <div name="roadTestFileInput" className="dropzone" {...getRoadTestRootProps()}>
                                                                                    <input type="file" {...getRoadTestInputProps()} />
                                                                                    {
                                                                                        roadTestAcceptedFiles.length > 0 ? <p>{roadTestAcceptedFiles.length} file selected</p> :
                                                                                            isRoadTestDragActive ?
                                                                                                <p>Drop the files here ...</p> :
                                                                                                <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                                    }
                                                                                </div>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={2}>
                                                                            <SpinnerButton onClick={() => onUploadFile(InspectionSection.RoadTest)} disabled={roadTestAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Road Test Pictures" loading={uploadLoading} />
                                                                        </Col>
                                                                    </>}
                                                                <Col md={12}>
                                                                    <Row>
                                                                        {uploadedFiles.filter(t => t.subCategory == InspectionSection.RoadTest).map(t =>
                                                                            <Col md={2} className="my-2">
                                                                                <ImageArea src={t.filePath} disableDelete={!isEdit ? true : disableUploadItem} handleClick={() => handleRemoveImage(t.id)} />
                                                                            </Col>
                                                                        )}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingSeven">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                        RV Extras
                                                    </button>
                                                </h2>
                                                <div id="collapseSeven" class="accordion-collapse border-0 collapse" aria-labelledby="headingSeven" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="refrigerator" size="xs">Refrigerator</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="refrigerator"
                                                                                name="refrigerator"
                                                                                value={data.refrigerator}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.refrigerator) && <OtherInput disabled={!isEdit} text="Refrigerator Issues" name="refrigeratorIssues" value={data.refrigeratorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="stove" size="xs">Stove</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="stove"
                                                                                name="stove"
                                                                                value={data.stove}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.stove) && <OtherInput disabled={!isEdit} text="Stove Issues" name="stoveIssues" value={data.stoveIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="microwave" size="xs">Microwave</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="microwave"
                                                                                name="microwave"
                                                                                value={data.microwave}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.microwave) && <OtherInput disabled={!isEdit} text="Microwave Issues" name="microwaveIssues" value={data.microwaveIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="acSystem" size="xs">A/C System</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="acSystem"
                                                                                name="acSystem"
                                                                                value={data.acSystem}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={WorkingNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.acSystem) && <OtherInput disabled={!isEdit} text="AC System Issues" name="acSystemIssues" value={data.acSystemIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="grayWaterTank" size="xs">Gray Water Tank</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="grayWaterTank"
                                                                                name="grayWaterTank"
                                                                                value={data.grayWaterTank}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={HasIssueNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.grayWaterTank) && <OtherInput disabled={!isEdit} text="Gray Water Tank Issues" name="grayWaterTankIssues" value={data.grayWaterTankIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="blackWaterTank" size="xs">Black Water Tank</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="blackWaterTank"
                                                                                name="blackWaterTank"
                                                                                value={data.blackWaterTank}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={HasIssueNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.blackWaterTank) && <OtherInput disabled={!isEdit} text="Black Water Tank Issues" name="blackWaterTankIssues" value={data.blackWaterTankIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="solarPanels" size="xs">Solar Panels</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="solarPanels"
                                                                                name="solarPanels"
                                                                                value={data.solarPanels}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={HasIssueNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.solarPanels) && <OtherInput disabled={!isEdit} text="Solar Panels Issues" name="solarPanelsIssues" value={data.solarPanelsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="waterHeater" size="xs">Water Heater</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="waterHeater"
                                                                                name="waterHeater"
                                                                                value={data.waterHeater}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={HasIssueNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.waterHeater) && <OtherInput disabled={!isEdit} text="Water Heater Issues" name="waterHeaterIssues" value={data.waterHeaterIssues} updateFields={updateFields} />}

                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="kitchen" size="xs">Kitchen</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="kitchen"
                                                                                name="kitchen"
                                                                                value={data.kitchen}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={HasIssueNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.kitchen) && <OtherInput disabled={!isEdit} text="Kitchen Issues" name="kitchenIssues" value={data.kitchenIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="bathrooms" size="xs">Bathrooms</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="bathrooms"
                                                                                name="bathrooms"
                                                                                value={data.bathrooms}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={HasIssueNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.bathrooms) && <OtherInput disabled={!isEdit} text="Bathroom Issues" name="bathroomsIssues" value={data.bathroomsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="bedrooms" size="xs">Bedrooms</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="bedrooms"
                                                                                name="bedrooms"
                                                                                value={data.bedrooms}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={HasIssueNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.bedrooms) && <OtherInput disabled={!isEdit} text="Bedroom Issues" name="bedroomsIssues" value={data.bedroomsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="livingRoom" size="xs">Living Room</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="livingRoom"
                                                                                name="livingRoom"
                                                                                value={data.livingRoom}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={HasIssueNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.livingRoom) && <OtherInput disabled={!isEdit} text="Living Room Issues" name="livingRoomIssues" value={data.livingRoomIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="lighting" size="xs">Lighting</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="lighting"
                                                                                name="lighting"
                                                                                value={data.lighting}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={HasIssueNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.lighting) && <OtherInput disabled={!isEdit} text="Lighting Issues" name="lightingIssues" value={data.lightingIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="generator" size="xs">Generator</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="generator"
                                                                                name="generator"
                                                                                value={data.generator}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={HasIssueNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.generator) && <OtherInput disabled={!isEdit} text="Generator Issues" name="generatorIssues" value={data.generatorIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="slideOuts" size="xs">Slide Outs</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="slideOuts"
                                                                                name="slideOuts"
                                                                                value={data.slideOuts}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={HasIssueNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.slideOuts) && <OtherInput disabled={!isEdit} text="Slide Outs Issues" name="slideOutsIssues" value={data.slideOutsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="awning" size="xs">Awning</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="awning"
                                                                                name="awning"
                                                                                value={data.awning}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={HasIssueNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>{isFairOrBad(data.awning) && <OtherInput disabled={!isEdit} text="Awning Issues" name="awningIssues" value={data.awningIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="cigaretteUse">Cigarette Use</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="cigaretteUse"
                                                                                name="cigaretteUse"
                                                                                value={data.cigaretteUse}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={CigaretteUse} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.cigaretteUse) && <OtherInput disabled={!isEdit} text="Cigarette Use Issues" name="cigaretteUseIssues" value={data.cigaretteUseIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="unusualOdors" size="xs">Unusual Odors</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="unusualOdors"
                                                                                name="unusualOdors"
                                                                                value={data.unusualOdors}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={UnusualOdors} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.unusualOdors) && <OtherInput disabled={!isEdit} text="Unusual Odors Issues" name="unusualOdorsIssues" value={data.unusualOdorsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="securitySystems" size="xs">Security Systems</Label>
                                                                            <Input
                                                                                type="select"
                                                                                disabled={true}
                                                                                id="securitySystems"
                                                                                name="securitySystems"
                                                                                value={data.securitySystems}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={HasIssueNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.securitySystems) && <OtherInput disabled={!isEdit} text="Security Systems Issues" name="securitySystemsIssues" value={data.securitySystemsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="extras" size="xs">Extras</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="extras"
                                                                        name="extras"
                                                                        value={data.extras}
                                                                        onChange={e => updateFields({ extras: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="rvExtraFileInput">Prior to approval, please review all uploaded images to ensure they meet our quality standards, including clarity, detail, and relevance to the inspection report.</Label></Col>
                                                            {(!disableUploadItem && isEdit) &&
                                                                <>
                                                                    <Col md={10}>
                                                                        <FormGroup>
                                                                            <div name="rvExtraFileInput" className="dropzone" {...getRVExtraRootProps()}>
                                                                                <input type="file" {...getRVExtraInputProps()} />
                                                                                {
                                                                                    rvExtraAcceptedFiles.length > 0 ? <p>{rvExtraAcceptedFiles.length} file selected</p> :
                                                                                        isRVExtraDragActive ?
                                                                                            <p>Drop the files here ...</p> :
                                                                                            <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                                }
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <SpinnerButton onClick={() => onUploadFile(InspectionSection.RVExtra)} disabled={rvExtraAcceptedFiles.length == 0} className="btn btn-warning" text="Upload RV Extra Pictures" loading={uploadLoading} />
                                                                    </Col>
                                                                </>}
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.RVExtra).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} disableDelete={!isEdit ? true : disableUploadItem} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingEight">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                        Summary
                                                    </button>
                                                </h2>
                                                <div id="collapseEight" class="accordion-collapse border-0 collapse" aria-labelledby="headingEight" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={12}>
                                                                <Label for="summary">Please provide a summary of inspection with few paragraphs.</Label>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="summary"
                                                                        name="summary"
                                                                        value={data.summary}
                                                                        onChange={e => updateFields({ summary: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="summaryFileInput">Prior to approval, please review all uploaded images to ensure they meet our quality standards, including clarity, detail, and relevance to the inspection report.</Label></Col>
                                                            {(!disableUploadItem && isEdit) &&
                                                                <>
                                                                    <Col md={10}>
                                                                        <FormGroup>
                                                                            <div name="summaryFileInput" className="dropzone" {...getSummaryRootProps()}>
                                                                                <input type="file" {...getSummaryInputProps()} />
                                                                                {
                                                                                    summaryAcceptedFiles.length > 0 ? <p>{summaryAcceptedFiles.length} file selected</p> :
                                                                                        isSummaryDragActive ?
                                                                                            <p>Drop the files here ...</p> :
                                                                                            <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                                }
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <SpinnerButton onClick={() => onUploadFile(InspectionSection.Summary)} disabled={summaryAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Additional Pictures" loading={uploadLoading} />
                                                                    </Col>
                                                                </>}
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.Summary).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} disableDelete={!isEdit ? true : disableUploadItem} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {!(data.status == QuotationStatus.InspectionApproved || data.status == QuotationStatus.Completed) &&
                                            <Row>
                                                <Col md={9}>
                                                    <div style={{ color: "red", fontSize: "0.9rem" }}>
                                                        {Object.keys(validationError).length > 0 ? Object.values(validationError).map(item => <div className="row"><span>* {item}</span></div>) : null}
                                                    </div>
                                                </Col>
                                                {!isEdit ? <>
                                                    <Col md={1}>
                                                        <button className="btn btn-dark my-5" onClick={() => setIsEdit(true)}>Edit</button>
                                                    </Col>
                                                    <Col md={2}>
                                                        <SpinnerButton className="btn btn-primary my-5" text="Approve Report" loading={loading} onClick={() => setApprovalModal({ show: true, message: "Are you sure you want to approve this report? This action cannot be undone." })} />
                                                    </Col> </> : <>
                                                    <Col md={1}>
                                                        <button className="btn btn-dark my-5" onClick={() => setCancelModal({ show: true })}>Cancel</button>
                                                    </Col>
                                                    <Col md={2}>
                                                        <SpinnerButton onClick={() => setSaveModal({ show: true })} className="btn btn-primary my-5" text="Save" loading={loading} />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </section>
            </div>
        </div>

    )
}

export default RVOrder;