import React from 'react'
import HerosectionHeader from '../../Componet/Herosection/HerosectionHeader'
import { Container, Row, Col } from 'reactstrap';

const AboutUsInspector = () => {
    return (
        <>
            <HerosectionHeader name={"About US"} />
            <div class="page-content">
                <section>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="8">
                                <div className="mb-5">
                                    <h2 className="mb-0">
                                        <span className="font-w-4 d-block"></span>We are setting the standard for pre-purchase car inspections
                                    </h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <div class="mt-5 col-md-12 col-lg-12">
                                <div class="d-flex justify-content-between">
                                    <div class="me-3"></div>
                                    <div>
                                        <h5 class="mb-2">About inspectiver</h5>
                                        <p class="mb-0">inspectiver is dedicated to ensuring the highest standards of vehicle inspections before purchase.
                                            Our mission is to connect vehicle buyers with certified mechanics who can provide thorough,
                                            reliable, and professional inspections, helping customers make informed decisions. We pride
                                            ourselves on our commitment to quality, transparency, and customer satisfaction.</p>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div class="mt-5 col-md-12 col-lg-12">
                                <div class="d-flex justify-content-between">
                                    <div class="me-3"></div>
                                    <div>
                                        <h5 class="mb-2">Our Story</h5>
                                        <p class="mb-0">Founded with the vision to revolutionize the car inspection process, inspectiver bridges the gap
                                            between car buyers and qualified mechanics. Our platform is designed to provide convenience,
                                            trust, and peace of mind, making vehicle buying a hassle-free experience. By partnering with
                                            top-tier mechanics nationwide, we ensure that every vehicle inspection is conducted with the
                                            utmost precision and care.</p>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </section>
                {/* <AboutL3 />
        <TestimonialL1 />
        <BlogL1 />
        <NewsletterL1 /> */}
            </div>
        </>
    )
}

export default AboutUsInspector
