import { useState, useEffect, useRef } from "react";
import "./auto-complete-input.css";

const AutoCompleteInput = ({ id, options, placeholder, value, onSuggestionClick, handleChange, disabled=false }) => {
    const [showSuggestions, setShowSuggestions] = useState(false);

    const suggestions = options?.filter(option => option.name.toLowerCase().includes(value?.toLowerCase()))

    const autocompleteRef = useRef(id);

    useEffect(() => {
        const handleClick = (event) => {
            if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
                setShowSuggestions(false)
            }
        };
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick)
        }
    }, [])

    const handleSuggestionClick = (suggestion) => {
        onSuggestionClick(suggestion);
        setShowSuggestions(false);
    }

    return (
        <div className="autocomplete" ref={autocompleteRef}>
            <input
                type="text"
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                disabled={disabled}
                className="form-control"
                onFocus={() => setShowSuggestions(true)}
            />
            {showSuggestions && (
                <div className="suggestions">
                    {suggestions && suggestions.map(suggestion => (
                        <div className="suggestion-item" onClick={() => handleSuggestionClick(suggestion)} key={suggestion.id}>
                            {suggestion.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
};

export default AutoCompleteInput;