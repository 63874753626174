import React, { FormEvent, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";
import {
    Col,
    Container,
    Row,
} from "reactstrap";
import Modal from 'react-bootstrap/Modal';

import { isValidEmail, isValidPhoneNumber, isValidZipCode } from "../../../store/Utility/Helper";

import axios from "../../../api/axios";
import { QuotationStep, VehicleTypes } from "../../../store/Utility/Status";

import { useMultipStepForm } from "./useMultiStepForm";
import { VehicleTypeStep } from "./VehicleTypeStep";
import { VehicleStep } from "./VehicleStep";
import { SellerStep } from "./SellerStep";
import { RequesterStep } from "./RequesterStep";
import { InspectorNoteStep } from './InspectorNoteStep';
import { PaymentStep } from "./PaymentStep";


const InitialData = {
    id: uuidv4(),
    vehicleType: "",
    isCarfaxReportRequested: false,
    category: "",
    make: "",
    model: "",
    year: "",
    licensePlate: "",
    VIN: "",
    sellerContactName: "",
    sellerAddress: "",
    sellerCity: "",
    sellerState: "",
    sellerZipCode: "",
    sellerPhone: "",
    requesterFirstName: "",
    requesterLastName: "",
    requesterEmail: "",
    requesterAddress: "",
    requesterCity: "",
    requesterState: "",
    requesterZipCode: "",
    requesterPhone: "",
    requesterNote: "",
    isTermAndConditionAccepted: false,
    quotationStep: 1,
    sellerLatitude: null,
    sellerLongitude: null
}


function Quotation() {
    const [data, setData] = useState(InitialData);
    const [errors, setErrors] = useState({});
    const [modal, showModal] = useState({ show: false, message: null });
    const [isLoading, setIsLoading]=useState(false);
    const paymentStepRef = useRef();

    function updateFields(fields) {
        setData(prev => {
            return { ...prev, ...fields }
        })
    }

    const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next, description, header } =
        useMultipStepForm([
            <VehicleTypeStep {...data} header="Vehicle Type" updateFields={updateFields} description="Please select vehicle type" />,
            <VehicleStep {...data} header="Vehicle" updateFields={updateFields} description="Please select your vehicle for inspection" />,
            <SellerStep  {...data} header="Seller's Info" updateFields={updateFields} description="Please enter seller's contact information and vehicle's location" />,
            <RequesterStep {...data} header="Your Info" updateFields={updateFields} description="Please enter your information" />,
            <InspectorNoteStep {...data} header="Notes to Inspector" updateFields={updateFields} description="Note to Inspector" />,
            <PaymentStep {...data} header="Checkout" ref={paymentStepRef} updateFields={updateFields} description={null} />
        ]);

    const handleClose = () => showModal({ show: false, message: null });

    const validateValues = (inputValues) => {
        let errors = {};
        if (currentStepIndex == 0) {
            if (inputValues.vehicleType == '') {
                errors.vehicleType = "Please select vehicle type";
            }
        } else if (currentStepIndex == 1) {
            if (inputValues.vehicleType == VehicleTypes.Car) {
                if (inputValues.makeId == undefined || inputValues.makeId == '') errors.makeId = "Please select Make";
                if (inputValues.modelId == undefined || inputValues.modelId == '') errors.modelId = "Please select Model";
            } else {
                if (inputValues.make == '') errors.make = "Make is required";
                if (inputValues.model == '') errors.model = "Model is required";
                if (inputValues.categoryId == '') errors.categoryId = "Please select Category";
            }
            if (inputValues.year == '') errors.year = "Please select Year";

        } else if (currentStepIndex == 2) {
            if (inputValues.sellerContactName == '') errors.sellerContactName = "Contact Name is required";
            if (inputValues.sellerAddress == '') errors.sellerAddress = "Vehicle Location Address is required";
            if (inputValues.sellerCity == '') errors.sellerCity = "City is required";
            if (inputValues.sellerState == '') errors.sellerState = "State is required";
            if (inputValues.sellerZipCode == '') {
                errors.sellerZipCode = "Zip Code is required";
            } else {
                if (!isValidZipCode(inputValues.sellerZipCode)) errors.sellerZipCode = "Please enter valid zip code";
            }
            if (inputValues.sellerPhone == '') {
                errors.sellerPhone = "Phone is required";
            } else {
                if (!isValidPhoneNumber(inputValues.sellerPhone)) errors.sellerPhone = "Please enter valid phone number";
            }
        } else if (currentStepIndex == 3) {
            if (inputValues.requesterFirstName == '') errors.requesterFirstName = "First Name is required";
            if (inputValues.requesterLastName == '') errors.requesterLastName = "Last Name is required";
            if (inputValues.requesterEmail == '') {
                errors.requesterEmail = "Email is required";
            } else {
                if (!isValidEmail(inputValues.requesterEmail)) errors.requesterEmail = "Please enter valid email";
            }

            if (inputValues.requesterAddress == '') errors.requesterAddress = "Address is required";
            if (inputValues.requesterCity == '') errors.requesterCity = "City is required";
            if (inputValues.requesterState == '') errors.requesterState = "State is required";
            if (inputValues.requesterZipCode == '') {
                errors.requesterZipCode = "Zip Code is required";
            } else {
                if (!isValidZipCode(inputValues.requesterZipCode)) errors.requesterZipCode = "Please enter valid zip code";
            }
            if (inputValues.requesterPhone == '') {
                errors.requesterPhone = "Phone is required";
            } else {
                if (!isValidPhoneNumber(inputValues.requesterPhone)) errors.requesterPhone = "Please enter valid phone number";
            }
        } else if (currentStepIndex == 4) {
            if (!inputValues.isTermAndConditionAccepted) errors.requesterFirstName = "Terms and condition should be checked to continue";
        }

        return errors;
    };

    async function onSubmit(e: FormEvent) {
        e.preventDefault();
       
        var error = validateValues(data);
        setErrors(error);

        if (Object.keys(error).length > 0) return;

        setIsLoading(true);

        if (!isLastStep) {
            if (currentStepIndex == QuotationStep.SellerInfo || currentStepIndex == QuotationStep.RequesterInfo) {
                
                const response = await axios.post(`v1/quotation`, { ...data, quotationStep: currentStepIndex + 1 })
                .then(res => {
                    setIsLoading(false);
                }).catch(error => {
                    setIsLoading(false);
                });
            }
            setIsLoading(false);
            return next();
        }
        
        const response = await axios.post(`v1/quotation`, { ...data, quotationStep: QuotationStep.Payment }).then(res => {
            // showModal({ show: true, message: "Your Inspection successfull saved!" });
            paymentStepRef.current.handleSubmit(e);
            // setData(InitialData);
        }).catch(error => {
            setIsLoading(false);
            showModal({ show: true, message: "An error occured while saving" });
        });
    }

    const onClickBack = () => {
        setErrors({});
        back()
    }

    return (
        <div>
            <div className="page-content">
                {<Modal show={modal.show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Result</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modal.message}</Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-dark" onClick={handleClose}>Close</button>
                    </Modal.Footer>
                </Modal>}
                <section>
                    <Container>
                        <Row >
                            <Col lg={2} md={12}></Col>
                            <Col lg={8} md={12}>
                                <div className="checkout-form border p-4 rounded">
                                    <h2 className="mb-4">{header}</h2>
                                    <div class="progress mb-4">
                                        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style={{ "width": (currentStepIndex + 1) * 16.66 + '%' }} ></div>
                                    </div>
                                    <div>
                                        <h4>{description}</h4>
                                    </div>
                                    <div className="mt-5">
                                        <form onSubmit={onSubmit}>
                                            <div className="step-component">
                                                {step}
                                            </div>
                                            <div style={{ color: "red", fontSize: "0.9rem" }}>
                                                {Object.keys(errors).length > 0 ? Object.values(errors).map(item => <div className="row"><span>* {item}</span></div>) : null}
                                            </div>
                                            <div>
                                                {!isFirstStep && <button type="button" className="btn btn-dark my-5 btn-sm ms-3" onClick={onClickBack} >Back</button>}
                                                <button type="submit" disabled={isLoading} className="btn btn-dark my-5 btn-sm ms-3">
                                                    {isLoading ? (
                                                        <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                    ) : null}
                                                    {isLastStep ? "Finish" : "Next"}</button>
                                                {/* {isLastStep && <button type="submit" className="btn btn-dark my-5 btn-sm ms-3">Finish</button>} */}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </div>
    );
}

export default Quotation;