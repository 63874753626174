import React from "react";
import { Row, Col } from "reactstrap";
import OptionText from "../../../Componet/Option/OptionText";
import {
    InteriorMaterial, TransmissionType, EngineLayout, CylinderConfiguration, DriveType, FuelType, EFB, Dings, Dents,
    Rust, EFBNA, HornCondition, WorkingNA, CigaretteUse, Leaks, TireBrand, Warnings,
    CommercialTireTreadDepth, SpareTire, SpareTireSet, UnusualOdors, Scratches, RoadTestAvailabilityReasons
} from "../../../store/Utility/Data";

const CommercialInspectionReport = ({ data }) => {

    function isOther(value) {
        if (value == undefined || value == null) return false;
        return value.toLowerCase() == "other";
    }

    return (
        <>
            {data &&
                <Row>
                    <Col md={12}>
                        <table className="table table-bordered tdfirst-nowrap">
                            <thead>
                                <tr style={{ backgroundColor: "#20c997" }}>
                                    <th colSpan={3} style={{ textAlign: "center" }}>Vehicle Info</th>
                                </tr>
                                <tr style={{ backgroundColor: "#d5d5d5" }}>
                                    <th>Vehicle Detail</th>
                                    <th>Specification</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Make</td>
                                    <td>{data.makeInfo}</td>
                                </tr>
                                <tr>
                                    <td>Model</td>
                                    <td>{data.modelInfo}</td>
                                </tr>
                                <tr>
                                    <td>Year</td>
                                    <td>{data.year}</td>
                                </tr>
                                <tr>
                                    <td>Mileage</td>
                                    <td>{data.mileage}</td>
                                </tr>
                                <tr>
                                    <td>License Plate</td>
                                    <td>{data.licensePlate}</td>
                                </tr>
                                <tr>
                                    <td>VIN</td>
                                    <td>{data.vin}</td>
                                </tr>
                                <tr>
                                    <td>Body Color</td>
                                    <td>{data.bodyColor}</td>
                                </tr>
                                <tr>
                                    <td>Interior Color</td>
                                    <td>{data.interiorColor}</td>
                                </tr>
                                <tr>
                                    <td>Interior Material</td>
                                    <td>{isOther(data.interiorMaterial) ? data.interiorMaterialInfo : <OptionText source={InteriorMaterial} value={data.interiorMaterial} />}</td>
                                </tr>
                                <tr>
                                    <td>Transmission Type</td>
                                    <td>{isOther(data.transmissionType) ? data.transmissionTypeInfo : <OptionText source={TransmissionType} value={data.transmissionType} />}</td>
                                </tr>
                                <tr>
                                    <td>Engine Layout</td>
                                    <td>{isOther(data.engineLayout) ? data.engineLayoutInfo : <OptionText source={EngineLayout} value={data.engineLayout} />}</td>
                                </tr>
                                <tr>
                                    <td>Cylinder Configuration</td>
                                    <td>{isOther(data.cylinderConfiguration) ? data.cylinderConfigurationInfo : <OptionText source={CylinderConfiguration} value={data.cylinderConfiguration} />}</td>
                                </tr>
                                <tr>
                                    <td>Drive Type</td>
                                    <td>{isOther(data.driveType) ? data.driveTypeInfo : <OptionText source={DriveType} value={data.driveType} />}</td>
                                </tr>
                                <tr>
                                    <td>Fuel Type</td>
                                    <td>{isOther(data.fuelType) ? data.fuelTypeInfo : <OptionText source={FuelType} value={data.fuelType} />}</td>
                                </tr>
                                <tr>
                                    <td>Comments</td>
                                    <td>{data.vehicleInfoNote}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col md={12}>
                        <table className="table table-bordered tdfirst-nowrap tdsecond-nowrap">
                            <thead>
                                <tr style={{ backgroundColor: "#20c997" }}>
                                    <th colSpan={3} style={{ textAlign: "center" }}>Exteriror</th>
                                </tr>
                                <tr style={{ backgroundColor: "#d5d5d5" }}>
                                    <th>Inspection Point</th>
                                    <th>Condition</th>
                                    <th>Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Body Alignment Condition</td>
                                    <td><OptionText source={EFB} value={data.bodyAlignment} /></td>
                                    <td>{data.bodyAlignmentIssues}</td>
                                </tr>
                                <tr>
                                    <td>Paint Condition</td>
                                    <td><OptionText source={EFB} value={data.paint} /></td>
                                    <td>{data.paintIssues}</td>
                                </tr>
                                <tr>
                                    <td>Frame Condition</td>
                                    <td><OptionText source={EFB} value={data.frame} /></td>
                                    <td>{data.frameIssues}</td>
                                </tr>
                                <tr>
                                    <td>Glass & Window Condition</td>
                                    <td><OptionText source={EFB} value={data.glassAndWindow} /></td>
                                    <td>{data.glassAndWindowIssues}</td>
                                </tr>
                                <tr>
                                    <td>Scratches</td>
                                    <td><OptionText source={Scratches} value={data.scratches} /></td>
                                    <td>{data.scratchesIssues}</td>
                                </tr>
                                <tr>
                                    <td>Dings</td>
                                    <td><OptionText source={Dings} value={data.dings} /></td>
                                    <td>{data.dingsIssues}</td>
                                </tr>
                                <tr>
                                    <td>Dents</td>
                                    <td> <OptionText source={Dents} value={data.dents} /></td>
                                    <td>{data.dentsIssues}</td>
                                </tr>
                                <tr>
                                    <td>Rust</td>
                                    <td><OptionText source={Rust} value={data.rust} /></td>
                                    <td>{data.rustIssues}</td>
                                </tr>
                                <tr>
                                    <td>Body Seals Condition</td>
                                    <td><OptionText source={EFB} value={data.bodySeals} /></td>
                                    <td>{data.bodySealsIssues} </td>
                                </tr>
                                <tr>
                                    <td>Side Moldings Condition</td>
                                    <td><OptionText source={EFBNA} value={data.sideMoldings} /></td>
                                    <td>{data.sideMoldingsIssues} </td>
                                </tr>
                                <tr>
                                    <td>Side Mirrors Condition</td>
                                    <td><OptionText source={EFB} value={data.sideMirrors} /></td>
                                    <td>{data.sideMirrorsIssues} </td>
                                </tr>
                                <tr>
                                    <td>Exterior Lights Condition</td>
                                    <td><OptionText source={EFB} value={data.exteriorLights} /></td>
                                    <td>{data.exteriorLightsIssues} </td>
                                </tr>
                                <tr>
                                    <td>Left Front Fender Condition</td>
                                    <td><OptionText source={EFB} value={data.leftFrontFender} /></td>
                                    <td>{data.leftFrontFenderIssues} </td>
                                </tr>
                                <tr>
                                    <td>Right Front Fender Condition</td>
                                    <td><OptionText source={EFB} value={data.rightFrontFender} /></td>
                                    <td>{data.rightFrontFenderIssues} </td>
                                </tr>
                                <tr>
                                    <td>Grille & Front Bumper Condition</td>
                                    <td><OptionText source={EFB} value={data.grilleAndFrontBumper} /></td>
                                    <td>{data.grilleAndFrontBumperIssues} </td>
                                </tr>
                                <tr>
                                    <td>Hood Condition</td>
                                    <td><OptionText source={EFB} value={data.hood} /></td>
                                    <td>{data.hoodIssues} </td>
                                </tr>
                                <tr>
                                    <td>Left Front Door Condition</td>
                                    <td><OptionText source={EFB} value={data.leftFrontDoor} /></td>
                                    <td>{data.leftFrontDoorIssues} </td>
                                </tr>
                                <tr>
                                    <td>Left Rear Door Condition</td>
                                    <td><OptionText source={EFBNA} value={data.leftRearDoor} /></td>
                                    <td>{data.leftRearDoorIssues} </td>
                                </tr>
                                <tr>
                                    <td>Left Rear Fender Condition</td>
                                    <td><OptionText source={EFB} value={data.leftRearFender} /></td>
                                    <td>{data.leftRearFenderIssues} </td>
                                </tr>
                                <tr>
                                    <td>Right Rear Fender Condition</td>
                                    <td><OptionText source={EFB} value={data.rightRearFender} /></td>
                                    <td>{data.rightRearFenderIssues} </td>
                                </tr>
                                <tr>
                                    <td>Rear Bumper Condition</td>
                                    <td><OptionText source={EFB} value={data.rearBumper} /></td>
                                    <td>{data.rearBumperIssues} </td>
                                </tr>
                                <tr>
                                    <td>Trunk Condition</td>
                                    <td><OptionText source={EFBNA} value={data.trunk} /></td>
                                    <td>{data.trunkIssues} </td>
                                </tr>
                                <tr>
                                    <td>Right Front Door Condition</td>
                                    <td><OptionText source={EFBNA} value={data.rightFrontDoor} /></td>
                                    <td>{data.rightFrontDoorIssues} </td>
                                </tr>
                                <tr>
                                    <td>Right Rear Door Condition</td>
                                    <td><OptionText source={EFBNA} value={data.rightRearDoor} /></td>
                                    <td>{data.rightRearDoorIssues} </td>
                                </tr>
                                <tr>
                                    <td>Roof Panel Condition</td>
                                    <td><OptionText source={EFBNA} value={data.roof} /></td>
                                    <td>{data.roofIssues} </td>
                                </tr>
                                <tr>
                                    <td>Sunroof Condition</td>
                                    <td><OptionText source={EFBNA} value={data.sunroof} /></td>
                                    <td>{data.sunroofIssues} </td>
                                </tr>
                                <tr>
                                    <td>Wiper Blades Condition</td>
                                    <td><OptionText source={EFB} value={data.wiperBlades} /></td>
                                    <td>{data.wiperBladesIssues} </td>
                                </tr>
                                <tr>
                                    <td>Windshield Washer Condition</td>
                                    <td><OptionText source={EFB} value={data.windshieldWasher} /></td>
                                    <td>{data.windshieldWasherIssues} </td>
                                </tr>
                                <tr>
                                    <td>Comments</td>
                                    <td></td>
                                    <td>{data.exteriorNote} </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>

                    <Col md={12}>
                        <table className="table table-bordered tdfirst-nowrap tdsecond-nowrap">
                            <thead>
                                <tr style={{ backgroundColor: "#20c997" }}>
                                    <th colSpan={3} style={{ textAlign: "center" }}>Interior</th>
                                </tr>
                                <tr style={{ backgroundColor: "#d5d5d5" }}>
                                    <th>Inspection Point</th>
                                    <th>Condition</th>
                                    <th>Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Steering Wheel Condition</td>
                                    <td><OptionText source={EFBNA} value={data.steeringWheel} /></td>
                                    <td>{data.steeringWheelIssues}</td>
                                </tr>
                                <tr>
                                    <td>Horn Condition</td>
                                    <td><OptionText source={HornCondition} value={data.horn} /></td>
                                    <td>{data.hornIssues}</td>
                                </tr>
                                <tr>
                                    <td>Seats Condition</td>
                                    <td><OptionText source={EFBNA} value={data.seats} /></td>
                                    <td>{data.seatsIssues}</td>
                                </tr>
                                <tr>
                                    <td>Seat Mechanisms Condition</td>
                                    <td><OptionText source={EFBNA} value={data.seatMechanisms} /></td>
                                    <td>{data.seatMechanismsIssues}</td>
                                </tr>
                                <tr>
                                    <td>Sun Visors Condition</td>
                                    <td><OptionText source={EFBNA} value={data.sunVisors} /></td>
                                    <td>{data.sunVisorsIssues}</td>
                                </tr>
                                <tr>
                                    <td>Headliner Condition</td>
                                    <td><OptionText source={EFBNA} value={data.headliner} /></td>
                                    <td>{data.headlinerIssues}</td>
                                </tr>
                                <tr>
                                    <td>Inner Door Panels Condition</td>
                                    <td><OptionText source={EFBNA} value={data.innerDoorPanels} /></td>
                                    <td>{data.innerDoorPanelsIssues}</td>
                                </tr>
                                <tr>
                                    <td>Interior Trim Condition</td>
                                    <td><OptionText source={EFBNA} value={data.interiorTrim} /></td>
                                    <td>{data.interiorTrimIssues}</td>
                                </tr>

                                <tr>
                                    <td>Interior Lights Condition</td>
                                    <td><OptionText source={EFBNA} value={data.interiorLights} /></td>
                                    <td>{data.interiorLightsIssues}</td>
                                </tr>
                                <tr>
                                    <td>Power Locks Condition</td>
                                    <td><OptionText source={WorkingNA} value={data.powerLocks} /></td>
                                    <td>{data.powerLocksIssues}</td>

                                </tr>
                                <tr>
                                    <td>Window Switches Condition</td>
                                    <td><OptionText source={WorkingNA} value={data.windowSwitches} /></td>
                                    <td>{data.windowSwitchesIssues} </td>
                                </tr>
                                <tr>
                                    <td>Window Functioning Condition</td>
                                    <td><OptionText source={WorkingNA} value={data.windowFunctioning} /></td>
                                    <td>{data.windowFunctioningIssues} </td>
                                </tr>
                                <tr>
                                    <td>Trunk Actuator</td>
                                    <td><OptionText source={WorkingNA} value={data.trunkActuator} /></td>
                                    <td>{data.trunkActuatorIssues} </td>
                                </tr>
                                <tr>
                                    <td>Hood Actuator</td>
                                    <td><OptionText source={WorkingNA} value={data.hoodActuator} /></td>
                                    <td>{data.hoodActuatorIssues} </td>
                                </tr>
                                <tr>
                                    <td>Fuel Door Actuator</td>
                                    <td><OptionText source={WorkingNA} value={data.fuelDoorActuator} /></td>
                                    <td>{data.fuelDoorActuatorIssues} </td>
                                </tr>
                                <tr>
                                    <td>Dash Condition</td>
                                    <td><OptionText source={EFBNA} value={data.dash} /></td>
                                    <td>{data.dashIssues} </td>
                                </tr>
                                <tr>
                                    <td>Dash Lights</td>
                                    <td><OptionText source={EFBNA} value={data.dashLights} /></td>
                                    <td>{data.dashLightsIssues} </td>
                                </tr>
                                <tr>
                                    <td>Dash Warnings</td>
                                    <td><OptionText source={Warnings} value={data.dashWarnings} /></td>
                                    <td>{data.dashWarningsIssues} </td>
                                </tr>
                                <tr>
                                    <td>Dash Gauges</td>
                                    <td><OptionText source={EFBNA} value={data.dashGauges} /></td>
                                    <td>{data.dashGaugesIssues} </td>
                                </tr>
                                <tr>
                                    <td>Center Console Condition</td>
                                    <td><OptionText source={EFBNA} value={data.centerConsole} /></td>
                                    <td>{data.centerConsoleIssues} </td>
                                </tr>
                                <tr>
                                    <td>Cupholder Condition</td>
                                    <td><OptionText source={EFBNA} value={data.cupholder} /></td>
                                    <td>{data.cupholderIssues} </td>
                                </tr>
                                <tr>
                                    <td>Radio Condition</td>
                                    <td><OptionText source={EFBNA} value={data.radio} /></td>
                                    <td>{data.radioIssues} </td>
                                </tr>
                                <tr>
                                    <td>Navigation Condition</td>
                                    <td><OptionText source={EFBNA} value={data.navigation} /></td>
                                    <td>{data.navigationIssues} </td>
                                </tr>
                                <tr>
                                    <td>Airbags Condition</td>
                                    <td><OptionText source={EFBNA} value={data.airbags} /></td>
                                    <td>{data.airbagsIssues} </td>
                                </tr>
                                <tr>
                                    <td>Seat Belts Condition</td>
                                    <td><OptionText source={EFBNA} value={data.seatBelts} /></td>
                                    <td>{data.seatBeltsIssues} </td>
                                </tr>
                                <tr>
                                    <td>AC Condition</td>
                                    <td><OptionText source={WorkingNA} value={data.ac} /></td>
                                    <td>{data.acIssues} </td>
                                </tr>
                                <tr>
                                    <td>Heater Condition</td>
                                    <td><OptionText source={WorkingNA} value={data.heater} /></td>
                                    <td>{data.heaterIssues} </td>
                                </tr>
                                <tr>
                                    <td>Rear Defroster Condition</td>
                                    <td><OptionText source={WorkingNA} value={data.rearDefroster} /></td>
                                    <td>{data.rearDefrosterIssues} </td>
                                </tr>
                                <tr>
                                    <td>Blower Motor Condition</td>
                                    <td><OptionText source={WorkingNA} value={data.blowerMotor} /></td>
                                    <td>{data.blowerMotorIssues} </td>
                                </tr>
                                <tr>
                                    <td>Seat Heater Condition</td>
                                    <td><OptionText source={WorkingNA} value={data.seatHeater} /></td>
                                    <td>{data.seatHeaterIssues} </td>
                                </tr>
                                <tr>
                                    <td>Carpet Condition</td>
                                    <td><OptionText source={EFBNA} value={data.carpet} /></td>
                                    <td>{data.carpetIssues} </td>
                                </tr>
                                <tr>
                                    <td>Floor Mat Condition</td>
                                    <td><OptionText source={EFBNA} value={data.floorMat} /></td>
                                    <td>{data.floorMatIssues} </td>
                                </tr>
                                <tr>
                                    <td>Cigarette Use</td>
                                    <td><OptionText source={CigaretteUse} value={data.cigaretteUse} /></td>
                                    <td>{data.cigaretteUseIssues} </td>
                                </tr>
                                <tr>
                                    <td>Unusual Odors</td>
                                    <td><OptionText source={UnusualOdors} value={data.unusualOdors} /></td>
                                    <td>{data.unusualOdorsIssues} </td>
                                </tr>
                                <tr>
                                    <td>Comments</td>
                                    <td></td>
                                    <td>{data.interiorNote} </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>

                    <Col md={12}>
                        <table className="table table-bordered tdfirst-nowrap tdsecond-nowrap">
                            <thead>
                                <tr style={{ backgroundColor: "#20c997" }}>
                                    <th colSpan={3} style={{ textAlign: "center" }}>Mechanical</th>
                                </tr>
                                <tr style={{ backgroundColor: "#d5d5d5" }}>
                                    <th>Inspection Point</th>
                                    <th>Condition</th>
                                    <th>Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Key FOB</td>
                                    <td><OptionText source={EFBNA} value={data.keyFOB} /></td>
                                    <td>{data.keyFOBIssues}</td>
                                </tr>
                                <tr>
                                    <td>Computer Diagnosis / OBD2</td>
                                    <td><OptionText source={EFBNA} value={data.computerDiagnosisAndOBD2} /></td>
                                    <td>{data.computerDiagnosisAndOBD2Issues}</td>
                                </tr>
                                <tr>
                                    <td>Battery Condition</td>
                                    <td><OptionText source={EFBNA} value={data.battery} /></td>
                                    <td>{data.batteryIssues}</td>
                                </tr>
                                <tr>
                                    <td>Charging System</td>
                                    <td><OptionText source={EFBNA} value={data.chargingSystem} /></td>
                                    <td>{data.chargingSystemIssues}</td>
                                </tr>
                                <tr>
                                    <td>Alternator Condition</td>
                                    <td><OptionText source={EFBNA} value={data.alternator} /></td>
                                    <td>{data.alternatorIssues}</td>
                                </tr>
                                <tr>
                                    <td>Starter Condition</td>
                                    <td><OptionText source={EFBNA} value={data.starter} /></td>
                                    <td>{data.starterIssues}</td>
                                </tr>
                                <tr>
                                    <td>Belts Condition</td>
                                    <td><OptionText source={EFBNA} value={data.belts} /></td>
                                    <td>{data.beltsIssues}</td>
                                </tr>
                                <tr>
                                    <td>Hoses Condition</td>
                                    <td><OptionText source={EFBNA} value={data.hoses} /></td>
                                    <td>{data.hosesIssues}</td>
                                </tr>
                                <tr>
                                    <td>Air Filter Condition</td>
                                    <td><OptionText source={EFBNA} value={data.airFilter} /></td>
                                    <td>{data.airFilterIssues}</td>
                                </tr>
                                <tr>
                                    <td>Cooling Fan Condition</td>
                                    <td><OptionText source={EFBNA} value={data.coolingFan} /></td>
                                    <td>{data.coolingFanIssues} </td>
                                </tr>
                                <tr>
                                    <td>Radiator Condition and Leaks</td>
                                    <td><OptionText source={EFBNA} value={data.radiatorAndLeaks} /></td>
                                    <td>{data.radiatorAndLeaksIssues} </td>
                                </tr>
                                <tr>
                                    <td>Coolant System And Leaks</td>
                                    <td><OptionText source={EFBNA} value={data.coolantSystemAndLeaks} /></td>
                                    <td>{data.coolantSystemAndLeaksIssues} </td>
                                </tr>
                                <tr>
                                    <td>Power Steering System and Fluid</td>
                                    <td><OptionText source={EFBNA} value={data.powerSteeringSystemAndFluid} /></td>
                                    <td>{data.powerSteeringSystemAndFluidIssues} </td>
                                </tr>
                                <tr>
                                    <td>Power Steering Fluid Leaks</td>
                                    <td><OptionText source={EFBNA} value={data.powerSteeringFluidLeaks} /></td>
                                    <td>{data.powerSteeringFluidLeaksIssues} </td>
                                </tr>
                                <tr>
                                    <td>Engine Block and Oil Level</td>
                                    <td><OptionText source={EFBNA} value={data.engineBlockAndOil} /></td>
                                    <td>{data.engineBlockAndOilIssues} </td>
                                </tr>
                                <tr>
                                    <td>Engine Fluid and Oil Leaks</td>
                                    <td><OptionText source={Leaks} value={data.engineFluidAndOilLeaks} /></td>
                                    <td>{data.engineFluidAndOilLeaksIssues} </td>
                                </tr>
                                <tr>
                                    <td>Transmission System And Fluid</td>
                                    <td><OptionText source={EFBNA} value={data.transmissionSystemAndFluid} /></td>
                                    <td>{data.transmissionSystemAndFluidIssues} </td>
                                </tr>
                                <tr>
                                    <td>Transmission Fluid Leaks</td>
                                    <td><OptionText source={EFBNA} value={data.transmissionFluidLeaks} /></td>
                                    <td>{data.transmissionFluidLeaksIssues} </td>
                                </tr>
                                <tr>
                                    <td>Brake System and Pads</td>
                                    <td><OptionText source={EFBNA} value={data.brakeSystemAndPads} /></td>
                                    <td>{data.brakeSystemAndPadsIssues} </td>
                                </tr>
                                <tr>
                                    <td>Brake Fluid Leaks</td>
                                    <td><OptionText source={Leaks} value={data.brakeFluidLeaks} /></td>
                                    <td>{data.brakeFluidLeaksIssues} </td>
                                </tr>
                                <tr>
                                    <td>Differential System</td>
                                    <td><OptionText source={EFBNA} value={data.differentialSystem} /></td>
                                    <td>{data.differentialSystemIssues} </td>
                                </tr>
                                <tr>
                                    <td>Differential Fluid Leaks</td>
                                    <td><OptionText source={Leaks} value={data.differentialFluidLeaks} /></td>
                                    <td>{data.differentialFluidLeaksIssues} </td>
                                </tr>
                                <tr>
                                    <td>Catalytic Converter</td>
                                    <td><OptionText source={EFBNA} value={data.catalyticConverter} /></td>
                                    <td>{data.catalyticConverterIssues} </td>
                                </tr>
                                <tr>
                                    <td>Muffler System</td>
                                    <td><OptionText source={EFBNA} value={data.mufflerSystem} /></td>
                                    <td>{data.mufflerSystemIssues} </td>
                                </tr>
                                <tr>
                                    <td>CV Joints and Axles</td>
                                    <td><OptionText source={EFBNA} value={data.cvJointsAndAxles} /></td>
                                    <td>{data.cvJointsAndAxlesIssues} </td>
                                </tr>
                                <tr>
                                    <td>Shocks and Struts</td>
                                    <td><OptionText source={EFBNA} value={data.shocksAndStruts} /></td>
                                    <td>{data.shocksAndStrutsIssues} </td>
                                </tr>
                                <tr>
                                    <td>Bushings, Ball Joints, Rods and Arms</td>
                                    <td><OptionText source={EFBNA} value={data.bushingsBallJointsRodsAndArms} /></td>
                                    <td>{data.bushingsBallJointsRodsAndArmsIssues} </td>
                                </tr>
                                <tr>
                                    <td>Undercarriage Condition</td>
                                    <td><OptionText source={EFB} value={data.undercarriage} /></td>
                                    <td>{data.undercarriageIssues} </td>
                                </tr>
                                <tr>
                                    <td>Extras and Upgrades</td>
                                    <td></td>
                                    <td>{data.extrasAndUpgrades} </td>
                                </tr>
                                <tr>
                                    <td>Comments</td>
                                    <td></td>
                                    <td>{data.mechanicalNote} </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>

                    <Col md={12}>
                        <table className="table table-bordered tdfirst-nowrap">
                            <thead>
                                <tr style={{ backgroundColor: "#20c997" }}>
                                    <th colSpan={3} style={{ textAlign: "center" }}>Tires</th>
                                </tr>
                                <tr style={{ backgroundColor: "#d5d5d5" }}>
                                    <th>Tire Detail</th>
                                    <th>Specification</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Driver Front Tire Brand</td>
                                    <td><OptionText source={TireBrand} value={data.driverFrontBrand} /></td>
                                </tr>
                                <tr>
                                    <td>Driver Front Tire Size</td>
                                    <td>{data.driverFrontSize}</td>
                                </tr>
                                <tr>
                                    <td>Driver Front Tire Tread Depth</td>
                                    <td><OptionText source={CommercialTireTreadDepth} value={data.driverFrontTreadDepth} /></td>
                                </tr>
                                <tr>
                                    <td>Driver Front Tire and Wheel Condition</td>
                                    <td><OptionText source={EFBNA} value={data.driverFrontTireAndWheel} /></td>
                                </tr>
                                <tr>
                                    <td>Driver First Axle Inner Tire Brand</td>
                                    <td><OptionText source={TireBrand} value={data.driverFirstAxleInnerBrand} /></td>
                                </tr>
                                <tr>
                                    <td>Driver First Axle Inner Tire Size</td>
                                    <td>{data.driverFirstAxleInnerSize}</td>
                                </tr>
                                <tr>
                                    <td>Driver First Axle Inner Tire Tread Depth</td>
                                    <td><OptionText source={CommercialTireTreadDepth} value={data.driverFirstAxleInnerTreadDepth} /></td>
                                </tr>
                                <tr>
                                    <td>Driver First Axle Inner Tire and Wheel Condition</td>
                                    <td><OptionText source={EFBNA} value={data.driverFirstAxleInnerTireAndWheel} /></td>
                                </tr>
                                <tr>
                                    <td>Driver First Axle Outer Tire Brand</td>
                                    <td><OptionText source={TireBrand} value={data.driverFirstAxleOuterBrand} /></td>
                                </tr>
                                <tr>
                                    <td>Driver First Axle Outer Tire Size</td>
                                    <td>{data.driverFirstAxleOuterSize}</td>
                                </tr>
                                <tr>
                                    <td>Driver First Axle Outer Tire Tread Depth</td>
                                    <td><OptionText source={CommercialTireTreadDepth} value={data.driverFirstAxleOuterTreadDepth} /></td>
                                </tr>
                                <tr>
                                    <td>Driver First Axle Outer Tire and Wheel Condition</td>
                                    <td><OptionText source={EFBNA} value={data.driverFirstAxleOuterTireAndWheel} /></td>
                                </tr>
                                <tr>
                                    <td>Passenger Front Tire Brand</td>
                                    <td><OptionText source={TireBrand} value={data.passengerFrontBrand} /></td>
                                </tr>
                                <tr>
                                    <td>Passenger Front Tire Size</td>
                                    <td>{data.passengerFrontSize}</td>
                                </tr>
                                <tr>
                                    <td>Passenger Front Tire Tread Depth</td>
                                    <td><OptionText source={CommercialTireTreadDepth} value={data.passengerFrontTreadDepth} /></td>
                                </tr>
                                <tr>
                                    <td>Passenger Front Tire and Wheel Condition</td>
                                    <td><OptionText source={EFBNA} value={data.passengerFrontTireAndWheel} /></td>
                                </tr>
                                <tr>
                                    <td>Passenger First Axle Inner Tire Brand</td>
                                    <td><OptionText source={TireBrand} value={data.passengerFirstAxleInnerBrand} /></td>
                                </tr>
                                <tr>
                                    <td>Passenger First Axle Inner Tire Size</td>
                                    <td>{data.passengerFirstAxleInnerSize}</td>
                                </tr>
                                <tr>
                                    <td>Passenger First Axle Inner Tire Tread Depth</td>
                                    <td><OptionText source={CommercialTireTreadDepth} value={data.passengerFirstAxleInnerTreadDepth} /></td>
                                </tr>
                                <tr>
                                    <td>Passenger First Axle Inner Tire and Wheel Condition</td>
                                    <td><OptionText source={EFBNA} value={data.passengerFirstAxleInnerTireAndWheel} /></td>
                                </tr>
                                <tr>
                                    <td>Passenger First Axle Outer Tire Brand</td>
                                    <td><OptionText source={TireBrand} value={data.passengerFirstAxleOuterBrand} /></td>
                                </tr>
                                <tr>
                                    <td>Passenger First Axle Outer Tire Size</td>
                                    <td>{data.passengerFirstAxleOuterSize}</td>
                                </tr>
                                <tr>
                                    <td>Passenger First Axle Outer Tire Tread Depth</td>
                                    <td><OptionText source={CommercialTireTreadDepth} value={data.passengerFirstAxleOuterTreadDepth} /></td>
                                </tr>
                                <tr>
                                    <td>Passenger First Axle Outer Tire and Wheel Condition</td>
                                    <td><OptionText source={EFBNA} value={data.passengerFirstAxleOuterTireAndWheel} /></td>
                                </tr>

                                <tr>
                                    <td>Spare Tire</td>
                                    <td><OptionText source={SpareTire} value={data.spareTire} /></td>
                                </tr>
                                <tr>
                                    <td>Spare Tire Set</td>
                                    <td><OptionText source={SpareTireSet} value={data.spareTireSet} /></td>
                                </tr>
                                <tr>
                                    <td>Comments</td>
                                    <td>{data.tiresNote} </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>

                    <Col md={12}>
                        <table className="table table-bordered tdfirst-nowrap tdsecond-nowrap">
                            <thead>
                                <tr style={{ backgroundColor: "#20c997" }}>
                                    <th colSpan={3} style={{ textAlign: "center" }}>Road Test</th>
                                </tr>
                                <tr style={{ backgroundColor: "#d5d5d5" }}>
                                    <th>Inspection Point</th>
                                    <th>Condition</th>
                                    <th>Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!data.isRoadTestAvailable && <tr>
                                    <td>Road Test Availability</td>
                                    <td>Road Test Not Available</td>
                                    <td><OptionText source={RoadTestAvailabilityReasons} value={data.roadTestAvailabilityReason} /></td>
                                </tr>}
                                {data.isRoadTestAvailable && <> <tr>
                                    <td>Road Test Start Odometer Reading</td>
                                    <td>{data.odometerStartReading}</td>
                                    <td></td>
                                </tr>
                                    <tr>
                                        <td>Road Test End Odometer Reading</td>
                                        <td>{data.odometerEndReading}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Engine Starting Performance</td>
                                        <td><OptionText source={EFBNA} value={data.engineStartingPerformance} /></td>
                                        <td>{data.engineStartingPerformanceIssues}</td>
                                    </tr>
                                    <tr>
                                        <td>Wheel Bearing Performance</td>
                                        <td><OptionText source={EFBNA} value={data.wheelBearingPerformance} /></td>
                                        <td>{data.wheelBearingPerformanceIssues}</td>
                                    </tr>
                                    <tr>
                                        <td>Steering Performance</td>
                                        <td><OptionText source={EFBNA} value={data.steeringPerformance} /></td>
                                        <td>{data.steeringPerformanceIssues}</td>
                                    </tr>
                                    <tr>
                                        <td>Suspension Performance</td>
                                        <td><OptionText source={EFBNA} value={data.suspensionPerformance} /></td>
                                        <td>{data.suspensionPerformanceIssues}</td>
                                    </tr>
                                    <tr>
                                        <td>Transmission Performance</td>
                                        <td><OptionText source={EFBNA} value={data.transmissionPerformance} /></td>
                                        <td>{data.transmissionPerformanceIssues}</td>
                                    </tr>
                                    <tr>
                                        <td>Differential Performance</td>
                                        <td><OptionText source={EFBNA} value={data.differentialPerformance} /></td>
                                        <td>{data.differentialPerformanceIssues}</td>
                                    </tr>
                                    <tr>
                                        <td>Transfer Case Performance</td>
                                        <td><OptionText source={EFBNA} value={data.transferCasePerformance} /></td>
                                        <td>{data.transferCasePerformanceIssues} </td>
                                    </tr>
                                    <tr>
                                        <td>Braking Performance</td>
                                        <td><OptionText source={EFBNA} value={data.brakingPerformance} /></td>
                                        <td>{data.brakingPerformanceIssues} </td>
                                    </tr>
                                    <tr>
                                        <td>Interior Noise and Isolation Performance</td>
                                        <td><OptionText source={EFBNA} value={data.interiorNoisePerformance} /></td>
                                        <td>{data.interiorNoisePerformanceIssues} </td>
                                    </tr>
                                    <tr>
                                        <td>Overall Engine Performance</td>
                                        <td><OptionText source={EFBNA} value={data.overallEnginePerformance} /></td>
                                        <td>{data.overallEnginePerformanceIssues} </td>
                                    </tr>
                                    <tr>
                                        <td>Automatic Start Stop</td>
                                        <td><OptionText source={EFBNA} value={data.automaticStartStop} /></td>
                                        <td>{data.automaticStartStopIssues} </td>
                                    </tr>
                                    <tr>
                                        <td>Cruise Control</td>
                                        <td><OptionText source={WorkingNA} value={data.cruiseControl} /></td>
                                        <td>{data.cruiseControlIssues} </td>
                                    </tr>
                                    <tr>
                                        <td>Blind Spot Detection</td>
                                        <td><OptionText source={WorkingNA} value={data.blindSpotDetection} /></td>
                                        <td>{data.blindSpotDetectionIssues} </td>
                                    </tr>
                                    <tr>
                                        <td>Lane Assist</td>
                                        <td><OptionText source={WorkingNA} value={data.laneAssist} /></td>
                                        <td>{data.laneAssistIssues} </td>
                                    </tr>

                                    <tr>
                                        <td>Hill Start Assist</td>
                                        <td><OptionText source={WorkingNA} value={data.hillStartAssist} /></td>
                                        <td>{data.hillStartAssistIssues} </td>
                                    </tr>

                                    <tr>
                                        <td>Parking Assist</td>
                                        <td><OptionText source={WorkingNA} value={data.parkingAssist} /></td>
                                        <td>{data.parkingAssistIssues} </td>
                                    </tr>
                                    <tr>
                                        <td>Back-Up Camera Condition</td>
                                        <td><OptionText source={WorkingNA} value={data.backUpCamera} /></td>
                                        <td>{data.backUpCameraIssues} </td>
                                    </tr>
                                    <tr>
                                        <td>Auto Pilot Assist</td>
                                        <td><OptionText source={WorkingNA} value={data.autoPilotAssist} /></td>
                                        <td>{data.autoPilotAssistIssues} </td>
                                    </tr>
                                    <tr>
                                        <td>Comments</td>
                                        <td></td>
                                        <td>{data.roadTestNote} </td>
                                    </tr>
                                </>}
                            </tbody>
                        </table>
                    </Col>
                    <Col md={12}>
                        <table className="table table-bordered">
                            <thead>
                                <tr style={{ backgroundColor: "#20c997" }}>
                                    <th colSpan={3} style={{ textAlign: "center" }}>Summary</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{data.summary}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>}
        </>
    )
}

export default CommercialInspectionReport;