import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { Roles, InspectionSection } from "../../../../store/Utility/Utility";
import axios from "../../../../api/axios";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import HerosectionHeader from "../../../../Componet/Herosection/HerosectionHeader";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import useAutosave from "../../../../hooks/useAtuosave";
import OtherInput from "../../../../Componet/OtherInput";
import { isNullOrEmpty } from "../../../../store/Utility/Helper";
import AutoCompleteInput from "../../../../Componet/Autocomplete/AutoCompleteInput";
import SpinnerButton from "../../../../Componet/Button/SpinnerButton";
import {
    EFB, Dings, EFBNA, Leaks, SpareTire, SpareTireSet, Scratches, MotorcycleTireBrand, MotorcycleTireTreadDepth,
    MotorcycleEngineSize, MotorcycleFuelType, MotorcycleEngineType, MotorcycleTransmissionType
} from "../../../../store/Utility/Data";
import Options from "../../../../Componet/Option/Options";
import { useDropzone } from "react-dropzone";
import ImageArea from "../../../../Componet/Image/ImageArea";
import { QuotationStatus } from "../../../../store/Utility/Status";




const MotorcycleOrder = () => {
    let { id } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const [data, setData] = useState({});
    const [originalData, setOriginalData] = useState({});
    const [loading, setLoading] = useState(false);
    const [modal, showModal] = useState({ show: false, message: null, isError: null });
    const [approvalModal, setApprovalModal] = useState({ show: false, message: null, isError: null });
    const [saveModal, setSaveModal] = useState({ show: false });
    const [cancelModal, setCancelModal] = useState({ show: false });
    const [makes, setMake] = useState();
    const [models, setModel] = useState();
    const [validationError, setValidationError] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [disableUploadItem, setDisableUploadItem] = useState(true);
    const navigate = useNavigate();

    function updateFields(fields) {
        setData(prev => {
            return { ...prev, ...fields }
        })
    }

    useEffect(() => {
        const getMakes = async () => {
            const response = await axios.get(`v1/make`).then(res => {
                setMake(res.data.data);
            });
        }
        getMakes();
    }, []);

    const getModelByMakeId = async (makeId) => {
        const response = await axios.get(`v1/model/${makeId}`).then(res => {
            setModel(res.data.data);
        });
    }

    const onMakeSelect = (clickedItem) => {
        updateFields({ makeId: clickedItem.id, makeInfo: clickedItem.name });
        updateFields({ modelInfo: "" });
        getModelByMakeId(clickedItem.id);
    }

    const onModelSelect = (clickedItem) => {
        updateFields({ modelId: clickedItem.id, modelInfo: clickedItem.name });
    }

    const getMotorcycleInspection = async () => {
        await axiosPrivate.get(`v1/inspection/motorcycle/${id}`).then(res => {
            setData(res.data.data);
            if (res.data.data.makeId) {
                getModelByMakeId(res.data.data.makeId);
            }
            getInspectionFiles();
        }).catch(error => {
        });
    }

    useEffect(() => {
        getMotorcycleInspection();
    }, [])

    function isOther(value) {
        if (value == undefined || value == null) return false;
        return value.toLowerCase() == "other";
    }

    function isFairOrBad(value) {
        return value == "fair" || value == "bad";
    }

    function UpdateWithSelection(e) {
        updateFields({ [e.target.name]: e.target.value });
    }

    const handleClose = () => showModal({ show: false, message: null, isSuccess: null });

    const handleRedirectDashboard = () => {
        showModal({ show: false, message: null, isSuccess: null });
        navigate('/');
    }

    const validateFormItem = (inputValues) => {
        let errors = {};
        if (isNullOrEmpty(inputValues.makeId)) errors.makeId = "Select make";

        if (isNullOrEmpty(inputValues.modelId)) errors.modelId = "Select model";

        if (isNullOrEmpty(inputValues.year)) errors.year = "Select year";

        if (isNullOrEmpty(inputValues.mileage)) errors.mileage = "Enter mileage";

        if (isNullOrEmpty(inputValues.licensePlate)) errors.licensePlate = "Enter License Plate";

        if (isNullOrEmpty(inputValues.vin)) errors.vin = "Enter VIN";

        if (isNullOrEmpty(inputValues.transmissionType)) errors.transmissionType = "Select transmission type";
        if (isOther(inputValues.transmissionType) && isNullOrEmpty(inputValues.transmissionTypeInfo)) errors.transmissionTypeInfo = "Enter transmission type";

        if (isNullOrEmpty(inputValues.engineType)) errors.engineType = "Select engine type";
        if (isOther(inputValues.engineType) && isNullOrEmpty(inputValues.engineTypeInfo)) errors.engineTypeInfo = "Enter engine type";

        if (isNullOrEmpty(inputValues.fuelType)) errors.fuelType = "Select fuel type";
        if (isOther(inputValues.fuelType) && isNullOrEmpty(inputValues.fuelTypeInfo)) errors.fuelTypeInfo = "Enter fuel type";

        if (isNullOrEmpty(inputValues.engineSize)) errors.engineSize = "Select engine size";
        if (isOther(inputValues.engineSize) && isNullOrEmpty(inputValues.engineSizeInfo)) errors.engineSizeInfo = "Enter engine size";

        if (isNullOrEmpty(inputValues.vehicleInfoNote)) errors.vehicleInfoNote = "Comment about vehicle";


        if (isNullOrEmpty(inputValues.bodyAlignment)) errors.bodyAlignment = "Select body alignment";
        if (isFairOrBad(inputValues.bodyAlignment) && isNullOrEmpty(inputValues.bodyAlignmentIssues)) errors.bodyAlignmentIssues = "Enter body alignment issues";

        if (isNullOrEmpty(inputValues.paint)) errors.paint = "Select paint";
        if (isFairOrBad(inputValues.paint) && isNullOrEmpty(inputValues.paintIssues)) errors.paintIssues = "Enter paint issues";

        if (isNullOrEmpty(inputValues.frame)) errors.frame = "Select frame";
        if (isFairOrBad(inputValues.frame) && isNullOrEmpty(inputValues.frameIssues)) errors.frameIssues = "Enter frame issues";

        if (isNullOrEmpty(inputValues.exhaustSystem)) errors.exhaustSystem = "Select exhaust system";
        if (isFairOrBad(inputValues.exhaustSystem) && isNullOrEmpty(inputValues.exhaustSystemIssues)) errors.exhaustSystemIssues = "Enter exhaust system issues";

        if (isNullOrEmpty(inputValues.seats)) errors.seats = "Select seats";
        if (isFairOrBad(inputValues.seats) && isNullOrEmpty(inputValues.seatsIssues)) errors.seatsIssues = "Enter seats issues";

        if (isNullOrEmpty(inputValues.grips)) errors.grips = "Select grips";
        if (isFairOrBad(inputValues.grips) && isNullOrEmpty(inputValues.gripsIssues)) errors.gripsIssues = "Enter grips issues";

        if (isNullOrEmpty(inputValues.scratches)) errors.scratches = "Select scratches";
        if (isFairOrBad(inputValues.scratches) && isNullOrEmpty(inputValues.scratchesIssues)) errors.scratchesIssues = "Enter scratches issues";

        if (isNullOrEmpty(inputValues.dingsAndDents)) errors.dingsAndDents = "Select dings and dents";
        if (isFairOrBad(inputValues.dingsAndDents) && isNullOrEmpty(inputValues.dingsAndDentsIssues)) errors.dingsAndDentsIssues = "Enter dings and dents issues";

        if (isNullOrEmpty(inputValues.sideMirrors)) errors.sideMirrors = "Select side mirrors";
        if (isFairOrBad(inputValues.sideMirrors) && isNullOrEmpty(inputValues.sideMirrorsIssues)) errors.sideMirrorsIssues = "Enter side mirrors issues";

        if (isNullOrEmpty(inputValues.exteriorLights)) errors.exteriorLights = "Select exterior lights";
        if (isFairOrBad(inputValues.exteriorLights) && isNullOrEmpty(inputValues.exteriorLightsIssues)) errors.exteriorLightsIssues = "Enter exterior lights issues";

        if (isNullOrEmpty(inputValues.exteriorNote)) errors.exteriorNote = "Comment about exterior condition";

        if (isNullOrEmpty(inputValues.battery)) errors.battery = "Select battery";
        if (isFairOrBad(inputValues.battery) && isNullOrEmpty(inputValues.batteryIssues)) errors.batteryIssues = "Enter battery issues";

        if (isNullOrEmpty(inputValues.chargingSystem)) errors.chargingSystem = "Select charging system";
        if (isFairOrBad(inputValues.chargingSystem) && isNullOrEmpty(inputValues.chargingSystemIssues)) errors.chargingSystemIssues = "Enter charging system issues";

        if (isNullOrEmpty(inputValues.chainOrBelts)) errors.chainOrBelts = "Select chain or belts";
        if (isFairOrBad(inputValues.chainOrBelts) && isNullOrEmpty(inputValues.chainOrBeltsIssues)) errors.chainOrBeltsIssues = "Enter chain or belts issues";

        if (isNullOrEmpty(inputValues.hoses)) errors.hoses = "Select hoses";
        if (isFairOrBad(inputValues.hoses) && isNullOrEmpty(inputValues.hosesIssues)) errors.hosesIssues = "Enter hoses issues";

        if (isNullOrEmpty(inputValues.enginePerformance)) errors.enginePerformance = "Select engine performance";
        if (isFairOrBad(inputValues.enginePerformance) && isNullOrEmpty(inputValues.enginePerformanceIssues)) errors.enginePerformanceIssues = "Enter engine performance issues";

        if (isNullOrEmpty(inputValues.airFilter)) errors.airFilter = "Select air filter";
        if (isFairOrBad(inputValues.airFilter) && isNullOrEmpty(inputValues.airFilterIssues)) errors.airFilterIssues = "Enter air filter issues";

        if (isNullOrEmpty(inputValues.mufflerSystem)) errors.mufflerSystem = "Select muffler system";
        if (isFairOrBad(inputValues.mufflerSystem) && isNullOrEmpty(inputValues.mufflerSystemIssues)) errors.mufflerSystemIssues = "Enter muffler system issues";

        if (isNullOrEmpty(inputValues.shocks)) errors.shocks = "Select shocks";
        if (isFairOrBad(inputValues.shocks) && isNullOrEmpty(inputValues.shocksIssues)) errors.shocksIssues = "Enter shocks issues";

        if (isNullOrEmpty(inputValues.engineFluidLeaks)) errors.engineFluidLeaks = "Select engine fluid leaks";
        if (isFairOrBad(inputValues.engineFluidLeaks) && isNullOrEmpty(inputValues.engineFluidLeaksIssues)) errors.engineFluidLeaksIssues = "Enter engine fluid leaks issues";

        if (isNullOrEmpty(inputValues.transmissionFluidLeaks)) errors.transmissionFluidLeaks = "Select transmission fluid leaks";
        if (isFairOrBad(inputValues.transmissionFluidLeaks) && isNullOrEmpty(inputValues.transmissionFluidLeaksIssues)) errors.transmissionFluidLeaksIssues = "Enter transmission fluid leaks issues";

        if (isNullOrEmpty(inputValues.brakeSystemAndPads)) errors.brakeSystemAndPads = "Select brake system and pads";
        if (isFairOrBad(inputValues.brakeSystemAndPads) && isNullOrEmpty(inputValues.brakeSystemAndPadsIssues)) errors.brakeSystemAndPadsIssues = "Enter brake system and pads issues";

        if (isNullOrEmpty(inputValues.brakeFluidLeaks)) errors.brakeFluidLeaks = "Select brake fluid leaks";
        if (isFairOrBad(inputValues.brakeFluidLeaks) && isNullOrEmpty(inputValues.brakeFluidLeaksIssues)) errors.brakeFluidLeaksIssues = "Enter brake fluid leaks issues";

        if (isNullOrEmpty(inputValues.mechanicalNote)) errors.mechanicalNote = "Comment about mechanical condition";

        if (isNullOrEmpty(inputValues.frontBrand)) errors.frontBrand = "Select front brand";

        if (isNullOrEmpty(inputValues.frontSize)) errors.frontSize = "Select front size";

        if (isNullOrEmpty(inputValues.frontTreadDepth)) errors.frontTreadDepth = "Select front tread depth";

        if (isNullOrEmpty(inputValues.frontTireAndWheel)) errors.frontTireAndWheel = "Select front tire and wheel";

        if (isNullOrEmpty(inputValues.rearBrand)) errors.rearBrand = "Select rear brand";

        if (isNullOrEmpty(inputValues.rearSize)) errors.rearSize = "Select rear size";

        if (isNullOrEmpty(inputValues.rearTreadDepth)) errors.rearTreadDepth = "Select rear tread depth";

        if (isNullOrEmpty(inputValues.rearTireAndWheel)) errors.rearTireAndWheel = "Select rear tire and wheel";

        if (isNullOrEmpty(inputValues.spareTire)) errors.spareTire = "Select spare tire";

        if (isNullOrEmpty(inputValues.spareTireSet)) errors.spareTireSet = "Select spare tire set";

        if (isNullOrEmpty(inputValues.tiresNote)) errors.tiresNote = "Comment about tires note";

        if (isNullOrEmpty(inputValues.summary)) errors.summary = "Complete a summary of inspection";


        const groupedCounts = uploadedFiles.reduce((accumulator, currentValue) => {
            // Extract the key for grouping
            const key = currentValue.subCategory;

            // Initialize the key in the accumulator if it doesn't exist
            if (!accumulator[key]) {
                accumulator[key] = 0;
            }

            // Increment the count for the key
            accumulator[key] += 1;

            return accumulator;
        }, {});

        if (Object.keys(groupedCounts).length == 0) {
            errors.files = "Please upload Files";
        }
        // if (groupedCounts.VehicleInfo == undefined || groupedCounts.VehicleInfo < 5) {
        //     errors.VehicleInfo = "At least 5 photo should be uploaded for Vehicle Info";
        // }
        // if (groupedCounts.Exterior == undefined || groupedCounts.Exterior < 10) {
        //     errors.Exterior = "At least 10 photo should be uploaded for Exterior";
        // }
        // if (groupedCounts.Interior == undefined || groupedCounts.Interior < 10) {
        //     errors.Interior = "At least 10 photo should be uploaded for Interior";
        // }
        // if (groupedCounts.Mechanical == undefined || groupedCounts.Mechanical < 7) {
        //     errors.Mechanical = "At least 7 photo should be uploaded for Mechanical";
        // }
        // if (groupedCounts.Tires == undefined || groupedCounts.Tires < 4) {
        //     errors.Tires = "At least 4 photo should be uploaded for Tires";
        // }

        return errors;
    };

    const getInspectionFiles = useCallback(async () => {
        axiosPrivate.get(`v1/file/${id}`).then(res => {
            setUploadedFiles(res.data.data);
        }).catch(error => {
            console.error('Error:', error);
        });;
    }, [uploadedFiles])

    const onUploadFile = async (section) => {
        const formData = new FormData();

        if (section == InspectionSection.VehicleInfo) {
            if (vehicleInfoAcceptedFiles.length == 0) return;
            vehicleInfoAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.Exterior) {
            if (exteriorAcceptedFiles.length == 0) return;
            exteriorAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.Mechanical) {
            if (mechanicalAcceptedFiles.length == 0) return;
            mechanicalAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.Tires) {
            if (tiresAcceptedFiles.length == 0) return;
            tiresAcceptedFiles.map(file => formData.append('files', file));
        } else if (section == InspectionSection.Summary) {
            if (summaryAcceptedFiles.length == 0) return;
            summaryAcceptedFiles.map(file => formData.append('files', file));
        }
        else {
            return;
        }

        formData.append('relationId', id);
        formData.append('subCategory', section);
        setUploadLoading(true);
        const response = await axiosPrivate.post(`v1/file/upload`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            setUploadLoading(false);
            getInspectionFiles();
            removeFiles(section);
            // setVehicleInfoFile(res.data.data);
        }).catch(error => {
            setUploadLoading(false);
        });
    }

    const handleRemoveImage = async (id) => {
        var deleteItem = uploadedFiles.find(t => t.id == id);
        if (!deleteItem) return;
        const response = await axiosPrivate.post(`v1/file/delete`, { id: id, uniqueName: deleteItem.uniqueName, extension: deleteItem.extension }).then(res => {
            getInspectionFiles();
        }).catch(error => {

        });
    }

    const onSubmitReport = async () => {
        setLoading(true);
        var error = validateFormItem(data);

        if (Object.keys(error).length > 0) {
            setLoading(false);
            setValidationError(error);
            return;
        }

        const response = await axiosPrivate.post(`v1/inspection/motorcycle`, { ...data, quotationId: id, isSubmitReport: true }).then(res => {
            setLoading(false);
            showModal({ show: true, message: "Your application successfully submitted!", isError: false });
        }).catch(error => {
            setLoading(false);
            showModal({ show: true, message: "An error occurred while submitting your report. Please try again. If the problem persists, contact support.", isError: true });
        });
    }

    const handleRedirectToOrders = () => {
        showModal({ show: false, message: null, isSuccess: null });
        navigate('/orders');
    }

    const handleApproveReport = async () => {
        setLoading(true);
        const response = await axiosPrivate.post(`v1/quotation/approve/${id}`).then(res => {
            setLoading(false);
            showModal({ show: true, header: 'Approve Result', message: "Report successfully approved", isError: false });
        }).catch(error => {
            setLoading(false);
            showModal({ show: true, message: "An error occurred while approving report", isError: true });
        });

    }

    const handleCancelConfirm = async (e) => {
        setData(originalData);
        setIsEdit(false);
        setCancelModal({ show: false, message: null });

    }


    const removeFiles = (section) => {
        if (section == InspectionSection.VehicleInfo) {
            vehicleInfoAcceptedFiles.length = 0;
            vehicleInfoAcceptedFiles.splice(0, vehicleInfoAcceptedFiles.length);
        }
        if (section == InspectionSection.Exterior) {
            exteriorAcceptedFiles.length = 0;
            exteriorAcceptedFiles.splice(0, exteriorAcceptedFiles.length);
        }
        if (section == InspectionSection.Mechanical) {
            mechanicalAcceptedFiles.length = 0;
            mechanicalAcceptedFiles.splice(0, mechanicalAcceptedFiles.length);
        }
        if (section == InspectionSection.Tires) {
            tiresAcceptedFiles.length = 0;
            tiresAcceptedFiles.splice(0, tiresAcceptedFiles.length);
        }
        if (section == InspectionSection.Summary) {
            summaryAcceptedFiles.length = 0;
            summaryAcceptedFiles.splice(0, summaryAcceptedFiles.length);
        }
    }




    const { acceptedFiles: vehicleInfoAcceptedFiles, getRootProps: getVehicleInfoRootProps, getInputProps: getVehicleInfoInputProps, isDragActive: isVehicleDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (vehicleInfoAcceptedFiles) => {
        },
    });

    const { acceptedFiles: exteriorAcceptedFiles, getRootProps: getExteriorRootProps, getInputProps: getExteriorInputProps, isDragActive: isExteriorDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (exteriorAcceptedFiles) => {
        },
    });


    const { acceptedFiles: mechanicalAcceptedFiles, getRootProps: getMechanicalRootProps, getInputProps: getMechanicalInputProps, isDragActive: isMechanicalDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (mechanicalAcceptedFiles) => {
        },
    });

    const { acceptedFiles: tiresAcceptedFiles, getRootProps: getTiresRootProps, getInputProps: getTiresInputProps, isDragActive: isTiresDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (tiresAcceptedFiles) => {
        },
    });


    const { acceptedFiles: summaryAcceptedFiles, getRootProps: getSummaryRootProps, getInputProps: getSummaryInputProps, isDragActive: isSummaryDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (summaryAcceptedFiles) => {
        },
    });

    return (
        <div>
            <div className="page-wrapper">
                <HerosectionHeader name={"Review Inspection"} />
                <div className="page-content"></div>
                {<Modal show={modal.show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{modal.header ? modal.header : !modal.isError ? 'Report Modified' : 'Error'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modal.message}</Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-dark" onClick={!modal.isError ? handleRedirectToOrders : handleClose}>Close</button>
                    </Modal.Footer>
                </Modal>}

                {<Modal show={cancelModal.show} onHide={() => setCancelModal({ show: false, message: null })} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Dismiss Changes</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>Are you sure you want to cancel? Any unsaved changes will be lost.</Modal.Body>
                    <Modal.Footer>
                        <Col>
                            <Row>
                                <Col md={6}></Col>
                                <Col md={3}><button className="btn btn-dark" onClick={() => setCancelModal({ show: false, message: null })}>No</button></Col>
                                <Col md={3}>
                                    {/* <button type="submit" className="btn btn-warning">Confirm</button> */}
                                    <SpinnerButton text="Yes" onClick={handleCancelConfirm} className="btn btn-danger" loading={loading} />
                                </Col>
                            </Row>
                        </Col>
                    </Modal.Footer>

                </Modal>}
                {<Modal show={saveModal.show} onHide={() => setSaveModal({ show: false, message: null })} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Save Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you want to save the changes to this report? Ensure all information is correct before saving.</Modal.Body>
                    <Modal.Footer>
                        <Col>
                            <Row>
                                <Col md={5}></Col>
                                <Col md={3}><button className="btn btn-dark" onClick={() => setSaveModal({ show: false, message: null })}>Cancel</button></Col>
                                <Col md={4}>
                                    {/* <button type="submit" className="btn btn-warning">Confirm</button> */}
                                    <SpinnerButton onClick={onSubmitReport} text="Save" className="btn btn-danger" loading={loading} />
                                </Col>
                            </Row>
                        </Col>
                    </Modal.Footer>
                </Modal>}

                {<Modal show={approvalModal.show} onHide={() => setApprovalModal({ show: false, message: null })} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Approve Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{approvalModal.message}</Modal.Body>
                    <Modal.Footer>
                        <Col>
                            <Row>
                                <Col md={5}></Col>
                                <Col md={3}><button className="btn btn-dark" onClick={() => setApprovalModal({ show: false, message: null })}>Cancel</button></Col>
                                <Col md={4}>
                                    {/* <button type="submit" className="btn btn-warning">Confirm</button> */}
                                    <SpinnerButton onClick={handleApproveReport} text="Approve" className="btn btn-danger" loading={loading} />
                                </Col>
                            </Row>
                        </Col>
                    </Modal.Footer>
                </Modal>}
                <section>
                    <Container>
                        <Row >
                            <Col lg={12} md={12}>
                                <div className="checkout-form border p-4 rounded">
                                    <h2 className="mb-4">Review - {data.orderNumber ? data.orderNumber : "N/A"}</h2>
                                    <div className="col-lg-12 col-12">
                                    <p className="lead mb-5">Please carefully review the report before approving it, ensuring all information is correct and free of spelling or logical errors. Double-check the VIN and license plate against the pictures provided before ordering the CARFAX report, if ordered</p>
                                        <div class="accordion" id="accordion">
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVehicleInfo" aria-expanded="true" aria-controls="collapseOne">
                                                        Vehicle Info
                                                    </button>
                                                </h2>
                                                <div id="collapseVehicleInfo" class="accordion-collapse border-0 collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="Make">Make</Label>
                                                                            <AutoCompleteInput
                                                                                name="makeId"
                                                                                label="Make"
                                                                                placeholder="Make"
                                                                                value={data.makeInfo}
                                                                                options={makes}
                                                                                onSuggestionClick={onMakeSelect}
                                                                                handleChange={(event) => updateFields({ makeInfo: event.target.value })}
                                                                                disabled={!isEdit}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="modelId">Model</Label>
                                                                            <AutoCompleteInput placeholder="Model" disabled={!isEdit} value={data.modelInfo} options={models} onSuggestionClick={onModelSelect} handleChange={(event) => updateFields({ modelInfo: event.target.value })} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>

                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="Year">Year</Label>
                                                                            <Input
                                                                                type="text"
                                                                                id="Year"
                                                                                name="Year"
                                                                                value={data.year}
                                                                                onChange={e => updateFields({ year: e.target.value })}
                                                                                disabled={!isEdit}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="mileage" size="xs">Mileage</Label>
                                                                            <Input
                                                                                type="text"
                                                                                id="mileage"
                                                                                name="mileage"
                                                                                value={data.mileage}
                                                                                onChange={e => updateFields({ mileage: e.target.value })}
                                                                                disabled={!isEdit}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="licensePlate">License Plate</Label>
                                                                            <Input
                                                                                type="text"
                                                                                id="licensePlate"
                                                                                name="License Plate"
                                                                                value={data.licensePlate}
                                                                                onChange={e => updateFields({ licensePlate: e.target.value })}
                                                                                disabled={!isEdit}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="vin">VIN</Label>
                                                                            <Input
                                                                                type="text"
                                                                                id="vin"
                                                                                name="vin"
                                                                                value={data.vin}
                                                                                onChange={e => updateFields({ vin: e.target.value })}
                                                                                disabled={!isEdit}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="transmissionType">Transmission Type</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="transmissionType"
                                                                                name="transmissionType"
                                                                                value={data.transmissionType}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={MotorcycleTransmissionType} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isOther(data.transmissionType) && <OtherInput disabled={!isEdit} text="Transmission Type Info" name="transmissionTypeInfo" value={data.transmissionTypeInfo} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="engineType">Engine Type</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="engineType"
                                                                                name="engineType"
                                                                                value={data.engineType}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={MotorcycleEngineType} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isOther(data.engineType) && <OtherInput disabled={!isEdit} text="Engine Type Info" name="engineTypeInfo" value={data.engineTypeInfo} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="fuelType">Fuel Type</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="fuelType"
                                                                                name="fuelType"
                                                                                value={data.fuelType}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={MotorcycleFuelType} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isOther(data.fuelType) && <OtherInput disabled={!isEdit} text="Fuel Type Info" name="fuelTypeInfo" value={data.fuelTypeInfo} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="engineSize">Engine Size</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="engineSize"
                                                                                name="engineSize"
                                                                                value={data.engineSize}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={MotorcycleEngineSize} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isOther(data.engineSize) && <OtherInput disabled={!isEdit} text="Engine Size Info" name="engineSizeInfo" value={data.engineSizeInfo} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="vehicleInfoNote">Comments about vehicle info</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="vehicleInfoNote"
                                                                        name="vehicleInfoNote"
                                                                        value={data.vehicleInfoNote}
                                                                        onChange={e => updateFields({ vehicleInfoNote: e.target.value })}
                                                                        disabled={!isEdit}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="vehicleInfoFileInput">Prior to approval, please review all uploaded images to ensure they meet our quality standards, including clarity, detail, and relevance to the inspection report.</Label></Col>
                                                            {(!disableUploadItem && isEdit) &&
                                                                <>
                                                                    <Col md={10}>
                                                                        <FormGroup>
                                                                            <div name="vehicleInfoFileInput" className="dropzone" {...getVehicleInfoRootProps()}>
                                                                                <input type="file" {...getVehicleInfoInputProps()} />
                                                                                {
                                                                                    vehicleInfoAcceptedFiles.length > 0 ? <p>{vehicleInfoAcceptedFiles.length} file selected</p> :
                                                                                        isVehicleDragActive ?
                                                                                            <p>Drop the files here ...</p> :
                                                                                            <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                                }
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <SpinnerButton onClick={() => onUploadFile(InspectionSection.VehicleInfo)} disabled={vehicleInfoAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Vehicle Info Pictures" loading={uploadLoading} />
                                                                    </Col>
                                                                </>
                                                            }
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.VehicleInfo).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} disableDelete={!isEdit ? true : disableUploadItem} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingTwo">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Motorcycle
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="bodyAlignment" size="xs">Body Alignment Condition</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="bodyAlignment"
                                                                                name="bodyAlignment"
                                                                                className="form-control"
                                                                                value={data.bodyAlignment}
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.bodyAlignment) && <OtherInput disabled={!isEdit} text="Body Allignment Issues" name="bodyAlignmentIssues" value={data.bodyAlignmentIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="paint">Paint Condition</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="paint"
                                                                                name="paint"
                                                                                className="form-control"
                                                                                value={data.paint}
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>{isFairOrBad(data.paint) && <OtherInput disabled={!isEdit} text="Paint Issues" name="paintIssues" value={data.paintIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="frame" size="xs">Frame Condition</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="frame"
                                                                                name="frame"
                                                                                className="form-control"
                                                                                value={data.frame}
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.frame) && <OtherInput disabled={!isEdit} text="Frame Issues" name="frameIssues" value={data.frameIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="exhaustSystem">Exhaust System Condition</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="exhaustSystem"
                                                                                name="exhaustSystem"
                                                                                value={data.exhaustSystem}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>{isFairOrBad(data.exhaustSystem) && <OtherInput disabled={!isEdit} text="Exhaust System Issues" name="exhaustSystemIssues" value={data.exhaustSystemIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="seats" size="xs">Seat Condition</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="seats"
                                                                                name="seats"
                                                                                value={data.seats}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.seats) && <OtherInput disabled={!isEdit} text="Seats Issues" name="seatsIssues" value={data.seatsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="grips">Grips</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="grips"
                                                                                name="grips"
                                                                                value={data.grips}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.grips) && <OtherInput disabled={!isEdit} text="Grips Issues" name="gripsIssues" value={data.gripsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="scratches" size="xs">Scratches</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="scratches"
                                                                                name="scratches"
                                                                                value={data.scratches}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Scratches} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.scratches) && <OtherInput disabled={!isEdit} text="Scratches Issues" name="scratchesIssues" value={data.scratchesIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="dingsAndDents">Dings & Dents</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="dingsAndDents"
                                                                                name="dingsAndDents"
                                                                                value={data.dingsAndDents}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Dings} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.dingsAndDents) && <OtherInput disabled={!isEdit} text="Dings and Dents Issues" name="dingsAndDentsIssues" value={data.dingsAndDentsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="sideMirrors" size="xs">Side Mirrors Condition</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="sideMirrors"
                                                                                name="sideMirrors"
                                                                                value={data.sideMirrors}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.sideMirrors) && <OtherInput disabled={!isEdit} text="Side Mirrors Issues" name="sideMirrorsIssues" value={data.sideMirrorsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="exteriorLights">Exterior Lights Condition</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="exteriorLights"
                                                                                name="exteriorLights"
                                                                                value={data.exteriorLights}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFB} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.exteriorLights) && <OtherInput disabled={!isEdit} text="Exterior Lights Issues" name="exteriorLightsIssues" value={data.exteriorLightsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="exteriorNote">Comments about condition of motorcycle</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="exteriorNote"
                                                                        name="exteriorNote"
                                                                        value={data.exteriorNote}
                                                                        onChange={e => updateFields({ exteriorNote: e.target.value })}
                                                                        disabled={!isEdit}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="exteriorFileInput">Prior to approval, please review all uploaded images to ensure they meet our quality standards, including clarity, detail, and relevance to the inspection report.</Label></Col>
                                                            {(!disableUploadItem && isEdit) &&
                                                                <>
                                                                    <Col md={10}>
                                                                        <FormGroup>
                                                                            <div name="exteriorFileInput" className="dropzone" {...getExteriorRootProps()}>
                                                                                <input type="file" {...getExteriorInputProps()} />
                                                                                {
                                                                                    exteriorAcceptedFiles.length > 0 ? <p>{exteriorAcceptedFiles.length} file selected</p> :
                                                                                        isExteriorDragActive ?
                                                                                            <p>Drop the files here ...</p> :
                                                                                            <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                                }
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <SpinnerButton onClick={() => onUploadFile(InspectionSection.Exterior)} disabled={exteriorAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Exterior Pictures" loading={uploadLoading} />
                                                                    </Col>
                                                                </>}
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.Exterior).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} disableDelete={!isEdit ? true : disableUploadItem} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Mechanical
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse border-0 collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="battery">Battery Condition</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="battery"
                                                                                name="battery"
                                                                                value={data.battery}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.battery) && <OtherInput disabled={!isEdit} text="Battery Issues" name="batteryIssues" value={data.batteryIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="chargingSystem">Charging System</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="chargingSystem"
                                                                                name="chargingSystem"
                                                                                value={data.chargingSystem}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.chargingSystem) && <OtherInput disabled={!isEdit} text="Charging System Issues" name="chargingSystemIssues" value={data.chargingSystemIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="chainOrBelts" size="xs">Chain or Belts Condition</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="chainOrBelts"
                                                                                name="chainOrBelts"
                                                                                value={data.chainOrBelts}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.chainOrBelts) && <OtherInput disabled={!isEdit} text="Chain or Belts Issues" name="chainOrBeltsIssues" value={data.chainOrBeltsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="hoses">Hoses Condition</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="hoses"
                                                                                name="hoses"
                                                                                value={data.hoses}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.hoses) && <OtherInput disabled={!isEdit} text="Hoses Issues" name="hosesIssues" value={data.hosesIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="enginePerformance" size="xs">Engine Performance</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="enginePerformance"
                                                                                name="enginePerformance"
                                                                                value={data.enginePerformance}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.enginePerformance) && <OtherInput disabled={!isEdit} text="Engine Performance Issues" name="enginePerformanceIssues" value={data.enginePerformanceIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="airFilter" size="xs">Air Filter Condition</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="airFilter"
                                                                                name="airFilter"
                                                                                value={data.airFilter}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.airFilter) && <OtherInput disabled={!isEdit} text="Air Filter Issues" name="airFilterIssues" value={data.airFilterIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="mufflerSystem" size="xs">Muffler System</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="mufflerSystem"
                                                                                name="mufflerSystem"
                                                                                value={data.mufflerSystem}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.mufflerSystem) && <OtherInput disabled={!isEdit} text="Muffler System Issues" name="mufflerSystemIssues" value={data.mufflerSystemIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="shocks" size="xs">Shocks</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="shocks"
                                                                                name="shocks"
                                                                                value={data.shocks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.shocks) && <OtherInput disabled={!isEdit} text="Shocks Issues" name="shocksIssues" value={data.shocksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="engineFluidLeaks" size="xs">Engine Fluid Leaks</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="engineFluidLeaks"
                                                                                name="engineFluidLeaks"
                                                                                value={data.engineFluidLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.engineFluidLeaks) && <OtherInput disabled={!isEdit} text="Engine Fluid Leaks Issues" name="engineFluidLeaksIssues" value={data.engineFluidLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="transmissionFluidLeaks" size="xs">Transmission Fluid Leaks</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="transmissionFluidLeaks"
                                                                                name="transmissionFluidLeaks"
                                                                                value={data.transmissionFluidLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.transmissionFluidLeaks) && <OtherInput disabled={!isEdit} text="Transmission Fluid Leaks Issues" name="transmissionFluidLeaksIssues" value={data.transmissionFluidLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="brakeSystemAndPads">Brake System and Pads</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="brakeSystemAndPads"
                                                                                name="brakeSystemAndPads"
                                                                                value={data.brakeSystemAndPads}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={EFBNA} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.brakeSystemAndPads) && <OtherInput disabled={!isEdit} text="Brake System and Pads Issues" name="brakeSystemAndPadsIssues" value={data.brakeSystemAndPadsIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="brakeFluidLeaks" size="xs">Brake Fluid Leaks</Label>
                                                                            <Input
                                                                                type="select" disabled={true}
                                                                                id="brakeFluidLeaks"
                                                                                name="brakeFluidLeaks"
                                                                                value={data.brakeFluidLeaks}
                                                                                className="form-control"
                                                                                onChange={UpdateWithSelection}
                                                                            >
                                                                                <Options data={Leaks} />
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {isFairOrBad(data.brakeFluidLeaks) && <OtherInput disabled={!isEdit} text="Brake Fluid Leaks Issues" name="brakeFluidLeaksIssues" value={data.brakeFluidLeaksIssues} updateFields={updateFields} />}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="mechanicalNote">Comments about mechanical</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="mechanicalNote"
                                                                        name="mechanicalNote"
                                                                        value={data.mechanicalNote}
                                                                        onChange={e => updateFields({ mechanicalNote: e.target.value })}
                                                                        disabled={!isEdit}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="mechanicalFileInput">Prior to approval, please review all uploaded images to ensure they meet our quality standards, including clarity, detail, and relevance to the inspection report.</Label></Col>
                                                            {(!disableUploadItem && isEdit) &&
                                                                <>
                                                                    <Col md={10}>
                                                                        <FormGroup>
                                                                            <div name="mechanicalFileInput" className="dropzone" {...getMechanicalRootProps()}>
                                                                                <input type="file" {...getMechanicalInputProps()} />
                                                                                {
                                                                                    mechanicalAcceptedFiles.length > 0 ? <p>{mechanicalAcceptedFiles.length} file selected</p> :
                                                                                        isMechanicalDragActive ?
                                                                                            <p>Drop the files here ...</p> :
                                                                                            <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                                }
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <SpinnerButton onClick={() => onUploadFile(InspectionSection.Mechanical)} disabled={mechanicalAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Mechanical Pictures" loading={uploadLoading} />
                                                                    </Col>
                                                                </>}
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.Mechanical).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} disableDelete={!isEdit ? true : disableUploadItem} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingFour">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        Tires
                                                    </button>
                                                </h2>
                                                <div id="collapseFour" class="accordion-collapse border-0 collapse" aria-labelledby="headingFour" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="frontBrand">Front Tire Brand</Label>
                                                                    <Input
                                                                        type="select" disabled={true}
                                                                        id="frontBrand"
                                                                        name="frontBrand"
                                                                        className="form-control"
                                                                        value={data.frontBrand}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={MotorcycleTireBrand} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="frontSize">Front Tire Size</Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="frontSize"
                                                                        name="frontSize"
                                                                        value={data.frontSize}
                                                                        onChange={e => updateFields({ frontSize: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="frontTreadDepth">Front Tire Tread Depth</Label>
                                                                    <Input
                                                                        type="select" disabled={true}
                                                                        id="frontTreadDepth"
                                                                        name="frontTreadDepth"
                                                                        className="form-control"
                                                                        value={data.frontTreadDepth}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={MotorcycleTireTreadDepth} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="frontTireAndWheel">Front Tire and Wheel Condition</Label>
                                                                    <Input
                                                                        type="select" disabled={true}
                                                                        id="frontTireAndWheel"
                                                                        name="frontTireAndWheel"
                                                                        className="form-control"
                                                                        value={data.frontTireAndWheel}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={EFBNA} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="rearBrand">Rear Tire Brand</Label>
                                                                    <Input
                                                                        type="select" disabled={true}
                                                                        id="rearBrand"
                                                                        name="rearBrand"
                                                                        className="form-control"
                                                                        value={data.rearBrand}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={MotorcycleTireBrand} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="rearSize">Rear Tire Size</Label>
                                                                    <Input
                                                                        type="text"
                                                                        id="rearSize"
                                                                        name="rearSize"
                                                                        value={data.rearSize}
                                                                        onChange={e => updateFields({ rearSize: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="rearTreadDepth">Rear Tire Tread Depth</Label>
                                                                    <Input
                                                                        type="select" disabled={true}
                                                                        id="rearTreadDepth"
                                                                        name="rearTreadDepth"
                                                                        className="form-control"
                                                                        value={data.rearTreadDepth}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={MotorcycleTireTreadDepth} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="rearTireAndWheel">Rear Tire and Wheel Condition</Label>
                                                                    <Input
                                                                        type="select" disabled={true}
                                                                        id="rearTireAndWheel"
                                                                        name="rearTireAndWheel"
                                                                        className="form-control"
                                                                        value={data.rearTireAndWheel}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={EFBNA} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="spareTire">Spare Tire</Label>
                                                                    <Input
                                                                        type="select" disabled={true}
                                                                        id="spareTire"
                                                                        name="spareTire"
                                                                        className="form-control"
                                                                        value={data.spareTire}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={SpareTire} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="spareTireSet">Spare Tire Set</Label>
                                                                    <Input
                                                                        type="select" disabled={true}
                                                                        id="spareTireSet"
                                                                        name="spareTireSet"
                                                                        className="form-control"
                                                                        value={data.spareTireSet}
                                                                        onChange={UpdateWithSelection}
                                                                    >
                                                                        <Options data={SpareTireSet} />
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="tiresNote">Comments about tires and wheels condition of vehicle</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="tiresNote"
                                                                        name="tiresNote"
                                                                        value={data.tiresNote}
                                                                        onChange={e => updateFields({ tiresNote: e.target.value })}
                                                                        disabled={!isEdit}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="tiresFileInput">Prior to approval, please review all uploaded images to ensure they meet our quality standards, including clarity, detail, and relevance to the inspection report.</Label></Col>
                                                            {(!disableUploadItem && isEdit) &&
                                                                <>
                                                                    <Col md={10}>
                                                                        <FormGroup>
                                                                            <div name="tiresFileInput" className="dropzone" {...getTiresRootProps()}>
                                                                                <input type="file" {...getTiresInputProps()} />
                                                                                {
                                                                                    tiresAcceptedFiles.length > 0 ? <p>{tiresAcceptedFiles.length} file selected</p> :
                                                                                        isTiresDragActive ?
                                                                                            <p>Drop the files here ...</p> :
                                                                                            <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                                }
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <SpinnerButton onClick={() => onUploadFile(InspectionSection.Tires)} disabled={tiresAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Tires Pictures" loading={uploadLoading} />
                                                                    </Col>
                                                                </>}
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.Tires).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} disableDelete={!isEdit ? true : disableUploadItem} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item rounded mb-2">
                                                <h2 class="accordion-header" id="headingFive">
                                                    <button class="accordion-button border-0 mb-0 bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                        Summary
                                                    </button>
                                                </h2>
                                                <div id="collapseFive" class="accordion-collapse border-0 collapse" aria-labelledby="headingFive" data-bs-parent="#accordion">
                                                    <div class="accordion-body text-muted">
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="summary">Please provide a summary of inspection with few paragraphs.</Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="summary"
                                                                        name="summary"
                                                                        value={data.summary}
                                                                        onChange={e => updateFields({ summary: e.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row form>
                                                            <Col md={12}><Label for="summaryFileInput">Prior to approval, please review all uploaded images to ensure they meet our quality standards, including clarity, detail, and relevance to the inspection report.</Label></Col>
                                                            {(!disableUploadItem && isEdit) &&
                                                                <>
                                                                    <Col md={10}>
                                                                        <FormGroup>
                                                                            <div name="summaryFileInput" className="dropzone" {...getSummaryRootProps()}>
                                                                                <input type="file" {...getSummaryInputProps()} />
                                                                                {
                                                                                    summaryAcceptedFiles.length > 0 ? <p>{summaryAcceptedFiles.length} file selected</p> :
                                                                                        isSummaryDragActive ?
                                                                                            <p>Drop the files here ...</p> :
                                                                                            <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                                                }
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <SpinnerButton onClick={() => onUploadFile(InspectionSection.Summary)} disabled={summaryAcceptedFiles.length == 0} className="btn btn-warning" text="Upload Additional Pictures" loading={uploadLoading} />
                                                                    </Col>
                                                                </>}
                                                            <Col md={12}>
                                                                <Row>
                                                                    {uploadedFiles.filter(t => t.subCategory == InspectionSection.Summary).map(t =>
                                                                        <Col md={2} className="my-2">
                                                                            <ImageArea src={t.filePath} disableDelete={!isEdit ? true : disableUploadItem} handleClick={() => handleRemoveImage(t.id)} />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {!(data.status == QuotationStatus.InspectionApproved || data.status == QuotationStatus.Completed) &&
                                            <Row>
                                                <Col md={9}>
                                                    <div style={{ color: "red", fontSize: "0.9rem" }}>
                                                        {Object.keys(validationError).length > 0 ? Object.values(validationError).map(item => <div className="row"><span>* {item}</span></div>) : null}
                                                    </div>
                                                </Col>
                                                {!isEdit ? <>
                                                    <Col md={1}>
                                                        <button className="btn btn-dark my-5" onClick={() => setIsEdit(true)}>Edit</button>
                                                    </Col>
                                                    <Col md={2}>
                                                        <SpinnerButton className="btn btn-primary my-5" text="Approve Report" loading={loading} onClick={() => setApprovalModal({ show: true, message: "Are you sure you want to approve this report? This action cannot be undone." })} />
                                                    </Col> </> : <>
                                                    <Col md={1}>
                                                        <button className="btn btn-dark my-5" onClick={() => setCancelModal({ show: true })}>Cancel</button>
                                                    </Col>
                                                    <Col md={2}>
                                                        <SpinnerButton onClick={() => setSaveModal({ show: true })} className="btn btn-primary my-5" text="Save" loading={loading} />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </section>
            </div>
        </div>

    )
}

export default MotorcycleOrder;