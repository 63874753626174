import React from 'react'
import HeroSectionBecomeInspector from '../../Componet/Herosection/HeroSectionBecomeInspector'

function HomePageBecomeInspector() {
  return (
    <div>
      <HeroSectionBecomeInspector />
    </div>
  )
}

export default HomePageBecomeInspector
