import React, { FormEvent, useState } from 'react'
import PhoneMaskInput from '../../Componet/PhoneMaskInput';
import { isValidEmail, isValidPhoneNumber } from '../../store/Utility/Helper';
import Modal from 'react-bootstrap/Modal';
import axios from '../../api/axios';

const InitialData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "",
    message: ""
}

function Map() {
    const [validationError, setValidationError] = useState({});
    const [formData, setFormData] = useState(InitialData);
    const [modal, showModal] = useState({ show: false, message: null });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    const validateInput = (inputValues) => {
        let errors = {};
        if (inputValues.email == '') {
            errors.email = "Email is required";
        } else {
            if (!isValidEmail(inputValues.email)) errors.email = "Please enter valid email";
        }

        if (inputValues.phone == '') {
            errors.phone = "Phone is required";
        } else {
            if (!isValidPhoneNumber(inputValues.phone)) errors.phone = "Please enter valid phone number";
        }
        return errors;
    }

    const handleClose = () => showModal({ show: false, message: null });

    const onSubmit = async (e: FormEvent) => {

        e.preventDefault();
        var errors = validateInput(formData);
        setValidationError(errors);
        if (Object.keys(errors).length != 0) return;
        else {
            const response = await axios.post(`v1/contact`, formData).then(res => {
                showModal({ show: true, message: "Your application successfully submitted!" });
                setFormData(InitialData);
            }).catch(error => {
                showModal({ show: true, message: "An error occured while saving" })
            });
        }
    }

    return (
        <>
            <section >
            {<Modal show={modal.show} onHide={handleClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Result</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{modal.message}</Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-dark" onClick={handleClose}>Close</button>
                        </Modal.Footer>
                    </Modal>}
                <div className='container'>
                    <div className='row'>
                        <div className="col-12 col-lg-7">
                            <div>
                                <div>
                                    <h2><span class="font-w-4 d-block">Please complete the form below, and we'll reach out shortly with a response!</span></h2>
                                </div>
                                <form id="contact-form" className="row" onSubmit={onSubmit}>
                                    <div className="messages" />
                                    <div className="form-group col-md-6">
                                        <input id="firstName" type="text" name="firstName" value={formData.firstName} onChange={handleChange} className="form-control" placeholder="First Name" required="required" data-error="Name is required." />
                                        <div className="help-block with-errors" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input id="lastName" type="text" name="lastName" value={formData.lastName} onChange={handleChange} className="form-control" placeholder="Last Name" required="required" data-error="Name is required." />
                                        <div className="help-block with-errors" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input id="email" type="email" value={formData.email} name="email" onChange={handleChange} className="form-control" placeholder="Email" required="required" data-error="Valid email is required." />
                                        <div className="help-block with-errors" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <PhoneMaskInput value={formData.phone} onChange={handleChange} name="phone" className="form-control" />
                                        <div className="help-block with-errors" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <input id="subject" type="text" name="subject" value={formData.subject} onChange={handleChange} className="form-control" placeholder="Subject" required="required" data-error="Subject is required" />
                                        <div className="help-block with-errors" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <textarea id="message" name="message" className="form-control h-auto" onChange={handleChange} value={formData.message} placeholder="Message" rows={4} required="required" data-error="Please,leave us a message." defaultValue={""} />
                                        <div className="help-block with-errors" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <div style={{ color: "red", fontSize: "0.9rem" }}>
                                            {Object.keys(validationError).length > 0 ? Object.values(validationError).map(item => <div className="row"><span>* {item}</span></div>) : null}
                                        </div>
                                    </div>
                                    <div className="col mt-4">
                                        <button type='submit' className="btn btn-dark">Send Messages</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 ms-auto mt-5 mt-lg-0">
                            <div class="d-flex align-items-center bg-white p-3 shadow-sm rounded mb-3">
                                <div class="me-3">
                                    <div class="f-icon-s p-3 rounded" style={{ backgroundColor: "#d0faec" }}> <i class="flaticon-location"></i>
                                    </div>
                                </div>
                                <div>
                                    <h5 class="mb-1">Address:</h5>
                                    <span class="text-black">Road Wordwide Country, USA</span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center bg-white p-3 shadow-sm rounded  mb-3">
                                <div class="me-3">
                                    <div class="f-icon-s p-3 rounded" style={{ backgroundColor: "#d0faec" }}> <i class="flaticon-mail"></i>
                                    </div>
                                </div>
                                <div>
                                    <h5 class="mb-1">Email Us:</h5>
                                    <a class="btn-link" href="mailto:themeht23@gmail.com"> mailto:themeht23@gmail.com</a>
                                </div>
                            </div>
                            <div class="d-flex align-items-center bg-white p-3 shadow-sm rounded">
                                <div class="me-3">
                                    <div class="f-icon-s p-3 rounded" style={{ backgroundColor: "#d0faec" }}> <i class="flaticon-telephone"></i>
                                    </div>
                                </div>
                                <div>
                                    <h5 class="mb-1">Call Us:</h5>
                                    <a class="btn-link" href="tel:+912345678900">+91-234-567-8900</a>
                                </div>
                            </div>
                            <div className='map h-50 mt-5'>
                                <iframe title='Wink' class="w-100 h-100 border-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.840108181602!2d144.95373631539215!3d-37.8172139797516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d4c2b349649%3A0xb6899234e561db11!2sEnvato!5e0!3m2!1sen!2sin!4v1497005461921" allowfullscreen=""></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Map
