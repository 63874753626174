import React from 'react'
import HerosectionHeader from '../../Componet/Herosection/HerosectionHeader'
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const JoinUs = () => {
    return (
        <>
            <HerosectionHeader name={"Join US"} />
            <div class="page-content">
                <section>
                    <Container>
                        <Row className="justify-content-left">
                            <Col lg="8">
                                <div className="mb-5">
                                    <h2 className="mb-0">
                                        <span className="font-w-4 d-block"></span>Why Join inspectiver
                                    </h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" md="12">
                                <h4 class="text-primary">1. Premium Pay</h4>
                                <p class="mb-3">Earn a great rate per inspection, with chances to earn bonuses based on your performance and the number of inspections completed.</p>
                                <h4 class="text-primary">2. Flexibility</h4>
                                <p class="mb-3">Enjoy the freedom to choose your working hours and locations. You decide
                                    when and where you want to conduct inspections.</p>

                                <h4 class="text-primary">3. Professional Growth</h4>
                                <p class="mb-3">Enhance your skills and build your reputation as a top-tier
                                    inspector. We provide continuous support and resources to help you excel in your role.</p>

                                <h4 class="text-primary">4. Weekly Payments</h4>
                                <p class="mb-3">Get paid weekly via direct deposit for all the inspections you
                                    complete, ensuring a steady and reliable income stream.</p>

                                <h4 class="text-primary">5. Comprehensive Support</h4>
                                <p class="mb-3">Our dedicated support team is always here to assist you with
                                    any questions or issues, ensuring a smooth and efficient experience.</p>



                            </Col>
                        </Row>
                        <Row className="justify-content-left">
                            <Col lg="8">
                                <div className=" mt-3 mb-3">
                                    <h2 className="mb-0">
                                        <span className="font-w-4 d-block"></span>Join Our Network
                                    </h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" md="12">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <p class="mb-0">By joining inspectiver, you become part of a trusted network of professionals who are passionate
                                            about delivering quality services. We value your expertise and are committed to providing you
                                            with the best tools and support to succeed. Apply today and take the first step towards a
                                            rewarding partnership with inspectiver!</p>
                                    </div>
                                </div>

                            </Col>
                            <Col lg="12" md="12"><Link className="btn btn-dark mt-2" to="/inspector">Become an Inpsector</Link></Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>
    )
}

export default JoinUs
