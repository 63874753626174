import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
    Col,
    Container,
    FormGroup,
    Input,
    Row,
    Label
} from "reactstrap";
import Modal from 'react-bootstrap/Modal';
import HerosectionHeader from "../../../Componet/Herosection/HerosectionHeader";
import { InspectorStatus } from '../../../store/Utility/Status';
import { useSpinner } from "../../../context/SpinnerContext";
import SpinnerButton from "../../../Componet/Button/SpinnerButton";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import { Roles } from "../../../store/Utility/Utility";
import moment from "moment";

const InitialData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    experiencedBrandFirst: "",
    experiencedBrandSecond: "",
    experiencedBrandThird: "",
    certifications: "",
    workExperience: "",
    education: "",
    status: null,
    warningCount: 0
}

const initialModal = {
    show: false,
    contractUrl: null
};

const initialSuspendModal = {
    show: false,
    inspectorId: null,
    isValid: true
}

const initialWarningdModal = {
    show: false,
    inspectorId: null,
    isValid: true
}

function InspectorDetail() {
    let { id } = useParams();
    const [formData, setFormData] = useState(InitialData);
    const [modal, showModal] = useState({ show: false, message: null });
    const [suspendModal, setSuspendModal] = useState(initialSuspendModal);
    const [warningMessage, setWarningMessage] = useState(null);
    const [warningModal, setWarningModal] = useState(initialWarningdModal);
    const [contractModal, setContractModal] = useState(initialModal);
    const [validationError, setValidationError] = useState({});
    const [loading, setLoading] = useState(false);
    const { showLoader, hideLoader } = useSpinner();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const checkHandler = (event) => {
        setFormData((prevFormData) => ({ ...prevFormData, isSSNConfirmed: !prevFormData.isSSNConfirmed }));
    }

    const navigate = useNavigate();

    const handleClose = () => {
        showModal({ show: false, message: null });
        navigate("/inspector-list");
    }

    const handleContractClose = () => setContractModal({ show: false });

    useEffect(() => {
        const fetchInspector = async () => {
            await axiosPrivate.get(`v1/inspector/${id}`).then(res => {
                setFormData(res?.data?.data);
           
            })
                .catch(error => {
                    console.error('Error:', error);
                });
        };
        fetchInspector();
    }, []);


    const handleApprove = async (e) => {
        e.preventDefault();
        if (!formData.isSSNConfirmed) {
            let errors = {};
            errors.ssnCheck = "SSN should be checked";
            setValidationError(errors);
            return;
        }
        showLoader();
        const response = await axiosPrivate.post(`v1/inspector/approve`, { id: id, isSSNConfirmed: formData.isSSNConfirmed }).then(res => {
            showModal({ show: true, message: "Inspector succesfully approved!" });
            hideLoader();
        }).catch(error => {
            showModal({ show: true, message: "Sorry! An error occured" });
            hideLoader();
        });
    }

    const handleReject = async () => {
        const response = await axiosPrivate.post(`v1/inspector/Status`, { id: id, status: InspectorStatus.Rejected }).then(res => {
            showModal({ show: true, message: "Item rejected!" })
        }).catch(error => {
            showModal({ show: true, message: "Sorry! An error occured" })
        });
    }

    const handleSendContract = async () => {
        showLoader();
        const response = await axiosPrivate.post(`v1/inspector/Status`, { id: id, status: InspectorStatus.AwaitingContract }).then(res => {
            hideLoader();
            showModal({ show: true, message: "Contract sent!" });
            handleContractClose();
        }).catch(error => {
            hideLoader();
            showModal({ show: true, message: "Sorry! An error occured" })
        });
    }

    const onSuspendSubmitHandler = async (e) => {
    
        e.preventDefault();
        if (loading) return;
        let reason = e.target.elements.suspendReason?.value;
        if (reason == '') {
            setSuspendModal({ ...suspendModal, isValid: false });
            return;
        }
        setLoading(true);
        const response = await axiosPrivate.post(`v1/inspector/Status`, { id: id, status: InspectorStatus.Suspended, reason: reason }).then(res => {
            setLoading(false);
            setSuspendModal(initialSuspendModal);
            showModal({ show: true, message: "Inspector successfully suspended" });
        }).catch(error => {
            setLoading(false);
            showModal({ show: true, message: "Sorry! An error occured" })
        });

    }

    const onWarningSubmitHandler = async (e) => {
        e.preventDefault();
        if (loading) return;
        let reason = e.target.elements.warningReason?.value;
        if (reason == '') {
            setWarningModal({ ...warningModal, isValid: false });
            return;
        }
        setLoading(true);
        const response = await axiosPrivate.post(`v1/inspector/Status`, { id: id, status: InspectorStatus.Warned, reason: reason }).then(res => {
            setLoading(false);
            setWarningModal(initialWarningdModal);
            showModal({ show: true, message: "Inspector successfully warned" });
        }).catch(error => {
            setLoading(false); 
            showModal({ show: true, message: "Sorry! An error occured" })
        });
    }

    const handleUnsuspendAccount = async (e) => {
        e.preventDefault();
        showLoader();
        const response = await axiosPrivate.post(`v1/inspector/Status`, { id: id, status: InspectorStatus.Approved }).then(res => {
            hideLoader();
            showModal({ show: true, message: "Suspend successfully removed" });
        }).catch(error => {
            hideLoader();
            showModal({ show: true, message: "Sorry! An error occured" })
        });
    }


    const handleWarning = async (e) => {
        e.preventDefault();
        if (e.target.ariaExpanded == "false" || warningMessage != null) return;
        await axiosPrivate.get(`v1/inspector/warnings`, { params: { inspectorId: id } }).then(res => {
            setWarningMessage(res?.data?.data);
        })
            .catch(error => {
                console.error('Error:', error);
            });
    }


    return (
        <>
            {<Modal show={contractModal.show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Contract Url</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <label for="contractUrl">Contract Url</label>
                            <input type="text" name="contractUrl" className="form-control" value={contractModal.contractUrl} onChange={(e) => { setContractModal({ ...contractModal, contractUrl: e.target.value }) }} placeholder="Enter contract url" />
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={handleClose}>Cancel</button>
                    <button className="btn btn-dark" onClick={() => handleSendContract()}>Send</button>
                </Modal.Footer>
            </Modal>}

            {<Modal show={suspendModal.show} onHide={() => setSuspendModal(initialSuspendModal)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Suspension Reason</Modal.Title>
                </Modal.Header>
                <form onSubmit={onSuspendSubmitHandler}>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <label htmlFor="suspendReason">Please enter a reason</label>
                                <Input
                                    type="text"
                                    id="suspendReason"
                                    name="suspendReason"
                                    className="form-control"
                                />
                            </Col>
                        </Row>
                        {(!suspendModal.isValid) &&
                            <Row>
                                <Col md={12} className="mt-2" style={{ color: "red", fontSize: "0.9rem" }}>
                                    <div className="row"><span>* Please enter suspension reason</span></div>
                                </Col>
                            </Row>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Col>
                            <Row>
                                <Col md={5}></Col>
                                <Col md={3}><button className="btn btn-primary" onClick={() => setSuspendModal(initialSuspendModal)}>Close</button></Col>
                                <Col md={4}>
                                    <SpinnerButton type="submit" text="Confirm" className="btn btn-danger" loading={loading} />
                                </Col>
                            </Row>
                        </Col>
                    </Modal.Footer>
                </form>
            </Modal>}

            {<Modal show={warningModal.show} onHide={() => setWarningModal(initialWarningdModal)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Warning Reason</Modal.Title>
                </Modal.Header>
                <form onSubmit={onWarningSubmitHandler}>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <label htmlFor="warningReason">Please enter a reason</label>
                                <Input
                                    type="text"
                                    id="warningReason"
                                    name="warningReason"
                                    className="form-control"
                                />
                            </Col>
                        </Row>
                        {(!warningModal.isValid) &&
                            <Row>
                                <Col md={12} className="mt-2" style={{ color: "red", fontSize: "0.9rem" }}>
                                    <div className="row"><span>* Please enter warning reason</span></div>
                                </Col>
                            </Row>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Col>
                            <Row>
                                <Col md={5}></Col>
                                <Col md={3}><button className="btn btn-primary" onClick={() => setWarningModal(initialWarningdModal)}>Close</button></Col>
                                <Col md={4}>
                                    {/* <button type="submit" className="btn btn-warning">Confirm</button> */}
                                    <SpinnerButton type="submit" text="Confirm" className="btn btn-warning" loading={loading} />
                                </Col>
                            </Row>
                        </Col>
                    </Modal.Footer>
                </form>
            </Modal>}
            <div>
                <div className="page-wrapper">
                    <HerosectionHeader name={"Inspector Detail"} />
                    <div className="page-content">
                        <section>
                            {<Modal show={modal.show} onHide={handleClose} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>Result</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{modal.message}</Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-dark" onClick={handleClose}>Close</button>
                                </Modal.Footer>
                            </Modal>}
                            <Container>
                                <Row >
                                    <Col lg={12} md={12}>
                                        <div className="checkout-form border p-4 rounded">
                                            <Row>
                                                <Col md={1}>
                                                    {formData.status == InspectorStatus.Applicant && <Col><h2 className="la la-user-clock text-info"> </h2><span className="text-info">Applicant</span></Col>}
                                                    {formData.status == InspectorStatus.Approved && <Col><h2 className="la la-user-check text-success"></h2><span className="text-success">Approved</span></Col>}
                                                    {formData.status == InspectorStatus.Suspended && <Col><h2 className="la la-user-minus text-danger"> </h2><span className="text-danger">Suspended</span></Col>}
                                                </Col>
                                                <Col md={9}>

                                                    <h2 className="mb-4">{(formData.status == InspectorStatus.Applicant) ? "Inspector Application" : "Inspector : " + formData.inspectorCode}</h2></Col>
                                                {formData.status >= InspectorStatus.Approved && <Col md={2}>
                                                    <div className="form-group">
                                                        <img src={formData.profileImageUrl} style={{ borderRadius: "50%", height: "100px", width: "100px", border: "1px solid #f1f1f1" }} />
                                                    </div>

                                                </Col>}
                                            </Row>
                                            {formData.status != InspectorStatus.Approved && <div className="col-lg-12 col-12">
                                                <p className="lead mb-5">Prior to accepting any applications, please thoroughly review the details in the form below and verify their ASE number with the institute for accuracy.</p>
                                            </div>}
                                            <form>
                                                <Row form>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="firstName">First Name</Label>
                                                            <Input
                                                                type="text"
                                                                id="firstName"
                                                                placeholder="First Name"
                                                                name="firstName"
                                                                value={formData.firstName}
                                                                disabled
                                                            />

                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="middleName">Middle Name</Label>
                                                            <Input
                                                                type="text"
                                                                id="middleName"
                                                                placeholder="Middle Name"
                                                                name="middleName"
                                                                value={formData.middleName}
                                                                disabled
                                                            />

                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="lastName">Last Name</Label>
                                                            <Input
                                                                type="text"
                                                                id="lastName"
                                                                name="lastName"
                                                                placeholder="Last Name"
                                                                value={formData.lastName}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="email">Email</Label>
                                                            <Input
                                                                type="text"
                                                                id="email"
                                                                name="email"
                                                                placeholder="E-mail"
                                                                value={formData.email}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="phone">Phone</Label>
                                                            <Input
                                                                type="text"
                                                                name="phone"
                                                                id="phone"
                                                                placeholder="Phone"
                                                                value={formData.phone}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={9}>
                                                        <FormGroup>
                                                            <Label for="address">Address</Label>
                                                            <Input
                                                                type="text"
                                                                id="address"
                                                                name="address"
                                                                placeholder="Address"
                                                                value={formData.address}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <Label for="address">Unit/Apt #</Label>
                                                            <Input
                                                                type="text"
                                                                id="addressUnit"
                                                                name="addressUnit"
                                                                placeholder="Unit/Apt #"
                                                                value={formData.addressUnit}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="city">City</Label>
                                                            <Input
                                                                type="text"
                                                                id="city"
                                                                name="city"
                                                                placeholder="City"
                                                                value={formData.city}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="state">State</Label>
                                                            <Input
                                                                type="select"
                                                                id="state"
                                                                name="state"
                                                                className="form-control"
                                                                value={formData.state}
                                                                disabled
                                                            >
                                                                <option>State</option>
                                                                <option value="AL">Alabama</option>
                                                                <option value="AK">Alaska</option>
                                                                <option value="AZ">Arizona</option>
                                                                <option value="AR">Arkansas</option>
                                                                <option value="CA">California</option>
                                                                <option value="CO">Colorado</option>
                                                                <option value="CT">Connecticut</option>
                                                                <option value="DE">Delaware</option>
                                                                <option value="DC">District Of Columbia</option>
                                                                <option value="FL">Florida</option>
                                                                <option value="GA">Georgia</option>
                                                                <option value="HI">Hawaii</option>
                                                                <option value="ID">Idaho</option>
                                                                <option value="IL">Illinois</option>
                                                                <option value="IN">Indiana</option>
                                                                <option value="IA">Iowa</option>
                                                                <option value="KS">Kansas</option>
                                                                <option value="KY">Kentucky</option>
                                                                <option value="LA">Louisiana</option>
                                                                <option value="ME">Maine</option>
                                                                <option value="MD">Maryland</option>
                                                                <option value="MA">Massachusetts</option>
                                                                <option value="MI">Michigan</option>
                                                                <option value="MN">Minnesota</option>
                                                                <option value="MS">Mississippi</option>
                                                                <option value="MO">Missouri</option>
                                                                <option value="MT">Montana</option>
                                                                <option value="NE">Nebraska</option>
                                                                <option value="NV">Nevada</option>
                                                                <option value="NH">New Hampshire</option>
                                                                <option value="NJ">New Jersey</option>
                                                                <option value="NM">New Mexico</option>
                                                                <option value="NY">New York</option>
                                                                <option value="NC">North Carolina</option>
                                                                <option value="ND">North Dakota</option>
                                                                <option value="OH">Ohio</option>
                                                                <option value="OK">Oklahoma</option>
                                                                <option value="OR">Oregon</option>
                                                                <option value="PA">Pennsylvania</option>
                                                                <option value="RI">Rhode Island</option>
                                                                <option value="SC">South Carolina</option>
                                                                <option value="SD">South Dakota</option>
                                                                <option value="TN">Tennessee</option>
                                                                <option value="TX">Texas</option>
                                                                <option value="UT">Utah</option>
                                                                <option value="VT">Vermont</option>
                                                                <option value="VA">Virginia</option>
                                                                <option value="WA">Washington</option>
                                                                <option value="WV">West Virginia</option>
                                                                <option value="WI">Wisconsin</option>
                                                                <option value="WY">Wyoming</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="zipCode">Zip Code</Label>
                                                            <Input
                                                                type="text"
                                                                id="zipCode"
                                                                name="zipCode"
                                                                value={formData.zipCode}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="experiencedBrandFirst">Experienced Brand 1</Label>
                                                            <Input
                                                                type="text"
                                                                id="experiencedBrandFirst"
                                                                name="experiencedBrandFirst"
                                                                value={formData.experiencedBrandFirst}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="experiencedBrandSecond">Experienced Brand 2</Label>
                                                            <Input
                                                                type="text"
                                                                id="experiencedBrandSecond"
                                                                name="experiencedBrandSecond"
                                                                value={formData.experiencedBrandSecond}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="experiencedBrandThird">Experienced Brand 3</Label>
                                                            <Input
                                                                type="text"
                                                                id="experiencedBrandThird"
                                                                name="experiencedBrandThird"
                                                                value={formData.experiencedBrandThird}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="certifications">ASE ID Number </Label>
                                                            <Input
                                                                type="textarea"
                                                                id="aseCertification"
                                                                name="aseCertification"
                                                                value={formData.aseCertification}
                                                                disabled
                                                            />
                                                        </FormGroup>

                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="workExperience">Work Experience</Label>
                                                            <Input
                                                                type="textarea"
                                                                id="workExperience"
                                                                name="workExperience"
                                                                value={formData.workExperience}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="education">Education</Label>
                                                            <Input
                                                                type="textarea"
                                                                id="education"
                                                                name="education"
                                                                value={formData.education}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="certifications">Other Certifications</Label>
                                                            <Input
                                                                type="textarea"
                                                                id="certifications"
                                                                name="certifications"
                                                                value={formData.otherCertifications}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="referralCode">Referral Code</Label>
                                                            <Input
                                                                type="text"
                                                                id="referralCode"
                                                                name="referralCode"
                                                                value={formData.referralCode}
                                                                disabled
                                                            />
                                                        </FormGroup>

                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="ssn">Social Security Number</Label>
                                                            <Input
                                                                type="text"
                                                                id="ssn"
                                                                name="ssn"
                                                                value={formData.ssn}
                                                                disabled
                                                            />
                                                        </FormGroup>

                                                    </Col>


                                                </Row>
                                                {(formData.licenseFrontImageId != undefined && formData.licenseFrontImageId != null) &&
                                                    <>
                                                        <Row>
                                                            <Col md={4}>
                                                                <Label>Front of driver's license</Label>
                                                                <FormGroup>
                                                                    <img src={formData.licenseFrontImageUrl} style={{ height: "250px", width: "400px" }} />
                                                                </FormGroup>

                                                            </Col>
                                                            <Col md={4} className="ml-5">
                                                                <Label>Back of driver's license</Label>
                                                                <FormGroup>
                                                                    <img src={formData.licenseBackImageUrl} style={{ height: "250px", width: "400px" }} />
                                                                </FormGroup>

                                                            </Col>
                                                            <Col md={4} className="ml-5">
                                                                <Label>ASE Certificate</Label>
                                                                <FormGroup>
                                                                    <img src={formData.aseCertificationFileUrl} style={{ height: "250px", width: "400px" }} />
                                                                </FormGroup>

                                                            </Col>
                                                        </Row>
                                                    </>}
                                                {(formData.status == InspectorStatus.AwaitingApprove) && <Row>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <div className="form-group mb-0 mt-3">
                                                                <div className="form-check">
                                                                    <input type="checkbox" className="form-check-input" id="customCheck1" checked={formData.isSSNConfirmed} onChange={checkHandler} />
                                                                    <label className="form-check-label" for="customCheck1" >Inspector SSN Checked</label>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>}
                                                {
                                                    (formData.status == InspectorStatus.Approved && auth?.user?.roles?.find(role => [Roles.Admin].includes(role)))
                                                    &&
                                                    <Row>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <label for="routingNumber">Routing Number</label>
                                                                <Input
                                                                    type="text"
                                                                    id="routingNumber"
                                                                    name="routingNumber"
                                                                    placeholder="Routing Number"
                                                                    value={formData.routingNumber}
                                                                    disabled={true}
                                                                    required
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <label for="accountNumber">Account Number</label>
                                                                <Input
                                                                    type="text"
                                                                    id="accountNumber"
                                                                    name="accountNumber"
                                                                    placeholder="Account Number"
                                                                    value={formData.accountNumber}
                                                                    disabled={true}
                                                                    required
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                }
                                                <Row>
                                                    <Col md={12}>
                                                        <div style={{ color: "red", fontSize: "0.9rem" }}>
                                                            {Object.keys(validationError).length > 0 ? Object.values(validationError).map(item => <div className="row"><span>* {item}</span></div>) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {(formData.warningCount > 0) &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <div class="accordion" id="accordion">
                                                                <div class="accordion-item rounded mb-2">
                                                                    <h2 class="accordion-header" id="headingOne">
                                                                        <button class="accordion-button border-0 mb-0 bg-transparent" type="button" data-bs-toggle="collapse" onClick={handleWarning} data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                            Warnings
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseOne" class="accordion-collapse border-0 collapse" aria-labelledby="headingOne" data-bs-parent="#accordion">
                                                                        {warningMessage && warningMessage.map(item => <div class="accordion-body text-muted"> {moment(item.createdDate).format('MM.DD.YYYY HH:mm')}-{item.message}</div>)}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Col>
                                                    </Row>}
                                                <Row >
                                                    <Col md={1}><Link className="btn btn-dark" to='/inspector-list'>Back</Link> </Col>
                                                    <Col md={2}></Col>
                                                    <Col md={9}>
                                                        <Row className="justify-content-end">
                                                            {formData.status == InspectorStatus.Applicant && <Col md={3}><button type="button" className="btn btn-warning" onClick={handleSendContract}>Send Contract</button></Col>}
                                                            {formData.status == InspectorStatus.Applicant && <Col md={2}><button type="button" className="btn btn-danger" onClick={handleReject}>Reject</button> </Col>}
                                                            {formData.status == InspectorStatus.AwaitingApprove && <Col md={2}><button type="button" className="btn btn-primary" onClick={handleApprove}>Approve</button></Col>}
                                                            {(formData.status == InspectorStatus.Approved && formData.status != InspectorStatus.Suspended) && <Col md={3}><button type="button" className="btn btn-warning" onClick={() => setWarningModal({ show: true, inspectorId: id, isValid: true })}>Send Warning</button></Col>}
                                                            {(formData.status == InspectorStatus.Approved) && <Col md={3}><button type="button" className="btn btn-danger" onClick={() => setSuspendModal({ show: true, inspectorId: id, isValid: true })}>Suspend Account</button></Col>}
                                                            {(formData.status == InspectorStatus.Suspended) && <Col md={3}><button type="button" className="btn btn-success" onClick={handleUnsuspendAccount}>Unsuspend Account</button></Col>}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </div>
                </div>
            </div >
        </>
    );
}

export default InspectorDetail
