

export function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
}

export function isValidPhoneNumber(phone) {
    return /\d{10}/.test(phone.replace(/\D/g, ''));
}

export function isValidZipCode(zipCode) {
    // return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode); 
    return /(^\d{5}$)/.test(zipCode);
}
export function isNullOrEmpty(value) {
    return value == undefined || value == null || value == '';
}


export function isValidSSN(str) {
    // Regex to check valid
    // SSN CODE
    let regex = new RegExp(/^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/);

    //  if str CODE
    // is empty return false
    if (str == null) {
        return false;
    }

    return regex.test(str);
}

export function defaultIfNull(value) {
    if (value == undefined || value == null || value == '') return 'N/A';
    return value;
}