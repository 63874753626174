const ImageArea = ({ src, handleClick, disableDelete, toggleSlider }) => {
    const onClick = () => {
        handleClick();
    }

    const onToggleClick = () => {
        toggleSlider();
    }

    return <div class="image-area" onClick={toggleSlider ? onToggleClick : null}>
        <img src={src} alt="Preview" />
        {!disableDelete && <a class="remove-image" title="Delete" onClick={onClick} style={{ display: "inline" }} >&#215;</a>}
    </div>
}

export default ImageArea;