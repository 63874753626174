import React, { createContext, useState, useContext } from 'react';
import { ClipLoader } from 'react-spinners';

const SpinnerContext = createContext();

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

export const SpinnerProvider = ({ children }) => {
    const [showSpinner, setShowSpinner] = useState(false);
    const showLoader = () => setShowSpinner(true);
    const hideLoader = () => setShowSpinner(false);
    let [color, setColor] = useState("#ffffff");
    return (
        <SpinnerContext.Provider value={{ showLoader, hideLoader }}>
            {showSpinner &&
                <div className="backdrop"><ClipLoader
                    color={color}
                    // cssOverride={override}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                /></div>

            }
            {children}

        </SpinnerContext.Provider>
    )
};

export const useSpinner = () => useContext(SpinnerContext);