import React from "react";
import { Row, Col } from "reactstrap";
import OptionText from "../../../Componet/Option/OptionText";
import {
    TransmissionType, FuelType, EFB, Dings, EFBNA, SpareTire, SpareTireSet,
    Scratches, MotorcycleEngineType, MotorcycleEngineSize, MotorcycleTireBrand, MotorcycleTireTreadDepth
} from "../../../store/Utility/Data";

const MotorcycleInspectionReport = ({ data }) => {

    function isOther(value) {
        if (value == undefined || value == null) return false;
        return value.toLowerCase() == "other";
    }

    return (
        <>
            {data &&
                <Row>
                    <Col md={12}>
                        <table className="table table-bordered tdfirst-nowrap">
                            <thead>
                                <tr style={{ backgroundColor: "#20c997" }}>
                                    <th colSpan={3} style={{ textAlign: "center" }}>Vehicle Info</th>
                                </tr>
                                <tr style={{ backgroundColor: "#d5d5d5" }}>
                                    <th>Vehicle Detail</th>
                                    <th>Specification</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Make</td>
                                    <td>{data.makeInfo}</td>
                                </tr>
                                <tr>
                                    <td>Model</td>
                                    <td>{data.modelInfo}</td>
                                </tr>
                                <tr>
                                    <td>Year</td>
                                    <td>{data.year}</td>
                                </tr>
                                <tr>
                                    <td>Mileage</td>
                                    <td>{data.mileage}</td>
                                </tr>
                                <tr>
                                    <td>License Plate</td>
                                    <td>{data.licensePlate}</td>
                                </tr>
                                <tr>
                                    <td>VIN</td>
                                    <td>{data.vin}</td>
                                </tr>
                                <tr>
                                    <td>Transmission Type</td>
                                    <td>{isOther(data.transmissionType) ? data.transmissionTypeInfo : <OptionText source={TransmissionType} value={data.transmissionType} />}</td>
                                </tr>
                                <tr>
                                    <td>Engine Type</td>
                                    <td>{isOther(data.engineType) ? data.engineTypeInfo : <OptionText source={MotorcycleEngineType} value={data.engineType} />}</td>
                                </tr>
                                <tr>
                                    <td>Fuel Type</td>
                                    <td>{isOther(data.fuelType) ? data.fuelTypeInfo : <OptionText source={FuelType} value={data.fuelType} />}</td>
                                </tr>
                                <tr>
                                    <td>Engine Size</td>
                                    <td>{isOther(data.engineSize) ? data.engineSizeInfo : <OptionText source={MotorcycleEngineSize} value={data.engineSize} />}</td>
                                </tr>
                                <tr>
                                    <td>Comments</td>
                                    <td>{data.vehicleInfoNote}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col md={12}>
                        <table className="table table-bordered tdfirst-nowrap tdsecond-nowrap">
                            <thead>
                                <tr style={{ backgroundColor: "#20c997" }}>
                                    <th colSpan={3} style={{ textAlign: "center" }}>Exteriror</th>
                                </tr>
                                <tr style={{ backgroundColor: "#d5d5d5" }}>
                                    <th>Inspection Point</th>
                                    <th>Condition</th>
                                    <th>Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Body Alignment Condition</td>
                                    <td><OptionText source={EFB} value={data.bodyAlignment} /></td>
                                    <td>{data.bodyAlignmentIssues}</td>
                                </tr>
                                <tr>
                                    <td>Paint Condition</td>
                                    <td><OptionText source={EFB} value={data.paint} /></td>
                                    <td>{data.paintIssues}</td>
                                </tr>
                                <tr>
                                    <td>Frame Condition</td>
                                    <td><OptionText source={EFB} value={data.frame} /></td>
                                    <td>{data.frameIssues}</td>
                                </tr>
                                <tr>
                                    <td>Exhaust Condition</td>
                                    <td><OptionText source={EFB} value={data.exhaustSystem} /></td>
                                    <td>{data.exhaustSystemIssues}</td>
                                </tr>
                                <tr>
                                    <td>Seat Condition</td>
                                    <td><OptionText source={EFB} value={data.seats} /></td>
                                    <td>{data.seatsIssues}</td>
                                </tr>
                                <tr>
                                    <td>Grips</td>
                                    <td><OptionText source={EFB} value={data.grips} /></td>
                                    <td>{data.gripsIssues}</td>
                                </tr>
                                <tr>
                                    <td>Scratches</td>
                                    <td><OptionText source={Scratches} value={data.scratches} /></td>
                                    <td>{data.scratchesIssues}</td>
                                </tr>
                                <tr>
                                    <td>Dings & Dents</td>
                                    <td><OptionText source={Dings} value={data.dingsAndDents} /></td>
                                    <td>{data.dingsAndDentsIssues}</td>
                                </tr>
                                <tr>
                                    <td>Side Mirrors Condition</td>
                                    <td><OptionText source={EFB} value={data.sideMirrors} /></td>
                                    <td>{data.sideMirrorsIssues} </td>
                                </tr>
                                <tr>
                                    <td>Exterior Lights Condition</td>
                                    <td><OptionText source={EFB} value={data.exteriorLights} /></td>
                                    <td>{data.exteriorLightsIssues} </td>
                                </tr>
                                <tr>
                                    <td>Comments</td>
                                    <td></td>
                                    <td>{data.exteriorNote} </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>

                    <Col md={12}>
                        <table className="table table-bordered tdfirst-nowrap tdsecond-nowrap">
                            <thead>
                                <tr style={{ backgroundColor: "#20c997" }}>
                                    <th colSpan={3} style={{ textAlign: "center" }}>Mechanical</th>
                                </tr>
                                <tr style={{ backgroundColor: "#d5d5d5" }}>
                                    <th>Inspection Point</th>
                                    <th>Condition</th>
                                    <th>Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Battery Condition</td>
                                    <td><OptionText source={EFBNA} value={data.battery} /></td>
                                    <td>{data.batteryIssues}</td>
                                </tr>
                                <tr>
                                    <td>Charging System</td>
                                    <td><OptionText source={EFBNA} value={data.chargingSystem} /></td>
                                    <td>{data.chargingSystemIssues}</td>
                                </tr>
                                <tr>
                                    <td>Chain or Belts Condition</td>
                                    <td><OptionText source={EFBNA} value={data.chainOrBelts} /></td>
                                    <td>{data.chainOrBeltsIssues}</td>
                                </tr>
                                <tr>
                                    <td>Hoses Condition</td>
                                    <td><OptionText source={EFBNA} value={data.hoses} /></td>
                                    <td>{data.hosesIssues}</td>
                                </tr>
                                <tr>
                                    <td>Engine Performance</td>
                                    <td><OptionText source={EFBNA} value={data.enginePerformance} /></td>
                                    <td>{data.enginePerformanceIssues}</td>
                                </tr>
                                <tr>
                                    <td>Air Filter Condition</td>
                                    <td><OptionText source={EFBNA} value={data.airFilter} /></td>
                                    <td>{data.airFilterIssues}</td>
                                </tr>
                                <tr>
                                    <td>Muffler System</td>
                                    <td><OptionText source={EFBNA} value={data.mufflerSystem} /></td>
                                    <td>{data.mufflerSystemIssues} </td>
                                </tr>
                                <tr>
                                    <td>Shocks</td>
                                    <td><OptionText source={EFBNA} value={data.shocks} /></td>
                                    <td>{data.shocksIssues} </td>
                                </tr>
                                <tr>
                                    <td>Engine Fluid Leaks</td>
                                    <td><OptionText source={EFBNA} value={data.engineFluidLeaks} /></td>
                                    <td>{data.engineFluidLeaksIssues} </td>
                                </tr>

                                <tr>
                                    <td>Transmission Fluid Leaks</td>
                                    <td><OptionText source={EFBNA} value={data.transmissionFluidLeaks} /></td>
                                    <td>{data.transmissionFluidLeaksIssues} </td>
                                </tr>
                                <tr>
                                    <td>Brake System and Pads</td>
                                    <td><OptionText source={EFBNA} value={data.brakeSystemAndPads} /></td>
                                    <td>{data.brakeSystemAndPadsIssues} </td>
                                </tr>
                                <tr>
                                    <td>Brake Fluid Leaks</td>
                                    <td><OptionText source={EFBNA} value={data.brakeFluidLeaks} /></td>
                                    <td>{data.brakeFluidLeaksIssues} </td>
                                </tr>
                                <tr>
                                    <td>Comments</td>
                                    <td></td>
                                    <td>{data.mechanicalNote} </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>

                    <Col md={12}>
                        <table className="table table-bordered tdfirst-nowrap">
                            <thead>
                                <tr style={{ backgroundColor: "#20c997" }}>
                                    <th colSpan={3} style={{ textAlign: "center" }}>Tires</th>
                                </tr>
                                <tr style={{ backgroundColor: "#d5d5d5" }}>
                                    <th>Tire Detail</th>
                                    <th>Specification</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Front Tire Brand</td>
                                    <td><OptionText source={MotorcycleTireBrand} value={data.frontBrand} /></td>
                                </tr>
                                <tr>
                                    <td>Front Tire Size</td>
                                    <td>{data.frontSize}</td>
                                </tr>
                                <tr>
                                    <td>Front Tire Tread Depth</td>
                                    <td><OptionText source={MotorcycleTireTreadDepth} value={data.frontTreadDepth} /></td>
                                </tr>
                                <tr>
                                    <td>Front Tire and Wheel Condition</td>
                                    <td><OptionText source={EFBNA} value={data.frontTireAndWheel} /></td>
                                </tr>
                                <tr>
                                    <td>Rear Tire Brand</td>
                                    <td><OptionText source={MotorcycleTireBrand} value={data.rearBrand} /></td>
                                </tr>
                                <tr>
                                    <td>Rear Tire Size</td>
                                    <td>{data.rearSize}</td>
                                </tr>
                                <tr>
                                    <td>Rear Tire Tread Depth</td>
                                    <td><OptionText source={MotorcycleTireTreadDepth} value={data.rearTreadDepth} /></td>
                                </tr>
                                <tr>
                                    <td>Rear Tire and Wheel Condition</td>
                                    <td><OptionText source={EFBNA} value={data.rearTireAndWheel} /></td>
                                </tr>
                                <tr>
                                    <td>Spare Tire</td>
                                    <td><OptionText source={SpareTire} value={data.spareTire} /></td>
                                </tr>
                                <tr>
                                    <td>Spare Tire Set</td>
                                    <td><OptionText source={SpareTireSet} value={data.spareTireSet} /></td>
                                </tr>
                                <tr>
                                    <td>Comments</td>
                                    <td>{data.tiresNote} </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col md={12}>
                        <table className="table table-bordered">
                            <thead>
                                <tr style={{ backgroundColor: "#20c997" }}>
                                    <th colSpan={3} style={{ textAlign: "center" }}>Summary</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{data.summary}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>}
        </>
    )
}

export default MotorcycleInspectionReport;