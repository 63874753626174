import React from "react";


const SpinnerButton = (props) => {
    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };

    const handleSubmit = (event) => {
        if (props.onSubmit) {
            props.onSubmit();
        }
    };

    const buttonType = props.type === 'submit' ? 'submit' : 'button';
    const className = props.className ? props.className : 'btn btn-primary';
    const buttonText = props.loading ? (props.loadingText ? props.loadingText : props.text) : props.text;

    return (
        <button
            className={className}
            type={buttonType}
            onClick={props.type === 'submit' ? undefined : handleClick}
            onSubmit={props.type === 'submit' ? handleSubmit : undefined}
            disabled={props.disabled}

        >
            {props.loading ? (
                <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            ) : null}
            {buttonText}
        </button>
    )

}

export default SpinnerButton;