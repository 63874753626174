import React, { useEffect, useState } from 'react';
import { redirect, useNavigate } from 'react-router-dom';

// import { useAuth } from '../../auth/AuthContext';

const Callback = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const { user, initUser } = useAuth();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        var currentUser= null;//await AuthService.handleCallback();
        // const currentUser = await AuthService.getUser();
        // initUser(currentUser);
        navigate('/dashboard');
      } catch (error) {
        setError(error.message || 'An error occurred during login.');
      } finally {
        setLoading(false);
      }
    };

    handleCallback();
  }, [navigate]);

  return (
    <div>
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
    </div>
  );
};

export default Callback;