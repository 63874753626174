import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import HerosectionHeader from "../../../../Componet/Herosection/HerosectionHeader";
import { Container, Table, Row, Col } from "reactstrap";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import moment from "moment";
import EmptyDataResult from "../../../../Componet/EmptyDataResult";

const OfferedInspector = () => {
    let { id } = useParams();
    const [data, setData] = useState();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const fetchOfferedInspectors = async () => {
        await axiosPrivate.get(`v1/quotation/offered/${id}`).then(res => {
            setData(res.data.data);
        });
    };

    useEffect(() => {
        fetchOfferedInspectors();
    }, []);

    return (
        <div className="page-wrapper">
            <HerosectionHeader name={"Offered Inspectors"} />
            <div className="page-content">
                <section>
                    <Container>
                        {(data && data.length > 0) ? <Row>
                            <div className="col">
                                <div className="table-responsive table-bordered border-bottom-0">
                                    <Table className="cart-table text-left table mb-0">
                                        <thead className="bg-light">
                                            <tr>
                                                <th scope="col" style={{ textAlign: "left" }}>
                                                    <strong>Inspector Id</strong>
                                                </th>
                                                <th scope="col" style={{ textAlign: "left" }}>
                                                    <strong>Inspector Name</strong>
                                                </th>
                                                <th scope="col" style={{ textAlign: "left" }}>
                                                    <strong>Inspector SZC</strong>
                                                </th>
                                                <th scope="col" style={{ textAlign: "left" }}>
                                                    <strong>Date</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {
                                                data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><span>{item.inspectorCode}</span></td>
                                                        <td><span>{item.firstName} {item.middleName} {item.lastName}</span></td>
                                                        <td><span>{item.serviceZipCode}</span></td>
                                                        <td><span>{moment(item.offeredDate).format('MM.DD.YYYY HH:mm')}</span></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                        </Row>
                            : <EmptyDataResult message="There is no data to display" />}
                        <Row>
                            <Col md={11}></Col>
                            <Col md={1}><button className="btn btn-dark mt-2" onClick={() => navigate("/orders")}>Back</button></Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </div >
    );
}

export default OfferedInspector;