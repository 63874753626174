import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import HerosectionHeader from "../../../../Componet/Herosection/HerosectionHeader";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Modal from 'react-bootstrap/Modal';
import VehicleTypeIcon from "../../../../Componet/VehicleTypeIcon";
import { useDropzone } from "react-dropzone";
import {
    Label,
    Col,
    Container,
    FormGroup,
    Row
} from "reactstrap";
import SpinnerButton from "../../../../Componet/Button/SpinnerButton";
import { QuotationStatus } from "../../../../store/Utility/Status";

const CarfaxReport = () => {
    let { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState({});
    const [modal, showModal] = useState({ show: false, message: null, isError: null });
    const [validationError, setValidationError] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const fetchOrders = async (selected) => {
        await axiosPrivate.get(`v1/quotation/carfax/${id}`).then(res => {
            setOrder(res.data.data);
        });
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        const file = new FileReader;
        file.readAsDataURL(acceptedFiles[0]);
        file.onload = function () {
            delete validationError.carfaxReport;
            setValidationError(validationError);
        }
    }, [])

    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop, maxFiles: 1,
        accept: {
            'application/pdf': [],
        }
    });

    const handleClose = () => showModal({ show: false, message: null, isSuccess: null });

    const handleRedirectOrders = () => {
        showModal({ show: false, message: null, isSuccess: null });
        navigate('/orders');
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        let error = {};
        if (acceptedFiles.length == 0) { error.carfaxReport = "Please select carfax report" }
        setValidationError(error);
        if (Object.keys(error).length != 0) return;

        const formData = new FormData();
        formData.append('carfaxReportFile', acceptedFiles[0]);
        formData.append('quotationId', id);
        setLoading(true);
        const response = await axiosPrivate.post(`v1/quotation/carfax`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            setLoading(false);
            showModal({ show: true, message: "CARFAX report successfully submitted", isError: false });
        }).catch(error => {
            setLoading(false);
            showModal({ show: true, message: error.response.data.Error, isError: true });
        });
    }

    return (<>
        <HerosectionHeader name={"CARFAX Report"} />
        <div className="page-wrapper">
            <div className="page-content">

                {<Modal show={modal.show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{!modal.isError ? 'Result' : 'Error'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modal.message}</Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-dark" onClick={!modal.isError ? handleRedirectOrders : handleClose}>Close</button>
                    </Modal.Footer>
                </Modal>}
                <section>
                    <Container>
                        <div className="checkout-form border p-4 rounded">
                            <form onSubmit={onSubmit}>

                                <Row style={{ alignItems: "center" }}>
                                    <Col md={1}>
                                        <div className="bg-primary d-inline-block rounded m-1">
                                            <div className="f-icon-m text-white">
                                                <VehicleTypeIcon vehicleType={order.vehicleType} />
                                            </div>
                                        </div></Col>
                                    <Col md={5}>
                                        <strong><span>{order.make}/{order.model}/{order.year}</span></strong>
                                    </Col>
                                    <Col md={3}>
                                        <div><span><strong>VIN: </strong>{order.vin}</span></div>
                                    </Col>
                                    <Col md={3}>
                                        <div><span><strong>License Plate: </strong>{order.licensePlate}</span></div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mt-5">
                                        {!(order.status == QuotationStatus.Completed || order.status == QuotationStatus.Closed || order.status == QuotationStatus.Cancelled) &&
                                            <FormGroup>
                                                <Label for="carFaxReportInput">{order.carFaxReportId ? "You can upload a new CARFAX Report here to update the existing one. Please double-check the VIN and license plate against the pictures provided in report before uploading" : "You can upload a new CARFAX Report here. Please double-check the VIN and license plate against the pictures provided in report before uploading"}</Label>
                                                <div name="carFaxReportInput" className="dropzone" {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {
                                                        acceptedFiles.length > 0 ? <p>{acceptedFiles.length} file selected</p> :
                                                            isDragActive ?
                                                                <p>Drop the files here ...</p> :
                                                                <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                    }
                                                </div>
                                            </FormGroup>
                                        }
                                    </Col>
                                </Row>
                                {order.carFaxReportId && <Row>
                                    <Col md={12}>
                                        <iframe src={order.carFaxReportUrl} width="100%" height="800px"></iframe>
                                    </Col>
                                </Row>}
                                <Row>
                                    <Col md={10}>
                                        <div style={{ color: "red", fontSize: "0.9rem" }}>
                                            {Object.keys(validationError).length > 0 ? Object.values(validationError).map(item => <div className="row"><span>* {item}</span></div>) : null}
                                        </div>
                                    </Col>
                                    <Col md={1}>
                                     {!(order.status == QuotationStatus.Completed || order.status == QuotationStatus.Closed || order.status == QuotationStatus.Cancelled) &&  <SpinnerButton type="submit" text="Submit" className="btn btn-dark my-5" loading={loading}></SpinnerButton>}  
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </Container>
                </section >
            </div>
        </div>
    </>);
}

export default CarfaxReport;