import React from "react";
import InputMask from 'react-input-mask';

const PhoneMaskInput = ({ name, value, disabled, className, onChange }) => {
  return (
    <InputMask
      name={name}
      mask="+1 (999) 999 9999"
      maskChar="_"
      value={value}
      onChange={onChange}
      className={className}
      disabled={disabled}
      placeholder="Enter phone number"
    />
  );
};

export default PhoneMaskInput;