import { Route, Routes, Navigate, Outlet, useLocation } from 'react-router-dom'
// import { useAuth } from './auth/AuthContext';
import useAuth from './hooks/useAuth';


const PrivateRoutes = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();
    return (
        auth?.user?.roles?.find(role => allowedRoles.includes(role)) 
        ?  <Outlet />
        : auth?.user
            ? <Navigate to="/unauthorized" state={{ from: location }} replace />
            : <Navigate to="/login" state={{ from: location }} replace />
    );
    // return auth?.user ? (
    //    <Outlet/>
    // ) : <p>Login will be here</p>
}

export default PrivateRoutes;