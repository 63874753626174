import React from "react";
import { VehicleTypes } from "../store/Utility/Status";
import truck from '../assets/images/icon/truck.png';
import motorbike from '../assets/images/icon/motorbike.png';
import van from '../assets/images/icon/van.png';
import car from '../assets/images/icon/car.png';


const VehicleTypeIcon = (props) => {
    let width = props.width;
    if (width == undefined || width == null) {
        width = 60;
    }
    switch (props.vehicleType) {
        case VehicleTypes.Car:
            return <img src={car} width={width} />
        case VehicleTypes.Commercial:
            return <img src={truck} width={width} />
        case VehicleTypes.Motorcycle:
            return <img src={motorbike} width={width} />
        case VehicleTypes.RV:
            return <img src={van} width={width} />
        default:
            return <i className="flaticon-lightbulb"></i>;
    }
}

export default VehicleTypeIcon;