import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";

import HerosectionHeader from "../../../../Componet/Herosection/HerosectionHeader";
import { Link } from 'react-router-dom'
import { VehicleTypes } from "../../../../store/Utility/Utility";
import EmptyDataResult from "../../../../Componet/EmptyDataResult";
import axios from "../../../../api/axios";

import VehicleTypeIcon from "../../../../Componet/VehicleTypeIcon";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";


const SearchJobs = () => {
    const [jobs, setJobs] = useState();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const SearchJob = async () => {
        await axiosPrivate.get(`v1/quotation/search`)
            .then(res => {
                setJobs(res.data.data);
            });
    }

    useEffect(() => {
        SearchJob();
    }, [])


    const onAcceptHandler = async (id) => {
        const response = await axiosPrivate.post(`v1/quotation/assign`, { quotationId: id, userId: auth?.user.id }).then(res => {
            navigate("/workhub/queued");
        }).catch(error => {
            alert("Sorry an error occured");
        });
    }

    return (
        <>
            <HerosectionHeader folder1={"pages"} folder2={"Company"} name={"Search Jobs"} />
            <div class="container">
                <div class="row">
                    <div class="col">
                        {(jobs && jobs.length > 0) ?
                            jobs.map((job, index) => ((
                                <div class="card bg-transparent flex-md-row align-items-center p-md-0 p-3 mt-3 mb-3">
                                    <div class="col-md-2">
                                        <div className="bg-primary p-2 d-inline-block rounded m-4">
                                            <div className="f-icon-m text-white">
                                                <VehicleTypeIcon vehicleType={job.vehicleType} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body col-md-6">
                                        <div>
                                            <div class="d-inline-block bg-light text-center px-2 py-1 rounded me-2">
                                                <span class="text-primary">{VehicleTypes.find(t => t.id == job.vehicleType).name}</span>
                                            </div>
                                            <span>{job.make} / {job.model} / {job.year}</span>

                                        </div>
                                        <h2 class="h5 my-3">
                                            <a class="link-title">{job.description}</a>
                                        </h2>
                                        <ul class="list-inline mb-0">
                                            <li class="list-inline-item pe-3"> <a class="list-group-item-action"><i class="lar la-calendar-info ms-1 text-primary ic-1x">
                                            </i> You will get ${job.vehicleType == 1 ? 70 :
                                                (
                                                    (job.vehicleType == 2 ? 70 :
                                                        (job.vehicleType == 3 ? 80 : 100)))} for this inspection</a>

                                            </li>
                                            <li class="list-inline-item pe-3">
                                                <i className="la la-map-marker"></i>
                                                <a class="list-group-item-action">
                                                   Est. {job.distance.toFixed(1)} miles
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-2">
                                        <button className="btn btn-primary" onClick={() => onAcceptHandler(job.id)}>Accept</button>
                                        {/* <Link className='btn btn-primary' onClick={onAcceptHandler} key={job.id} to="/workhub/queued"> Accept</Link> */}
                                    </div>
                                </div>))) : <EmptyDataResult />}

                    </div>
                </div>
            </div>
        </>);
}

export default SearchJobs;