import React, { useEffect, useRef, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

// Your Google Maps API Key
const API_KEY = 'AIzaSyB9SpTfchvzhAwUhVhxz1Nlc9tGIR7orrE';

// Configure the Google Maps API loader
const libraries = ['places'];

const AutocompleteComponent = (props) => {
  var parentInputRef = useRef(null);
  const inputRef = props.inputRef ? props.inputRef : parentInputRef;


  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
    libraries,
  });

  useEffect(() => {
    if (isLoaded && inputRef.current) {
      const options = {
        types: ['address'],
        componentRestrictions: { country: 'us' }
      };
      const autocompleteInstance = new window.google.maps.places.Autocomplete(inputRef.current, options);

      autocompleteInstance.addListener('place_changed', () => {
        const place = autocompleteInstance.getPlace();
        props.onPlaceChanged(place);
      });
    }
  }, [isLoaded]);

  return (
    <div>
      <input
        ref={inputRef}
        type="text"
        placeholder={props.placeholder ? props.placeholder : "Search for a place"}
        className='form-control'
        disabled={props.disabled}
        defaultValue={props.defaultValue}
      />
    </div>
  );
};

export default AutocompleteComponent;
