import React from "react";
import HerosectionHeader from '../../Componet/Herosection/HerosectionHeader';
// import AboutFAQ from '../../Componet/About/AboutFaq'

const FAQPublic=()=>{
    return(<div>
        <HerosectionHeader folder1={"pages"} folder2={"Company"} name={"FAQ"} />
        {/* <AboutFAQ /> */}
    </div>)
}

export default FAQPublic;