import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
    Container,
    Row
} from "reactstrap";
import { Tab, Tabs } from "react-bootstrap";

import CarInspectionReport from "./CarInspectionReport";
import MotorcycleInspectionReport from "./MotorcycleInspectionReport";
import CommercialInspectionReport from "./CommercialInspectionReport";
import RVInspectionReport from "./RVInspectionReport";
import { VehicleTypes } from "../../../store/Utility/Status";
import EmptyDataResult from "../../../Componet/EmptyDataResult";
import InspectionImages from "./InspectionImages";

const InspectionReport = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const axiosPrivate = useAxiosPrivate();
    const [key, setKey] = useState('inspection');
    const [data, setData] = useState(null);
    const [error, setErrorMessage] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const getReport = async () => {
        await axiosPrivate.get(`v1/quotation/report/${id}`).then(res => {
            var response = res?.data.data;
            setData(response);
        }).catch(error => {
            setErrorMessage(error.response.data.error);
        });
    };


    const getFiles = useCallback(async () => {
        await axiosPrivate.get(`v1/file/list/${id}`).then(res => {
            setUploadedFiles(res.data.data);
        }).catch(error => {
        });;
    }, [uploadedFiles])


    useEffect(() => {
        getReport();
        getFiles();
    }, []);



    let report = () => {
        switch (data.vehicleType) {
            case VehicleTypes.Car: return <CarInspectionReport data={data?.inspectionData} />;
            case VehicleTypes.Motorcycle: return <MotorcycleInspectionReport data={data?.inspectionData} />
            case VehicleTypes.Commercial: return <CommercialInspectionReport data={data?.inspectionData} />
            case VehicleTypes.RV: return <RVInspectionReport data={data?.inspectionData} />
            default: <EmptyDataResult />
        }
    };

   
    return (<div>
        <div className="page-wrapper">
            <div className="page-content">

                <section>
                    <Container>
                        <Row>
                            <span style={{ marginBottom: "20px" }}>
                                <h1>Inspection Report</h1></span>
                        </Row>
                        {data ? <Row>
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="mb-3"

                            >
                                <Tab eventKey="inspection" title="Inspection Report" tabClassName={key == "inspection" ? "" : "text-dark"}>
                                    {report()}
                                </Tab>
                                {data?.isCarfaxRequested && <Tab eventKey="carfax" title="CARFAX Report" tabClassName={key == "carfax" ? "" : "text-dark"}>
                                    <iframe src={data.carfaxReportUrl} width="100%" height="800px"></iframe>
                                </Tab>}
                                <Tab eventKey="pictures" title="Pictures" tabClassName={key == "pictures" ? "" : "text-dark"}>
                                    <InspectionImages uploadedFiles={uploadedFiles} />
                                </Tab>
                            </Tabs>
                        </Row>
                            : <EmptyDataResult message={error} />
                        }
                    </Container>
                </section>
            </div>
        </div>
    </div>)
}

export default InspectionReport;