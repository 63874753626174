import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from '../../api/axios';
import { RESET_PASS } from '../../store/Utility/Resource';
import { useSpinner } from '../../context/SpinnerContext';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';


const PasswordReset = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const email = queryParams.get('email');
    const [password, setPassword] = useState();
    const [passwordConfirm, setPasswordConfirm] = useState();
    const [modal, showModal] = useState({ show: false, message: null, isSuccess: null });
    const { showLoader, hideLoader } = useSpinner();


    const handleClose = () => showModal({ show: false });

    const handleGoToLogin = () => {
        showModal({ show: false, message: null, isSuccess: null });
        navigate('/login');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        showLoader();
        try {
            const response = await axios.post(RESET_PASS,
                JSON.stringify({ "email": email, "password": password, "confirmedPassword": passwordConfirm, token: token }),
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                });
            var res = response?.data;
            debugger
            if (res.data == false) {
                showModal({ show: true, message: "Your password reset could not be completed because the link has already been used!", isSuccess: false });
            } else {
                showModal({ show: true, message: "Your password has been successfully created. You can log in to your account using the button below.", isSuccess: true });
            }

            hideLoader();
        } catch (err) {
            showModal({ show: true, message: "An error occured while saving!", isSuccess: false });
            hideLoader();
            if (!err?.response) {
                // setErrMsg('No server response');
            } else if (err.response?.status === 400) {
                // setErrMsg('Missing username or password');
            } else if (err.response?.status === 401) {
                // setErrMsg('UnAuthorized')
            } else {
                // setErrMsg('Login Failed');
            }
            // err.current.focus();

        }
    }

    return (
        <div className="page-content">
            <div>
                <div>
                    <div>
                        {<Modal show={modal.show} onHide={handleClose} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>{modal.isSuccess ? 'Result' : 'Error'}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{modal.message}</Modal.Body>
                            <Modal.Footer>
                                {modal.isSuccess && <button className="btn btn-dark" onClick={handleGoToLogin}>Login</button>}
                                {!modal.isSuccess && <button className="btn btn-dark" onClick={handleClose}>Close</button>}
                            </Modal.Footer>
                        </Modal>}
                        <section>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-7 col-12">
                                        <img className="img-fluid" src={require("../../assets/images/login.png")} alt="" />
                                    </div>
                                    <div className="col-lg-5 col-12">
                                        <div>
                                            <h2 className="mb-3">Reset Password</h2>
                                            <form onSubmit={handleSubmit}>
                                                <div className="messages"></div>
                                                <div className="form-group">
                                                    <input
                                                        id="password"
                                                        type="password"
                                                        name="password"
                                                        className="form-control"
                                                        placeholder="New Password"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        value={password}
                                                        required="required"
                                                        data-error="password is required." />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        id="passwordConfirm"
                                                        type="password"
                                                        name="passwordConfirm"
                                                        className="form-control"
                                                        placeholder="Confirm New Password"
                                                        onChange={(e) => setPasswordConfirm(e.target.value)}
                                                        value={passwordConfirm}
                                                        required="required"
                                                        data-error="password confirm is required." />
                                                    <div className="help-block with-errors"></div>
                                                </div>

                                                <button className="btn btn-primary">Reset Password</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordReset;