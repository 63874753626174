import React, { useEffect, useState } from "react";
import HerosectionHeader from '../../../Componet/Herosection/HerosectionHeader'
import {
    Col,
    Container,
    FormGroup,
    Input,
    Row
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import CountUp from 'react-countup';
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { DashboardMessage } from "../../../store/Utility/Utility";
import { Roles } from "../../../store/Utility/Utility";
import moment from "moment";
import { OrderStatus } from "../../../store/Utility/Status";

const Dashboard = () => {
    const [messages, SetMessages] = useState();
    const [userStats, SetUserStats] = useState({ completedInspection: 0, awaitingInspection: 0 });
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const GetMessages = async () => {
        await axiosPrivate.get(`v1/dashboard/messages`)
            .then(res => {
                var data = res.data.data;
                if (!auth?.user?.profile) {
                    data.push({ key: 'ProfileImageMissing' });
                }
                SetMessages(data);
            });
    }

    const GetUserStats = async () => {
        await axiosPrivate.get(`v1/inspection/user-inspections/${auth?.user?.id}`)
            .then(res => {
                var data = res.data.data;               
                SetUserStats(data);
            });
    }

    useEffect(() => {
        if (auth?.user?.roles.indexOf(Roles.VehicleInspector) != -1) {
            GetMessages();
            GetUserStats();
        }
    }, [])

    const handleRedirect = (status) => {
        if (status == OrderStatus.AwaitingInspection) {
            navigate('/workhub/queued');
        } else if (status == OrderStatus.Completed) {
            navigate('/workhub/completed');
        }
    }

    return <>
        <HerosectionHeader folder1={"pages"} folder2={"Company"} name={"Dashboard"} />
        <div className="page-wrapper">
            <div className="page-content">
                <section>
                    <Container>
                        <Row>
                            {(messages && messages.length > 0)
                                && messages.map((item) => {
                                    let selectedItem = DashboardMessage[item.key];
                                    return (
                                        <div class={selectedItem.className ? selectedItem.className : "alert alert-primary"} role="alert">
                                            {selectedItem.badge ? <span className={selectedItem.badge.className}>{selectedItem.badge.text}</span> : null}
                                            {item.createdDate ? `${moment(item.createdDate).format('MM.DD.YYYY')} - ` : null}
                                            {selectedItem.text ? selectedItem.text : item.message} {selectedItem.navigation ? (<Link to={selectedItem.navigation}>here</Link>) : null}
                                        </div>)
                                })
                            }
                        </Row>
                        <Row>
                            <Row>
                                <div class="col-12 col-sm-6 col-xl-3">
                                    <div class="card widget-card border-light shadow-sm">
                                        <div class="card-body p-4" onClick={() => handleRedirect(OrderStatus.AwaitingInspection)} style={{ cursor: "pointer" }}>
                                            <div class="row">
                                                <div class="col-8">
                                                    <h5 class="card-title widget-card-title mb-3">Awaiting Inspection</h5>
                                                    <h4 class="card-subtitle text-body-secondary m-0">{userStats.awaitingInspection}</h4>
                                                </div>
                                                <div class="col-4">
                                                    <div class="d-flex justify-content-end">
                                                        <div class="lh-1 text-white bg-primary rounded-circle p-3 d-flex align-items-center justify-content-center">
                                                            <i class="las la-hourglass-half fs-4"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-xl-3">
                                    <div class="card widget-card border-light shadow-sm">
                                        <div class="card-body p-4" onClick={() => handleRedirect(OrderStatus.Completed)} style={{ cursor: "pointer" }}>
                                            <div class="row">
                                                <div class="col-8">
                                                    <h5 class="card-title widget-card-title mb-3">Completed Inspection</h5>
                                                    <h4 class="card-subtitle text-body-secondary m-0">{userStats.completedInspection}</h4>
                                                </div>
                                                <div class="col-4">
                                                    <div class="d-flex justify-content-end">
                                                        <div class="lh-1 text-white bg-primary rounded-circle p-3 d-flex align-items-center justify-content-center">
                                                            <i class="las la-check-circle fs-4"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </Row>
                    </Container>
                </section>
            </div>
        </div>
    </>
}

export default Dashboard;