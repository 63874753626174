import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    Col,
    Container,
    Row,
} from "reactstrap";
import axios from '../../../api/axios';
import { VehicleTypes } from '../../../store/Utility/Status';
import moment from 'moment';

import { Link } from 'react-router-dom';

function Completion(props) {
    const [messageBody, setMessageBody] = useState('');
    const [stripePromise, setStripePromise] = useState(loadStripe(process.env.REACT_APP_STRIPE_PK));
    const [order, setOrder] = useState({});

    useEffect(() => {
        if (!stripePromise) return;

        stripePromise.then(async (stripe) => {
            const url = new URL(window.location);
            const clientSecret = url.searchParams.get('payment_intent_client_secret');
            const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

            await axios.get(`v1/quotation/payment/${paymentIntent.id}`).then(res => {
                setOrder(res.data.data);
            });

            //   setMessageBody(error ? `> ${error.message}` : (
            //     <>&gt; Payment {paymentIntent.status}: <a href={`https://dashboard.stripe.com/test/payments/${paymentIntent.id}`} target="_blank" rel="noreferrer">{paymentIntent.id}</a></>
            //   ));
        });
    }, [stripePromise]);

    return (
        <div>
            <div className="page-content">
                <section>
                    <Container>
                        <Row >
                            <Col lg={2} md={12}></Col>
                            <Col lg={8} md={12}>
                                <div className="checkout-form border p-4 rounded">
                                    <h2 className="mb-4">Order Confirmed</h2>
                                    <div>
                                        <h4>Thank you {order.customerName}</h4>
                                        <span>Your inspection request has been successfully placed. You’ll receive email updates as your inspector is assigned, the inspection time is arranged, and your report is ready.</span>
                                    </div>
                                    <div className="mt-5">
                                        <div className="step-component">
                                            <Row>
                                                <span class="text"><b>Order Number: </b>{order.orderNumber}</span>

                                            </Row>
                                            <Row>
                                                <span class="text"><b>Order Details: </b>{order.vehicleType == VehicleTypes.Car ? 'Car Inspection' :
                                                    order.vehicleType == VehicleTypes.Commercial ? 'Commercial Inspection' :
                                                        order.vehicleType == VehicleTypes.Motorcycle ? 'Motorcycle Inspection' :
                                                            order.vehicleType == VehicleTypes.RV ? 'RV Inspection' : null
                                                }
                                                    {order.isCarFaxReported ? ' & CARFAX Report' : null}
                                                </span>

                                            </Row>
                                            <Row>
                                                <span class="text"><b>Vehicle: </b>{`${order.make}, ${order.model}, ${order.year}`}</span>

                                            </Row>
                                            <Row>
                                                <span class="text"><b>Transaction Date: </b>{moment(order.transactionDate).format('LLL')} </span>

                                            </Row>
                                            <Row>
                                                <span class="text"><b>Total: </b>${order.amount?.toFixed(2)}</span>

                                            </Row>
                                        </div>
                                        {/* <div style={{ color: "red", fontSize: "0.9rem" }}>
                      {messageBody}
                    </div> */}

                                    </div>
                                    <div className="mt-3">
                                        <h4>What to Do:</h4>
                                        <ul>
                                            <li>Inform the seller that you’ve ordered an inspection service.</li>
                                            <li>Let the seller know that an inspector will contact them to schedule an appointment.</li>
                                            <li>Request that the seller has the vehicle washed before the inspection for better results.</li>
                                        </ul>
                                        <h6>Feel free to reach out to us at support@inspectiver.com if you have any questions or need assistance.</h6>
                                    </div>

                                    <div>
                                        {/* <button type="button" className="btn btn-dark my-5 btn-sm ms-3" >Print</button> */}
                                        <Link to="/" className="btn btn-dark my-5 btn-sm ms-3">Homepage</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </div>
    );
}

export default Completion;