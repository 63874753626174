import React, { useEffect } from 'react'
import "./Vender.js"

import { OidcProvider } from "@axa-fr/react-oidc";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { BrowserRouter as Router, Route, Routes, useLocation, Outlet } from 'react-router-dom'
import Footer1 from './Layout/Footer/Footer1.js'
import HomePageBecomeInspector from './defaultPages/ComingSoon/HomePageBecomeInspector.js';
import BecomeInspectorHeader from './defaultPages/ComingSoon/BecomeInspectorHeader.js';
import AboutUsInspector from './defaultPages/ComingSoon/AboutUsInspector.js';
import JoinUs from './defaultPages/ComingSoon/JoinUs.js';
import Unauthorized from './defaultPages/ComingSoon/Unauthorized.js';
// import Login from './defaultPages/Login/Login.js'
import Login from './Pages/Public/Login.js';
import Signup from './defaultPages/Signup/Signup.js'
import PasswordReset from './Pages/Public/PasswordReset.js';
import Header2 from './Layout/Header/Public/Header2.js';


import ForgotPassword from './Pages/Public/ForgotPassword.js';
import FAQ from './Pages/Utilities/FaqPage.js';
import Pricing from './Pages/Utilities/PricingPage.js';
import PrivacyPolicy from './Pages/Utilities/Privacy.js';
import TermsConditions from './Pages/Utilities/T&CPage.js';
import Maintenance from './Pages/Utilities/MaintanancePage.js';
import ComingSoon from './Pages/Utilities/ComingSoon.js';
import Error404 from './Pages/Utilities/Error404.js';
import Error404WithoutHeaderFooter from './Componet/NotDefinedPage.js';

import Inspector from './Pages/Public/Inspector/Inspector.js';
import ConfirmInspector from './Pages/Public/Inspector/ConfirmInspector.js';
import Quotation from './Pages/Public/Quotation/Quotation.js';
import Callback from './Pages/Public/callback.js';
import Profile from './Pages/Protected/Inspector/Profile/Profile.js';
import Stats from './Pages/Protected/Inspector/Profile/Stats.js';
import InspectorReport from './Pages/Protected/Inspector/Profile/InspectorReport.js';
import FAQs from './Pages/Protected/Inspector/FAQ.js';
import Payout from './Pages/Protected/Inspector/Payout.js';
import Support from './Pages/Protected/Inspector/Support.js';
import Training from './Pages/Protected/Inspector/Training.js';
import Workhub from './Pages/Protected/Inspector/Workhub.js';
import PrivateRoute from './PrivateRoute.js';
import HeaderPrivate from './Layout/Header/Private/HeaderPrivate.js';

import Dashboard from './Pages/Protected/Inspector/Dashboard.js';
import CompletedJobs from './Pages/Protected/Inspector/Workhub/CompletedJobs.js';
import QueuedJobs from './Pages/Protected/Inspector/Workhub/QueuedJobs.js';
import SearchJobs from './Pages/Protected/Inspector/Workhub/SearchJobs.js';
import InspectorList from './Pages/Protected/Admin/InspectorList.js';
import InspectorDetail from './Pages/Protected/Admin/InspectorDetail.js';
import OrderList from './Pages/Protected/Admin/OrderList.js';

import Header from './Layout/Header/Header.js';
import Contact from './Pages/Public/Contact.js';
import FAQPublic from './Pages/Public/Faq.js';

import TrainingDetail from './Pages/Protected/Inspector/TrainingDetail.js';

import PrivateRoutes from './PrivateRoutes.js';
import Logout from './Pages/Public/logout.js';

import CarInspection from './Pages/Protected/Inspector/Workhub/Inspection/CarInspection.js';
import MotorcycleInspection from './Pages/Protected/Inspector/Workhub/Inspection/MotorcycleInspection.js';
import CommercialInspection from './Pages/Protected/Inspector/Workhub/Inspection/CommercialInspection.js';
import RVInspection from './Pages/Protected/Inspector/Workhub/Inspection/RVInspection.js';
import OrderComplete from './Pages/Public/Quotation/OrderComplete.js';

import Completion from './Pages/Public/Quotation/Completion.js';

import CarOrder from './Pages/Protected/Admin/Order/CarOrder.js';
import MotorcycleOrder from './Pages/Protected/Admin/Order/MotorcycleOrder.js';
import CommercialOrder from './Pages/Protected/Admin/Order/CommercialOrder.js';
import RVOrder from './Pages/Protected/Admin/Order/RVOrder.js';

import { SpinnerProvider } from './context/SpinnerContext.js';

import { Roles } from './store/Utility/Utility.js';
import CarfaxReport from './Pages/Protected/Admin/Order/CarfaxReport.js';
import InspectionReport from './Pages/Public/Customer/InspectionReport.js';
import OfferedInspector from './Pages/Protected/Admin/Order/OfferedInspector.js';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import InspectorSearch from './Pages/Protected/Admin/Management/Inspector/InspectorSearch.js';
import InspectorPayment from './Pages/Protected/Admin/Management/Inspector/InspectorPayment.js';


function AppBecomeInspector() {
  const location = useLocation();
  const getUrl = (pathname) => {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/coming_soon'
      ? true
      : `/${pathArray[1]}` === '/maintenance'
        ? true
        : `/${pathArray[1]}` === '/error_404'
          ? true
          : `/${pathArray[1]}` === '*'
            ? true
            : false;
  };

  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const setHeader = (pathname) => {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/index4' ? true : false;
  }

  const setFooter = (pathname) => {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/index10' ? false : true;
  };
  const scrollbarStyle = `
  ::-webkit-scrollbar {
    display: none;
  }`;

  useEffect(() => {

    const handlePopstate = () => {
      window.location.reload(); // Refresh the page on popstate event (back button)
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {

    if (location.pathname == "/") {
      document.body.style.backgroundColor = "#d3d3d3";
    } else {
      document.body.style.backgroundColor = '';
    }
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, [location.pathname]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {getUrl(location.pathname) ?
        <Routes>
          <Route path="/coming_soon" element={<ComingSoon />} />
          <Route path="/error_404" element={<Error404 />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="*" element={<Outlet />}>
            {/* Render the Error404 component without Header and Footer */}
            <Route index element={<Error404WithoutHeaderFooter />} />
          </Route>
        </Routes> :

        <div className="page-wrapper">
          {/* {setHeader(location.pathname) ? <Header2 /> : <Header1 />} */}
          <BecomeInspectorHeader />
          <SpinnerProvider>
            <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey} >
              <Routes>
                {/* difaultHompage */}
                <Route path='/' element={<HomePageBecomeInspector />} />
                <Route path='/Login' element={<Login />} />
                <Route path='/Signup' element={<Signup />} />

                {/* difaultHompage */}

                {/* NavbarHome */}
                <Route path='/about-us' element={<AboutUsInspector />} />
                <Route path='/join-us' element={<JoinUs />} />


                <Route path='/sign_in' element={<Login />} />
                <Route path='/sign_up' element={<Signup />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/reset-password' element={<PasswordReset />} />


                <Route path='/terms_conditions' element={<TermsConditions />} />
                <Route path='/unauthorized' element={<Unauthorized />} />


                <Route path='/contact' element={<Contact />} />
                <Route path='/faq-public' element={<FAQPublic />} />

                <Route path='/logout' element={<Logout />} />

                <Route element={<PrivateRoutes allowedRoles={[Roles.Admin, Roles.VehicleInspector]} />}>
                  <Route path='/profile' element={<Profile />}></Route>
                  <Route path='/stats' element={<Stats />}></Route>
                  <Route path='/my-report' element={<InspectorReport />}></Route>
                </Route>

                <Route element={<PrivateRoutes allowedRoles={[Roles.Admin]} />}>
                  <Route path='/orders' element={<OrderList />} />
                </Route>

                <Route element={<PrivateRoutes allowedRoles={[Roles.Admin, Roles.EmployeeAdmin]} />}>
                  <Route path='/inspector-list' element={<InspectorList />} />
                  <Route path='/inspector/detail/:id' element={<InspectorDetail />} />
                </Route>

                <Route element={<PrivateRoutes allowedRoles={[Roles.Admin]} />}>
                  <Route path='/inspector-search' element={<InspectorSearch />} />
                  <Route path='/inspector/payment/:id' element={<InspectorPayment />} />
                </Route>

                <Route element={<PrivateRoutes allowedRoles={[Roles.Admin, Roles.EmployeeAdmin, Roles.Employee, Roles.VehicleInspector]} />}>
                  <Route path='/dashboard' element={<Dashboard />} />
                </Route>




                <Route path='/inspector' element={<Inspector />} />
                <Route path='/confirm-inspector' element={<ConfirmInspector />} />
                {/* <Route path='/quotation' element={<Quotation />} /> */}
                <Route path='/order-complete' element={<OrderComplete />} />
                <Route path="/completion" element={<Completion />} />
                <Route path="/callback" element={<Callback />} />
                <Route path='/report' element={<InspectionReport />} />
                {/* <Route path="/profile" element={<Profile/>} /> */}



                <Route element={<PrivateRoutes allowedRoles={[Roles.Admin, Roles.EmployeeAdmin, Roles.Employee]} />}>
                  {/* <Route path="/workhub" element={<Workhub />} /> */}
                  <Route path="/payout" element={<Payout />} />
                </Route>

                <Route element={<PrivateRoutes allowedRoles={[Roles.Admin, Roles.EmployeeAdmin, Roles.Employee, Roles.VehicleInspector]} />}>
                  <Route path="/faqs" element={<FAQs />} />
                  <Route path="/training" element={<Training />} />
                  <Route path="/training/:id" element={<TrainingDetail />} />
                  <Route path="/help" element={<Support />} />
                  <Route path="/support" element={<Support />} />
                </Route>

                <Route element={<PrivateRoutes allowedRoles={[Roles.Admin, Roles.VehicleInspector, Roles.EmployeeAdmin, Roles.Employee]} />}>
                  <Route path="/workhub" element={<Workhub />} />
                  <Route path="/workhub/completed" element={<CompletedJobs />} />
                  <Route path="/workhub/queued" element={<QueuedJobs />} />
                  <Route path="/workhub/search" element={<SearchJobs />} />
                  <Route path='/workhub/inspection/car/:id' element={<CarInspection />} />
                  <Route path='/workhub/inspection/motorcycle/:id' element={<MotorcycleInspection />} />
                  <Route path='/workhub/inspection/commercial/:id' element={<CommercialInspection />} />
                  <Route path='/workhub/inspection/rv/:id' element={<RVInspection />} />
                </Route>

                <Route element={<PrivateRoutes allowedRoles={[Roles.Admin, Roles.EmployeeAdmin, Roles.Employee]} />}>
                  <Route path='/orders/car/:id' element={<CarOrder />} />
                  <Route path='/orders/motorcycle/:id' element={<MotorcycleOrder />} />
                  <Route path='/orders/commercial/:id' element={<CommercialOrder />} />
                  <Route path='/orders/rv/:id' element={<RVOrder />} />
                  <Route path='/orders/carfax/:id' element={<CarfaxReport />} />
                  <Route path='/orders/offered/:id' element={<OfferedInspector />} />
                  {/* <Route path='/workhub/inspection/motorcycle/:id' element={<MotorcycleInspection />} />
              <Route path='/workhub/inspection/commercial/:id' element={<CommercialInspection />} />
              <Route path='/workhub/inspection/rv/:id' element={<RVInspection />} /> */}
                </Route>


                <Route path="*" element={<Error404 />} />

                {/* Company */}
              </Routes>
            </GoogleReCaptchaProvider>
          </SpinnerProvider>
        </div>
      }
    </>
  )
}

export default AppBecomeInspector

