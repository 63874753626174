import axios from "../api/axios";
import useAuth from "./useAuth";
import { REFRESH_TOKEN_URL } from '../store/Utility/Resource';

const useRefreshToken = () => {
    const { auth, setAuth } = useAuth();

    const refresh = async () => {
        
        const response = await axios.post(REFRESH_TOKEN_URL, JSON.stringify({ "token": auth.user.access_token, "refreshToken": auth.user.refresh_token }),{
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        });

        setAuth(prevAuth => ({
            ...prevAuth, // Spread the previous auth state to preserve other values
            user: {
              ...prevAuth.user, // Spread the user object to preserve its other properties
              access_token: response.data.data.token, // Update only the access_token
              refresh_token: response.data.data.refreshToken // Update only the refresh_token
            }
          }));

        return response.data.data.token;
    }
    
    return refresh;

}

export default useRefreshToken;