import React, { useState, useCallback } from "react";
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { useDropzone } from "react-dropzone";
import {
    Label,
    Col,
    Container,
    FormGroup,
    Input,
    Row
} from "reactstrap";
import InputMask from 'react-input-mask';
import Modal from 'react-bootstrap/Modal';
import { useSpinner } from "../../../context/SpinnerContext";
import { isValidSSN, isValidEmail } from "../../../store/Utility/Helper";
import { Agreements } from '../../../store/Utility/Utility';
import { IndependentContractor, InspectorAccountAccess, InspectorApplication, InspectorPrivacyPolicy } from "../../../store/Utility/Agreements";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const ConfirmInspector = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const axiosPrivate = useAxiosPrivate();
    const [isTermsAndConditionChecked, SetIsTermsAndConditionChecked] = useState(false);
    const [ssn, setSSN] = useState('');
    const [email, setEmail] = useState('');
    const [aseExpiryDate, setASEExpiryDate] = useState();
    const [modal, showModal] = useState({ show: false, message: null, isError: null });
    const [modalAgreement, setModalAgreement] = useState({ show: false, contractName: null });
    const [agreement, setAgreement] = useState(
        {
            independentContractorInputChecked: false, independentContractorSigned: false,
            inspectorAccountAccessInputChecked: false, inspectorAccountAccessSigned: false,
            inspectorApplicationInputChecked: false, inspectorApplicationSigned: false,
            inspectorPrivacyPolicyInputChecked: false, inspectorPrivacyPolicySigned: false
        });

    const { showLoader, hideLoader } = useSpinner();

    const [frontImagePreview, setFrontImagePreview] = useState();
    const [backImagePreview, setBackImagePreview] = useState();
    const [ASEImagePreview, setASEImagePreview] = useState();

    const [validationError, setValidationError] = useState({});
    const navigate = useNavigate();


    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        const file = new FileReader;
        file.readAsDataURL(acceptedFiles[0]);
        file.onload = function () {
            setFrontImagePreview(file.result);
        }
    }, [])
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop, maxFiles: 1,
        accept: {
            'image/jpeg': [],
            'image/png': []
        }
    });
    const { acceptedFiles: backAcceptedFile, getRootProps: getRootBackProps, getInputProps: getInputBackProps, isDragActive: isBackDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        maxFiles: 1,
        onDrop: (backAcceptedFile) => {
            const fileBackImage = new FileReader;
            fileBackImage.readAsDataURL(backAcceptedFile[0]);
            fileBackImage.onload = function () {
                setBackImagePreview(fileBackImage.result);
            }
        },
    });


    const { acceptedFiles: aseCertificateAcceptedFile, getRootProps: getRootASEProps, getInputProps: getInputASEProps, isDragActive: isASECertificteDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        maxFiles: 1,
        onDrop: (aseCertificateAcceptedFile) => {
            const fileASEImage = new FileReader;
            fileASEImage.readAsDataURL(aseCertificateAcceptedFile[0]);
            var fileType = aseCertificateAcceptedFile[0].type;
            fileASEImage.onload = function () {
                setASEImagePreview(fileASEImage.result);
            }
        },
    });


    const checkHandler = (event) => {
        SetIsTermsAndConditionChecked(!isTermsAndConditionChecked);
    }

    const handleClose = () => showModal({ show: false, message: null, isSuccess: null });

    const handleAgreementClose = () => setModalAgreement({ show: false, message: null });

    const handleAggreementClick = () => {
        if (modalAgreement.contractName == Agreements.IndependentContractor && agreement.independentContractorInputChecked) {
            setAgreement({ ...agreement, independentContractorSigned: true });
        } else if (modalAgreement.contractName == Agreements.InspectorAccountAccess && agreement.inspectorAccountAccessInputChecked) {
            setAgreement({ ...agreement, inspectorAccountAccessSigned: true });
        }
        else if (modalAgreement.contractName == Agreements.InspectorApplication && agreement.inspectorApplicationInputChecked) {
            setAgreement({ ...agreement, inspectorApplicationSigned: true });
        }
        else if (modalAgreement.contractName == Agreements.InspectorPrivacyPolicy && agreement.inspectorPrivacyPolicyInputChecked) {
            setAgreement({ ...agreement, inspectorPrivacyPolicySigned: true });
        }
        setModalAgreement({ show: false, message: null })
    }

    const validateInputs = () => {
        let errors = {};
        if (!agreement.independentContractorSigned) { errors.independentContractorSigned = "Please sign independent contract agreement" }
        if (!agreement.inspectorAccountAccessSigned) { errors.inspectorAccountAccessSigned = "Please sign inspector account guidelines" }
        if (!agreement.inspectorApplicationSigned) { errors.inspectorApplicationSigned = "Please sign inspector application and terms conditions" }
        if (!agreement.inspectorPrivacyPolicySigned) { errors.inspectorPrivacyPolicySigned = "Please sign inspector privacy policy" }
        if (!isValidSSN(ssn)) { errors.ssn = "Please enter valid Social Security Number" }
        if (!isValidEmail(email)) { errors.email = "Please enter valid email" }
        if (acceptedFiles.length == 0) { errors.frontImage = "Please select your license front image" }
        if (backAcceptedFile.length == 0) { errors.backImage = "Please select your license front image" }

        return errors;
    }

    const handleRedirectDashboard = () => {
        showModal({ show: false, message: null, isSuccess: null });
        navigate('/');
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        var error = validateInputs();
        setValidationError(error);
        if (Object.keys(error).length != 0) return;
        showLoader();
        if (typeof acceptedFiles[0] === 'undefined') return;
        if (typeof backAcceptedFile[0] === 'undefined') return;

        const formData = new FormData();
        formData.append('licenseFrontImage', acceptedFiles[0]);
        formData.append('licenseBackImage', backAcceptedFile[0]);
        formData.append('aseCertificationImage', aseCertificateAcceptedFile[0])
        formData.append('token', token);
        formData.append('ssn', ssn);
        formData.append('email', email);
        formData.append('independentContractorSigned', agreement.independentContractorSigned);
        formData.append('inspectorAccountAccessSigned', agreement.inspectorAccountAccessSigned);
        formData.append('inspectorApplicationSigned', agreement.inspectorApplicationSigned);
        formData.append('inspectorPrivacyPolicySigned', agreement.inspectorPrivacyPolicySigned);
        formData.append('aseExpiryDate', aseExpiryDate);

        const response = await axiosPrivate.post(`v1/inspector/confirm`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            hideLoader();
            showModal({ show: true, message: "Your application successfully submitted!", isError: false });
        }).catch(error => {
            hideLoader();
            showModal({ show: true, message: error.response.data.Error, isError: true });
        });
    }

    return <>
        <div>
            <div className="page-wrapper">
                <div className="page-content">
                    {<Modal show={modal.show} onHide={handleClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>{!modal.isError ? 'Result' : 'Error'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{modal.message}</Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-dark" onClick={!modal.isError ? handleRedirectDashboard : handleClose}>Close</button>
                        </Modal.Footer>
                    </Modal>}
                    {
                        <Modal show={modalAgreement.show} onHide={handleAgreementClose} centered size="xl">
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {modalAgreement.contractName == Agreements.IndependentContractor && "INDEPENDENT CONTRACTOR AGREEMENT"}
                                    {modalAgreement.contractName == Agreements.InspectorAccountAccess && "INSPECTOR ACCOUNT ACCESS GUIDELINES"}
                                    {modalAgreement.contractName == Agreements.InspectorApplication && "INSPECTOR APPLICATION TERMS AND  CONDITIONS"}
                                    {modalAgreement.contractName == Agreements.InspectorPrivacyPolicy && "INSPECTOR PRIVACY POLICY"}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
                                        {modalAgreement.contractName == Agreements.IndependentContractor && <IndependentContractor />}
                                        {modalAgreement.contractName == Agreements.InspectorAccountAccess && <InspectorAccountAccess />}
                                        {modalAgreement.contractName == Agreements.InspectorApplication && <InspectorApplication />}
                                        {modalAgreement.contractName == Agreements.InspectorPrivacyPolicy && <InspectorPrivacyPolicy />}
                                    </div>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        {modalAgreement.contractName == Agreements.IndependentContractor &&
                                            <> <input type="checkbox" className="form-check-input" id="independentContractor" checked={agreement.independentContractorInputChecked} onChange={() => { setAgreement({ ...agreement, independentContractorInputChecked: !agreement.independentContractorInputChecked }) }} />
                                                <span> I Accept terms and conditions</span>
                                            </>
                                        }
                                        {modalAgreement.contractName == Agreements.InspectorAccountAccess &&
                                            <> <input type="checkbox" className="form-check-input" id="inspectorAccountAccess" checked={agreement.inspectorAccountAccessInputChecked} onChange={() => { setAgreement({ ...agreement, inspectorAccountAccessInputChecked: !agreement.inspectorAccountAccessInputChecked }) }} />
                                                <span> I Accept terms and conditions</span>
                                            </>
                                        }
                                        {modalAgreement.contractName == Agreements.InspectorApplication &&
                                            <> <input type="checkbox" className="form-check-input" id="inspectorApplication" checked={agreement.inspectorApplicationInputChecked} onChange={() => { setAgreement({ ...agreement, inspectorApplicationInputChecked: !agreement.inspectorApplicationInputChecked }) }} />
                                                <span> I Accept terms and conditions</span>
                                            </>
                                        }

                                        {modalAgreement.contractName == Agreements.InspectorPrivacyPolicy &&
                                            <> <input type="checkbox" className="form-check-input" id="inspectorPrivacyPolicy" checked={agreement.inspectorPrivacyPolicyInputChecked} onChange={() => { setAgreement({ ...agreement, inspectorPrivacyPolicyInputChecked: !agreement.inspectorPrivacyPolicyInputChecked }) }} />
                                                <span> I Accept terms and conditions</span>
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="button" className="btn btn-danger"
                                    disabled={(modalAgreement.contractName == Agreements.IndependentContractor && !agreement.independentContractorInputChecked)
                                        || (modalAgreement.contractName == Agreements.InspectorAccountAccess && !agreement.inspectorAccountAccessInputChecked)
                                        || (modalAgreement.contractName == Agreements.InspectorApplication && !agreement.inspectorApplicationInputChecked)
                                        || (modalAgreement.contractName == Agreements.InspectorPrivacyPolicy && !agreement.inspectorPrivacyPolicyInputChecked)
                                    }
                                    onClick={handleAggreementClick}>Sign</button>
                                <button className="btn btn-dark" onClick={handleAgreementClose}>Close</button>
                            </Modal.Footer>
                        </Modal>
                    }
                    <section>
                        <Container>
                            <form onSubmit={onSubmit}>
                                <Row>
                                    <span style={{ marginBottom: "20px" }}>
                                        <h1>Inspector Onboarding</h1></span>
                                </Row>
                                <Row>
                                    <Col md={12} className="mt-3 mb-1">
                                        <span style={{ fontWeight: "700" }}>Please provide your Social Security Number (SSN), the email address used during signup, and your ASE certification expiration date below. </span>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup floating>
                                            <InputMask
                                                name="ssn"
                                                mask="999-99-9999"
                                                maskChar="_"
                                                value={ssn}
                                                onChange={e => setSSN(e.target.value)}
                                                className="form-control"
                                                placeholder="000-00-0000"
                                            />
                                            <Label for="ssn">Social Security Number</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup floating>
                                            <Input
                                                name="email"
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                                className="form-control"
                                                placeholder="Email"
                                            /> <Label for="ssn">Email</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup floating>
                                            <Input
                                                type="date"
                                                name="aseExpiryDate"
                                                className="form-control"
                                                value={aseExpiryDate}
                                                onChange={(e) => { setASEExpiryDate(e.target.value) }}
                                                placeholder="ASE Expiation Date" />
                                            <Label for="ssn">ASE Expiation Date</Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col md={12} className="mt-3 mb-1">
                                        <span style={{ fontWeight: "700" }}>Please upload clear pictures of the front and back of your driver's license, as well as your ASE Certificate, ensuring all information is legible. </span>
                                    </Col>
                                    <Col md={4} className="mt-3">
                                        <FormGroup>
                                            <Label for="driverLicenseFrontInput">Front of your driver's license</Label>
                                            <div name="driverLicenseFrontInput" className="dropzone" {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                {
                                                    acceptedFiles.length > 0 ? <p>{acceptedFiles.length} file selected</p> :
                                                        isDragActive ?
                                                            <p>Drop the files here ...</p> :
                                                            <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                }
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} className="mt-3">
                                        <FormGroup>
                                            <Label for="driverLicenseBackInput">Back of your driver's license</Label>
                                            <div name="driverLicenseBackInput" className="dropzone" {...getRootBackProps()}>
                                                <input type="file" {...getInputBackProps()} />
                                                {
                                                    backAcceptedFile.length > 0 ? <p>{backAcceptedFile.length} file selected</p> :
                                                        isBackDragActive ?
                                                            <p>Drop the files here ...</p> :
                                                            <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                }
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} className="mt-3">
                                        <FormGroup>
                                            <Label for="aseCertificateInput">ASE Certificate</Label>
                                            <div name="aseCertificateInput" className="dropzone" {...getRootASEProps()}>
                                                <input type="file" {...getInputASEProps()} />
                                                {
                                                    aseCertificateAcceptedFile.length > 0 ? <p>{aseCertificateAcceptedFile.length} file selected</p> :
                                                        isASECertificteDragActive ?
                                                            <p>Drop the files here ...</p> :
                                                            <p>Drag 'n' drop your image here, or click anywhere in this box to select files </p>
                                                }
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <img src={frontImagePreview} style={{ height: "250px", width: "100%" }} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <img src={backImagePreview} style={{ height: "250px", width: "100%" }} />
                                        </FormGroup>
                                    </Col>

                                    <Col md={4}>
                                        <FormGroup>
                                            <img src={ASEImagePreview} style={{ height: "250px", width: "100%" }} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <span><strong>Please complete your onboarding process by signing the required paperwork below.</strong></span>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={6}>
                                                Independent Contractor Agreement <a style={{ cursor: "pointer", color: "red" }} onClick={() => setModalAgreement({ show: true, contractName: Agreements.IndependentContractor})}> (Read and sign)</a>
                                            </Col>
                                            <Col md={6}>
                                                <input type="checkbox" className="form-check-input" id="independentContractor" checked={agreement.independentContractorSigned} disabled /> <span>{agreement.independentContractorSigned ? "Signed" : "Not Signed"}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={6}>
                                                Inspector Account Access Guidelines <a style={{ cursor: "pointer", color: "red" }} onClick={() => setModalAgreement({ show: true, contractName: Agreements.InspectorAccountAccess })}> (Read and sign)</a>
                                            </Col>
                                            <Col md={6}>
                                                <input type="checkbox" className="form-check-input" id="inspectorAccountAccess" checked={agreement.inspectorAccountAccessSigned} disabled /> <span>{agreement.inspectorAccountAccessSigned ? "Signed" : "Not Signed"}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={6}>
                                                Inspector Application Terms and Conditions <a style={{ cursor: "pointer", color: "red" }} onClick={() => setModalAgreement({ show: true, contractName: Agreements.InspectorApplication})}> (Read and sign)</a>
                                            </Col>
                                            <Col md={6}>
                                                <input type="checkbox" className="form-check-input" id="inspectorApplication" checked={agreement.inspectorApplicationSigned} disabled /> <span>{agreement.inspectorApplicationSigned ? "Signed" : "Not Signed"}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={6}>
                                                Inspector Privacy Policy <a style={{ cursor: "pointer", color: "red" }} onClick={() => setModalAgreement({ show: true, contractName: Agreements.InspectorPrivacyPolicy })}> (Read and sign)</a>
                                            </Col>
                                            <Col md={6}>
                                                <input type="checkbox" className="form-check-input" id="inspectorPrivacyPolicy" checked={agreement.inspectorPrivacyPolicySigned} disabled /> <span>{agreement.inspectorPrivacyPolicySigned ? "Signed" : "Not Signed"}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col md={10}>
                                        <div style={{ color: "red", fontSize: "0.9rem" }}>
                                            {Object.keys(validationError).length > 0 ? Object.values(validationError).map(item => <div className="row"><span>* {item}</span></div>) : null}
                                        </div>
                                    </Col>
                                    <Col md={1}>
                                        <button type="submit" className="btn btn-dark my-5">Submit</button>
                                    </Col>
                                </Row>
                            </form>
                        </Container>
                    </section>
                </div>
            </div>
        </div>
    </>
}

export default ConfirmInspector;