import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import HerosectionHeader from '../../Componet/Herosection/HerosectionHeader'
import { FORGOT_PASS } from '../../store/Utility/Resource';
import axios from '../../api/axios';
import Modal from 'react-bootstrap/Modal';
import { useSpinner } from '../../context/SpinnerContext';

const ForgotPassword = () => {
    const [email, setEmail] = useState();
    const [modal, showModal] = useState({ show: false, message: null });
    const { showLoader, hideLoader } = useSpinner();

    const handleClose = () => showModal({ show: false, message: null });

    const handleSubmit = async (e) => {
        e.preventDefault();
        showLoader();
        try {
            const response = await axios.post(FORGOT_PASS,
                JSON.stringify({ "email": email }),
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                });
            var res = response?.data;            
            showModal({show:true, message:'Your password reset request has been sent to your email!'});
            hideLoader();
        } catch (err) {
            showModal({ show: true, message: err.response?.data.Error});
            hideLoader();
            if (!err?.response) {
                // setErrMsg('No server response');
            } else if (err.response?.status === 400) {
                // setErrMsg('Missing username or password');
            } else if (err.response?.status === 401) {
                // setErrMsg('UnAuthorized')
            } else {
                // setErrMsg('Login Failed');
            }
            // err.current.focus();

        }
    }


    return (
        <>
            <HerosectionHeader folder1={"pages"} folder2={"Account"} name={"Forgot Password"} />
            <div class="page-content">
                {<Modal show={modal.show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Result</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modal.message}</Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-dark" onClick={handleClose}>Close</button>
                    </Modal.Footer>
                </Modal>}
                <section>
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-lg-5">
                                <div>
                                    <div className="mb-5">
                                        <h2>Forgot your password?</h2>
                                        <p>Enter your email to reset your password.</p>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="messages" />
                                        <div className="form-group">
                                            <input id="email"
                                                type="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="Email"
                                                required="required"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                data-error="Valid email is required." />
                                            <div className="help-block with-errors" />
                                        </div>
                                        <button className="btn btn-dark btn-block">Reset Password</button>
                                    </form>
                                    <div className="mt-4">
                                        <Link className="link-title" to="/sign_in">Back to sign in</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default ForgotPassword;