import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { LOGIN_URL } from '../../store/Utility/Resource';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import SpinnerButton from '../../Componet/Button/SpinnerButton';
import {  useGoogleReCaptcha } from 'react-google-recaptcha-v3';


const Login = () => {

    const { setAuth } = useAuth();
    const navigate = useNavigate();

    const location = useLocation();
    const from = location.state?.from.pathname || "/";

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { executeRecaptcha } = useGoogleReCaptcha();

  
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading) return;
        try {
            // showLoader();
            setLoading(true);
            const recaptchaToken=await executeRecaptcha("login");

            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ "email": username, "password": password, recaptchaToken: recaptchaToken }),
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                });
            var res = response?.data;
            setAuth({
                user: {
                    id: res.data.id,
                    username,
                    access_token: res.data.accessToken,
                    refresh_token:res.data.refreshToken,
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    roles: res.data.roles,
                    profile: res.data.profileImageUrl
                }
            });
            setUserName('');
            setPassword('');
            // hideLoader();
            setLoading(false);
            navigate("/dashboard", { replace: true });
        } catch (err) {
            // hideLoader();
            setLoading(false);
            if (err.code = 'ERR_NETWORK') {
                setError("Server error!");
            }
            if (!err?.response) {
                // setErrMsg('No server response');
            } else if (err.response?.status == 500) {
                setError(err.response?.data.Error);
            }

            else if (err.response?.status === 400) {
                setError(err.response?.data.Error);
            } else if (err.response?.status === 401) {
                // setErrMsg('UnAuthorized')
            } else {
                // setErrMsg('Login Failed');
            }
            // err.current.focus();

        }
    }

    return (
        <div className="page-content">
            <div>
                <div>
                    <div>
                        <section>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-7 col-12">
                                        <img className="img-fluid" src={require("../../assets/images/login_v1.png")} alt="" />
                                    </div>
                                    <div className="col-lg-5 col-12">
                                        <div>
                                            <h2 className="mb-3">Sign In</h2>
                                            <form onSubmit={handleSubmit}>
                                                <div className="messages"></div>
                                                <div className="form-group">
                                                    <input
                                                        id="username"
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder="User name"
                                                        onChange={(e) => setUserName(e.target.value)}
                                                        value={username}
                                                        required="required"
                                                        data-error="Username is required." />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        id="password"
                                                        type="password"
                                                        name="password"
                                                        className="form-control"
                                                        placeholder="Password"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        value={password}
                                                        required="required"
                                                        data-error="password is required." />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                                <div className="form-group mt-4 mb-5">
                                                    <div className="remember-checkbox d-flex align-items-center justify-content-between">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="check1" />
                                                            <label className="form-check-label" for="check1">Remember me</label>
                                                        </div> <Link to="/forgot-password">Forgot Password?</Link>
                                                    </div>
                                                </div>
                                                {/* <button className="btn btn-primary">Login Now</button> */}
                                                {error && <div>
                                                    <div class="alert alert-warning alert-dismissible fade show" role="alert">
                                                        {error}
                                                        <button type="button" class="btn-close" onClick={() => setError(null)} data-dismiss="alert" aria-label="Close">

                                                        </button>
                                                    </div>
                                                </div>}
                                                <SpinnerButton type="submit" text="Login Now" loading={loading} loadingText="Logging in" />
                                            </form>
                                            {/* <div className="d-flex align-items-center mt-4"> <span className="text-muted me-1">Don't have an account?</span>
                                                <Link to="/sign_up">Sign Up</Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
