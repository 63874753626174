import React, { useEffect, useState } from "react";
import HerosectionHeader from '../../../../Componet/Herosection/HerosectionHeader'
import { Col, Container, FormGroup, Input, Row } from "reactstrap";
import { Link } from "react-router-dom";
import useAuth from '../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { DashboardMessage, Roles } from "../../../../store/Utility/Utility";
import moment from "moment";

const Stats = () => {
    const [messages, SetMessages] = useState();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [paymentStats, SetPaymentStats] = useState({});

    const GetMessages = async () => {
        await axiosPrivate.get(`v1/dashboard/messages`)
            .then(res => {
                var data = res.data.data;
                if (!auth?.user?.profile) {
                    data.push({ key: 'ProfileImageMissing' });
                }
                SetMessages(data);
            });
    }

    const getPaymentStats = async () => {
        await axiosPrivate.get(`v1/inspector/stats/payments/${auth?.user.id}`)
            .then(res => {
                SetPaymentStats(res.data.data);
            });
    }

    useEffect(() => {
        if (auth?.user?.roles.indexOf(Roles.VehicleInspector) != -1) {
            // GetMessages();
            getPaymentStats();
        }
    }, [])


    return <>
        <HerosectionHeader folder1={"pages"} folder2={"Company"} name={"My Stats"} />
        <div className="page-wrapper">
            <div className="page-content">
                <section>
                    <Container>
                        <Row className="mb-3">

                            <div class="col-12 col-sm-6 col-xl-4">
                                <div class="card widget-card border-light shadow-sm">
                                    <div class="card-body p-4">
                                        <div class="row">
                                            <div class="col-8">
                                                <h5 class="card-title widget-card-title mb-3">Balance</h5>
                                                <h4 class="card-subtitle text-body-secondary m-0">{paymentStats?.balance ? '$' + paymentStats?.balance?.toFixed(2):'N/A'}</h4>
                                            </div>
                                            <div class="col-4">
                                                <div class="d-flex justify-content-end">
                                                    <div class="lh-1 text-white bg-primary rounded-circle p-3 d-flex align-items-center justify-content-center">
                                                        <i class="las la-money-bill fs-4"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-4">
                                <div class="card widget-card border-light shadow-sm">
                                    <div class="card-body p-4">
                                        <div class="row">
                                            <div class="col-8">
                                                <h5 class="card-title widget-card-title mb-3">Payments</h5>
                                                <h4 class="card-subtitle text-body-secondary m-0">{paymentStats?.totalPaidAmount ?'$' + paymentStats?.totalPaidAmount?.toFixed(2):'N/A'}</h4>
                                            </div>
                                            <div class="col-4">
                                                <div class="d-flex justify-content-end">
                                                    <div class="lh-1 text-white bg-primary rounded-circle p-3 d-flex align-items-center justify-content-center">
                                                        <i class="las la-file-invoice-dollar fs-4"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-4">
                                <div class="card widget-card border-light shadow-sm">
                                    <div class="card-body p-4">
                                        <div class="row">
                                            <div class="col-8">
                                                <h5 class="card-title widget-card-title mb-3">History</h5>
                                                <h4 class="card-subtitle text-body-secondary m-0">{paymentStats?.paymentCount ? paymentStats?.paymentCount : 'N/A'}</h4>
                                            </div>
                                            <div class="col-4">
                                                <div class="d-flex justify-content-end">
                                                    <div class="lh-1 text-white bg-primary rounded-circle p-3 d-flex align-items-center justify-content-center">
                                                        <i class="las la-list fs-4"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div class="col-12 col-sm-6 col-xl-4">
                                <div class="card widget-card border-light shadow-sm">
                                    <div class="card-body p-4">
                                        <div class="row">
                                            <div class="col-8">
                                                <h5 class="card-title widget-card-title mb-3">Completion Rate</h5>
                                                <h4 class="card-subtitle text-body-secondary m-0">N/A</h4>
                                            </div>
                                            <div class="col-4">
                                                <div class="d-flex justify-content-end">
                                                    <div class="lh-1 text-white bg-primary rounded-circle p-3 d-flex align-items-center justify-content-center">
                                                        <i class="las la-percentage fs-4"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-4">
                                <div class="card widget-card border-light shadow-sm">
                                    <div class="card-body p-4">
                                        <div class="row">
                                            <div class="col-8">
                                                <h5 class="card-title widget-card-title mb-3">Inspection Speed</h5>
                                                <h4 class="card-subtitle text-body-secondary m-0">N/A</h4>
                                            </div>
                                            <div class="col-4">
                                                <div class="d-flex justify-content-end">
                                                    <div class="lh-1 text-white bg-primary rounded-circle p-3 d-flex align-items-center justify-content-center">
                                                        <i class="las la-clock fs-4"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-4">
                                <div class="card widget-card border-light shadow-sm">
                                    <div class="card-body p-4">
                                        <div class="row">
                                            <div class="col-8">
                                                <h5 class="card-title widget-card-title mb-3">Rating</h5>
                                                <h4 class="card-subtitle text-body-secondary m-0">N/A</h4>
                                            </div>
                                            <div class="col-4">
                                                <div class="d-flex justify-content-end">
                                                    <div class="lh-1 text-white bg-primary rounded-circle p-3 d-flex align-items-center justify-content-center">
                                                        <i class="las la-star fs-4"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </section>
            </div>
        </div>
    </>
}

export default Stats;