import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import { AuthProvider } from './auth/AuthContext';
import { AuthProvider } from './context/AuthProvider';
import App from './App';
import AppBecomeInspector from './AppBecomeInspector';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore, persistReducer } from "redux-persist"; // Import persistStore and persistReducer
import storage from "redux-persist/lib/storage"; // Import the storage option
import rootReducer from "../src/store/reducer/rootReducer";
const persistConfig = {
  key: "root",
  storage,
};



// Create the persistedReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);


// Create the Redux store
const store = configureStore({
  reducer: persistedReducer, // Use the persistedReducer
});

// Create the persisted store
const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthProvider>
        <BrowserRouter>
            <AppBecomeInspector/>
        </BrowserRouter>
      </AuthProvider>
    </PersistGate>
  </Provider>
);

