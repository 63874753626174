import React, { useState } from 'react'
import { Collapse } from 'reactstrap';
import HerosectionHeader from '../../../Componet/Herosection/HerosectionHeader'

const FAQs=()=>{
    const [openAccordion, setOpenAccordion] = useState(1);

    const toggleAccordion = (accordionIndex) => {
        setOpenAccordion(openAccordion === accordionIndex ? null : accordionIndex);
    };
    return(<div>
        <HerosectionHeader folder1={"pages"} folder2={"Company"} name={"FAQ"} />
        <section>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-12 col-lg-12 col-xl-12">
                            <div className="accordion" id="accordion">
                                <div className="accordion-item rounded mb-2">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button border-0 mb-0 bg-transparent"
                                            color="link"
                                            onClick={() => toggleAccordion(1)}
                                            aria-expanded={openAccordion === 1}
                                            aria-controls="collapseOne"
                                        >
                                            When do i need to complete the inspection?
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openAccordion === 1} aria-labelledby="headingOne" data-bs-parent="#accordion">
                                        <div className="accordion-body text-muted">
                                        We recommend completing the inspection as soon as possible. 
                                        If not done within 48 hours, it will be reassigned to another inspector. 
                                        Failure to complete within this timeframe may result in a loss of access privileges to future inspections.
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="accordion-item rounded mb-2">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button border-0 mb-0 bg-transparent"
                                            color="link"
                                            onClick={() => toggleAccordion(2)}
                                            aria-expanded={openAccordion === 2}
                                            aria-controls="collapseTwo"
                                        >
                                            What should i do first when i accept an order?
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openAccordion === 2} aria-labelledby="headingTwo" data-bs-parent="#accordion">
                                        <div className="accordion-body text-muted">
                                        Upon accepting the order, your first action should be immediate contact with the seller. 
                                        Arrange an inspection time that accommodates both parties as soon as possible, ensuring it occurs within 48 hours of the acceptance of the inspection.
                                        Also kindly remind them to have the vehicle cleaned before inspection time.                                 </div>
                                    </Collapse>
                                </div>
                                <div className="accordion-item rounded mb-2">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button border-0 mb-0 bg-transparent"
                                            color="link"
                                            onClick={() => toggleAccordion(3)}
                                            aria-expanded={openAccordion === 3}
                                            aria-controls="collapseThree"
                                        >
                                            How many inspections can I queue?
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openAccordion === 3} aria-labelledby="headingThree" data-bs-parent="#accordion">
                                        <div className="accordion-body text-muted">
                                        Our system operates on a tiered program. Initially, at the starter stage, you're granted the ability to queue up to 5 inspections. 
                                        Once you've completed 10 inspections, your capacity increases to queue 7. 
                                        Achieving an expert level after 30 inspections elevates your capacity to queue up to 10 inspections simultaneously.
                                        <p>Remember, timely completion within 48 hours of acceptance is vital to unlock additional queueing capabilities. 
                                        Failure to complete any inspection within this timeframe may result in a loss of access to the platform.</p>
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="accordion-item rounded mb-2">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button border-0 mb-0 bg-transparent"
                                            color="link"
                                            onClick={() => toggleAccordion(4)}
                                            aria-expanded={openAccordion === 4}
                                            aria-controls="collapseFour"
                                        >
                                            What devices and tools do i need to bring to inspection?
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openAccordion === 4} aria-labelledby="headingFour" data-bs-parent="#accordion">
                                        <div className="accordion-body text-muted">
                                        To conduct a thorough inspection, consider have these essential devices and tools:
                                        <p>-ODB2 Scanner</p>
                                        <p>-Paint Thickness Gauge</p>
                                        <p>-Tire Tread Gauge</p>
                                        <p>-Flaslight</p>
                                        <p>-Pointer for showing the area need attention in picture (white and black for different colored backgrounds) 
                                            you can use any plastic or soft material that won't damage the vehicle</p>
                                        <p>-Mirror on a stick for hard to reach areas</p>
                                        <p>-If available; Jack, Jack Stands, Car Ramp or Lift</p>
                                        <p>-Personal Safety Gear</p>
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="accordion-item rounded mb-2">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className="accordion-button border-0 mb-0 bg-transparent"
                                            color="link"
                                            onClick={() => toggleAccordion(5)}
                                            aria-expanded={openAccordion === 5}
                                            aria-controls="collapseFive"
                                        >
                                            Do i need to get under the car for inspection?
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openAccordion === 5} aria-labelledby="headingFive" data-bs-parent="#accordion">
                                        <div className="accordion-body text-muted">
                                        If you have essential tools like a jack, jack stands, a car ramp, or access to lift of yours or seller ensuring safety underneath the car, then it's safe to inspect from below. 
                                        However, if these tools aren't available, avoid going beneath the car for inspection. 
                                        Instead, utilize a mirror on a stick to examine under the car.
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="accordion-item rounded mb-2">
                                    <h2 className="accordion-header" id="headingSix">
                                        <button
                                            className="accordion-button border-0 mb-0 bg-transparent"
                                            color="link"
                                            onClick={() => toggleAccordion(6)}
                                            aria-expanded={openAccordion === 6}
                                            aria-controls="collapseSix"
                                        >
                                            How many pictures do i need to take for inspection?                
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openAccordion === 6} aria-labelledby="headingSix" data-bs-parent="#accordion">
                                        <div className="accordion-body text-muted">
                                        You need to take a minimum of 30 pictures to provide a comprehensive view of the car, ensuring you highlight all details, including any issues. 
                                        Feel free to take additional photos beyond 30 to thoroughly showcase all aspects.
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="accordion-item rounded mb-2">
                                    <h2 className="accordion-header" id="headingSeven">
                                        <button
                                            className="accordion-button border-0 mb-0 bg-transparent"
                                            color="link"
                                            onClick={() => toggleAccordion(7)}
                                            aria-expanded={openAccordion === 7}
                                            aria-controls="collapseSeven"
                                        >
                                            How long does an inspection take?               
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openAccordion === 7} aria-labelledby="headingSeven" data-bs-parent="#accordion">
                                        <div className="accordion-body text-muted">
                                        An inspection typically takes between 30 to 45 minutes. 
                                        However, for some vehicles, it can extend up to an hour depending on the complexity and specific details needing examination.
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="accordion-item rounded mb-2">
                                    <h2 className="accordion-header" id="headingEight">
                                        <button
                                            className="accordion-button border-0 mb-0 bg-transparent"
                                            color="link"
                                            onClick={() => toggleAccordion(8)}
                                            aria-expanded={openAccordion === 8}
                                            aria-controls="collapseEight"
                                        >
                                            How much will I get paid for completing an inspection?               
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openAccordion === 8} aria-labelledby="headingEight" data-bs-parent="#accordion">
                                        <div className="accordion-body text-muted">
                                        The payment for completing an inspection varies according to the vehicle type. 
                                        You will get paid $70 for cars and motorcycles, $80 for trucks and  $100 for RVs.
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="accordion-item rounded mb-2">
                                    <h2 className="accordion-header" id="headingNine">
                                        <button
                                            className="accordion-button border-0 mb-0 bg-transparent"
                                            color="link"
                                            onClick={() => toggleAccordion(9)}
                                            aria-expanded={openAccordion === 9}
                                            aria-controls="collapseNine"
                                        >
                                            When will i get paid?               
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openAccordion === 9} aria-labelledby="headingNine" data-bs-parent="#accordion">
                                        <div className="accordion-body text-muted">
                                        inspectiver will process your payment for the previous week on the following Monday. 
                                        Please note, depending on your bank, it may take up to 2 days for the payment to be reflected in your account.
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="accordion-item rounded mb-2">
                                    <h2 className="accordion-header" id="headingTen">
                                        <button
                                            className="accordion-button border-0 mb-0 bg-transparent"
                                            color="link"
                                            onClick={() => toggleAccordion(10)}
                                            aria-expanded={openAccordion === 10}
                                            aria-controls="collapseTen"
                                        >
                                            What happens if i cancel an inspection?              
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openAccordion === 10} aria-labelledby="headingTen" data-bs-parent="#accordion">
                                        <div className="accordion-body text-muted">
                                        After accepting an order, we strongly advise promptly contacting the seller to confirm and agree upon an inspection date and time. 
                                        If you anticipate being unable to complete the order within the specified time, you will have a one-hour window after acceptance to cancel 
                                        the inspection without any impact on your account. However, cancelling an order beyond this timeframe may affect your access tier and associated privileges for future inspections. 
                                        We recommend accepting orders only if you are confident in your ability to complete them within the next 48 hours.
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="accordion-item rounded mb-2">
                                    <h2 className="accordion-header" id="headingEleven">
                                        <button
                                            className="accordion-button border-0 mb-0 bg-transparent"
                                            color="link"
                                            onClick={() => toggleAccordion(11)}
                                            aria-expanded={openAccordion === 11}
                                            aria-controls="collapseEleven"
                                        >
                                            What should I do if I can't reach/contact the seller?              
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openAccordion === 11} aria-labelledby="headingEleven" data-bs-parent="#accordion">
                                        <div className="accordion-body text-muted">
                                        Your patience in reaching out to the seller is appreciated. 
                                        If you're unable to establish contact within the initial 12 business hours, kindly reach out to our support team using the subject line 'Can't Reach Seller.' 
                                        We'll promptly intervene by contacting both the customer and seller to ensure they are reachable by phone.
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="accordion-item rounded mb-2">
                                    <h2 className="accordion-header" id="headingTwelve">
                                        <button
                                            className="accordion-button border-0 mb-0 bg-transparent"
                                            color="link"
                                            onClick={() => toggleAccordion(12)}
                                            aria-expanded={openAccordion === 12}
                                            aria-controls="collapseTwelve"
                                        >
                                            What should I do if I accidentally close the report creation page while conducting an inspection?             
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openAccordion === 12} aria-labelledby="headingTwelve" data-bs-parent="#accordion">
                                        <div className="accordion-body text-muted">
                                        The report creation page features an autosave function. 
                                        If you happen to lose access to the report accidentally, simply return to the page, and you'll seamlessly resume from where you left off.
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="accordion-item rounded">
                                    <h2 className="accordion-header" id="headingThirteen">
                                        <button
                                            className="accordion-button border-0 mb-0 bg-transparent"
                                            color="link"
                                            onClick={() => toggleAccordion(13)}
                                            aria-expanded={openAccordion === 13}
                                            aria-controls="collapseThirteen"
                                        >
                                            How can I reach out to you regarding issues that I can't find information about here?            
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openAccordion === 13} aria-labelledby="headingThirteen" data-bs-parent="#accordion">
                                        <div className="accordion-body text-muted">
                                        You can easily reach us by submitting a support form, utilizing our web chat feature, or simply giving us a call.
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>)
}

export default FAQs;