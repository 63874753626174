import React from 'react'
import { Link } from 'react-router-dom'

function Signupcomponet() {
  return (
    <div>
      <section className="register">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-8 col-md-12">
              <div className="mb-5">
                <h2><span className="font-w-4">Sign up to have quick access to your inspections anytime.</span> </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-10 ms-auto me-auto">
              <div className="register-form text-center">
                <form id="contact-form" method="post" action="php/contact.php">
                  <div className="messages"></div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input id="form_name" type="text" name="name" className="form-control" placeholder="First name" required="required" data-error="Firstname is required." />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input id="form_lastname" type="text" name="surname" className="form-control" placeholder="Last name" required="required" data-error="Lastname is required." />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input id="form_email" type="email" name="email" className="form-control" placeholder="Email" required="required" data-error="Valid email is required." />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input id="form_phone" type="tel" name="phone" className="form-control" placeholder="Phone" required="required" data-error="Phone is required" />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input id="form_password" type="password" name="password" className="form-control" placeholder="Password" required="required" data-error="password is required." />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input id="form_password1" type="password" name="password" className="form-control" placeholder="Confirm Password" required="required" data-error="Conform Password is required." />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <div className="remember-checkbox clearfix mb-4">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input float-none" id="customCheck1" />
                          <label className="form-check-label" for="customCheck1">I agree to the term of use and privacy policy</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <a href="/" className="btn btn-dark">Create Account</a>
                      <span className="mt-4 d-block">Have An Account ? <Link to="/sign_in"><i>Sign In!</i></Link></span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Signupcomponet
